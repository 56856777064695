var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("icon-library-wrapper  " + (_vm.isConvertingInProgress || _vm.localIconUploading ? 'item-disabled' : ''))},[_c('v-tabs',{class:("library-tabs " + (this.noOfTabsEnabled)),attrs:{"centered":"","grow":"","mobile-breakpoint":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [(_vm.isFlatIconsLibraryEnabled)?_c('v-tab',_vm._g(_vm._b({staticClass:"tab-item flat_icons-library-tab--auto",attrs:{"href":'#' + _vm.flat_icons},on:{"click":function($event){return _vm.setTab(_vm.flat_icons)}}},'v-tab',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('generate.library'))+" ")]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('generate.library')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [(_vm.isBrandIconsEnabled)?_c('v-tab',_vm._g(_vm._b({staticClass:"tab-item company-sourced-tab--auto",attrs:{"href":'#' + _vm.brand},on:{"click":function($event){return _vm.setTab(_vm.brand)}}},'v-tab',attrs,false),on),[_vm._v(" "+_vm._s(_vm.companyDisplayName)+" ")]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.companyDisplayName))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [(_vm.isCustomIconUploadEnabled)?_c('v-tab',_vm._g(_vm._b({staticClass:"tab-item upload-image-tab--auto",attrs:{"href":'#' + _vm.local},on:{"click":function($event){return _vm.setTab(_vm.local)}}},'v-tab',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('common.upload'))+" ")]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('common.upload')))])]),_c('v-tabs-items',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[(_vm.isFlatIconsLibraryEnabled)?_c('v-tab-item',{attrs:{"value":_vm.flat_icons}},[_c('FlatIconsLibrary',{staticClass:"icon-library-item ml-2",attrs:{"selectedIconData":_vm.currentSelectedSlideIconData,"slideData":_vm.slideData,"isSearchActionTriggerredFromBrandIcons":_vm.isSearchActionTriggerredFromBrandIcons,"brandIconsSearchKey":_vm.brandIconsSearchKey,"isGenerate":_vm.isGenerate},on:{"iconSelected":function($event){return _vm.iconSelected($event)},"iconSearch":function($event){return _vm.iconSearch($event)}}})],1):_vm._e(),(_vm.isBrandIconsEnabled)?_c('v-tab-item',{attrs:{"value":_vm.brand}},[_c('BrandIcons',{staticClass:"icon-library-item ml-2",attrs:{"selectedIconData":_vm.currentSelectedSlideIconData,"onTryFlatIconsSearch":_vm.onTryFlatIconsSearch,"isFlatIconsLibraryEnabled":_vm.isFlatIconsLibraryEnabled,"slideData":_vm.slideData,"isGenerate":_vm.isGenerate},on:{"iconSelected":function($event){return _vm.iconSelected($event)},"iconSearch":function($event){return _vm.iconSearch($event)}}})],1):_vm._e(),(_vm.isCustomIconUploadEnabled)?_c('v-tab-item',{attrs:{"value":_vm.local}},[_c('IconUpload',{staticClass:"icon-library-item ml-2",attrs:{"selectedIconData":_vm.currentSelectedSlideIconData},on:{"iconSelected":function($event){return _vm.iconSelected($event)}}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }