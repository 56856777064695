var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.getIsLoadingInRepIcon)?_c('div',{staticClass:"text-center loading-spinner"},[_c('v-progress-circular',{attrs:{"size":50,"width":2,"color":"primary","indeterminate":""}})],1):_c('v-row',{attrs:{"no-gutters":""}},[(
        _vm.filteredIcons &&
        _vm.filteredIcons.length > 0 &&
        !_vm.isErrorLoadingIconReplaceData
      )?[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"replace-icon pl-1"},[_c('div',{staticClass:"header text-subtitle-1 text-left font-weight-bold"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('replaceIcon.selectIconReplace'))+" ")])]),(_vm.filteredIcons && _vm.filteredIcons.length > 0)?_c('div',{class:("select-icon my-1 " + (_vm.isConvertingInProgress || _vm.isLocalIconUploading
                ? 'item-disabled'
                : '') + " "),attrs:{"justify":"center","align":"center"}},[_c('v-slide-group',{attrs:{"show-arrows":"always"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.filteredIcons),function(item){return _c('v-slide-item',{key:item.imageIndex,class:("" + (item.seq_id ===
                  (_vm.currentSelectedSlideIconData &&
                    _vm.currentSelectedSlideIconData.seq_id)
                    ? 'selected'
                    : '')),scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var toggle = ref.toggle;
return [(!item.invalid)?_c('v-img',{staticClass:"select-icon__item ma-2",attrs:{"max-height":"80","max-width":"100","height":"80","contain":"","src":item.imgUrl,"lazy-src":"/assets/img/slides/placeholder-slide.svg"},on:{"click":function($event){return _vm.onIconSelected(item, toggle)}}}):_vm._e()]}}],null,true)})}),1)],1):_vm._e()])]),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.getIsLoadingInRepIcon && _vm.currentSelectedSlideIconData)?_c('IconLibrary',{key:_vm.iconLibraryComponentKey,attrs:{"currentSelectedSlideIconData":_vm.currentSelectedSlideIconData,"isConvertingInProgress":_vm.isConvertingInProgress,"slideData":_vm.selectedSlide},on:{"iconSelected":function($event){return _vm.onReplaceIcon($event)},"onIconSearch":_vm.onIconSearch}}):_vm._e()],1)]:_c('div',{staticClass:"no-icon"},[_c('img',{attrs:{"src":'/assets/img/generate/slide-with-no-visuals.svg',"alt":"image","contain":""}}),_c('div',{staticClass:"desc"},[_c('div',[_vm._v(_vm._s(_vm.$t(_vm.errorMessageLoadingIconReplaceData)))])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }