<template>
  <div class="synthesisActionModalContainer">
    <div class="closeIconContainer">
      <v-icon medium @click="handleCloseModal">{{ 'mdi-close' }}</v-icon>
    </div>
    <div class="headingContainer">
      {{ heading }}
    </div>
    <div class="content">
      <div
        class="nameEditorAndLabel"
        v-if="
          modalType === 'download' ||
          modalType === 'slidesLibrary' ||
          (modalType === 'favorites' && !isAddedToLibrary)
        "
      >
        <div class="label">
          {{ $t('generate.typeLabel', { type: capitalize(type) }) }}
        </div>
        <div class="textField">
          <v-text-field v-model="slideName" :readonly="operations">
            <template #append>
              <v-icon
                v-if="slideName.length > 0 && !operations"
                medium
                @click="clearSlideName"
                color="#21a7e0"
                :placeholder="$t('generate.enterSlideName')"
              >
                {{ 'mdi-close-circle' }}
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </div>
      <div
        v-if="modalType === 'favorites' && isAddedToLibrary"
        class="favoritesText"
      >
        {{ $t('generate.actionLabel', { typeLabel: type }) }}
      </div>
      <div
        class="add-prez-lib"
        v-if="!isAddedToLibrary && modalType === 'download'"
      >
        <v-checkbox
          v-model="addToLibrary"
          :label="
            type === 'slide'
              ? $t('generate.addSlideToLibrary')
              : $t('generate.addPrezentationToLibrary')
          "
          color="#20a7e0"
          hide-details
        />
      </div>
    </div>
    <div
      class="actions"
      :style="modalType === 'download' ? { margin: '0px 0px 12px 0px' } : {}"
    >
      <div
        v-if="!operations || operation.loading"
        class="buttons"
        :style="modalType === 'download' ? { width: '40%' } : {}"
      >
        <v-btn class="cancelBtn" @click="handleCloseModal">{{
          $t('common.cancel')
        }}</v-btn>
        <!-- !-->
        <Download
          v-if="modalType === 'download'"
          :file-name="getFilename"
          :item="{ ...downloadItem, name: `${slideName}` }"
          :downloading="isDownloadingFileFromSnackbar"
          :log-download="logDownload"
          :count-first-time="1"
          :on-download="() => handleSlideDownload()"
          origin="slide"
          limitsKey="slide"
          :isDisabled="isDownloadingFileFromSnackbar || slideName.length === 0"
          :speedDialDirection="'top'"
          :speedDialPosTop="'-80'"
          :speedDialPosRight="'0'"
          :closePreviousPopups="
            () => closePreviousPopups(slideName, addToLibrary)
          "
          :allowDownloadSettingsIcon="false"
        />
        <!-- !-->
        <v-btn
          v-else
          class="primaryBtn"
          :disabled="(!isAddedToLibrary && slideName === '') || operations"
          @click="handlePrimaryButtonAction"
          :loading="operation.loading"
        >
          {{ primaryText }}
        </v-btn>
      </div>
      <div
        v-if="operations && operation.success && !operation.loading"
        class="success"
      >
        <v-icon color="#20a7e0">mdi-check</v-icon
        ><span>{{ successMessage }}</span>
      </div>
      <div
        v-if="operations && !operation.success && !operation.loading"
        class="success"
      >
        <v-icon color="#20a7e0" size="18">mdi-alert-outline</v-icon
        ><span>{{
          modalType === 'favorites'
            ? $t('premiumFeature.addToFavoritesFailed')
            : $t('premiumFeature.addToLibraryfailed')
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Download from '../../common/Download.vue';

export default {
  name: 'SynthesizeActionModal',
  data: () => ({
    slideName: '',
    primaryText: '',
    operations: false,
    addToLibrary: true,
    // downloading: false,
  }),
  props: {
    heading: {
      type: String,
      default: '',
    },
    primaryBtnText: {
      type: String,
      default: '',
    },
    modalType: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'slide',
    },
    operation: {
      type: Object,
      default: () => null,
    },
    isAddedToLibrary: {
      type: Boolean,
      default: false,
    },
    primaryBtnAction: {
      type: Function,
      default: () => {},
    },
    filename: {
      type: String,
      default: '',
    },
    downloadItem: {
      type: Object,
      default: () => ({}),
    },
    logDownload: {
      type: Function,
      default: () => {},
    },
    closePreviousPopups: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Download,
  },
  computed: {
    ...mapGetters('commonDownloads', ['getDownloadLoaders']),
    isDownloadingFileFromSnackbar() {
      const ldr = this.getDownloadLoaders.find(
        (ld) => ld.loaderId === this.downloadItem?.downloadLoaderId,
      );
      return ldr && ldr.downloading;
    },
    successMessage() {
      if (this.modalType === 'favorites') {
        return this.$t('premiumFeature.addedToFavorites');
      }
      if (this.modalType === 'download') {
        return this.$t('premiumFeature.downloaded');
      }
      return this.$t('premiumFeature.addedToLibrary');
    },
    getFilename() {
      return this.slideName || this.filename;
    },
  },
  methods: {
    clearSlideName() {
      this.slideName = '';
    },
    capitalize(s) {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    },

    formatProperty(str) {
      return str.charAt(0) === '/' ? str : `/${str}`;
    },

    async handlePrimaryButtonAction() {
      await this.primaryBtnAction(
        this.slideName,
        this.modalType === 'favorites',
        this.addToLibrary,
      );
      if (
        this.modalType === 'favorites' ||
        this.modalType === 'slidesLibrary'
      ) {
        // this.primaryText =
        //   this.modalType === 'favorites'
        //     ? 'Added to Favorites'
        //     : 'Added to Library';
        this.operations = true;
      }
    },

    handleCloseModal() {
      this.$modal.hide('SynthesizeActionModal');
    },

    async handleSlideDownload() {
      // this.downloading = true;
      await this.primaryBtnAction(
        this.slideName,
        this.modalType === 'favorites',
        this.addToLibrary,
      );
      this.operations = true;
      // this.downloading = false;
    },
  },
  mounted() {
    this.primaryText = this.primaryBtnText;
    this.slideName = this.filename;
  },
};
</script>

<style lang="scss" scoped>
.synthesisActionModalContainer {
  padding: 20px;
  .closeIconContainer {
    position: absolute;
    right: 10px;
    top: 10px;
    .v-icon {
      cursor: pointer;
    }
  }
  .headingContainer {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: normal;
    margin: 0px 0px 20px 0px;
  }
  .content {
    margin: 0px 0px 24px 0px;
    .add-prez-lib {
      ::v-deep .v-input {
        margin: 0 !important;
        padding: 0 !important;
        .v-input__control {
          .v-input__slot {
            .v-label {
              color: #000000;
              padding: 0px 0px 0px 10px;
            }
          }
        }
      }
    }
    .nameEditorAndLabel {
      .label {
        font-weight: 600;
      }
      .textField {
        margin: 0px 0px 0px 0px;
        .v-input {
          margin: 0;
          padding: 0;
        }
      }
    }
    .favoritesText {
      font-size: 16px;
      margin: 0px 0px 16px 0px;
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    .buttons {
      // width: 34%;
      display: flex;
      justify-content: space-between;
      .cancelBtn,
      .primaryBtn {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 8px 18px;
        outline: none;
        letter-spacing: normal;
        text-transform: none;
        border-radius: 20px;
        margin-left: 15px;
        font-weight: bold;
      }
      .cancelBtn {
        background-color: #ffffff;
        border: 1px solid #21a7e0;
        color: #21a7e0;
        font-weight: bold;
      }
      .primaryBtn {
        background-color: #21a7e0;
        color: #ffffff;
      }
      ::v-deep .download-actions {
        .new-slide-detail-speed {
          .download {
            border-radius: 25px;
            color: white !important;
            background: #21a7e0;
            font-size: 14px;
            font-style: normal;
            font-stretch: normal;
            font-weight: bold;
            height: 38px;
            line-height: normal;
            letter-spacing: normal;
            text-transform: none;
            outline: none;
            border: none;
          }
        }
        .downloadPreferences {
          .downloadEleWithPrefs {
            .download {
              border-radius: 25px;
              color: white !important;
              background: #21a7e0;
              font-size: 14px;
              font-style: normal;
              font-stretch: normal;
              font-weight: bold;
              height: 38px;
              line-height: normal;
              letter-spacing: normal;
              text-transform: none;
              outline: none;
              border: none;
            }
          }
          .alwaysAskPref {
            .new-slide-detail-speed {
              .download {
                border-radius: 25px;
                color: white !important;
                background: #21a7e0;
                font-size: 14px;
                font-style: normal;
                font-stretch: normal;
                font-weight: bold;
                height: 38px;
                line-height: normal;
                letter-spacing: normal;
                text-transform: none;
                outline: none;
                border: none;
              }
            }
          }
        }
        .save-to-gdrive-disabled {
          .download {
            border-radius: 25px;
            color: white !important;
            background: #21a7e0;
            font-size: 14px;
            font-style: normal;
            font-stretch: normal;
            font-weight: bold;
            height: 38px;
            line-height: normal;
            letter-spacing: normal;
            text-transform: none;
            outline: none;
            border: none;
          }
        }
      }
    }
    .success {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      font-size: 16px;
      font-weight: bold;
    }
    .successStatus {
      .v-btn {
        box-shadow: none;
        background-color: #ffffff !important;
        letter-spacing: normal;
        text-transform: none;
      }
    }
  }
}
</style>
