<template>
  <div
    class="bestPractisesContainer"
    :style="{
      height: `${mainSlideHeight ? `${mainSlideHeight}px` : '100%'}`,
    }"
  >
    <div class="bestPractisesListAndBtnContainer">
      <div
        v-if="getBestPractisesOnSlide.length > 0"
        class="bestPractisesListContainer"
      >
        <div
          v-for="(bs, index) in getBestPractisesOnSlide.filter(
            (item) => item.isAdded,
          )"
          :class="`${
            getBestPractiseSelected.name === bs.name &&
            (isOwner || bs.isNew) &&
            isBestPractiseToggleActive
              ? 'bestPractiseListItemSelected'
              : 'bestPractiseListItem'
          }`"
          :key="index"
          @click="
            (isOwner || bs.isNew) && isBestPractiseToggleActive
              ? handlerSetBestPractiseSelected(bs)
              : null
          "
        >
          <div class="bestPractiseNumber">{{ bs.name }}</div>
          <div
            class="d-flex justify-space-between align-center bestPractiseContent"
            v-if="!bs.isEditing"
          >
            <p class="bsText">{{ bs.tooltip }}</p>
            <div class="actions">
              <v-icon @click="handleEnterEditingMode(bs)">{{
                'mdi-pencil'
              }}</v-icon>
              <v-icon @click="handleOpenBestPractiseRemoveDialog(bs)">{{
                'mdi-delete'
              }}</v-icon>
            </div>
          </div>
          <v-text-field
            autofocus
            class="editBsText"
            v-model="bs.tooltip"
            @keyup.enter="(e) => handleUpdateBestPractise(e, bs)"
            v-else
          >
            <template #append-outer>
              <v-icon
                @click="(e) => handleUpdateBestPractise(e, bs)"
                :disabled="bs.tooltip === ''"
              >
                {{ 'mdi-play' }}
              </v-icon>
              <v-icon class="pr-3" small @click="handleRemoveEditingMode(bs)">
                {{ 'mdi-close' }}
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </div>
      <div class="addBestPractiseBtnContainer">
        <v-btn
          :disabled="
            getBestPractisesOnSlide.length >= 5 ||
            !isBestPractiseToggleActive ||
            getBestPractisesOnSlide.filter((item) => !item.isAdded).length > 0
          "
          @click="handleAddBestPractiseClick"
        >
          <v-icon>{{ 'mdi-plus' }}</v-icon>
          {{ $t('userUploadPrezentation.addBestPractice') }}
        </v-btn>
      </div>
    </div>
    <!--
    <div class="otherMemebersCheckbox">
      <v-checkbox
        v-model="makeVisibleToMembers"
        color="primary"
        label="Best practices visible to other members"
      />
    </div>
    !-->
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions, mapState } from 'vuex';
import RemoveBestPractiseDialog from './RemoveBestPractiseDialog.vue';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents';

export default {
  name: 'BestPractises',
  data() {
    return {
      makeVisibleToMembers: true,
      bsBeforeEdit: {},
      updateText: '',
      editing: false,
    };
  },
  props: {
    mainSlideHeight: {
      type: Number,
      default: 0,
    },
    isBestPractiseToggleActive: {
      type: Boolean,
      default: false,
    },
    getAcceptButtonState: {
      type: Function,
      default: () => {},
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('slidesStore', [
      'setBestPractiseSelected',
      'setIsSlideBestPractisesDirty',
      'setBestPractisesOnSlide',
    ]),

    handlerSetBestPractiseSelected(bs) {
      this.setBestPractiseSelected(bs);
      const existingBps = [...this.getBestPractisesOnSlide].filter(
        (item) => item.isAdded,
      );
      this.setBestPractisesOnSlide(existingBps);
    },

    getNextBestPractiseName() {
      return `#${this.getBestPractisesOnSlide.length + 1}`;
    },

    handleAddBestPractiseClick() {
      const bestPractiseToAdd = {
        name: `${this.getNextBestPractiseName()}`,
        tooltip: '',
        isAdded: false,
        isNew: true,
        x: 0,
        y: 0,
      };
      const existingBp = [...this.getBestPractisesOnSlide];
      existingBp.push(bestPractiseToAdd);
      this.setBestPractisesOnSlide(existingBp);
      this.setBestPractiseSelected({});
    },

    handleEnterEditingMode(point) {
      point.isEditing = true;
    },

    handleRemoveEditingMode(point) {
      point.isEditing = false;
    },

    handleOpenBestPractiseRemoveDialog(point) {
      this.$modal.show(
        RemoveBestPractiseDialog,
        {
          bestPrt: point,
        },
        {
          name: 'RemoveBestPractiseDialog',
          width: '500px',
          height: 'auto',
          'border-radius': '20px',
          styles: {
            'max-height': '50%',
            'max-width': '600px',
            overflow: 'auto',
            'border-radius': '20px',
          },
        },
      );
    },
    handleCloseBestPractiseEditor() {
      this.setBestPractiseSelected({});
    },

    handleUpdateBestPractise(e, point, type = 'update') {
      const getCurrentBestParctises = [...this.getBestPractisesOnSlide].map(
        (item) => {
          if (item.name === point.name) {
            return {
              x: point.x,
              y: point.y,
              name: point.name,
              tooltip: point.tooltip,
              isAdded: true,
              isNew: true,
            };
          }
          return {
            ...item,
          };
        },
      );
      this.setBestPractisesOnSlide(getCurrentBestParctises);
      this.setBestPractiseSelected({});
      point.isEditing = false;
      if (type === 'update')
        trackSlideEvents.slidesBestPracticesEdited(
          this.getActiveSlide,
          this.currentUser,
          {},
        );
      e.preventDefault();
    },
  },
  watch: {
    disableAccept(val) {
      this.getAcceptButtonState(val);
      this.setIsSlideBestPractisesDirty(!val);
    },
  },
  computed: {
    ...mapGetters('slidesStore', [
      'getBestPractisesOnSlide',
      'getBestPractiseSelected',
      'getActiveSlide',
    ]),
    ...mapState('users', ['currentUser']),

    disableAccept() {
      if (
        _.isEqual(
          this.getBestPractisesOnSlide.filter((bp) => bp.isAdded),
          this.bsBeforeEdit,
        )
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.bsBeforeEdit = this.getBestPractisesOnSlide;
  },
};
</script>

<style lang="scss" scoped>
.bestPractisesContainer {
  padding: 0px 8px 0px 0px;
  .bestPractisesListAndBtnContainer {
    height: 90%;
    overflow: auto;
    .bestPractisesListContainer {
      padding: 0px 8px 0px 0px;
      .bestPractiseListItem,
      .bestPractiseListItemSelected {
        padding: 0px 0px 8px 0px;
        margin: 0px 0px 8px 0px;
        .bestPractiseNumber {
          font-size: 16px;
          color: #9c9c9c;
        }
        .editBsText {
          margin-top: 0;
          padding-top: 0;
          height: 27px;
        }
        .bestPractiseContent {
          .bsText {
            font-size: 16px;
            margin: 0;
          }

          .actions {
            display: flex;
            padding-right: 7px;
            .v-icon {
              padding: 2px;
              cursor: pointer;
            }
          }
        }
      }
      .bestPractiseListItem,
      .bestPractiseListItemSelected:not(:last-child) {
        border-bottom: 0.5px solid rgb(189 189 189);
      }
      .bestPractiseListItemSelected {
        background-color: rgb(225 245 251);
        cursor: pointer;
      }
    }
    .addBestPractiseBtnContainer {
      margin: 0px 0px 12px 0px;
      .v-btn {
        box-shadow: none;
        letter-spacing: normal;
        text-transform: none;
        color: #21a7e0;
        background: #ffffff;
        padding: 0px;
        &:hover {
          background: #ffffff;
        }
        &:before {
          background: #ffffff;
        }
      }
      .theme--light {
        background-color: #ffffff !important;
      }
    }
  }
  .otherMemebersCheckbox {
    margin: 24px 0px 0px 0px;
    ::v-deep .v-input {
      padding: 0;
      .v-input__control {
        .v-input__slot {
          margin: 0 !important;
        }
        .v-messages {
          display: none;
        }
      }
    }
  }
}
</style>
