export const LOCAL_ICON_UPLOADING = 'LOCAL_ICON_UPLOADING';
export const LOCAL_ICON_UPLOADED = 'LOCAL_ICON_UPLOADED';
export const LOCAL_ICON_SELECTED = 'LOCAL_ICON_SELECTED';
export const ICON_UPLOADING = 'ICON_UPLOADING';
export const ICON_UPLOAD_FAILED = 'ICON_UPLOAD_FAILED';
export const FLAT_ICON_SELECTED = 'FLAT_ICON_SELECTED';
export const BRAND_ICON_SELECTED = 'BRAND_ICON_SELECTED';
export const ICON_FORMAT_PNG = 'image/png';
export const ICON_FORMAT_JPEG = 'image/jpeg';
export const ICON_FORMAT_BMP = 'image/bmp';
export const ICON_FORMAT_TIFF = 'image/tiff';
export const ICON_FORMAT_SVG = 'image/svg+xml';
export const ICON_FORMAT_SVG_MIME = 'application/xml';
export const FLAT_ICONS = 'flat_icons';
export const BRAND = 'brand';
export const LOCAL = 'local';
export const CREATE = 'create';
