var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.type === 'status')?_c('div',[(
        _vm.slideType === 'prezentSourcedSlide' ||
        _vm.slideType === 'companyBrandedSlide'
      )?_c('div',[_c('div',{staticClass:"permission-status"},[_c('span',[_c('v-icon',{staticClass:"permission-status-icon"},[_vm._v(" "+_vm._s('mdi-lock-outline')+" ")]),_vm._v(" "+_vm._s(_vm.$t('prezentationShareStatus.viewer'))+" ")],1)])]):(
        _vm.slideType === 'userUploadedSlide' ||
        _vm.slideType === 'userGeneratedSlide' ||
        _vm.slideType === 'synthesisSlide' ||
        _vm.slideType === 'userComplySlide' ||
        _vm.slideType === 'userRedesignSlide'
      )?_c('div',[_c('div',{staticClass:"permission-status"},[(
            _vm.itemData.isRestricted === true ||
            _vm.itemData.permission_level === 0 ||
            _vm.itemData.permission === 'Restricted'
          )?_c('span',[_c('v-icon',{staticClass:"permission-status-icon"},[_vm._v(" "+_vm._s('mdi-lock-outline')+" ")]),_vm._v(" "+_vm._s(_vm.$t('prezentationShareStatus.restricted'))+" ")],1):(_vm.itemData.isOwner && _vm.itemData.isShared === true)?_c('span',{staticClass:"prez-status",on:{"click":_vm.openSlideSharePopup}},[_c('v-icon',{staticClass:"status-icon"},[_vm._v(" "+_vm._s('mdi-account-check-outline')+" ")]),_vm._v(" "+_vm._s(_vm.$t('prezentationShareStatus.shared'))+" ")],1):(_vm.itemData.isOwner && _vm.itemData.isShared === false)?_c('span',{staticClass:"prez-status",on:{"click":_vm.openSlideSharePopup}},[_c('v-icon',{staticClass:"status-icon"},[_vm._v(" "+_vm._s('mdi-account-check-outline')+" ")]),_vm._v(" "+_vm._s(_vm.$t('prezentationShareStatus.private'))+" ")],1):(!_vm.itemData.isOwner)?_c('span',[_c('v-icon',{staticClass:"permission-status-icon"},[_vm._v(" "+_vm._s(_vm.getSlidesIcons())+" ")]),_vm._v(" "+_vm._s(("" + _vm.getSlidePermissionText))+" ")],1):_vm._e()])]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }