<template>
  <div
    class="root"
    :class="
      (!isBothSlidesVisible || error || !loader.isIdle() || !slides.length) &&
      'root--single'
    "
    :data-pendo-id="
      !loader.isIdle() || !!error || slides.length === 0
        ? ''
        : 'synthesis-executive-summary'
    "
  >
    <template v-if="error">
      <div class="sdc__slide offset-top">
        <img class="sdc__image" :src="errorLandscape" />
      </div>
    </template>
    <template v-else-if="!loader.isIdle()">
      <div class="sdc__slide offset-top">
        <VideoLoader v-model="loader" class="sdc__image" />
      </div>
    </template>
    <template v-else-if="slides.length">
      <!-- Uploaded Slide -->
      <div class="sdc" v-if="visible.uploadedSlide">
        <div class="sdc__header">
          <div class="sdc__header-left">
            <h4 class="sdc__title">
              {{ $t('synthesize.viewer.uploadedSlide') }}
            </h4>
            <v-btn
              icon
              @click="
                handleVisibility(
                  'executiveSlide',
                  !isBothSlidesVisible,
                  'MinimizeOrMaximize',
                )
              "
            >
              <v-icon class="sdc__expend" v-if="isBothSlidesVisible">
                mdi-arrow-expand
              </v-icon>
              <v-icon class="sdc__expend" v-else>mdi-arrow-collapse</v-icon>
            </v-btn>
          </div>
          <span></span>
          <v-btn
            text
            color="primary"
            class="sdc__show"
            v-if="!isBothSlidesVisible"
            @click="
              handleVisibility('executiveSlide', true, 'showExecutiveSummary')
            "
          >
            {{ $t('synthesize.viewer.showExecutiveSummary') }}
          </v-btn>
          <a href="javascript:void(0)" class=""></a>
        </div>
        <div class="sdc__slide">
          <img class="sdc__image" :src="slide.thumbnail_jpg.thumbnail" />
        </div>
      </div>

      <!-- Executive Summary Slide -->
      <div class="sdc" v-if="visible.executiveSlide">
        <div class="sdc__header">
          <div class="sdc__header-left">
            <h4 class="sdc__title">
              {{ $t('synthesize.viewer.executiveSummary') }}
            </h4>
            <v-btn
              icon
              @click="
                handleVisibility(
                  'uploadedSlide',
                  !isBothSlidesVisible,
                  'MinimizeOrMaximize',
                )
              "
            >
              <v-icon class="sdc__expend" v-if="isBothSlidesVisible">
                mdi-arrow-expand
              </v-icon>
              <v-icon class="sdc__expend" v-else>mdi-arrow-collapse</v-icon>
            </v-btn>
          </div>
          <span></span>
          <v-btn
            text
            color="primary"
            class="sdc__show"
            v-if="!isBothSlidesVisible"
            @click="
              handleVisibility('uploadedSlide', true, 'showUploadedSlide')
            "
            data-pendo-id="synthesis-show-current-slide"
          >
            {{ $t('synthesize.viewer.showUploadedSlide') }}
          </v-btn>
        </div>
        <div class="sdc__slide">
          <img class="sdc__image" :src="execSummary.landscape" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="sdc__slide offset-top" id="replace-main-image">
        <img class="sdc__image" :src="exampleLandscapeUrl" />
      </div>
    </template>

    <div class="root__disclaimer">
      <div class="root__disclaimer__sub">
        {{ $t('synthesize.viewer.warningAstrid') }}
      </div>
      <div>
        {{ $t('synthesize.viewer.disclaimer') }}
        <a href="https://www.prezent.ai/security" target="_blank">{{
          $t('synthesize.viewer.disclaimerMore')
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import SynthesizeLoader from './SynthesizeLoader.vue';
import { trackSynthesisEvents } from '../../../common/Analytics/SynthesisEvents';
import {
  TD_PREZNAME,
  TD_TEMPLATE,
  TD_NUMBEROFSLIDES,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
  TD_MAXIMIZE,
  TD_MINIMIZE,
  TD_CLICKED_TO,
  TD_NODECOUNT,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import VideoLoader from '../../../common/VideoLoader.vue';

export default {
  name: 'SynthesizeDeckContent',
  data() {
    return {
      visible: {
        uploadedSlide: false,
        executiveSlide: true,
      },
    };
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    loader: {
      type: Object,
      required: true,
    },
    slideIndex: {
      type: Number,
      default: 0,
    },
    filename: {
      type: Array,
      default: () => [],
    },
    templateName: {
      type: Object,
      default: null,
    },
  },
  components: { VideoLoader },
  inject: ['getExecutiveSummary'],
  watch: {
    execSummary(newVal, oldVal) {
      if ((!oldVal || oldVal.loading) && newVal && !newVal.loading) {
        this.loader.nextState(2);
      }
    },
  },

  computed: {
    ...mapState('users', ['currentUser']),
    errorLandscape() {
      if (this.error === 'UPL_ERR') {
        return '/assets/img/comply/file-upload-30-slides.svg';
      }
      if (this.error === 'SIZE_ERR') {
        return '/assets/img/comply/file-upload-failed-size-error-humbnail-large.svg';
      }
      if (this.error === 'SYNTHSIZE_ERR') {
        return '/assets/img/redesign/load-failure.svg';
      }
      return '/assets/img/synthesize/synthesis_failed.svg';
    },
    slide() {
      return this.slides[this.slideIndex];
    },
    execSummary() {
      return this.getExecutiveSummary();
    },
    isBothSlidesVisible() {
      if (this.visible.uploadedSlide && this.visible.executiveSlide) {
        return true;
      }
      return false;
    },
    ...mapState('users', ['currentUser']),
    exampleLandscapeUrl() {
      return this.currentUser.user.visualPreference &&
        this.currentUser.user.visualPreference[0].toLowerCase() === 'vibrant'
        ? `/assets/img/synthesize/synthesis-landing-vibrant.svg`
        : `/assets/img/comply/minimal-comply-text-flattened.svg`;
    },
  },

  methods: {
    handleVisibility(slide, show, action) {
      this.$set(this.visible, slide, show);
      if (slide === 'uploadedSlide' && action === 'showUploadedSlide') {
        const otherData = {
          [TD_PREZNAME]: this.filename[0].name,
          [TD_TEMPLATE]: this.templateName.code,
          [TD_NUMBEROFSLIDES]: this.slides.length,
          [TD_NODECOUNT]: this.execSummary.nodeCount,
        };
        trackSynthesisEvents.synthesisShowCurrentSlide(
          this.currentUser,
          otherData,
        );
      } else if (
        slide === 'executiveSlide' &&
        action === 'showExecutiveSummary'
      ) {
        const otherData = {
          [TD_PREZNAME]: this.filename[0].name,
          [TD_TEMPLATE]: this.templateName.code,
          [TD_NUMBEROFSLIDES]: this.slides.length,
          [TD_NODECOUNT]: this.execSummary.nodeCount,
        };
        trackSynthesisEvents.synthesisShowExecutiveSummary(
          this.currentUser,
          otherData,
        );
      } else if (show && action === 'MinimizeOrMaximize') {
        const otherData = {
          [TD_PREZNAME]: this.filename[0].name,
          [TD_TEMPLATE]: this.templateName.code,
          [TD_NUMBEROFSLIDES]: this.slides.length,
          [TD_COMMON_COLUMN_NAME]: TD_CLICKED_TO,
          [TD_COMMON_COLUMN_VALUE]: TD_MINIMIZE,
          [TD_NODECOUNT]: this.execSummary.nodeCount,
        };
        trackSynthesisEvents.synthesisMinimizeMaximizeClick(
          this.currentUser,
          otherData,
        );
      } else {
        const otherData = {
          [TD_PREZNAME]: this.filename[0].name,
          [TD_TEMPLATE]: this.templateName.code,
          [TD_NUMBEROFSLIDES]: this.slides.length,
          [TD_COMMON_COLUMN_NAME]: TD_CLICKED_TO,
          [TD_COMMON_COLUMN_VALUE]: TD_MAXIMIZE,
          [TD_NODECOUNT]: this.execSummary.nodeCount,
        };
        trackSynthesisEvents.synthesisMinimizeMaximizeClick(
          this.currentUser,
          otherData,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.root {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-auto-flow: column;
  gap: 8px;
  transition: 0.4s;
  // overflow: hidden;

  &__disclaimer {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: center;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 20px;
    letter-spacing: 0.01em;
    color: #697077;
    &__sub {
      margin-bottom: 2px;
    }
    a {
      color: #21a7e0;
      cursor: pointer;
    }
  }

  &--single {
    grid-template-columns: 1fr;
  }
}

.offset-top {
  margin-top: 42px;
}
.sdc {
  display: grid;
  grid-template-rows: 40px 1fr;
  gap: 8px;
  // overflow: hidden;

  &__header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: start;
    align-items: center;

    &-left {
      display: grid;
      grid-auto-flow: column;
      gap: 16px;
      align-items: center;
    }
  }

  &__expend {
    font-size: 22px !important;
  }

  &__show {
    text-transform: unset;
    font-weight: 600;
    letter-spacing: normal;
  }

  &__slide {
    // overflow: hidden;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__image {
    max-height: 100%;
    box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
    border-radius: 8px;
    aspect-ratio: 16/9;
    width: 100%;
    // overflow: hidden;
  }
}
</style>
