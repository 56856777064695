<template>
  <div class="search-page-wrapper">
    <v-sheet class="chipgroup ml-n4 pl-n1" max-width="100%">
      <v-chip-group
        show-arrows
        mandatory
        active-class="primary"
        v-model="selectedChip"
      >
        <v-chip
          color="#21a7e0"
          outlined
          v-for="(keyword, index) in keywords"
          :key="index"
          :value="keyword.name.toLowerCase()"
          @click="handleChipChange"
        >
          {{ $t(keyword.display_title) }}
        </v-chip>
      </v-chip-group>
    </v-sheet>
    <div class="people-wrapper">
      <div class="people-wrapper-title">
        {{ $t('fingerprint.searchPage.people') }}
      </div>
      <div class="people-items">
        <div
          class="people-item"
          v-for="(item, index) in people"
          :key="index"
          @click="() => handlePeopleClick(item)"
        >
          <AudienceItem
            :itemClass="'no-padding'"
            :isInComplete="!item.item.fingerPrint || !item.item.isActive"
            :fullName="item.item.fullName"
            :firstName="item.item.firstName"
            :lastName="item.item.lastName"
            :profileImgS3Path="item.item.profileImage"
            :email="item.item.isGeneratedEmail ? '' : item.item.email"
            :creator="item.item.creator"
            :companyName="item.item.companyName"
            :ownerId="item.item.ownerID"
            :isPrivate="item.item.isPrivate"
            :isShared="item.isShared"
            origin="zentrends"
            :isDifferentCompany="item.item.isDifferentCompany"
            :isPublicLimited="item.item.isPublicLimited"
            :isGroup="item.item.isGroup"
            :fingerprintType="
              item.item.fingerprintType
                ? item.item.fingerprintType.toLowerCase()
                : 'default'
            "
            :size="'50'"
            :status="item.item.isActive ? '' : 'Disabled'"
          />
        </div>
        <div class="btn-wrapper">
          <v-btn
            color="primary"
            class="see-more-btn"
            rounded
            outlined
            @click="handleSeeMore()"
            v-if="!seeMore && !(people.length < 12)"
            >{{ $t('fingerprint.searchPage.seeMoreResults') }}</v-btn
          >
        </div>
        <div v-if="!people.length" class="no-result">
          <NoResultPage
            img-url="/assets/img/no-results-found-people.svg"
            :is-btn-visible="true"
            :buttonName="'fingerprint.searchPage.addNewAudience'"
            :description="'fingerprint.searchPage.description'"
            :handleCTA="addNewAudience"
          />
        </div>
      </div>
    </div>
    <div v-if="fingerprints.length" class="fingerprint-wrapper">
      <div class="fingerprint-wrapper-title">
        {{ $t('fingerprint.searchPage.videos') }}
      </div>
      <div class="fingerprint-items">
        <div
          class="fingerprint-item"
          v-for="(item, index) in fingerprints"
          :key="index"
          @click="handleFingerprintClick(item.item)"
        >
          <img src="/assets/img/fingerprint-background.svg" alt width="60px" />
          <v-icon
            id="support"
            class="icon-title support"
            color="white"
            size="35"
          >
            mdi-fingerprint
          </v-icon>
          <div class="heading-cont">
            <div class="fing-heading">
              {{ $t('fingerprint.searchPage.videoText') }}
              {{ item.name.charAt(0).toUpperCase()
              }}{{ item.name.slice(1) }} ({{ item.item.videoDuration }}
              {{ $t('fingerprint.searchPage.min') }})
            </div>
            <div class="fing-subheading">
              <span v-if="item.name.toLowerCase() === 'overview'">
                {{ $t('fingerprint.searchPage.overview') }}
              </span>
              <span v-else>
                {{ $t('fingerprint.searchPage.overview1') }}
                {{ item.name.charAt(0).toUpperCase() }}{{ item.name.slice(1) }}
                {{ $t('fingerprint.searchPage.overview2') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import AudienceItem from '../../common/AudienceItem.vue';
import VideoModal from './VideoModal.vue';
import NoResultPage from '../../common/NoResultPage.vue';
import AddCollegueDetail from '../NewPrezentation/AddCollegueDetail.vue';
import { AddNewAudienceModalProps } from '../AddNewAudience/AddNewAudienceModalProps';
import { fpLearnFingerprints } from '../../../mock/fingerprint';

export default {
  name: 'SearchPage',
  components: {
    AudienceItem,
    NoResultPage,
  },
  data() {
    return {
      keywords: [
        {
          display_title: 'fingerprint.exploreFingerprint.global',
          name: 'Global',
          category: 'global',
        },
        {
          display_title: 'fingerprint.exploreFingerprint.company',
          name: 'Company',
          category: 'company',
        },
        {
          display_title: 'fingerprint.exploreFingerprint.team',
          name: 'Team',
          category: 'team',
        },
      ],
      selectedChip: 'Global',
      items: [],
      users: [],
      seeMore: false,
    };
  },
  props: {
    itemsFiltered: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    itemsFiltered(val) {
      this.items = [...val];
    },
  },
  emits: ['audienceClicked'],
  computed: {
    ...mapState('users', ['currentUser']),
    people() {
      const people = this.items.filter((item) => {
        if (item.type !== 'People') {
          return false;
        }

        if (
          this.selectedChip === 'company' &&
          item.item.companyID !== this.currentUser?.user.companyID
        ) {
          return false;
        }

        if (
          this.selectedChip === 'team' &&
          item.item.teamID !== this.currentUser?.user.teamID
        ) {
          return false;
        }

        return true;
      });

      return this.seeMore ? people : people.slice(0, 12);
    },
    fingerprints() {
      return [
        ...this.items.filter((ele) => ele.type === 'Fingerprint'),
        { name: 'Overview', item: fpLearnFingerprints },
      ];
    },
  },
  mounted() {
    this.items = [...this.itemsFiltered];
  },
  methods: {
    handlePeopleClick(item) {
      if (!item.item.isActive) return;
      this.$emit('audienceClicked', {
        audienceType: item.item.audienceType,
        id: item.item.uuid || item.item.id,
        shared: item.isShared,
      });
    },
    handleSeeMore() {
      this.seeMore = true;
    },
    handleChipChange() {
      this.seeMore = false;
    },
    addNewAudience() {
      this.$modal.show(
        AddCollegueDetail,
        {
          origin: 'Zentrends',
        },
        AddNewAudienceModalProps,
      );
    },
    handleFingerprintClick(item) {
      this.$modal.show(
        VideoModal,
        {
          fingerPrint: item,
        },
        {
          name: 'VideoModal',
          width: '1280px',
          height: '720px',
        },
      );
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/scss/variables.scss';
.search-page-wrapper {
  width: 100%;
  .chipgroup {
    padding: 0px 0px 10px 20px;
    ::v-deep .v-chip {
      border-radius: 40px;
      font-size: 16px;
      font-weight: 500;
      height: 40px;
      margin-right: 18px !important;
      padding: 0 25px !important;

      &.v-chip--active.v-chip--active {
        background: $zen-blue-default !important;
        color: white !important;
      }
    }
  }
  .people-wrapper {
    margin-left: 5px;
    .people-wrapper-title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 30px;
    }
    .people-items {
      margin: 0 19px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 25px;
      border-bottom: 1px solid #dfdfdf;
      margin-bottom: 53px;
      .btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        .see-more-btn {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          text-transform: none;
          font-size: 14px;
          line-height: 19px;
          color: #21a7e0;
          border: 1.5px solid #21a7e0;
          border-radius: 25px;
        }
      }
      .no-result {
        width: 100%;
      }

      .people-item {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 29px;

        ::v-deep .audience-item {
          max-width: 90%;
          .profile-avatar {
            &:hover {
              box-shadow: -4px 4px 25px 1px rgb(0 0 0 / 30%);
            }
            .profile-avatar--initial {
              font-size: 22px;
            }
          }
        }
        .people-item-img-wrapper {
          width: 68px;
          height: 68px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 9px;
        }

        .people-item-info {
          .people-item-info-name {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-bottom: 4px;
          }

          .people-item-info-desc {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #68788c;
          }
        }
      }
    }
  }
  .fingerprint-wrapper {
    margin-left: 5px;
    .fingerprint-wrapper-title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 30px;
    }
    .fingerprint-items {
      margin: 0 19px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 25px;
      border-bottom: 1px solid #dfdfdf;
      margin-bottom: 53px;

      .fingerprint-item {
        width: 35%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 29px;
        position: relative;
        .icon-title {
          background-color: #21a7e0;
          border-radius: 50%;
          position: absolute;
          width: 45px;
          left: 5px;
          height: 45px;
        }
        .heading-cont {
          margin-left: 10px;
          width: 60%;
          .fing-heading {
            font-weight: 700;
            font-size: 14px;
          }
          .fing-subheading {
            color: #68788c;
            font-size: 11px;
          }
        }
      }
      .people-item-img-wrapper {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 9px;
      }

      .people-item-info {
        .people-item-info-name {
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-bottom: 4px;
        }

        .people-item-info-desc {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #68788c;
        }
      }
    }
  }
}
</style>
