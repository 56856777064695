<template>
  <div class="storyline-view">
    <div v-if="hasAccess && !hasNoData">
      <div class="spinner-container" v-if="isLoading">
        <v-progress-circular :size="50" color="primary" indeterminate />
      </div>
      <v-row v-else>
        <v-col cols="6">
          <v-btn class="btn-back" @click="goToSearchLandingPage">
            <v-icon class="icon" color="primary">mdi-chevron-left</v-icon>
            <span>{{
              $t(
                previousRoute === 'search'
                  ? 'slides.backToSearch'
                  : 'storyline.backToStorylines',
              )
            }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          class="actions-container"
          :style="{ 'max-width': `${leftNavExpanded ? '50%' : '22%'}` }"
        >
          <div class="actions">
            <v-tooltip
              top
              max-width="200"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="copy-storyline--auto"
                  color="primary"
                  @click="handleCopyStoryline"
                >
                  mdi-content-copy
                </v-icon>
              </template>
              <span>{{
                copiedFPId === storylineData.id
                  ? $t('common.copied')
                  : $t('userUploadPrezentation.copyLink')
              }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="storylineData.isOwner"
              top
              max-width="200"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="share-storyline--auto"
                  color="primary"
                  @click="handleShare"
                >
                  mdi-account-multiple-plus-outline
                </v-icon>
              </template>
              <span>{{ $t('common.share') }}</span>
            </v-tooltip>

            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon color="primary"> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="handleDuplicateStoryline">
                  <v-list-item-icon>
                    <v-icon class="duplicate-storyline--auto">
                      mdi-plus-box-multiple-outline
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t('common.duplicate')
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="storylineData.permissionLevel !== 'Viewer'"
                  @click="handleEditStoryline"
                >
                  <v-list-item-icon>
                    <v-icon class="edit-storyline--auto">
                      mdi-pencil-outline
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t('profile.storylinesTabContent.edit')
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="
                    storylineData.isOwner &&
                    storylineData.permissionLevel !== 'Viewer'
                  "
                  @click="handleDeleteStoryline"
                >
                  <v-list-item-icon>
                    <v-icon class="delete-storyline--auto">
                      mdi-delete-outline
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t('common.delete')
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="12" class="main-body">
          <div class="content">
            <v-row>
              <v-col cols="12">
                <StorylineContainer
                  ref="storylineContainer"
                  :storylineData="storylineData"
                  :origin="'viewStoryline'"
                  renderFor="storyline"
                />
              </v-col>
              <div class="footer" :class="{ expanded: leftNavExpanded }">
                <div class="footer-container">
                  <div class="accept-outline">
                    <v-btn
                      rounded
                      class="primary-button mb-3"
                      color="#21a7e0"
                      height="48"
                      @click="() => handleBuildStoryline('build')"
                      id="accept-outline-button"
                      :disabled="disableAcceptStoryLine || isStorylineUpdating"
                    >
                      {{ $t('storylinesView.buildCTA') }}
                    </v-btn>
                  </div>
                  <div
                    class="accept-outline"
                    v-if="storylineData.permissionLevel !== 'Viewer'"
                  >
                    <v-checkbox
                      v-model="updateExistingStoryline"
                      :label="$t('storyline.updateExistingStoryline')"
                      :disabled="disableAcceptStoryLine"
                    ></v-checkbox>
                  </div>
                  <div class="accept-outline" v-if="updateExistingStoryline">
                    <v-btn
                      text
                      color="primary"
                      @click="handleAcceptAndSave"
                      class="accept-btn"
                      id="accept-and-save-outline-button"
                      :disabled="
                        disableAcceptStoryLine ||
                        saveStorylineText === 'storyline.storyLineSaved'
                      "
                      :loading="
                        isStorylineUpdating &&
                        saveStorylineText !== 'storyline.storyLineSaved'
                      "
                    >
                      {{ $t(saveStorylineText) }}
                    </v-btn>
                  </div>
                  <div class="accept-outline" v-else>
                    <v-btn
                      text
                      color="primary"
                      @click="handleSaveAs"
                      class="accept-btn"
                      id="accept-and-save-outline-button"
                      :disabled="disableAcceptStoryLine"
                      :loading="isStorylineUpdating"
                    >
                      {{ $t('storyline.saveAsNewStoryline') }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <StorylineModal
        v-if="showEdit"
        :show="showEdit"
        title="profile.storylinesTabContent.editStorylineDetails"
        text="profile.storylinesTabContent.editStorylineDetailsText"
        primaryBtnText="common.cancel"
        :secondaryBtnText="editButtonText"
        :type="storylinePopupEditData.type"
        :description="storylinePopupEditData.description"
        :onPrimaryClick="
          () => {
            showEdit = false;
            resetPopupData();
          }
        "
        :onSecondaryClick="handleEditOutline"
        :isSecondaryloading="isUpdating"
        :onChange="handleInputChange"
        :onClose="
          () => {
            editError = false;
            showEdit = false;
            resetPopupData();
          }
        "
      />
      <StorylineModal
        v-if="showSaveAs"
        :show="showSaveAs"
        title="storyline.saveAsNewstorline"
        text="build.step2.saveThisStoryline"
        primaryBtnText=""
        secondaryBtnText="common.save"
        type=""
        description=""
        :onSecondaryClick="() => handleDuplicateOutline(true)"
        :isSecondaryloading="isUpdating"
        :onChange="(key, value) => handleInputChange(key, value, true)"
        :onClose="
          () => {
            showSaveAs = false;
            resetPopupData();
          }
        "
      />

      <StorylineModal
        v-if="showDuplicate"
        :show="showDuplicate"
        title="profile.storylinesTabContent.duplicateStoryline"
        text="profile.storylinesTabContent.duplicateStorylineText"
        secondaryBtnText="common.duplicate"
        primaryBtnText="common.cancel"
        :type="storylinePopupEditData.type"
        :description="storylinePopupEditData.description"
        :onPrimaryClick="
          () => {
            showDuplicate = false;
            resetPopupData();
          }
        "
        :onSecondaryClick="() => handleDuplicateOutline()"
        :isSecondaryloading="isCreating"
        :onChange="(key, value) => handleInputChange(key, value, true)"
        :onClose="
          () => {
            showDuplicate = false;
            resetPopupData();
          }
        "
      />
      <ConfirmationModal
        v-if="showDuplicateConfirm || showSaveAsConfirm"
        :show="showDuplicateConfirm || showSaveAsConfirm"
        :title="
          showSaveAsConfirm
            ? 'storyline.saveAsNewstoryline'
            : 'profile.storylinesTabContent.duplicateStoryline'
        "
        :text="
          $t('storyline.storylineCreatedCarefully', { type: newStoryline.type })
        "
        primaryBtnText="storyline.stayOnExistingStoryline"
        secondaryBtnText="storyline.goToNewStoryLine"
        :onPrimaryClick="
          () => {
            if (showDuplicateConfirm) {
              trackProfileEvents.profileStorylineviewDuplicateStayOnExistingStoryline(
                currentUser,
                getOtherDataForMatomo(),
              );
            }
            if (showSaveAsConfirm) {
              trackProfileEvents.profileStorylineviewSaveAsNewStorylineSaveStayOnExistingStoryline(
                currentUser,
                getOtherDataForMatomo(),
              );
            }
            showDuplicateConfirm = false;
            showSaveAsConfirm = false;
            resetPopupData();
          }
        "
        :onSecondaryClick="
          () => {
            resetPopupData();
            if (showDuplicateConfirm) {
              trackProfileEvents.profileStorylineviewDuplicateGotoNewStoryline(
                currentUser,
                getOtherDataForMatomo(),
              );
            }
            if (showSaveAsConfirm) {
              trackProfileEvents.profileStorylineviewSaveAsNewStorylineSaveGotoNewStoryline(
                currentUser,
                getOtherDataForMatomo(),
              );
            }
            showDuplicateConfirm = false;
            showSaveAsConfirm = false;
            this.$router.push(`/home/storyline/${newStoryline.uuid}`);
            uuid = newStoryline.uuid;
            getData();
          }
        "
        :onClose="
          () => {
            showDuplicateConfirm = false;
            showSaveAsConfirm = false;
          }
        "
      />
      <ConfirmationModal
        v-if="showDelete"
        :show="showDelete"
        title="profile.storylinesTabContent.deleteStoryline"
        :text="$t('profile.storylinesTabContent.deleteStorylineText')"
        :onPrimaryClick="() => (showDelete = false)"
        :onSecondaryClick="handleDeleteOutline"
        :isConfirming="isDeleting"
        :onClose="() => (showDelete = false)"
      />
      <ConfirmationModal
        v-if="showConfirm"
        :show="showConfirm"
        :title="
          confirmFrom === 'build'
            ? 'storyline.buildPrezentation'
            : 'storyline.backToStorylines'
        "
        :text="$t('storyline.areYouSureyouWantToContinue')"
        :onPrimaryClick="() => (showConfirm = false)"
        :onSecondaryClick="() => handleConfirmClick()"
        :onClose="() => (showConfirm = false)"
      />
      <ConfirmationModal
        v-if="editError"
        :show="editError"
        title="profile.storylinesTabContent.editStorylineDetails"
        :text="$t('profile.storylinesTabContent.unableToUpdateStoryline')"
        :primaryBtnText="''"
        :secondaryBtnText="''"
        :onClose="() => (editError = false)"
      />
      <NoAccessModal
        v-if="showNoAccess"
        :noAccess="noAccess"
        :show="showNoAccess"
        :uuid="uuid"
        :isDuplicate="isDuplicate"
        :onClose="
          () => {
            showNoAccess = false;
            noAccess = false;
            isDuplicate = false;
          }
        "
      />
    </div>
    <div class="no-outline-access" v-else-if="!hasAccess && hasNoData">
      <div>
        <img src="/assets/img/outline-no-data.png" alt />
      </div>
      <div class="no-access-text">
        {{ $t('storyline.thisStorylineDoesNotExist') }}
      </div>
    </div>
    <div class="no-outline-access" v-else>
      <div>
        <img src="/assets/img/outline-no-access.png" alt />
      </div>
      <div class="no-access-text">
        {{ $t('storyline.thisStorylineDoesNotShared') }}
      </div>
      <div>
        <v-btn
          rounded
          class="primary-button mb-3"
          color="#21a7e0"
          height="48"
          @click="handleNoAccess"
          :disabled="requestAccessBtnText === 'storyline.requestSent'"
          :loading="accessRequestSending"
        >
          {{ $t(requestAccessBtnText) }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
// import { v4 as uuidv4 } from 'uuid';
// import draggable from 'vuedraggable';
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { API, graphqlOperation } from 'aws-amplify';
import {
  createStorylines,
  deleteStorylines,
  getStorylineByID,
  logSearchQuery,
  requestStorylineAccess,
  updateStorylines,
} from '../../../utils/api-helper';
import ConfirmationModal from '../../common/ConfirmationDialog.vue';
import StorylineModal from '../Profile/StorylineModal.vue';
import ShareStoryline from './ShareStoryline.vue';
import { getTooltipForKeyword } from '../../common/KeywordTooltipMapping';
// import VueSuggestions from '../../vue-suggestions.vue';
import NoAccessModal from './NoAccessModal.vue';
import StorylineContainer from '../NewPrezentation/BuildPrezentation/Storyline/StorylineContainer.vue';
// import { getPrezentationOutlineForEdit } from '../NewPrezentation/getPrezentationOutline';
import {
  performAutoComplete,
  performHybridSearch,
} from '../../../graphql/queries';
import ideaGallerySearchItemTemplate from '../../common/ideaGallerySearchItemTemplate';
import {
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_QUERY,
  TD_STORYLINE_ORIGIN_DETAIL,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { trackSearchEvents } from '../../common/Analytics/SearchEvents';
import usersApi from '../../../API/users-api';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';
import EventBus from '../../common/event-bus';
import { GraphQLService } from '../../../services/GraphQLService';

export default {
  name: 'ViewStoryline',
  components: {
    // draggable,
    ConfirmationModal,
    StorylineModal,
    // VueSuggestions,
    NoAccessModal,
    StorylineContainer,
  },
  data() {
    return {
      previousRoute: '',
      hasAccess: true,
      uuid: null,
      isLoading: true,
      storylineData: {},
      newStoryline: {},
      resultData: {},
      storylinePopupEditData: {},
      componentKey: 0,
      requestAccessBtnText: 'build.step3.requestAccess',
      accessRequestSending: false,
      crossButton: false,
      isSearchColor: '',
      isLoadingOutlineSuggestions: false,
      ideaGallerySearchItemTemplate,
      ideaFiltered: [],
      addingItem: {},
      userAssociatedCompanies: [],
      copiedFPId: '',
      showDelete: false,
      isDeleting: false,
      showDuplicate: false,
      isCreating: false,
      showEdit: false,
      isUpdating: false,
      showShare: false,
      showConfirm: false,
      confirmFrom: '',
      isStorylineUpdating: false,
      showDuplicateConfirm: false,
      updateExistingStoryline: true,
      showSaveAs: false,
      showSaveAsConfirm: false,
      hasNoData: false,
      isDataChanged: false,
      saveStorylineText: 'storyline.saveStoryline',
      editButtonText: 'storyline.saveEdits',
      editError: false,
      showNoAccess: false,
      noAccess: false,
      isDuplicate: false,
      trackProfileEvents,
      leftNavExpanded: false,
    };
  },
  beforeMount() {
    this.uuid = this.$route.params.id;
    this.getData();
  },
  mounted() {
    this.previousRoute = this.$router.app.previousRoute.name;
    EventBus.$emit('MINIMIZE_LEFTNAV');
    EventBus.$on('LEFTNAV_EXPANDED', () => {
      this.leftNavExpanded = true;
    });
    EventBus.$on('LEFTNAV_MINIMIZED', () => {
      this.leftNavExpanded = false;
    });
  },
  beforeDestroy() {
    EventBus.$off('LEFTNAV_EXPANDED');
    EventBus.$off('LEFTNAV_MINIMIZED');
  },
  watch: {
    searchValue(val) {
      this.ideaFiltered = [];
      // eslint-disable-next-line no-unused-expressions
      val && val !== this.selectValue && this.inputChange(val);
    },
    '$route.query': {
      async handler() {
        if (this.$route.params.id) {
          this.uuid = this.$route.params.id;
          this.getData();
        }
      },
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    dragOptions() {
      return {
        animation: 900,
        ghostClass: 'ghost-list',
      };
    },
    disableAcceptStoryLine() {
      let disable = false;
      const { list = [] } = this.storylineData?.sections || [];
      if (list.length === 0) {
        disable = true;
      }
      list.forEach((sec) => {
        if (sec.slides.length === 0 && !disable) {
          disable = true;
        }
      });

      return disable;
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      if (this.uuid) {
        getStorylineByID(this.uuid)
          .then((res) => {
            this.resultData = JSON.parse(JSON.stringify(res.data));

            if (!Object.hasOwn(res.data, 'hasAccess')) {
              this.hasAccess = false;
              this.hasNoData = true;
            } else if (res?.data?.hasAccess) {
              this.resetPopupData();
              this.updateExistingStoryline =
                this.resultData.permissionLevel !== 'Viewer';

              this.storylineData = res.data;
              this.storylinePopupEditData = {
                type: this.storylineData.type,
                description: this.storylineData.description,
              };
            } else {
              this.hasAccess = false;
            }
          })
          .catch((error) => console.log(error))
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
      }
    },
    resetPopupData() {
      this.storylinePopupEditData = {
        type: this.storylineData.type,
        description: this.storylineData.description,
      };
    },
    getDescription(keyword) {
      return getTooltipForKeyword(keyword);
    },
    handleSaveAs() {
      this.storylineData.sections.list =
        this.$refs.storylineContainer.returnFinalData();

      trackProfileEvents.profileStorylineviewSaveAsNewStoryline(
        this.currentUser,
        this.getOtherDataForMatomo(),
      );
      this.showSaveAs = true;
    },
    handleAcceptAndSave() {
      this.isStorylineUpdating = true;
      trackProfileEvents.profileStorylineviewSaveStoryline(
        this.currentUser,
        this.getOtherDataForMatomo(),
      );
      getStorylineByID(this.uuid)
        .then((response) => {
          if (!response.data || !Object.hasOwn(response.data, 'hasAccess')) {
            // storyline deleted
            this.showNoAccess = true;
            this.noAccess = false;
            this.isStorylineUpdating = false;
          } else if (!response?.data?.hasAccess) {
            // request access
            this.showNoAccess = true;
            this.noAccess = true;
            this.isStorylineUpdating = false;
          } else if (
            response?.data?.hasAccess &&
            response?.data?.permissionLevel &&
            response.data.permissionLevel === 'Viewer'
          ) {
            // request access
            this.showNoAccess = true;
            this.noAccess = true;
            this.isStorylineUpdating = false;
          } else if (response?.data?.hasAccess) {
            this.updateStorylineFunc();
          }
        })
        .catch((error) => {
          console.log(error);
          this.isStorylineUpdating = false;
        })
        .finally(() => {
          // this.isStorylineUpdating = false;
        });
    },
    updateStorylineFunc() {
      this.storylineData.sections.list =
        this.$refs.storylineContainer.returnFinalData();
      this.resultData.sections.list = JSON.parse(
        JSON.stringify(this.$refs.storylineContainer.returnFinalData()),
      );
      this.isDataChanged = false;
      this.isStorylineUpdating = true;
      const { sections, uuid, type, description } = {
        ...this.storylineData,
        ...this.storylinePopupEditData,
      };

      updateStorylines({ sections, uuid, type, description })
        .then((res) => {
          console.log(res);
          this.saveStorylineText = 'storyline.storyLineSaved';
          setTimeout(this.callSetTimeout, 1300);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isStorylineUpdating = false;
        });
    },
    handleConfirmClick() {
      if (this.confirmFrom === 'build') {
        this.$router.push(`/home/build?outline_id=${this.storylineData.uuid}`);
      } else {
        this.$router.push(`/profile?tab=storylines`);
      }
    },
    isOutlineEdited() {
      return isEqual(
        this.storylineData.sections.list,
        this.resultData.sections.list,
      );
    },
    handleBuildStoryline(text) {
      this.confirmFrom = text;
      if (this.isDataChanged || this.isOutlineEdited() === false) {
        this.showConfirm = true;
      } else {
        this.handleConfirmClick();
        trackProfileEvents.profileStorylineviewBuild(
          this.currentUser,
          this.getOtherDataForMatomo(),
        );
      }
    },
    goToSearchLandingPage() {
      if (this.isDataChanged || this.isOutlineEdited() === false) {
        this.showConfirm = true;
      } else {
        this.$router.back();
      }
    },
    handleCopyStoryline() {
      const copyLink = `${window.location.origin}/home/storyline/${this.storylineData.uuid}`;
      navigator.clipboard.writeText(copyLink);
      this.copiedFPId = this.storylineData.id;
      trackProfileEvents.profileStorylineviewCopylink(
        this.currentUser,
        this.getOtherDataForMatomo(),
      );
    },
    handleEditStoryline() {
      this.editButtonText = 'storyline.saveEdits';
      this.showEdit = true;
      trackProfileEvents.profileStorylineviewEdit(
        this.currentUser,
        this.getOtherDataForMatomo(),
      );
    },
    handleEditOutline(goToStoryline) {
      this.isUpdating = true;
      this.isDataChanged = false;
      const { uuid, sections } = this.storylineData;
      const { type, description } = this.storylinePopupEditData;

      trackProfileEvents.profileStorylineviewEditSaveEdits(
        this.currentUser,
        this.getOtherDataForMatomo(),
      );
      getStorylineByID(uuid)
        .then((response) => {
          if (response?.data?.hasAccess) {
            this.storylineData = response.data || {};
          }
          if (!response.data || !Object.hasOwn(response.data, 'hasAccess')) {
            // storyline deleted
            this.showEdit = false;
            this.showNoAccess = true;
            this.noAccess = false;
          } else if (!response?.data?.hasAccess) {
            // request access
            this.showEdit = false;
            this.showNoAccess = true;
            this.noAccess = true;
          } else if (
            response?.data?.hasAccess &&
            response?.data?.permissionLevel &&
            response.data.permissionLevel === 'Viewer'
          ) {
            // request access
            this.showEdit = false;
            this.showNoAccess = true;
            this.noAccess = true;
          } else if (response?.data?.hasAccess) {
            updateStorylines({ uuid, type, description, sections })
              .then((res) => {
                if (res?.error) {
                  this.showEdit = false;
                  this.editError = true;
                } else {
                  this.editButtonText = 'storyline.editsSaved';
                  setTimeout(this.callSetTimeout, 1300);
                  this.storylineData = res.data;
                }
              })
              .catch((error) => {
                this.showEdit = false;
                this.editError = true;
                console.log(error);
              })
              .finally(() => {
                this.isUpdating = false;
                if (goToStoryline) {
                  this.$router.push(`/home/storyline/${uuid}`);
                }
              });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // this.isStorylineUpdating = false;
        });
    },
    callSetTimeout() {
      this.editButtonText = 'storyline.saveEdits';
      this.showEdit = false;
      this.isStorylineUpdating = false;
      this.saveStorylineText = 'storyline.saveStoryline';
      this.resetPopupData();
    },
    handleViewStoryline() {
      this.$router.push(`/home/storyline/${this.storylineData.uuid}`);
    },
    handleDuplicateStoryline() {
      this.showDuplicate = true;
    },
    handleInputChange(key, value, isNewCreation) {
      this.isDataChanged = true;
      if (value) {
        if (isNewCreation) {
          this.newStoryline[key] = value;
          this.storylinePopupEditData[key] = value;
        } else {
          this.storylinePopupEditData[key] = value;
        }
      }
    },
    handleDuplicateOutline(isSave) {
      this.isCreating = true;
      this.isDataChanged = false;
      getStorylineByID(this.uuid)
        .then((response) => {
          if (response?.data?.hasAccess) {
            this.storylineData = response.data;
          }
          if (!response.data || !Object.hasOwn(response.data, 'hasAccess')) {
            // storyline deleted
            this.showDuplicate = false;
            this.showSaveAs = false;
            this.showNoAccess = true;
            this.noAccess = false;
            this.isDuplicate = true;
          } else if (!response?.data?.hasAccess) {
            // request access
            this.showDuplicate = false;
            this.showSaveAs = false;
            this.showNoAccess = true;
            this.noAccess = true;
            this.isDuplicate = true;
          } else if (response?.data?.hasAccess) {
            const { sections, type, description } = {
              ...this.storylineData,
              ...this.storylinePopupEditData,
            };
            createStorylines({ sections, type, description })
              .then((res) => {
                this.newStoryline = res.data;
                this.showDuplicate = false;
                this.showSaveAs = false;
                if (isSave) {
                  this.showSaveAsConfirm = true;
                  trackProfileEvents.profileStorylineviewSaveAsNewStorylineSave(
                    this.currentUser,
                    this.getOtherDataForMatomo(),
                  );
                } else {
                  this.showDuplicateConfirm = true;
                  trackProfileEvents.profileStorylineviewDuplicate(
                    this.currentUser,
                    this.getOtherDataForMatomo(),
                  );
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.isCreating = false;
              });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isCreating = false;
          this.showSaveAsConfirm = false;
          this.showDuplicateConfirm = false;
        });
    },
    handleDeleteStoryline() {
      this.showDelete = true;
    },
    handleDeleteOutline() {
      this.isDeleting = true;
      if (this.storylineData.uuid) {
        deleteStorylines(this.storylineData.uuid)
          .then((res) => {
            console.log(res);
            this.showDelete = false;
            this.$router.push(`/profile?tab=storylines`);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isDeleting = false;
          });
        trackProfileEvents.profileStorylineviewDelete(
          this.currentUser,
          this.getOtherDataForMatomo(),
        );
      }
    },
    handleShare() {
      trackProfileEvents.profileStorylineviewShare(
        this.currentUser,
        this.getOtherDataForMatomo(),
      );
      this.showShare = true;
      this.$modal.show(
        ShareStoryline,
        {
          data: this.storylineData,
          onUpdate: () => {
            // this.getData();
          },
          origin: TD_STORYLINE_ORIGIN_DETAIL,
        },
        {
          name: 'ShareStoryline',
          width: 700,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    clearSearch() {
      this.crossButton = false;
      this.addingItem = {};
    },
    serializeParam(params) {
      return Object.entries({ ...params })
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    },
    /**
     * Debounce the typehaead queries by 500ms
     */
    // eslint-disable-next-line func-names
    debounceInputChange: debounce(function (searchText) {
      this.inputChange(searchText);
    }, 500),

    inputChange(text) {
      this.searchValue = text;
      this.ideaFiltered.splice(0, this.ideaFiltered.length);
      const matchedItems = [];
      if (text && text.length > 1) {
        this.isLoadingOutlineSuggestions = true;
        const params = {
          username: this.currentUser.user.cognitoID, // "pz_rath_admin",
          query: text,
          company: this.currentUser.company.name.toLowerCase(),
          limit: 10,
        };
        const serialized = this.serializeParam(params);
        GraphQLService.requestWithToken(
          graphqlOperation(performAutoComplete, {
            parameters: serialized,
          }),
        )
          .then((res) => {
            this.isLoadingOutlineSuggestions = false;
            const result = JSON.parse(res.data.performAutoComplete);
            if (result.statusCode === 200 && result.body.payloads) {
              result.body.payloads.forEach((item) => {
                if (item.keytype === 'K_CATEGORY') {
                  matchedItems.push({
                    outline: item.display_name,
                    category: item.name,
                  });
                }
              });
              const list = [];
              this.storylineData.list.forEach((section) => {
                section.slides.forEach((item) => {
                  list.push(item.category.toLowerCase());
                });
              });

              this.ideaFiltered = matchedItems.filter((item) =>
                list.indexOf(item.category.toLowerCase()),
              );
            }
          })
          .catch(() => {
            this.isLoadingOutlineSuggestions = false;
          });
      }
    },

    generateSearchParams(companiesList) {
      const params = {
        username: this.currentUser.user.cognitoID, // "pz_rath_admin",
        query: this.searchValue,
        orderby: 'CHOICE_DESC',
        choice: { visual: 6.0, data: 5.0 },
        companies: companiesList,
        limit: 10,
        skip: 0,
      };
      return params;
    },

    onEnterKeyPress() {
      const logPayload = {
        searchTerm: this.searchValue?.toLowerCase() || '',
        searchTermResult: false,
        type: 'Idea Gallery',
      };

      this.isLoadingOutlineSuggestions = true;
      this.searchClicked = true;
      let serialized;
      if (this.userAssociatedCompanies.length > 0) {
        const companiesList = [];
        this.userAssociatedCompanies.map((company) =>
          companiesList.push(company.name),
        );
        companiesList.push(`${this.currentUser.company.name}`);
        serialized = JSON.stringify(this.generateSearchParams(companiesList));
      } else {
        serialized = JSON.stringify(
          this.generateSearchParams([`${this.currentUser.company.name}`]),
        );
      }
      API.graphql(
        graphqlOperation(performHybridSearch, {
          parameters: serialized,
        }),
      )
        .then((res) => {
          const result = JSON.parse(res.data.performHybridSearch);
          if (
            result.statusCode === 200 &&
            result.body.payloads &&
            result.body.payloads.length > 0
          ) {
            const cat = [];
            result.body.payloads.forEach((it) => {
              if (it.keytype === 'K_CATEGORY') {
                cat.push({
                  outline: it.display_name,
                  category: it.name,
                });
              }
            });

            const list = [];
            this.storylineData.list.forEach((section) => {
              section.slides.forEach((item) => {
                list.push(item.category.toLowerCase());
              });
            });

            this.ideaFiltered = [];
            this.ideaFiltered = cat.filter((item) =>
              list.indexOf(item.category.toLowerCase()),
            );

            if (this.ideaFiltered.length) {
              logPayload.searchTermResult = true;
            } else {
              logPayload.searchTermResult = false;
            }
          } else {
            logPayload.searchTermResult = false;
          }
          this.isLoadingOutlineSuggestions = false;
          logSearchQuery(logPayload)
            .then()
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          this.isLoadingOutlineSuggestions = false;
          console.log(err);
        });
    },

    setLabel(item) {
      return item.name;
    },
    onClickHandle(check) {
      if (check) {
        this.isSearchColor = '#21a7e0';
      } else {
        this.isSearchColor = 'black';
      }
    },
    getRandom(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    async itemSelected(item) {
      this.searchClicked = false;
      this.crossButton = false;
      const otherData = {
        [TD_AUD]: this.currentUser.user.id,
        [TD_QUERY]: item.name,
      };
      if (this.result.id) {
        trackSearchEvents.trackDropdownPrezentationIdeaGallery(
          this.currentUser,
          {
            ...otherData,
            [TD_PREZNAME]: this.currNewPrezentation?.name,
            [TD_PREZTYPE]: this.currNewPrezentation?.type,
          },
        );
      } else {
        trackSearchEvents.trackDropdownBuildIdeaGallery(
          this.currentUser,
          otherData,
        );
      }
      this.addingItem = item;

      this.storylineData.ideaGallery = this.storylineData?.ideaGallery || [];
      this.storylineData.ideaGallery.unshift({
        id: this.getRandom(100, 200),
        outline: this.addingItem.name,
        category: this.addingItem.category,
      });
      const payload = {};
      payload.category = this.addingItem.category;
      payload.username = this.currentUser.user.cognitoID; // "pz_rath_admin"),
      payload.limit = 96;
      payload.skip = 0;
      payload.choice = this.getChoice(this.currentUser.user.fingerPrint);
      const slides = await this.getRecommendedSlides(payload);
      if (!this.alreadySearchedIdeas.includes(this.addingItem.name)) {
        this.alreadySearchedIdeas.push(this.addingItem.name);
        // we don't need to wait this
        const userData = {
          performIncrements: {
            countSearches: 1,
          },
        };
        usersApi.methods
          .updateUserProfile(userData)
          .then((updatedUserDetails) => {
            const userInfo = this.currentUser;
            userInfo.user = updatedUserDetails;
            this.setCurrentUser(userInfo);
          })
          .catch((err) =>
            console.log('error while updating the search count', err),
          );
      }
      this.storylineData.ideaGallery.slide = slides
        ? slides[this.getRandom(0, Math.min(slides.length, 5))]
        : null;
      this.storylineData.ideaGallery.relatedSlides = slides;

      const temp = this.ideaSearch.filter(
        (i) =>
          !i.name.toLowerCase().includes(this.addingItem.name.toLowerCase()),
      );
      this.ideaSearch = [...temp];
      this.addingItem = { name: '' };
      if (document.getElementsByClassName('gallery-search')[0])
        document.getElementsByClassName('gallery-search')[0].value = '';

      this.ideaKey++;
      document.getElementById('ideagallery').scrollIntoView(false);

      // Adding to search terms
      this.logSearch({ item, results: slides });
    },
    handleNoAccess() {
      this.accessRequestSending = true;
      requestStorylineAccess(this.uuid)
        .then((res) => {
          console.log(res);
          this.requestAccessBtnText = 'storyline.requestSent';
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.accessRequestSending = false;
        });
    },
    getOtherDataForMatomo(data) {
      const prezData = data || this.storylinePopupEditData;
      return {
        [TD_PREZTYPE]: prezData?.type,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.storyline-view {
  padding: 20px 40px;

  .spinner-container {
    width: 5%;
    margin: 40vh auto 0px auto;
  }

  .btn-back {
    border: none;
    text-transform: none;
    background-color: #ffffff;
    box-shadow: none;
    letter-spacing: normal;
    color: #21a7e0;
    padding: 0;
    margin: 0px 0px 0px -8px;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0px !important;
  }

  .gallery-wrapper {
    position: relative;
  }
  .outline-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.idea {
      padding-left: 0px;
    }
  }

  .content {
    margin-top: 5px;
  }

  .main-body {
    margin: auto !important;
  }

  .primary-button {
    color: #fff;
    text-transform: none !important;
    font-size: 18px;
    text-transform: none;
    letter-spacing: normal;
  }
  .outline-header {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #21a7e0;
    text-align: left;
    margin-right: 10px;
    &.idea {
      color: black;
    }
  }
  .list-group {
    margin-top: 14px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 500px;
    border-radius: 5px;
    max-width: 337px;
    min-width: 200px;
    height: 100%; //min-height: 100%;
  }
  .list-group-item {
    height: 40px;
    width: 190px;
    cursor: pointer;
    margin: 1px 1px 14px 3px;
    padding: 11px 8px 10px 12px;
    border-width: 2px;
    border-radius: 20px;
    box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.2);
    background-color: trasparent;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: justify;
    text-justify: inter-word;
    .idea-text {
      min-width: 80%;
    }
    .idea-galary {
      padding-top: 15px;
      text-align: left;
      max-width: 150px;
      word-wrap: break-word;
      text-align-last: left;
    }
    &.idea {
      margin-left: 40px;
    }
    .element-info-wrapper {
      display: none;
      float: right;
    }
    &:hover {
      box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.5),
        0 2px 4px 1px rgba(0, 0, 0, 0.15), 0 0 3px 0 rgba(0, 0, 0, 0.5);
      border: solid 3px #21a7e0;
      .element-info-wrapper {
        display: flex;
        margin: 0px;
        padding: 0px;
      }
    }
  }

  .footer {
    pointer-events: none;
    position: fixed;
    left: 80px;
    bottom: 15px;
    width: 73%;
    display: flex;
    justify-content: center;

    &.expanded {
      left: 305px;
      width: calc(100% - 280px);
    }
    .footer-container {
      pointer-events: all;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .accept-btn {
    color: $zen-blue-default;
    text-transform: none !important;
    font-size: 18px;
    text-transform: none;
    letter-spacing: normal;
    background-color: #fff;
  }

  .actions-container {
    .actions {
      display: flex;
      justify-content: flex-end;
      .v-icon {
        margin: 0 5px !important;
      }
    }
  }

  .no-outline-access {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .no-access-text {
      margin: 25px 0;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: #000000;
    }
  }

  .search-bar {
    width: 190px;
    margin-top: 14px;
  }

  .main-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.01em;
  }

  .sub-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
  }

  * {
    font-family: 'Lato';
  }

  .accept-outline {
    background-color: #fff;
  }
}

::v-deep .v-list-item__icon {
  min-width: unset !important;
  margin: 16px 16px 16px 0px !important;
}
</style>
