<template>
  <div class="st">
    <div class="st__header">
      <h2 class="st__title">{{ $t('synthesize.tone.title') }}</h2>
      <p class="st__subtitle">{{ $t('build.step3.poweredByZenSence') }}</p>
      <div class="st__text">{{ $t('synthesize.tone.description') }}</div>
    </div>
    <div class="st__body">
      <v-radio-group v-model="selectedTone">
        <v-expansion-panels flat accordion v-model="panelValue">
          <v-expansion-panel v-for="(tone, i) in tones" :key="i">
            <v-expansion-panel-header class="st-list__panel_header">
              <div class="st-list__header">
                <v-radio
                  class="st-list__input"
                  :value="i"
                  @click.stop
                  :ripple="false"
                ></v-radio>
                <img
                  class="st-list__icon"
                  :src="`/assets/img/synthesize/tone-${tone.id}.svg`"
                />
                <div>
                  {{ $t(tone.label) }}
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="st-list__content">
                <p class="st-list__description">{{ $t(tone.description) }}</p>
                <img class="st-list__image" :src="tone.slide" />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-radio-group>
    </div>
    <div class="st__footer">
      <v-btn
        rounded
        color="primary"
        class="st__button"
        :disabled="initialTone === selectedTone"
        @click="resenthesize()"
        >Resynthesize</v-btn
      >
    </div>
  </div>
</template>

<script>
import { SynthesizeOperation, SlideTone } from './SynthesizeUtills';

export default {
  name: 'Tone',
  data() {
    return {
      tones: [
        {
          id: SlideTone.Neutral,
          label: 'synthesize.tone.neutral.label',
          description: 'synthesize.tone.neutral.text',
          slide: '/assets/img/synthesize/synthesis_tone_neutral.png',
        },
        {
          id: SlideTone.Factual,
          label: 'synthesize.tone.factual.label',
          description: 'synthesize.tone.factual.text',
          slide: '/assets/img/synthesize/synthesis_tone_factual.png',
        },
        {
          id: SlideTone.Implicative,
          label: 'synthesize.tone.implication.label',
          description: 'synthesize.tone.implication.text',
          slide: '/assets/img/synthesize/synthesis_tone_implication.png',
        },
      ],
      selectedTone: 0,
      panelValue: 0,
    };
  },
  computed: {
    initialTone() {
      const idx = this.tones.findIndex((i) => i.id === this.tone);
      return idx > 0 ? idx : 0;
    },
  },
  props: {
    tone: {
      type: String,
      default: SlideTone.Neutral,
    },
  },
  watch: {
    selectedTone() {
      this.panelValue = this.selectedTone;
    },
  },
  methods: {
    resenthesize() {
      // console.log('this.tones[this.selectedTone]', this.tones[this.panelValue]);
      this.$emit('change', {
        type: SynthesizeOperation.ToneChange,
        data: this.tones[this.selectedTone],
      });
    },
  },
  mounted() {
    this.selectedTone = this.initialTone;
    this.panelValue = this.selectedTone;
  },
};
</script>

<style lang="scss" scoped>
.st {
  background: $white-default;
  padding: 20px;

  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;

  &__header {
    margin-bottom: 10px;
  }

  &__title {
    font-weight: 700;
    line-height: 24px;
  }
  &__subtitle {
    font-family: 'Lato';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #697077;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  &__text {
    font-size: 16px;
  }

  &__button {
    padding: 6px 16px;
    width: auto;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: normal;
    line-height: 19px;
    color: #ffffff;
    background: #21a7e0;
    border-radius: 25px;
  }
}

.st-list {
  &__panel_header {
    padding: 16px 0;
  }
  &__header {
    display: flex;
    font-weight: 600;
    color: #075689 !important;
    align-items: center;
  }
  &__icon {
    margin-right: 8px;
  }
  &__input {
    margin-bottom: 0 !important;
  }
  &__content {
    padding: 0 22px;
  }
  &__description {
    font-size: 15px;
  }
  &__image {
    border: 1px solid #c1c7cd;
    border-radius: 10px;
    overflow: hidden;
  }
}
</style>
