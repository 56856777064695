<template>
  <v-container fluid>
    <div class="slide-detail-wrapper">
      <div
        class="backToSlidesContainer"
        v-if="
          !isImageReplaceable &&
          !isImageReplaceWalkThrough &&
          !isIconReplaceable
        "
      >
        <v-btn
          @click="handleClose()"
          data-pendo-id="back-btn"
          :style="{
            margin: `0px 0px 0px -${
              getBackButtonText() === 'Back' ? '12' : '8'
            }px`,
          }"
        >
          <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
          {{ getBackButtonText() }}
        </v-btn>
      </div>
      <!--
      <div @click="handleClose" class="btn-close">
        <v-icon>mdi-close</v-icon>
      </div>
      !-->
      <div class="slide-detail-inner">
        <div class="backToSlidesContainer" v-if="isImageReplaceWalkThrough">
          <v-btn @click="handleClose()" class="walkthrough-back">
            <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
            {{ $t('slides.backToSlides') }}
          </v-btn>
        </div>
        <div v-if="!isImageReplaceable && !isIconReplaceable">
          <SlideDetail
            :favorite-slides="favoriteSlides"
            :handleFavorite="handleFavorite"
            :handleUpdateActiveSlide="handleUpdateActiveSlide"
            :canReplaceImage="canReplaceImage"
            :canReplaceIcons="canReplaceIcons"
            :isModal="false"
            :audience="audience"
            :handleDownload="handleDownload"
            :logDownload="logDownload"
            :savedQueryPayload="savedQueryPayload"
            :dialog-content="selectedSlide"
            @toggleImageReplace="toggleImageReplace"
            @toggleIconReplace="toggleIconReplace"
            :from="origin"
            :slideType="slideType"
            :processingFavSlide="processingFavSlide"
            :downloadingSlide="downloadingSlide"
            :updateThemeUpdated="updateThemeUpdated"
          />
        </div>
        <div v-if="isImageReplaceable">
          <SlideImageReplacement
            :key="slideImageReplacementComponentKey"
            :canReplaceIcons="canReplaceIcons"
            :favorite-slides="favoriteSlides"
            @toggleImageReplace="toggleImageReplace"
            :selectedSlide="selectedSlide"
            :logDownload="logDownload"
            :handleReset="handleSlideReplacementReset"
            :eventStart="eventStart"
            :origin="origin"
            :selectedAudience="audience"
            :switchToReplaceIcons="switchToReplaceIcons"
          />
        </div>
        <div v-if="isIconReplaceable">
          <SlideIconReplacement
            :key="slideIconReplacementComponentKey"
            :canReplaceImage="canReplaceImage"
            :favorite-slides="favoriteSlides"
            @toggleIconReplace="toggleIconReplace"
            :selectedSlide="selectedSlide"
            :logDownload="logDownload"
            :handleReset="handleSlideIconReplacementReset"
            :eventStart="eventStart"
            :origin="origin"
            :selectedAudience="audience"
            :switchToReplaceImage="switchToReplaceImage"
          />
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters, mapState } from 'vuex';
import SlideImageReplacement from '../SlideImageReplacement/SlideImageReplacement.vue';
import SlideIconReplacement from '../SlideIconReplacement/SlideIconReplacement.vue';
import SlideDetail from '@/components/common/SlideDetail';
import {
  SLIDES,
  TD_PLACEMENT,
  TD_PREZENTATION_LIBRARY,
  TD_SLIDES_LIBRARY,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { doesPrefsObjectHasImage } from '../../utils';
import { trackIconReplacementEvents } from '../../common/Analytics/IconReplacementEvents';

export default {
  name: 'SlideDetailWrapper',
  provide() {
    return {
      isImageReplaceWalkThrough: this.isImageReplaceWalkThrough,
    };
  },
  data() {
    return {
      isImageReplaceable: false,
      isIconReplaceable: false,
      slideImageReplacementComponentKey: 1,
      slideIconReplacementComponentKey: uuidv4(),
      origin: SLIDES,
      eventStart: null,
    };
  },
  components: {
    SlideImageReplacement,
    SlideDetail,
    SlideIconReplacement,
  },
  computed: {
    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('slidesStore', ['getIsReplaceImageActionClicked']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),
    ...mapState('users', ['currentUser', 'themes', 'startLoadingSlidesFlag']),
    canReplaceImage() {
      return doesPrefsObjectHasImage(this.selectedSlide.prefs);
    },
    hasIconBus() {
      if (this.selectedSlide.prefs && this.selectedSlide.prefs.has_icons_bus) {
        if (typeof this.selectedSlide.prefs.has_icons_bus === 'string') {
          return this.selectedSlide.prefs.has_icons_bus === 'yes';
        }
        return this.selectedSlide.prefs.has_icons_bus;
      }
      return false;
    },
    hasIcons() {
      if (this.selectedSlide.prefs && this.selectedSlide.prefs.has_icons) {
        if (typeof this.selectedSlide.prefs.has_icons === 'string') {
          return this.selectedSlide.prefs.has_icons === 'yes';
        }
        return this.selectedSlide.prefs.has_icons;
      }
      return false;
    },
    hasIconAuto() {
      if (this.selectedSlide.prefs && this.selectedSlide.prefs.has_icon_auto) {
        if (typeof this.selectedSlide.prefs.has_icon_auto === 'string') {
          return this.selectedSlide.prefs.has_icon_auto === 'yes';
        }
        return this.selectedSlide.prefs.has_icon_auto;
      }
      return false;
    },
    canReplaceIcons() {
      return (
        (this.slideType === 'prezentSourcedSlide' &&
          (this.hasIcons || this.hasIconsBus)) ||
        (this.slideType === 'companyBrandedSlide' &&
          (this.hasIcons || this.hasIconsBus || this.hasIconAuto)) ||
        (this.slideType === 'userGeneratedSlide' &&
          (this.hasIcons || this.hasIconsBus)) ||
        (this.slideType === 'userUploadedSlide' &&
          (this.hasIcons || this.hasIconsBus)) ||
        (this.slideType === 'userRedesignSlide' &&
          (this.hasIcons || this.hasIconsBus)) ||
        (this.slideType === 'userComplySlide' &&
          (this.hasIcons || this.hasIconsBus)) ||
        (this.slideType === 'synthesisSlide' &&
          (this.hasIcons || this.hasIconsBus))
      );
    },
  },
  props: {
    favoriteSlides: {
      type: Array,
    },
    audience: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    savedQueryPayload: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    handleDownload: {
      type: Function,
      required: true,
    },
    handleFavorite: {
      type: Function,
      required: true,
    },
    setInternalResetFn: {
      type: Function,
    },
    setInternalResetFnIcon: {
      type: Function,
    },
    handleUpdateActiveSlide: {
      type: Function,
      required: true,
    },
    logDownload: {
      type: Function,
      required: true,
    },
    selectedSlide: {
      type: Object,
    },
    handleClose: {
      type: Function,
      default: () => {},
    },
    isImageReplaceWalkThrough: {
      type: Boolean,
      default: false,
    },
    updateThemeUpdated: {
      type: Function,
      default: () => {},
    },
    slideViewOrigin: {
      type: String,
      default: '',
    },
    slideType: {
      type: String,
      default: '',
    },
    processingFavSlide: {
      type: Boolean,
      default: false,
    },
    downloadingSlide: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.getIsReplaceImageActionClicked) {
      this.isImageReplaceable = true;
    }
    if (this.canReplaceIcons && this.canReplaceImage) {
      this.setInternalResetFn(this.handleInternalReset);
      this.setInternalResetFnIcon(this.handleInternalResetIcon);
    } else if (this.canReplaceImage) {
      this.setInternalResetFn(this.handleInternalReset);
    } else {
      this.setInternalResetFnIcon(this.handleInternalResetIcon);
    }
  },
  methods: {
    ...mapActions('replaceImageStore', ['reset']),
    ...mapActions('slidesStore', ['setFlowActive', 'setReplaceStackData']),
    getBackButtonText() {
      if (this.slideViewOrigin === 'slidesListPage') {
        return this.$t('common.back');
      }
      if (this.slideViewOrigin === 'hybrid_search') {
        return this.$t('slides.backToSearch');
      }
      return this.$t('slides.backToSlides');
    },
    handleInternalReset() {
      this.isImageReplaceable = false;
    },
    handleInternalResetIcon() {
      this.isIconReplaceable = false;
    },
    toggleImageReplace() {
      if (this.getIsDirty) {
        this.$emit('replaceImageDirty');
      } else if (this.getIsDirtyIcon) {
        this.$emit('replaceIconDirty');
      } else {
        this.eventStart = new Date();
        console.log('Event triggered');
        this.isImageReplaceable = !this.isImageReplaceable;
        this.setReplaceStackData({});
      }
    },
    toggleIconReplace() {
      if (this.getIsDirtyIcon) {
        this.$emit('replaceIconDirty');
      } else if (this.getIsDirty) {
        this.$emit('replaceImageDirty');
      } else {
        this.eventStart = new Date();
        console.log('Event triggered');
        this.isIconReplaceable = !this.isIconReplaceable;
        this.setReplaceStackData({});
      }
    },
    switchToReplaceIcons() {
      trackIconReplacementEvents.iconReplacementClickOnReplaceIcon(
        this.selectedSlide,
        this.currentUser,
        {
          [TD_PLACEMENT]:
            this.origin === 'slides'
              ? TD_SLIDES_LIBRARY
              : TD_PREZENTATION_LIBRARY,
        },
      );
      this.setFlowActive('replaceIcons');
      this.isIconReplaceable = true;
      this.isImageReplaceable = false;
    },
    switchToReplaceImage() {
      this.setFlowActive('replaceImage');
      this.isImageReplaceable = true;
      this.isIconReplaceable = false;
    },
    handleSlideReplacementReset() {
      this.slideImageReplacementComponentKey++;
    },
    handleSlideIconReplacementReset() {
      this.slideIconReplacementComponentKey = uuidv4();
    },
  },
};
</script>
<style lang="scss" scoped>
.slide-detail-wrapper {
  border-radius: 10px;
  // box-shadow: $item-box-shadow;
  height: 100%;
  min-width: 922px;
  overflow: auto;
  padding: 14px;
  position: relative;
  width: 100%;
  .backToSlidesContainer {
    margin: 0px 0px 14px 0px !important;
    .v-btn {
      border: none;
      text-transform: none;
      background-color: #ffffff;
      box-shadow: none;
      letter-spacing: normal;
      color: #21a7e0;
      padding: 0;
      background-color: #ffffff !important;
      height: 24px !important;
      &:before {
        background-color: #ffffff !important;
      }
    }
    .walkthrough-back {
      margin-left: 1%;
    }
  }
}

.slide-detail-inner {
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 8px;

  & > div {
    height: 100%;
  }

  // stylimg replace image screen for slides
  ::v-deep .container {
    .btn-back {
      margin: 0px 0px 9px 0px;
    }
    .main-image {
      .main-image-header {
        padding-bottom: 0px !important;
        .text-subtitle-1 {
          margin: 0px 0px 10px 0px;
        }
      }
      .main-image__wrapper {
        max-height: 100%;
        aspect-ratio: 16/9;
        .v-image {
          max-height: 100% !important;
        }
      }
    }
    .replace-image {
      .text-subtitle-1 {
        span {
          padding: 6px 6px 6px 0px;
          line-height: 38px;
        }
      }
    }
    .choose-image {
      padding: 3px 5px 5px 5px !important;
      .image-library {
        .image-library-wrapper {
          height: 100%;
          .v-tabs {
            height: 100%;
            .v-window {
              height: 100%;
              .container {
                height: 100%;
                .images {
                  max-height: 100%;
                }
              }
            }
          }
        }
      }
      .actions {
        position: relative !important;
        min-height: 60px;
        margin: 68px 0px 0px 0px;
        align-items: center;
        bottom: 0px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        right: 0px;
      }
    }
  }
}
.btn-close {
  cursor: pointer;
  padding: 0.5rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 1;
}
.related-products-thumbails {
  max-height: 22rem;
}
</style>
