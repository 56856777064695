<template>
  <div class="compare-section" ref="compare">
    <div class="compare-heading">
      {{ $t('fingerprint.compareFingerprint.compareFingerprints') }}
    </div>
    <div class="compare-sub-heading">
      {{
        $t('fingerprint.compareFingerprint.compareThePresentationPreferences')
      }}
    </div>
    <div class="compare-audiences">
      <div class="compare-audience-list">
        <div
          v-for="(member, i) in groupMembers"
          :key="i"
          class="compare-single-audience"
        >
          <v-icon
            v-if="
              !isCompared &&
              (groupMembers.length > 1 ||
                (groupMembers.length === 1 && groupMembers[0].audience))
            "
            @click="removeAudience(i)"
          >
            mdi-close
          </v-icon>
          <CompareFingAud
            :isShowMore="isShowMore"
            v-model="member.audience"
            :data="member.audience"
            :isCompared="isCompared"
            @addAudience="showDialogFn"
            @openRemindModal="openRemindModal"
            @input="audienceSelected"
          ></CompareFingAud>
        </div>
        <div class="add-aud-button">
          <v-btn
            text
            class="add-audience-button"
            height="30"
            color="primary"
            @click="addAudience()"
            v-if="groupMembers.length < 5 && !isCompared"
            :ripple="false"
          >
            <v-icon color="primary" :size="18"> mdi-plus </v-icon>
            {{ $t('fingerprint.compareFingerprint.addAudience') }}
          </v-btn>
        </div>
      </div>
    </div>
    <div class="compare-fingerprint-btn">
      <v-btn
        rounded
        outlined
        class="compare-fing-btn"
        height="30"
        color="primary"
        @click="handleCompare()"
        v-if="!isCompared"
        :disabled="!isComparable"
      >
        {{ $t('fingerprint.compareFingerprint.compare') }}
      </v-btn>
      <v-btn
        text
        class="add-audience-button"
        height="30"
        color="primary"
        @click="handleShowMore()"
        v-if="isCompared && !isShowMore"
        :ripple="false"
      >
        {{ $t('fingerprint.compareFingerprint.showMore') }}
      </v-btn>
      <v-btn
        text
        class="add-audience-button"
        height="30"
        color="primary"
        @click="handleShowMore()"
        v-if="isCompared && isShowMore"
      >
        {{ $t('fingerprint.compareFingerprint.showless') }}
      </v-btn>
      <v-btn
        text
        class="add-audience-button"
        height="30"
        color="primary"
        @click="generateGroupInsights()"
        v-if="isCompared"
      >
        {{ $t('fingerprint.compareFingerprint.generateGroupInsights') }}
      </v-btn>
      <v-btn
        text
        class="add-audience-button"
        height="30"
        color="primary"
        @click="shareGift()"
        v-if="isCompared"
      >
        {{ $t('fingerprint.compareFingerprint.shareTheGiftOfFingerprint') }}
      </v-btn>
      <v-btn
        rounded
        outlined
        class="compare-fing-btn"
        height="30"
        color="primary"
        @click="reset()"
        v-if="isCompared"
      >
        {{ $t('common.reset') }}
      </v-btn>
    </div>
    <ConfirmationDialog
      :show="showDialog"
      :on-primary-click="onDialogClose"
      :on-secondary-click="onDialogConfirm"
      :on-close="onDialogClose"
      :primary-btn-text="$t('common.cancel')"
      :secondary-btn-text="$t('fingerprint.groupInsights.yesSure')"
      :title="$t('fingerprint.groupInsights.areYouSure')"
      :text="$t('fingerprint.groupInsights.dialogText')"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import CompareFingAud from './CompareFingAud.vue';
import ShareFreeTrailNew from '../../navbar/ShareFreeTrailNew.vue';
// import { zentrendsService } from './ZentrendsService';
import EventBus from '../../common/event-bus';
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';
import AddCollegueDetail from '../NewPrezentation/AddCollegueDetail.vue';
import { AddNewAudienceModalProps } from '../AddNewAudience/AddNewAudienceModalProps';
import ReminderDialog from '../MyPrezentation/ReminderDialog.vue';
import { updateCompareFingerprintCount } from '../../../utils/api-helper';
import { trackFingerprintEvents } from '../../common/Analytics/FingerprintEvents';
import {
  TD_AUD,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_COUNT_OF_AUDIENCES_COMPARED,
  TD_FINGERPRINT_ADD_AUDIENCE_COMPARE,
  TD_ORIGIN_FINGERPRINT,
  TD_PLACEMENT,
  TD_SHARE_THE_GIFT_OF_FINGERPRINT,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { trackGeneralEvents } from '../../common/Analytics/GeneralEvents';

export default {
  name: 'CompareFingerprint',
  computed: {
    ...mapState('users', ['currentUser', 'startLoadingSlidesFlag']),
    isComparable() {
      return this.groupMembers.filter((i) => i.audience).length >= 2;
    },
  },
  data() {
    return {
      groupMembers: [],
      isCompared: false,
      isShowMore: false,
      isReset: false,
      showDialog: false,
    };
  },
  watch: {
    startLoadingSlidesFlag(val) {
      if (val) {
        this.setDefaultAudiences();
      }
    },
    '$route.query.compare': {
      handler(newVal, oldVal) {
        if (oldVal === newVal) return;
        this.setDefaultAudiences();
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    CompareFingAud,
    ConfirmationDialog,
  },
  methods: {
    removeAudience(index) {
      if (
        this.groupMembers.length === 1 &&
        index === 0 &&
        this.groupMembers[0].audience
      ) {
        this.groupMembers[0].audience = null;
      } else {
        this.groupMembers.splice(index, 1);
      }
    },
    showDialogFn() {
      this.showDialog = true;
    },
    audienceSelected(val) {
      trackFingerprintEvents.fingerprintsAddaudienceSelected(this.currentUser, {
        [TD_PLACEMENT]: TD_FINGERPRINT_ADD_AUDIENCE_COMPARE,
        [TD_AUD]: val?.id,
      });
    },
    openRemindModal(currentAudience) {
      this.$modal.show(
        ReminderDialog,
        {
          currentAudience,
          isUser: currentAudience.type === 'user',
        },
        {
          name: 'ReminderDialog',
          width: '649px',
          height: '80%',
        },
      );
    },
    onDialogClose() {
      this.showDialog = false;
    },
    onDialogConfirm() {
      this.showDialog = false;
      this.$modal.show(
        AddCollegueDetail,
        {
          addColleague: this.addColleague,
          origin: 'zentrends',
        },
        AddNewAudienceModalProps,
      );
    },
    shareGift() {
      trackGeneralEvents.sharetrialsIconClick(this.currentUser, {
        [TD_PLACEMENT]: TD_SHARE_THE_GIFT_OF_FINGERPRINT,
      });
      this.$modal.show(
        ShareFreeTrailNew,
        {
          name: 'RightSidebarModalComponent',
          close: this.closeModal,
          origin: TD_ORIGIN_FINGERPRINT,
        },
        {
          name: 'ShareFreeTrailNew',
          classes: 'v-application',
          width: 800,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px', padding: '20px' },
        },
      );
    },
    generateGroupInsights() {
      // zentrendsService.setGroupInsights({
      //   name: '',
      //   edit: false,
      //   groupMembers: this.groupMembers.map((i) => ({
      //     type: i.audience.type,
      //     audience: i.audience,
      //     audienceType: i.audience.audienceType,
      //   })),
      // });
      EventBus.$emit('GENERATE_INSIGHTS', {
        name: '',
        edit: true,
        groupMembers: [...this.groupMembers],
      });
      trackFingerprintEvents.fingerprintsComparefgGenerateGroupInisghtsClick(
        this.currentUser,
      );
    },
    closeModal() {
      this.$modal.hide('ShareFreeTrailNew');
    },
    reset() {
      this.setDefaultAudiences();
      this.isCompared = false;
      this.isShowMore = false;
      trackFingerprintEvents.fingerprintsComparefgGroupInisghtsReset(
        this.currentUser,
        {
          [TD_PLACEMENT]: TD_FINGERPRINT_ADD_AUDIENCE_COMPARE,
        },
      );
    },
    addAudience() {
      this.groupMembers.push({ audience: null });
    },
    handleCompare() {
      this.groupMembers = this.groupMembers.filter((aud) => aud.audience);
      this.isCompared = true;
      updateCompareFingerprintCount().then((resp) => {
        console.log(resp);
      });
      trackFingerprintEvents.fingerprintsComparefgCompareButtonclick(
        this.currentUser,
        {
          [TD_COMMON_COLUMN_NAME]: [TD_COUNT_OF_AUDIENCES_COMPARED],
          [TD_COMMON_COLUMN_VALUE]: this.groupMembers?.length,
        },
      );
    },
    handleShowMore() {
      if (!this.isShowMore) {
        trackFingerprintEvents.fingerprintsComparefgShowMoreClick(
          this.currentUser,
        );
      }

      this.isShowMore = !this.isShowMore;
    },
    setDefaultAudiences() {
      const left = {
        audience: {
          defaultAudience: 'SELF',
        },
      };
      let right = {
        audience: null,
      };

      if (this.$route.query?.compare && this.startLoadingSlidesFlag) {
        right = {
          audience: {
            defaultAudience: this.$route.query.compare,
          },
        };
        setTimeout(() => {
          if (this.$refs.compare) {
            this.$refs.compare.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }
        }, 200);
      }

      this.groupMembers = [left, right];
    },
  },
  mounted() {
    this.setDefaultAudiences();
  },
};
</script>
<style scoped lang="scss">
.compare-section {
  font-family: 'Lato';
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  padding: 24px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  .compare-heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 12px;
    color: #000;
  }

  .compare-sub-heading {
    margin-bottom: 24px;
  }
  .compare-audience-list {
    display: flex;
    justify-content: center;
    gap: 8px;
    .compare-single-audience {
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
        -1px -1px 4px rgba(0, 0, 0, 0.1);
      margin: 5px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      min-height: 228px;
      width: 200px;
      align-items: flex-end;
      padding: 8px;
      border-radius: 10px;
    }
    .add-aud-button {
      height: 228px;
      display: flex;
      align-items: center;
      .add-audience-button {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #21a7e0;
        text-transform: capitalize;
      }
    }
  }
  .compare-fingerprint-btn {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    .compare-fing-btn {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #21a7e0;
      border: 1.5px solid #21a7e0;
      border-radius: 25px;
      text-transform: capitalize;
    }
    .compare-fing-btn:disabled {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.26);
      border: 1.5px solid rgba(0, 0, 0, 0.26);
      border-radius: 25px;
      text-transform: capitalize;
    }
    .add-audience-button {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #21a7e0;
      text-transform: capitalize;
    }
  }
}
</style>
