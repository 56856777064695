var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":300,"offset-y":"","left":"","content-class":"rating-popover"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('div',_vm._b({staticClass:"rating-btn-container",attrs:{"data-pendo-id":_vm.getRatingBtnText}},'div',attrs,false),[_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{class:{
              bounce: _vm.animateLike,
              'rating-disabled': _vm.form.isLiked !== null,
            },attrs:{"disabled":_vm.disabled,"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.form.isLiked === null ? _vm.handleRatingClick('like') : function () {}}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":(hover && _vm.form.isLiked === null) ||
                    _vm.form.isLiked === true ||
                    _vm.animateLike
                      ? '#21a7e0'
                      : '#000',"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.form.isLiked === true || _vm.animateLike ? 'mdi-thumb-up' : 'mdi-thumb-up-outline')+" ")])]}}],null,true),model:{value:(_vm.likeTooltip),callback:function ($$v) {_vm.likeTooltip=$$v},expression:"likeTooltip"}},[_c('span',[_vm._v(_vm._s(_vm.animateLike ? _vm.$t(_vm.animationTooltipTxt) : _vm.$t(_vm.likeTooltipTxt)))])])],1)]}}],null,true)})],1),_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('v-btn',{class:{ 'rating-disabled': _vm.form.isLiked !== null },attrs:{"disabled":_vm.disabled,"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.form.isLiked === null ? _vm.handleRatingClick('dislike') : function () {}}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":(hover && _vm.form.isLiked === null) || _vm.form.isLiked === false
                      ? '#21a7e0'
                      : '#000',"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.form.isLiked === false ? 'mdi-thumb-down' : 'mdi-thumb-down-outline')+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.dislikeTooltipTxt)))])])],1)]}}],null,true)})],1)])]}}]),model:{value:(_vm.feedbackPopup),callback:function ($$v) {_vm.feedbackPopup=$$v},expression:"feedbackPopup"}},[_c('v-card',[_c('v-btn',{staticClass:"rating-popover__close",attrs:{"elevation":"0","icon":""},on:{"click":_vm.closeFeedback}},[_c('v-icon',[_vm._v("mdi-close")])],1),(_vm.feedbackSuccess)?_c('div',{staticClass:"feedback-success"},[_c('img',{attrs:{"src":"/assets/img/generate/feedback_thanks.png"}}),_c('span',[_vm._v("Thanks for your feedback!")])]):[_c('div',{staticClass:"rating-popover__content"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('div',[_c('label',{staticClass:"form__label"},[_vm._v(" "+_vm._s(_vm.$t('generate.feedbackIsInvaluable'))+" ")]),_c('v-textarea',{staticClass:"form__textarea",attrs:{"label":"Type your feedback","solo":"","rows":4,"required":"","hide-details":true},model:{value:(_vm.form.feedback),callback:function ($$v) {_vm.$set(_vm.form, "feedback", $$v)},expression:"form.feedback"}})],1),_c('div',{staticClass:"form__share"},[_c('v-checkbox',{attrs:{"hide-details":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"share-label"},[_vm._v(" Share the deck details with our team to help improve future results "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":18}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" We don't use confidential customer data for training ML models ")])])],1)]},proxy:true}]),model:{value:(_vm.form.shareDetails),callback:function ($$v) {_vm.$set(_vm.form, "shareDetails", $$v)},expression:"form.shareDetails"}})],1)])],1),_c('v-card-actions',{staticClass:"form__action"},[_c('v-spacer'),_c('v-btn',{staticClass:"primary-button",staticStyle:{"text-transform":"none !important"},attrs:{"color":"primary","rounded":"","width":"100","height":"48","disabled":_vm.form.feedback.length === 0},on:{"click":_vm.submitRatings}},[_vm._v(" Submit ")])],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }