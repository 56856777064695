<template>
  <IconsPlaceHolder v-if="!selectedIconData" />
  <div v-else class="image-upload__container">
    <v-container v-if="isUploading" style="height: 400px">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col cols="12">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="8"
          ></v-progress-linear>
        </v-col>
        <v-col class="text-subtitle-1 text-center" cols="12">
          {{ $t('userUploadPrezentation.uploading') }}
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-alert
        v-if="errorMsg"
        class="upload-alert body-2"
        icon="mdi-alert-outline"
      >
        {{ $t(errorMsg) }}
      </v-alert>
      <v-img
        v-if="!selectedFile"
        src="/assets/img/common/upload-image.svg"
        contain
      ></v-img>
      <div
        v-if="selectedFile"
        class="selected-file__wrapper subtitle-1 text-center"
      >
        <v-img
          class="image-placeholder"
          :src="iconFileUrl"
          max-width="200"
          max-height="200"
        ></v-img>
        <span v-if="uploadingError">
          {{ $t(uploadingError) }}
        </span>
        <v-tooltip bottom content-class="tooltip-active" max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              class="text-truncate selected-file__name pt-2"
            >
              {{ selectedFile.name }}
            </span>
          </template>
          <span>{{ selectedFile.name }}</span>
        </v-tooltip>
        <span v-if="!uploadingError" class="pt-2">{{
          $t('upload.uploadedSuccessfully')
        }}</span>
      </div>
      <div class="upload-btn" @click="handleFileImport">
        <v-icon color="primary">mdi-tray-arrow-up</v-icon>
        <div class="text-primary subtitle-1 text-center">
          {{
            selectedFile
              ? $t('userUploadPrezentation.reuploadIcon')
              : $t('userUploadPrezentation.selectIcon')
          }}
        </div>
      </div>
      <p class="grey--text text-center body-2">
        {{ $t('userUploadPrezentation.iconUpto4Mb') }}
      </p>

      <!-- Hidden File Input that will be triggered with JavaScript -->
      <input
        ref="uploader"
        class="d-none"
        type="file"
        :accept="supportedFormatsInString"
        @change="onFileChanged"
      />
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex';
// import fileTypeChecker from 'file-type-checker';
// import { fromBuffer } from 'file-type';
import IconsPlaceHolder from './IconsPlaceHolder.vue';
import EventBus from '../../common/event-bus';
import {
  FLAT_ICON_SELECTED,
  BRAND_ICON_SELECTED,
  ICON_UPLOAD_FAILED,
  LOCAL_ICON_SELECTED,
  LOCAL_ICON_UPLOADED,
  LOCAL_ICON_UPLOADING,
  ICON_FORMAT_SVG,
  ICON_FORMAT_SVG_MIME,
} from './replaceIconConstants';
// import { TD_REPLACE_ICON_SOURCE_UPLOAD } from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'IconUpload',
  props: {
    selectedIconData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isSelecting: false,
      selectedFile: null,
      iconFileUrl: null,
      isUploading: false,
      uploadingError: null,
      errorMsg: '',
      uploadFailureMessage: 'userUploadPrezentation.uploadADifferentIcon',
      supportedFormats: [ICON_FORMAT_SVG, ICON_FORMAT_SVG_MIME],
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    supportedFormatsInString() {
      return this.supportedFormats.toLocaleString();
    },
  },
  components: {
    IconsPlaceHolder,
  },

  created() {
    EventBus.$on(FLAT_ICON_SELECTED, () => {
      this.selectedFile = null;
    });
    EventBus.$on(BRAND_ICON_SELECTED, () => {
      this.selectedFile = null;
    });
  },
  beforeDestroy() {
    EventBus.$off(FLAT_ICON_SELECTED);
    EventBus.$off(BRAND_ICON_SELECTED);
  },

  methods: {
    handleFileImport() {
      this.isSelecting = true;

      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true },
      );
      this.$refs.uploader.click();
    },

    isSVGString(svgString) {
      // Create a dummy SVG element
      const dummySVG = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg',
      );

      // Try to parse the SVG string and append it to the dummy SVG element
      try {
        dummySVG.innerHTML = svgString;
      } catch (e) {
        return false; // If there is an error parsing, it is not a valid SVG string
      }

      // Check if the dummy SVG element contains any child elements
      return dummySVG.childNodes.length > 0;
    },

    async onFileChanged(e) {
      // reset error flag
      this.errorMsg = '';
      this.selectedFile = null;

      // check if file is actually selected
      if (e.target.files.length === 0) return;

      // check the filesize
      const fileObj = e.target.files[0];

      if (fileObj?.size > 4000000) {
        this.errorMsg = 'userUploadPrezentation.iconSizeExceedsLimit';
        return;
      }

      if (!this.supportedFormats.includes(fileObj?.type)) {
        this.errorMsg = 'userUploadPrezentation.invalidFileFormat';
        return;
      }

      this.selectedFile = fileObj;

      this.iconFileUrl = URL.createObjectURL(this.selectedFile);
      this.isUploading = true;
      EventBus.$emit(LOCAL_ICON_UPLOADING);
      const fileNameParams = this.selectedFile.name.split('.');

      let extension;
      if (fileNameParams && fileNameParams.length >= 2) {
        extension = this.selectedFile.name.split('.').pop();
      }

      // convert image to base64 and call lambda function
      const reader = new FileReader();
      try {
        this.uploadingError = null;
        reader.onloadend = async (ev) => {
          const svgString = await fetch(ev.target.result).then((response) =>
            response.text(),
          );
          const encodedData = Buffer.from(svgString).toString('base64');
          // const buf = Buffer.from(
          //   reader.result.replace(/^data:.*base64,/, ''),
          //   'base64',
          // );

          // const fileType = await fromBuffer(buf);
          // Check actual file type
          if (!this.isSVGString(svgString)) {
            this.errorMsg = 'userUploadPrezentation.invalidFileFormat';
            this.isUploading = false;
            this.selectedFile = null;
            EventBus.$emit(
              ICON_UPLOAD_FAILED,
              this.$t(this.uploadFailureMessage),
            );
            return;
          }

          this.$emit('iconSelected', {
            // origin: TD_REPLACE_ICON_SOURCE_UPLOAD,
            origin: 'upload',
            source: 'upload',
            image: encodedData,
            url: this.iconFileUrl,
            extension,
          });
          EventBus.$emit(LOCAL_ICON_SELECTED);

          this.isUploading = false;
          EventBus.$emit(LOCAL_ICON_UPLOADED);
        };
        reader.readAsDataURL(this.selectedFile);
      } catch (err) {
        console.log('uploadLocalIcon error: ', err);
        this.uploadingError = 'userUploadPrezentation.uploadFailed';
        EventBus.$emit(ICON_UPLOAD_FAILED, this.$t(this.uploadFailureMessage));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.image-upload__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upload-btn {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.image-placeholder {
  border-radius: 8px;
  border: 3px solid #21a7e0 !important;
  box-shadow: $item-box-shadow;
}

.selected-file__wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-top: 1rem;
}

.selected-file__name {
  max-width: 200px;
}

.upload-alert::v-deep .v-alert__wrapper .v-alert__icon.v-icon {
  color: #21a7e0;
  font-size: 32px;
}
</style>
