<template>
  <div
    :key="searchKey"
    class="search-bar"
    :class="isReduceWidth ? 'reduce-width' : ''"
    data-pendo-id="local-search-bar"
  >
    <SlideSuggestions
      :items="itemFiltered"
      v-model="item"
      :set-label="setLabel"
      :item-template="slideLibrarySearchTemplate"
      @changed="debounceInputChange"
      @click="onClickHandle"
      @selected="itemSelected"
      @enter="debounceOnEnterKeyPress"
      ref="SlideSuggestions"
      :placeholder="getPlaceHolder"
      :values="searchValue"
      :minLen="0"
      :filterOptionCategory="filterOptionCategory"
    >
      <template #searchSlot>
        <v-icon
          class="icon"
          v-text="`mdi-close`"
          @click="clearSearch"
          name="global-cross-icon"
          color="#21a7e0"
          v-show="crossButton"
        />
        <v-icon
          class="icon"
          v-text="`mdi-magnify`"
          @click="debounceOnEnterKeyPress"
          name="global-search-icon"
          :color="isSearchColor"
        />
      </template>
    </SlideSuggestions>
  </div>
</template>
<script>
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapState, mapGetters } from 'vuex';
import { graphqlOperation } from 'aws-amplify';
import EventBus from '../../common/event-bus';
import SlideSuggestions from '../../SlideSuggestions.vue';
import { performAutoComplete } from '../../../graphql/queries';
import { GraphQLService } from '../../../services/GraphQLService';
import slideLibrarySearchTemplate from '../../common/slideLibrarySearchTemplate.vue';
import {
  addSearchTerms,
  updateSearchTerms,
  getUserSearchTerms,
} from '../../../utils/api-helper';

export default {
  name: 'SlidesSearch',
  components: {
    SlideSuggestions,
  },
  props: {
    showQuickSelection: {
      type: Boolean,
      default: false,
    },
    filterOptionCategory: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchKey: 0,
      searchValue: '',
      isSearchColor: 'gray',
      crossButton: false,
      search_query: '',
      itemFiltered: [],
      item: null,
      searchTerms: [],
      slideLibrarySearchTemplate,
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'currentTheme']),
    ...mapState('knowStore', ['knowCategories']),
    ...mapGetters('slidesStore', ['getSlideLocalSearchText']),
    ...mapGetters('bestPracticesExamples', ['getBPESlideLandingSearchText']),
    isBPElanding() {
      return this.$route?.name === 'best-practice-library';
    },
    isReduceWidth() {
      return this.showQuickSelection;
    },
    getPlaceHolder() {
      if (this.isBPElanding) return 'Search best practice examples';
      const placeholders = {
        brand_slides: 'Search brand slides',
        generated: 'Search generated slides by file name',
        redesign: 'Search redesigned slides by file name',
        comply: 'Search converted slides by file name',
        uploaded: 'Search uploads by file name',
        synthesis: 'Search synthesis output slides by file name',
        favorites: 'Search favorites by file name',
        downloads: 'Search downloads by file name',
      };
      return placeholders[this.filterOptionCategory] || '';
    },
  },
  mounted() {
    EventBus.$on('CLEAR_SLIDE_LIBRARY_SEARCH', () => {
      this.clearSearch();
    });
    EventBus.$on('CLEAR_BPE_SLIDE_LIBRARY_SEARCH', () => {
      this.clearSearch();
    });
    if (this.getSlideLocalSearchText.length > 0) {
      this.searchValue = this.getSlideLocalSearchText;
    }
    if (this.getBPESlideLandingSearchText.length > 0) {
      this.searchValue = this.getBPESlideLandingSearchText;
    }
  },
  beforeDestroy() {
    EventBus.$off('CLEAR_SLIDE_LIBRARY_SEARCH');
    EventBus.$off('CLEAR_BPE_SLIDE_LIBRARY_SEARCH');
  },
  methods: {
    ...mapActions('users', [
      'setSearchProgress',
      'updateRecentSlideQueries',
      'updateRecentBPESlideQueries',
    ]),
    ...mapActions('slidesStore', [
      'saveSlidesStateSnapshot',
      'setSlideLocalSearch',
    ]),
    setLabel(item) {
      return item.name;
    },
    clearSearch() {
      this.crossButton = false;
      this.item = {};
      this.searchValue = '';
      this.searchKey++;
      this.itemFiltered = [];
    },
    debounceInputChange: _.debounce(function (searchText) {
      this.inputChange(searchText);
      if (searchText.length) {
        this.crossButton = true;
      } else {
        this.crossButton = false;
      }
    }, 300),
    async inputChange(text) {
      this.searchValue = text;
      if (!this.isBPElanding && this.filterOptionCategory !== 'brand_slides')
        return;
      this.itemFiltered = [];
      if (text && text.length > 1) {
        const [slidesAndBPEs] = await Promise.all([
          this.getSlidesAndBPEs(text),
        ]);
        this.itemFiltered.push(...slidesAndBPEs);
        const inputItem = {
          name: text,
          type: this.isBPElanding ? 'Best Practice Slides' : 'Slides',
          category: '',
        };
        const slideIndex = this.itemFiltered.findIndex(
          (item) =>
            item &&
            item.name &&
            item.name.toLowerCase() === inputItem.name.trim().toLowerCase(),
        );
        if (slideIndex === -1) {
          this.itemFiltered.splice(0, 0, inputItem);
        }
      }
    },
    getTheme() {
      const isNonBrandedCompany =
        this.currentUser?.company?.isNonBranded || false;
      const { hasBPGSlides } = this.currentUser?.theme || {};
      if (isNonBrandedCompany && hasBPGSlides) {
        return this.currentUser?.theme.code;
      }
      return this.currentUser?.company?.defaultTemplate;
    },
    getSlidesAndBPEs(text) {
      const theme = this.getTheme();
      const params = {
        username: this.currentUser.user.cognitoID,
        query: text,
        companies: `${this.currentUser.company.name.toLowerCase()},generic`,
        limit: 2,
        vaultmode: true,
        type: 'keywords',
        theme: this.isBPElanding
          ? theme
          : this.currentTheme?.code ||
            this.currentUser?.theme?.code ||
            'default',
      };
      if (this.isBPElanding) params.is_know = 'yes';
      // need to add is_know PENDING
      const serialized = this.serializeParam(params);
      return GraphQLService.requestWithToken(
        graphqlOperation(performAutoComplete, {
          parameters: serialized,
        }),
      )
        .then((res) => {
          this.itemFiltered = [];
          const slides = [];
          const bpe = [];
          const result = JSON.parse(res.data.performAutoComplete);
          if (result.statusCode === 200 && result.body.payloads) {
            const payload = result.body.payloads;
            const sortedMatch = payload;
            sortedMatch.forEach(async (item) => {
              if (item.keytype === 'K_CATEGORY') {
                if (item.slide_type === 'brand_slides') {
                  slides.push({
                    name: item.display_name,
                    type: 'Slides',
                    category: item.name,
                    item,
                  });
                }
                if (
                  this.isBPElanding &&
                  this.knowCategories.indexOf(item.name) >= 0
                ) {
                  bpe.push({
                    name: item.display_name,
                    type: 'Best Practice Slides',
                    category: item.name,
                    item,
                  });
                }
              }
            });
          }
          return this.isBPElanding ? [...bpe] : [...slides];
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
    },
    serializeParam(params) {
      return Object.entries({ ...params })
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    },
    onClickHandle(check) {
      this.setSlideLocalSearch('');
      if (check) {
        this.isSearchColor = '#21a7e0';
      } else {
        this.isSearchColor = 'gray';
      }
    },
    debounceOnEnterKeyPress: _.debounce(function () {
      this.doSearch(this.searchValue, 'enterPress');
    }, 300),
    async itemSelected(item) {
      this.item = item;
      this.searchValue = item.name;
      this.crossButton = true;
      this.search_query = this.item.category ? this.item.category : '';
      if (this.search_query && !this.isBPElanding) {
        this.logSearch(item.item);
      }
      this.doSearch(item, 'itemSelect');
    },
    async logSearch(term) {
      try {
        await this.getSearchTerms();
        const index =
          this.searchTerms &&
          this.searchTerms.findIndex((item) => {
            const category = item.category.toLowerCase().replace('_', ' ');
            const termName = term.name.toLowerCase().replace('_', ' ');
            return category === termName;
          });
        if (index >= 0) {
          this.searchTerms[index].count += 1;
          const searchData = {
            id: this.searchTerms[index].id,
            count: this.searchTerms[index].count,
            searchTermResult: !(term === undefined),
          };
          updateSearchTerms(searchData)
            .then((value) => {
              console.log(value);
              this.getSearchTerms();
            })
            .catch((err) => console.log(err));
        } else {
          const searchData = {
            id: uuidv4(),
            userID: this.currentUser.user.id,
            searchTerm: term.display_name,
            category: term.name,
            count: 1,
            createdAt: new Date().toISOString(),
          };
          addSearchTerms(searchData)
            .then((value) => {
              console.log(value);
              this.addSearchTerms(value.data.createSearchTerm);
              this.getSearchTerms();
            })
            .catch((err) => console.log(err));
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getSearchTerms() {
      await getUserSearchTerms()
        .then((searchData) => {
          const terms = searchData.data.searchByUser.items;
          if (terms.length) {
            this.searchTerms = terms;
          }
        })
        .catch((err) => console.log(err));
      // this.searchTermss = this.searchTerms;
    },
    doSearch(item, type) {
      if (this.isBPElanding) {
        this.handleBPEslideSearch(item, type);
        return;
      }
      this.handleSlidesSearch(item, type);
    },
    handleSlidesSearch(item, type) {
      this.setSearchProgress(true);
      const payload = {};
      payload.username = this.currentUser.user.cognitoID;
      payload.limit = 33;
      payload.skip = 0;
      payload.isSlideLibrarySearch = true;
      payload.type = type;
      if (type === 'enterPress') {
        payload.category = 'none';
        payload.query = item;
        const queryPayload = {
          type: 'Slides',
          category: '',
          name: item,
        };
        this.updateRecentSlideQueries(queryPayload);
      } else {
        payload.category = item?.category ? item.category : 'none';
        payload.query = item.name;
        const queryPayload = {
          type: 'Slides',
          category: payload.category,
          name: item.name,
        };
        this.updateRecentSlideQueries(queryPayload);
      }
      this.saveSlidesStateSnapshot({});
      EventBus.$emit('DO_SEARCH', payload);
    },
    handleBPEslideSearch(item, type) {
      const payload = {};
      payload.isBPELibrarySearch = true;
      payload.type = type;
      if (type === 'enterPress') {
        payload.category = '';
        payload.query = item;
        payload.origin = 'Hybrid';
        const queryPayload = {
          type: 'Best Practice Slides',
          category: '',
          name: item,
        };
        this.updateRecentBPESlideQueries(queryPayload);
      } else {
        payload.category = item?.category ? item.category : '';
        payload.query = item.name;
        payload.origin = 'Dropdown';
        const queryPayload = {
          type: 'Best Practice Slides',
          category: payload.category,
          name: item.name,
        };
        this.updateRecentBPESlideQueries(queryPayload);
      }
      EventBus.$emit('DO_BPE_SEARCH', payload);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-bar {
  width: 380px;
  z-index: 3;
}
.reduce-width {
  width: 230px !important;
  ::v-deep.vs__input {
    // width: 200px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
}
</style>
