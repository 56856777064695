<template>
  <div
    class="slider_content_wrapper"
    :data-pendo="id === 0 ? 'slides-card' : null"
  >
    <v-card
      style="
         {
          color: white;
        }
      "
      class="mx-auto card-wrapper"
    >
      <div :key="iconsKey" class="icons-wrapper">
        <v-tooltip bottom max-width="200" content-class="tooltip-content" fixed>
          <template v-slot:activator="{ on, attrs }">
            <v-img
              v-bind="attrs"
              v-on="on"
              v-if="isTrending"
              src="/assets/img/trending.svg"
              alt
              width="34px"
              height="34px"
            />
          </template>
          <span> {{ $t('bestPracticesGuide.knowSlideCard.trending') }} </span>
        </v-tooltip>
        <v-tooltip bottom max-width="200" content-class="tooltip-content" fixed>
          <template v-slot:activator="{ on, attrs }">
            <v-img
              v-bind="attrs"
              v-on="on"
              v-if="slideDownloaded === 'regDownload'"
              src="/assets/img/already-downloaded.svg"
              alt
              width="34px"
              height="34px"
            />
          </template>
          <span> {{ $t('common.downloaded') }} </span>
        </v-tooltip>
        <v-tooltip bottom max-width="200" content-class="tooltip-content" fixed>
          <template v-slot:activator="{ on, attrs }">
            <v-img
              v-bind="attrs"
              v-on="on"
              v-if="
                slideDownloaded === 'imageReplaced' ||
                slideDownloaded === 'iconReplaced'
              "
              src="/assets/img/already-downloaded-image-replaced.svg"
              alt
              width="34px"
              height="34px"
            />
          </template>
          <span> {{ $t('common.downloaded') }} </span>
        </v-tooltip>
      </div>
      <div @click="handleClickDetail(item, id, 'thumbnail')">
        <v-img
          contain
          :src="
            itemData.thumbnail || '/assets/img/slides/placeholder-slide.svg'
          "
          loading="lazy"
          lazy-src="/assets/img/slides/placeholder-slide.svg"
          alt
          :aspect-ratio="16 / 9"
        />
      </div>
      <div class="prezInfoAndActions">
        <div class="nameAndActions">
          <div
            v-if="
              slideType === 'prezentSourcedSlide' ||
              slideType === 'companyBrandedSlide'
            "
            class="name"
            :style="{ width: `${slideNameWidth}%`, maxWidth: `53%` }"
            @click="handleClickDetail(item, id, 'thumbnail')"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ capitalizeFirstLetter(item.title) }}
                </span>
                <img
                  v-if="item.prefs && item.prefs.source === 'prezent'"
                  src="/assets/img/prezent_avatar.svg"
                  alt
                  width="22px"
                  style="margin: 2px 0px 0px 0px"
                />
              </template>
              <span>{{ capitalizeFirstLetter(item.title) }}</span>
            </v-tooltip>
          </div>
          <div
            v-else
            :style="{ width: `${slideNameWidth}%`, maxWidth: `53%` }"
            class="nameForUploadedAndGenerated"
          >
            <div
              class="uploadedOrGenName"
              v-if="!isEditingName"
              @click="handleClickDetail(item, id, 'thumbnail')"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{
                      itemData.asset && itemData.asset.name
                        ? itemData.asset.name
                        : ''
                    }}
                  </span>
                </template>
                <span>
                  {{
                    itemData.asset && itemData.asset.name
                      ? itemData.asset.name
                      : ''
                  }}
                </span>
              </v-tooltip>
            </div>
            <div class="card-edit-name" v-if="isEditingName">
              <div
                class="errorText"
                v-if="
                  itemData.asset.name.length < 5 ||
                  itemData.asset.name.length > 100
                "
              >
                {{
                  `${
                    itemData.asset.name.length > 5
                      ? ''
                      : $t('slides.enterAtleastFiveCharacters')
                  }`
                }}
                {{
                  `${
                    itemData.asset.name.length > 100
                      ? $t('slides.cannotExceed')
                      : ''
                  }`
                }}
              </div>
              <v-text-field
                v-model="itemData.asset.name"
                hide-details
                :label="itemData.asset.name"
                solo
                @focus="handleEditNameFocus()"
                @blur="handleEditNameBlur()"
                autofocus
              >
                <template #append>
                  <v-btn
                    color="white"
                    rounded
                    min-width="25"
                    height="40"
                    :loading="updatingSlideData"
                    @click="handleEditName"
                    :disabled="
                      itemData.asset.name === '' ||
                      itemData.asset.name.length < 5 ||
                      itemData.asset.name.length > 100 ||
                      itemData.asset.name === cardNameBeforeUpdate
                    "
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </div>
          </div>
          <div class="actions">
            <template>
              <v-tooltip
                bottom
                max-width="200"
                content-class="tooltip-content"
                fixed
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-if="!isFavorite">
                    <v-progress-circular
                      indeterminate
                      :size="20"
                      color="primary"
                      v-if="isSlideAddingToFav"
                    />
                    <v-icon
                      v-else
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleFavorite(itemData, slideOrigin)"
                      :size="22"
                      style="margin: 1px 0px 0px 8px"
                    >
                      mdi-heart-outline
                    </v-icon>
                  </div>
                  <div v-else>
                    <v-progress-circular
                      indeterminate
                      :size="20"
                      color="primary"
                      v-if="isSlideRemovingFromFav"
                    />
                    <v-icon
                      v-else
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleFavorite(itemData, slideOrigin)"
                      :size="22"
                      style="margin: 1px 0px 0px 8px"
                    >
                      mdi-heart
                    </v-icon>
                  </div>
                </template>
                <span>{{
                  `${
                    isFavorite
                      ? $t('bestPracticesGuide.knowSlideCard.removeFavorites')
                      : $t('bestPracticesGuide.knowSlideCard.addFavorites')
                  }`
                }}</span>
              </v-tooltip>
            </template>
            <div
              class="show-detail pl-2"
              @mouseenter="trackCollectionMaxLimitEvent"
              @click="handleCollection(item)"
            >
              <v-tooltip
                bottom
                max-width="200"
                content-class="tooltip-content"
                fixed
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-if="isAddedToCollection" v-bind="attrs" v-on="on">
                    <v-progress-circular
                      indeterminate
                      :size="20"
                      color="primary"
                      v-if="removingFromCollection"
                    />
                    <v-icon v-else color="primary" :size="21">
                      mdi-file-minus
                    </v-icon>
                  </div>
                  <div v-else v-bind="attrs" v-on="on">
                    <v-progress-circular
                      indeterminate
                      :size="20"
                      color="primary"
                      v-if="addingToCollection"
                    />
                    <v-icon
                      v-else
                      :color="isCollectionLimitReached ? 'grey' : 'primary'"
                      v-bind="attrs"
                      v-on="on"
                      :size="21"
                    >
                      mdi-file-plus-outline
                    </v-icon>
                  </div>
                  <NewFeatureBadge
                    module-name="collections"
                    :feature-id="'collections_thumbnail_id'"
                    :offsetX="'26'"
                    :offsetY="'-16'"
                    :size="'small'"
                    v-if="id === 0"
                  >
                  </NewFeatureBadge>
                </template>
                <span>{{
                  `${
                    !isAddedToCollection
                      ? isCollectionLimitReached
                        ? $t('replaceImage.addToCollectionTooltip')
                        : $t('replaceImage.addToCollection')
                      : $t('slides.removeFromCollection')
                  }`
                }}</span>
              </v-tooltip>
            </div>
            <v-progress-circular
              indeterminate
              :size="20"
              color="primary"
              v-if="isLocalDownloading"
              :style="{ margin: '3px 0px 0px 6px' }"
            />
            <Download
              v-else
              class="pl-1"
              :mode="'icon'"
              :file-name="getFilename(item)"
              :item="item"
              :log-download="logDownload"
              :count-first-time="1"
              :on-download="
                () =>
                  handleDownloadFromListFunction(
                    false,
                    item,
                    false,
                    slideOrigin,
                    true,
                  )
              "
              origin="slide"
              limitsKey="slide"
              :speedDialPosTop="'-140'"
              :speedDialPosRight="'0'"
              :data-pendo-id="id === 0 ? 'slides-download-action' : null"
            />
            <v-menu
              top
              absolute
              offset-y
              :close-on-click="true"
              :close-on-content-click="closeEllipsisMenu"
              :position-x="menuPos.x"
              :position-y="menuPos.y"
              :key="menuKey"
            >
              <template #activator="menu">
                <v-tooltip bottom>
                  <template #activator="tooltip">
                    <div style="position: relative">
                      <v-icon
                        v-on="{ ...menu.on, ...tooltip.on }"
                        color="primary"
                        :disabled="disableEllipsisOnThumbnail"
                      >
                        mdi-dots-vertical
                      </v-icon>
                      <NewFeatureBadge
                        module-name="slides"
                        :feature-id="'slides_revamp_id'"
                        :offsetX="'0'"
                        :offsetY="'0'"
                        :size="'small'"
                        v-if="id === 0"
                        style="position: absolute; top: 2px; right: 26px"
                      >
                      </NewFeatureBadge>
                    </div>
                  </template>
                  <span>{{ $t('slides.moreActions') }}</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item
                  v-for="(action, index) in getActionsWithPermissions()"
                  :key="index"
                  @click="handleDotOptionClick(action.name)"
                >
                  <v-list-item-title>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <div
                          v-on="on"
                          v-if="action.name === 'Copy link'"
                          class="copyLink"
                        >
                          <div class="copyIconAndTitle">
                            <div class="icon">
                              <v-icon>{{ action.icon }}</v-icon>
                            </div>
                            <div class="actionName">
                              {{ $t(action.display_name) }}
                            </div>
                          </div>
                          <div v-if="showCopied === true" class="copiedStatus">
                            <v-icon small color="#21a7e0">mdi-check</v-icon>
                            <div>
                              {{ $t('navbarActions.shareFreeTrial.copied') }}
                            </div>
                          </div>
                        </div>
                        <div v-else v-on="on" class="otherActions">
                          <div class="icon">
                            <v-icon>{{ action.icon }}</v-icon>
                          </div>
                          <div class="actionName">
                            {{ $t(action.display_name) }}
                          </div>
                        </div>
                      </template>
                      <span>
                        {{
                          action.name === 'Delete'
                            ? $t('build.step3.deleteSlide')
                            : $t(action.display_name)
                        }}
                      </span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div
          class="typeAndDate"
          @click="handleClickDetail(item, id, 'thumbnail')"
        >
          <div class="type">
            {{ getViewsCount }}
          </div>
          <template v-if="slideType !== 'prezentSourcedSlide'">
            <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
            <div class="date" v-if="slideType === 'companyBrandedSlide'">
              {{
                $t('slides.uploadedOnLabel', {
                  formattedDate: formatCompanyBrandSlideDate(
                    item.prefs ? item.prefs.created_at : '',
                  ),
                })
              }}
            </div>
            <div
              class="date"
              v-else-if="
                [
                  'synthesisSlide',
                  'userUploadedSlide',
                  'userGeneratedSlide',
                  'userRedesignSlide',
                  'userComplySlide',
                ].includes(slideType)
              "
            >
              {{
                `${$t(getDateText)} ${formatDate(
                  item.prefs ? item.prefs.created_at : '',
                )}`
              }}
            </div>
          </template>
        </div>
        <div
          class="authorAndAudience"
          @click="handleClickDetail(item, id, 'thumbnail')"
        >
          <div class="author">
            {{
              $t('prezentationsList.sourceLabel', {
                sourceName: getSourceForTile(),
              })
            }}
          </div>
          <template v-if="slideType !== 'prezentSourcedSlide'">
            <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
            <div class="audience" v-if="slideType === 'companyBrandedSlide'">
              {{ $t('slides.author') }}
            </div>
            <div
              class="audience"
              v-else-if="
                [
                  'synthesisSlide',
                  'userUploadedSlide',
                  'userGeneratedSlide',
                  'userRedesignSlide',
                  'userComplySlide',
                ].includes(slideType)
              "
            >
              {{
                $t('prezentationsList.authorLabel', {
                  authorName: item.isOwner
                    ? $t('fingerprint.compareFingAud.me')
                    : item.ownerName,
                })
              }}
            </div>
          </template>
        </div>
        <div class="permissionAndComments">
          <div class="permission">
            <SlideShareStatus
              v-if="
                itemData &&
                itemData.asset &&
                Object.keys(itemData.asset).length > 0
              "
              :itemData="itemData.asset"
              :type="'status'"
              :slideType="slideType"
              :updateSlideWithStatus="updateSlideShareStatus"
              :slidesMetaData="slidesMetaData"
            />
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import moment from 'moment';
import inViewport from 'vue-in-viewport-mixin';
import { mapActions, mapGetters, mapState } from 'vuex';
import Download from '@/components/common/Download';
import { getFileURL } from '@/utils/calyrex';
import { capitalizeFirstLetter, getFilename } from '../../../utils/common';
import { getThumbnail } from '../../../store/modules/helper';
import {
  deleteCollection,
  getFeatureUsage,
  postFeatureUsage,
  updateCollection,
  updateSlide,
} from '../../../utils/api-helper';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents';
import {
  NA,
  TD_AUD,
  TD_ISFAVORITED,
  TD_PLACEMENT,
  TD_COMPANY_OF_AUD_TAILORED_FOR,
  getAudienceCompanyName,
  TD_THUMBNAIL_VIEW,
  TD_ISDOWNLOAD,
  TD_VIEWTYPE,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { trackDownloadSettingsEvents } from '../../common/Analytics/DownloadSettingsEvents.js';
import SlideShareStatus from './SlideShareStatus.vue';
import ShareSlidePermissions from './ShareSlidePermissions.vue';
import SlideDeleteDialog from './SlideDeleteDialog.vue';
import UserDownloadPreferenceSelector from '../../common/UserDownloadPreferenceSelector.vue';
import { convertPdfToImage } from '../../../utils/pdf-to-png';
import { doesPrefsObjectHasImage } from '../../utils';

export default {
  components: {
    Download,
    NewFeatureBadge,
    SlideShareStatus,
  },
  mixins: [inViewport],
  name: 'Slide',
  data() {
    return {
      slideNameWidth: 0,
      itemData: {},
      isLoading: false,
      slideDownloaded: '',
      showCopied: false,
      iconsKey: Date.now(),
      isChangingFavorite: false,
      menuKey: 0,
      menuPos: {
        x: 0,
        y: 0,
      },
      closeEllipsisMenu: true,
      dotActions: [
        {
          name: 'Rename',
          display_name: 'overnightPresentations.rename',
          icon: 'mdi-pencil-outline',
          tooltipRequired: true,
          tooltipContent: 'Edit Slide Name',
        },
        {
          name: 'Copy link',
          display_name: 'userUploadPrezentation.copyLink',
          icon: 'mdi-link-variant',
          tooltipRequired: true,
          tooltipContent: 'Copy Slide Link',
        },
        {
          name: 'Share',
          display_name: 'common.share',
          icon: 'mdi-account-check-outline',
          tooltipRequired: true,
          tooltipContent: 'Share Slide Access',
        },
        {
          name: 'Delete',
          display_name: 'common.delete',
          icon: 'mdi-delete-outline',
          tooltipRequired: true,
          tooltipContent: 'Delete Slide',
        },
        {
          name: 'Replace image',
          display_name: 'replaceIcon.replaceImageCTAOption',
          icon: 'mdi-image-outline',
          tooltipRequired: true,
          tooltipContent: 'Replace image',
        },
      ],
      isEditingName: false,
      cardNameBeforeUpdate: '',
      updatingSlideData: false,
      addingToCollection: false,
      removingFromCollection: false,
    };
  },
  watch: {
    listDownloads() {
      this.handleIsDownloaded();
    },
    listImageReplaced() {
      this.handleIsDownloaded();
    },
    listIconReplaced() {
      this.handleIsDownloaded();
    },
    // eslint-disable-next-line func-names
    'inViewport.now': async function (visible) {
      if (visible) {
        await this.reload_data();
      }
    },
    item(newVal, oldVal) {
      // watch it
      if (newVal !== oldVal) {
        if (this.isLoading) return;
        (async () => {
          await this.reload_data();
        })();
      }
    },
  },
  methods: {
    ...mapActions('slidesCollection', [
      'setCollectionData',
      'setCollectionUpdateProgress',
    ]),
    ...mapActions('slidesStore', [
      'setReplaceImageActionClicked',
      'setFlowActive',
    ]),
    ...mapActions('users', ['setLimitData', 'setUpgradePopup']),
    capitalizeFirstLetter,
    getFilename,
    getSlideNameWidth() {
      if (window.innerWidth > 1300 && window.innerWidth <= 1400) {
        this.slideNameWidth = 50;
      } else if (window.innerWidth > 1200 && window.innerWidth <= 1300) {
        this.slideNameWidth = 50;
      } else {
        this.slideNameWidth = 60;
      }
    },
    async handleDownloadFromListFunction(
      param1,
      item,
      param2,
      slideOrigin,
      isSlideDownload,
    ) {
      if (!isSlideDownload) {
        await getFeatureUsage()
          .then((response) => {
            if (response?.data) {
              this.setLimitData(response.data);
            }
          })
          .catch((error) => console.log(error));
      }
      if (
        !this.limitsInfoObj?.slide?.isLimitCrossed ||
        this.currentUser.user.role !== 'TrialUser'
      ) {
        if (!isSlideDownload) {
          postFeatureUsage({ feature: 'slide' })
            .then((response) => {
              console.log(response);
              if (response?.data) {
                this.setLimitData(response.data);
              }
            })
            .catch((error) => console.log(error));
        }

        this.handleDownloadFromList(param1, item, param1, slideOrigin);
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'slide' });
      }
    },
    handleStartDelete() {
      this.$modal.show(
        SlideDeleteDialog,
        {
          slide: this.itemData.asset,
          removeSlideOnDelete: this.removeSlideOnDelete,
          currentUser: this.currentUser,
          slideOrigin: this.slideOrigin,
        },
        {
          name: 'SlideDeleteDialog',
          width: '500px',
          height: 'auto',
          'border-radius': '20px',
          styles: {
            'max-height': '50%',
            'max-width': '600px',
            overflow: 'auto',
            'border-radius': '20px',
          },
        },
      );
    },

    handleEditNameFocus() {
      this.cardNameBeforeUpdate = this.itemData.asset.name;
    },

    handleEditNameBlur() {
      this.itemData.asset.name = this.cardNameBeforeUpdate;
      this.isEditingName = false;
      trackSlideEvents.slidesRenameOutsideClick(this.item, this.currentUser, {
        [TD_ISFAVORITED]: this.favoriteSlides.includes(this.item?.unique_id)
          ? '1'
          : NA,
      });
    },

    handleStartRenameOfSlide() {
      this.isEditingName = true;
    },

    async handleEditName() {
      const slideName = this.itemData.asset.name.trim();
      if (!slideName) {
        this.isEditingName = false;
        return false;
      }
      this.updatingSlideData = true;
      await updateSlide({
        assetID: this.itemData.asset.unique_id,
        name: slideName,
      })
        .then((resp) => {
          console.log(resp);
          this.itemData = {
            ...this.itemData,
            asset: {
              ...this.itemData.asset,
              name: slideName,
            },
          };
          trackSlideEvents.slidesRenameTick(this.item, this.currentUser, {
            [TD_ISFAVORITED]: this.favoriteSlides.includes(this.item?.unique_id)
              ? '1'
              : NA,
          });
        })
        .catch((err) => {
          console.log(err);
        });
      this.updatingSlideData = false;
      this.isEditingName = false;
      return true;
    },

    handleIsDownloaded() {
      if (this.listImageReplaced.includes(this.item.unique_id)) {
        this.slideDownloaded = 'imageReplaced';
        this.iconsKey++;
        return;
      }
      if (this.listIconReplaced.includes(this.item.unique_id)) {
        this.slideDownloaded = 'iconReplaced';
        this.iconsKey++;
        return;
      }
      if (this.listDownloads.includes(this.item.unique_id)) {
        this.slideDownloaded = 'regDownload';
        this.iconsKey++;
      }
    },

    getDateWithMoment(date) {
      const newDate = new Date(date);
      return moment(newDate).format('MM/DD/YYYY');
    },

    formatDate(date) {
      if (date && date !== '') {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8);
        return `${month}/${day}/${year}`;
      }
      return 'NA';
    },

    formatCompanyBrandSlideDate(dateString) {
      if (dateString && dateString !== '') {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        // Add leading zero if month/day is less than 10
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;
        return `${month}/${day}/${year}`;
      }
      return 'NA';
    },

    updateSlideShareStatus(status) {
      this.itemData = {
        ...this.itemData,
        asset: {
          ...this.itemData.asset,
          isShared: status,
        },
      };
    },

    handleShareSlide() {
      this.$modal.show(
        ShareSlidePermissions,
        {
          slideOpened: this.item,
          template: this.currentTheme,
          type: 'modal',
          updateSlide: this.updateSlideShareStatus,
        },
        {
          name: 'ShareSlidePermissions',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '12px',
          },
          clickToClose: false,
        },
      );
    },

    handleDotOptionClick(type) {
      switch (type) {
        case 'Delete':
          this.handleStartDelete();
          break;
        case 'Rename':
          this.cardNameBeforeUpdate = this.itemData.asset.name;
          this.handleStartRenameOfSlide();
          break;
        case 'Copy link':
          this.copyToClipBoard();
          trackSlideEvents.slidesElipsisCopyLink(this.item, this.currentUser, {
            [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
            [TD_ISFAVORITED]: this.favoriteSlides.includes(this.item?.unique_id)
              ? '1'
              : NA,
          });
          break;
        case 'Share':
          this.handleShareSlide();
          break;
        case 'Replace image':
          this.setReplaceImageActionClicked(true);
          this.setFlowActive('replaceImage');
          this.handleClickDetail(this.item, this.id, 'thumbnail');
          break;
        case 'Download settings':
          this.handleDownloadSettingsEvents();
          this.openUserDownloadPreferenceModal();
          break;
        default:
          break;
      }
    },
    handleDownloadSettingsEvents() {
      const otherData = {
        [TD_ISDOWNLOAD]: 0,
        [TD_VIEWTYPE]: TD_THUMBNAIL_VIEW,
        [TD_PLACEMENT]: 'Slide Library',
      };
      trackDownloadSettingsEvents.downloadSettingsClick(
        this.currentUser,
        otherData,
      );
    },

    openUserDownloadPreferenceModal() {
      this.$modal.show(
        UserDownloadPreferenceSelector,
        {
          compType: 'modal',
          viewType: TD_THUMBNAIL_VIEW,
        },
        {
          name: 'UserDownloadPreferenceSelector',
          width: '500px',
          height: 'auto',
          styles: {
            borderRadius: '12px',
          },
          // clickToClose: false,
        },
      );
    },

    handleThreeDotsClick(e) {
      this.menuPos.x = e.clientX - 110;
      this.menuPos.y = e.clientY;
    },

    copyToClipBoard() {
      this.closeEllipsisMenu = false;
      this.showCopied = true;
      navigator.clipboard.writeText(
        `${window.location.origin}/home/slides/${
          [
            'synthesisSlide',
            'userUploadedSlide',
            'userGeneratedSlide',
            'userRedesignSlide',
            'userComplySlide',
          ].includes(this.slideType)
            ? this.item.id
            : this.item.unique_id
        }`,
      );
      setTimeout(() => {
        this.closeEllipsisMenu = true;
        this.showCopied = false;
        this.menuKey++;
      }, 1000);
    },

    getActionsWithPermissions() {
      let actions = [...this.dotActions];

      switch (this.slideType) {
        case 'prezentSourcedSlide':
          actions = [...this.dotActions].filter(
            (act) => act.name === 'Copy link',
          );
          break;
        case 'companyBrandedSlide':
          actions = [...this.dotActions].filter(
            (act) => act.name === 'Copy link',
          );
          break;
        case 'userGeneratedSlide':
        case 'userRedesignSlide':
        case 'userComplySlide':
          if (this.item.isOwner) {
            actions = [...this.dotActions];
            break;
          }
          actions = [...this.dotActions].filter(
            (act) => act.name === 'Copy link',
          );
          break;
        case 'userUploadedSlide':
          if (this.item.isOwner) {
            actions = [...this.dotActions];
            break;
          }
          actions = [...this.dotActions].filter(
            (act) => act.name === 'Copy link',
          );
          break;
        default:
          break;
      }
      if (
        this.canReplaceImage &&
        this.allowReplaceImageOverall &&
        this.allowReplaceImageTabs &&
        this.dotActions.length !== actions.length
      ) {
        actions.push(
          ...this.dotActions.filter((act) => act.name === 'Replace image'),
        );
      }

      // adding download settings option
      if (this.isGoogleDownloadEnabled) {
        actions.push({
          name: 'Download settings',
          display_name: 'common.downloadSettingsText',
          icon: 'mdi-cog-outline',
          tooltipRequired: true,
          tooltipContent: 'Download settings',
        });
      }
      return actions;
    },

    trackCollectionMaxLimitEvent() {
      if (this.isCollectionLimitReached && !this.isAddedToCollection) {
        trackSlideEvents.slidesCollectionMaxLimit(this.item, this.currentUser, {
          [TD_AUD]: this.tailorID || this.currentUser?.user?.id,
          [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
            this.audience,
            this.currentUser,
          ),
          [TD_ISFAVORITED]: this.favoriteSlides.includes(this.item.unique_id)
            ? '1'
            : NA,
        });
      }
    },

    async reload_data() {
      const obj = {
        thumbnail: '/assets/img/404.png',
        landscape: '/assets/img/404.png',
      };
      if (this.isLoading) return;
      this.isLoading = true;
      obj.asset = this.item;
      obj.asset.name = obj.asset.name ? obj.asset.name : '';
      obj.asset.file = obj.asset.filename;
      obj.asset.pptFile = obj.asset.file;
      obj.thumbnail = '';

      // Setting item intermedietly to avoid displaying image caching
      this.itemData = obj;
      if (obj.asset.thumbnail) {
        const thumbnailPath = obj.asset.poster;
        if (obj.asset.prefs.source === 'uploaded') {
          const tempThumbnail = await getFileURL(
            this.currentUser.user.cognitoID,
            thumbnailPath,
            this.currentUser.userIp,
            'IOCUpload',
          );
          obj.thumbnail = await convertPdfToImage(tempThumbnail);
        } else if (
          obj.asset.prefs.source === 'generated' ||
          obj.asset.prefs.source === 'synthesis' ||
          obj.asset.prefs.source === 'redesign' ||
          obj.asset.prefs.source === 'comply'
        ) {
          if (
            thumbnailPath.startsWith('/newImageReplaceOutput/output/') ||
            thumbnailPath.startsWith('/output/pptx/')
          ) {
            obj.thumbnail = await getThumbnail(thumbnailPath);
          } else {
            const tempThumbnail = await getFileURL(
              this.currentUser.user.cognitoID,
              thumbnailPath,
              this.currentUser.userIp,
              'IOCUpload',
            );
            obj.thumbnail = tempThumbnail;
          }
        } else {
          obj.thumbnail = await getFileURL(
            this.currentUser.user.cognitoID,
            thumbnailPath,
            this.currentUser.userIp,
          );
        }
      }
      this.itemData = obj;
      this.handleIsDownloaded();
      this.isLoading = false;
    },

    /*
      Function to find template object
      based on current slide associated template code
    */
    getTemplateForSlideLoaded(data) {
      const template = this.themes.find(
        (thm) => thm.code === this.getTemplateCodeFromSlideLoaded(data),
      );
      return template;
    },

    /*
      Function to return the slide object related theme code
      based on type of slide
    */
    getTemplateCodeFromSlideLoaded(data) {
      if (
        ['generated', 'uploaded', 'synthesis', 'redesign', 'comply'].includes(
          data?.prefs?.source,
        )
      ) {
        return data?.templateCode || '';
      }
      return data?.prefs?.theme || '';
    },

    async handleCollection() {
      console.log('handleCollection-----');
      this.setCollectionUpdateProgress(true);
      if (this.isCollectionLimitReached && !this.isAddedToCollection) return;
      if (this.isAddedToCollection) {
        const params = {
          type: 'slide',
          slideID: String(this.item.unique_id),
          templateID: this.getTemplateForSlideLoaded(this.item)?.id,
          slideFileName: String(this.item.file || this.item.filename),
        };
        this.removingFromCollection = true;
        await deleteCollection(params)
          .then((resp) => {
            console.log(resp);
            this.setCollectionData(resp.data.collections);
            this.$emit('openCollections');
            // this.collections[indexOfSet].slides.splice(indexOfSlide, 1);
          })
          .catch((err) => {
            console.log(err);
          });
        this.removingFromCollection = false;
        trackSlideEvents.slidesRemoveFromCollection(
          this.item,
          this.currentUser,
          {
            [TD_AUD]: this.tailorID || this.currentUser?.user?.id,
            [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
              this.audience,
              this.currentUser,
            ),
            [TD_ISFAVORITED]: this.favoriteSlides.includes(this.item.unique_id)
              ? '1'
              : NA,
            [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
          },
        );
      } else {
        const params = {
          templateID: this.getTemplateForSlideLoaded(this.item)?.id,
          templateCode: this.getTemplateCodeFromSlideLoaded(this.item),
          templateName: this.getTemplateForSlideLoaded(this.item)?.name,
          slideFileName: this.item.file || this.item.filename,
          slideThumbnail: this.item.thumbnail,
          slideID: String(this.item.unique_id),
          idea: this.item.title,
          tailorID: this.tailorID || this.currentUser.user.id,
          construct: this.item.prefs.construct,
          isImageReplaced: false,
          isIconReplaced: false,
          source: this.item.prefs.source,
          sanitizedLevel: this.item.sanitizedLevel,
          acceptedRejectedCount: this.item.acceptedRejectedCount,
        };
        this.addingToCollection = true;
        await updateCollection(params)
          .then((resp) => {
            console.log(resp);
            this.setCollectionData(resp.data.collections);
            this.$emit('openCollections');
          })
          .catch((err) => {
            console.log(err);
          });
        this.addingToCollection = false;
        trackSlideEvents.slidesAddToCollection(this.item, this.currentUser, {
          [TD_ISFAVORITED]: this.favoriteSlides.includes(this.item.unique_id)
            ? '1'
            : NA,
          [TD_AUD]: this.tailorID || this.currentUser?.user?.id,
          [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
            this.audience,
            this.currentUser,
          ),
          [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
        });
      }
      this.setCollectionUpdateProgress(false);
    },

    getSourceForTile() {
      let source = '';
      if (this.slideType === 'prezentSourcedSlide') {
        source = 'Prezent';
      } else if (this.slideType === 'companyBrandedSlide') {
        source = this.capitalizeFirstLetter(this.currentUser.company.name);
      } else if (
        [
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
        ].includes(this.slideType)
      ) {
        source = this.capitalizeFirstLetter(this.currentUser.company.name);
      } else if (this.slideType === 'synthesisSlide') {
        source = this.capitalizeFirstLetter(this.currentUser.company.name);
      }
      return source;
    },
  },
  props: {
    favoriteSlides: {
      type: Array,
    },
    id: {
      type: Number,
      required: false,
    },
    item: {
      type: Object,
      required: true,
    },
    isTrending: {
      type: Boolean,
      default: false,
    },
    audience: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    logDownload: {
      type: Function,
      default: () => ({}),
    },
    handleFavorite: {
      type: Function,
      default: () => ({}),
    },
    slideOrigin: {
      type: String,
      default: '',
    },
    listDownloads: {
      type: Array,
    },
    listImageReplaced: {
      type: Array,
      default: () => [],
    },
    listIconReplaced: {
      type: Array,
      default: () => [],
    },
    handleClickDetail: {
      type: Function,
      default: () => ({}),
    },
    handleDownloadFromList: {
      type: Function,
      default: () => ({}),
    },
    slidesMetaData: {
      type: Object,
      default: () => ({}),
    },
    removeSlideOnDelete: {
      type: Function,
      default: () => {},
    },
    downloadingSlides: {
      type: Array,
      default: () => [],
    },
    addingToFavSlides: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    styles() {
      return {
        width: this.size,
        height: this.size,
      };
    },
    ...mapState('users', [
      'currentUser',
      'isTrialExpired',
      'themes',
      'currentTheme',
      'lastAction',
      'assetVotes',
      'searchTerms',
      'limitsInfoObj',
    ]),
    ...mapState('slidesCollection', ['tailorID']),
    ...mapGetters('slidesCollection', [
      'getAddedCollections',
      // 'isCollectionLimitReached',
      'getAllAddedCollections',
    ]),
    ...mapGetters('users', [
      'allowReplaceImageOverall',
      'allowReplaceImageTabs',
    ]),

    ...mapGetters('commonDownloads', ['getDownloadLoaders']),

    /*
      computed property for determining
      is collection limit is reached for a collection
      belonging to this slide's template
    */
    isCollectionLimitReached() {
      let slidesInCollectionUnderSlideTemplate = [];
      const slideThemeObj = this.getTemplateForSlideLoaded(this.item);
      const currentCollection = this.getAllAddedCollections.find(
        (i) => i.templateID === slideThemeObj?.id,
      );
      if (currentCollection) {
        slidesInCollectionUnderSlideTemplate = currentCollection.slides.map(
          (i) => i.slideID,
        );
      } else {
        slidesInCollectionUnderSlideTemplate = [];
      }

      return slidesInCollectionUnderSlideTemplate.length >= 5;
    },

    isLocalDownloading() {
      const slideId =
        this.item.prefs.source === 'uploaded' ||
        this.item.prefs.source === 'generated' ||
        this.item.prefs.source === 'synthesis' ||
        this.item.prefs.source === 'redesign' ||
        this.item.prefs.source === 'comply'
          ? this.item.id
          : this.item.unique_id;
      const ldr = this.getDownloadLoaders.find((ld) => ld.loaderId === slideId);
      return ldr && ldr.downloading;
    },

    isGoogleDownloadEnabled() {
      if (this.currentUser) {
        const feature = this.currentUser.features.find(
          (f) => f.feature_name === 'google_slides_download',
        );
        return feature && feature.enabled;
      }
      return false;
    },

    getDateText() {
      if (this.slideType === 'userUploadedSlide') {
        return 'build.step3.updatedOn';
      }
      if (this.slideType === 'userGeneratedSlide') {
        return 'build.step3.generatedOn';
      }
      if (this.slideType === 'synthesisSlide') {
        return 'build.step3.synthesizedOn';
      }
      if (this.slideType === 'userRedesignSlide') {
        return 'build.step3.redesignedOn';
      }
      if (this.slideType === 'userComplySlide') {
        return 'build.step3.addedOn';
      }
      return '';
    },
    slideType() {
      let type = '';
      if (this.item.prefs) {
        const { source } = this.item.prefs;
        if (source === 'prezent') {
          type = 'prezentSourcedSlide';
        } else if (source === 'generated') {
          type = 'userGeneratedSlide';
        } else if (source === 'uploaded') {
          type = 'userUploadedSlide';
        } else if (source === 'synthesis') {
          type = 'synthesisSlide';
        } else if (source === 'redesign') {
          type = 'userRedesignSlide';
        } else if (source === 'comply') {
          type = 'userComplySlide';
        } else {
          type = 'companyBrandedSlide';
        }
      }
      return type;
    },
    isFavorite() {
      return this.favoriteSlides.includes(this.item.unique_id);
    },
    getViewsCount() {
      const views = this.slidesMetaData[this.item.unique_id];
      if (views && views.viewCount) {
        const view = views.viewCount;
        if (view > 0 && view < 1000) {
          return `${view} ${
            Number(view) === 1
              ? this.$t('bestPracticesGuide.knowSlideCard.view')
              : this.$t('bestPracticesGuide.knowSlideCard.views')
          }`;
        }
        if (view >= 1000 && view < 1000000) {
          return `${
            view % 1000 === 0 ? 1 : Number(view / 1000).toFixed(1)
          }K ${this.$t('bestPracticesGuide.knowSlideCard.views')}`;
        }
        if (view >= 1000000) {
          return `${
            view % 1000000 === 0 ? 1 : Number(view / 1000000).toFixed(1)
          }M ${this.$t('bestPracticesGuide.knowSlideCard.views')}`;
        }
      }
      return this.$t('bestPracticesGuide.knowSlideCard.noViews');
    },

    /*
      computed property for determining
      if the this slide belongs to a collection
      based on slide's template
    */
    isAddedToCollection() {
      let slidesInCollectionUnderSlideTemplate = [];
      const slideThemeObj = this.getTemplateForSlideLoaded(this.item);
      const currentCollection = this.getAddedCollections.find(
        (i) => i.templateID === slideThemeObj?.id,
      );
      if (currentCollection) {
        slidesInCollectionUnderSlideTemplate = currentCollection.slides
          .filter(
            (i) =>
              !i.isImageReplaced &&
              !i.isIconReplaced &&
              !['partial', 'full'].includes(
                i.sanitizedLevel && i.sanitizedLevel.toLowerCase(),
              ),
          )
          .map((i) => i.slideID);
      } else {
        slidesInCollectionUnderSlideTemplate = [];
      }

      return slidesInCollectionUnderSlideTemplate.includes(this.item.unique_id);
    },

    disableEllipsisOnThumbnail() {
      return (
        [
          'synthesisSlide',
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
        ].includes(this.slideType) &&
        !this.item.isOwner &&
        this.item.permission === 'Viewer'
      );
    },
    isSlideAddingToFav() {
      const matchedSlide = this.addingToFavSlides.find(
        (item) => item.id === this.item.unique_id,
      );
      return (
        this.addingToFavSlides &&
        this.addingToFavSlides.length &&
        matchedSlide &&
        matchedSlide.adding &&
        matchedSlide.adding === true
      );
    },
    isSlideRemovingFromFav() {
      const matchedSlide = this.addingToFavSlides.find(
        (item) => item.id === this.item.unique_id,
      );
      return (
        this.addingToFavSlides &&
        this.addingToFavSlides.length &&
        matchedSlide &&
        matchedSlide.removing &&
        matchedSlide.removing === true
      );
    },
    // downloadingSlide() {
    //   const matchedSlide = this.downloadingSlides.find(
    //     (item) => item.id === this.item.unique_id,
    //   );
    //   return (
    //     this.downloadingSlides &&
    //     this.downloadingSlides.length &&
    //     matchedSlide &&
    //     matchedSlide.downloading &&
    //     matchedSlide.downloading === true
    //   );
    // },
    canReplaceImage() {
      return doesPrefsObjectHasImage(this.item.prefs);
    },
  },
  mounted() {
    this.getSlideNameWidth();
    window.addEventListener('resize', this.getSlideNameWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.getSlideNameWidth);
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.slider_content_wrapper {
  padding: 8px;
  cursor: pointer;

  .card-wrapper {
    min-width: 200px;
    transition: all ease 0.5s;
    border-radius: 6px;

    .v-image {
      border-radius: 6px 6px 0px 0px;
    }

    .prezInfoAndActions {
      border-top: 0.5px solid rgb(235 236 235);
      padding: 8px 12px;

      .nameAndActions {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 16px;
        margin: 4px 0px;
        min-height: 30px;
        max-height: 30px;

        .name {
          display: flex;
          gap: 2px;
          align-items: center;

          span {
            display: inline-block;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .nameForUploadedAndGenerated {
          max-width: 60%;
          height: 30px;

          .uploadedOrGenName {
            span {
              width: 100%;
              display: inline-block;
              text-align: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .card-edit-name {
            cursor: pointer;

            .errorText {
              font-size: 10px;
              text-align: left;
              color: red;
              margin: -11px 0px 0px 0px;
            }

            ::v-deep .v-input {
              .v-input__control {
                min-height: 20px !important;

                .v-input__slot {
                  border-bottom: 1px solid black;
                  border-radius: 0;
                  box-shadow: none;
                  padding: 0;

                  .v-text-field__slot {
                    input {
                      font-size: 16px;
                      padding: 0px !important;
                      font-family: 'Lato', sans-serif;
                      font-weight: 500;
                      letter-spacing: 0.0125em;
                      word-break: break-all;
                    }
                  }

                  .v-input__append-inner {
                    padding-bottom: 4px;
                  }
                }
              }
            }

            .v-btn {
              height: 21px !important;
              margin: 0px 0px 0px 10px;
            }
          }
        }

        .actions {
          display: flex;
          justify-content: space-between;
          min-width: 28%;

          .v-icon {
            width: 25px;
            height: 25px;

            &:hover {
              background-color: #21a7e01c;
              border-radius: 20px;
            }

            &:after {
              background-color: transparent;
            }
          }

          .download-actions {
            display: inline;
          }
        }
      }

      .typeAndDate {
        color: rgb(130 131 130);
        font-size: 12px;
        display: flex;
        margin: 2px 0px;

        .type {
          text-align: left;
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .date {
          text-align: left;
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .authorAndAudience {
        color: rgb(130 131 130);
        font-size: 12px;
        display: flex;
        margin: 2px 0px;

        .author {
          text-align: left;
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .audience {
          text-align: left;
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .permissionAndComments {
        display: flex;
        margin: 4px 0px;
        justify-content: space-between;

        .comments {
          margin: 0px 3px 0px 0px;
        }

        .commentsWithSideBarAndAdvFilterActive {
          margin: 0;
        }

        .comments,
        .commentsWithSideBarAndAdvFilterActive {
          .iconWrapper {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &:hover {
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.5);
    }

    .icons-wrapper {
      display: flex;
      position: absolute;
      right: 12px;
      top: 12px;
      z-index: 1;
    }
  }
}

.v-menu__content {
  .v-list {
    .v-list-item {
      cursor: pointer;

      .v-list-item__title {
        .copyLink {
          .copyIconAndTitle {
            display: flex;

            .actionName {
              padding: 2px 4px 0px 4px;
            }
          }

          .copiedStatus {
            width: 60%;
            margin: 0 auto;
            font-size: 11px;
            display: flex;

            .v-icon {
              font-size: 12px !important;
            }
          }
        }

        .otherActions {
          display: flex;

          .actionName {
            padding: 2px 4px 0px 4px;
          }
        }
      }

      &:hover {
        background-color: #d8d8d8;
      }
    }
  }
}

.modalCard {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #68788c;
  height: 170px !important;
  width: 559px;

  .delete-btn-prezentation {
    border-radius: 25px;
    border: solid 2px #21a7e0;
    font-family: 'Roboto', sans-serif !important;
    font-size: 18px;
    height: 36px !important;
    margin: 0 30px 0 0 !important;
    padding: 11px 42px 11px 43px !important;
    text-transform: unset !important;
    letter-spacing: normal;
  }

  .cancel-btn-prezentation {
    border-radius: 25px;
    border: solid 2px #21a7e0;
    font-family: 'Roboto', sans-serif !important;
    font-size: 18px;
    height: 36px !important;
    margin: 0 30px 0 0 !important;
    padding: 11px 42px 11px 43px !important;
    text-transform: unset !important;
    max-width: 64px;
    padding-right: 48px !important;
    padding-left: 48px !important;
    letter-spacing: normal;
  }

  .remove-prezentation-title {
    color: #000;
    font-family: 'Roboto', sans-serif !important;
    font-size: 20px !important;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal !important;
    line-height: normal;
    padding-bottom: 15px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 20px !important;
  }
}
</style>
