<template>
  <v-menu
    v-model="feedbackPopup"
    :close-on-content-click="false"
    :nudge-width="300"
    offset-y
    left
    content-class="rating-popover"
  >
    <template v-slot:activator="{ attrs }">
      <div
        class="rating-btn-container"
        v-bind="attrs"
        :data-pendo-id="getRatingBtnText"
      >
        <div>
          <v-hover v-slot="{ hover }">
            <v-btn
              :disabled="disabled"
              icon
              @click.stop="
                form.isLiked === null ? handleRatingClick('like') : () => {}
              "
              :class="{
                bounce: animateLike,
                'rating-disabled': form.isLiked !== null,
              }"
            >
              <v-tooltip bottom v-model="likeTooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="
                      (hover && form.isLiked === null) ||
                      form.isLiked === true ||
                      animateLike
                        ? '#21a7e0'
                        : '#000'
                    "
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{
                      form.isLiked === true || animateLike
                        ? 'mdi-thumb-up'
                        : 'mdi-thumb-up-outline'
                    }}
                  </v-icon>
                </template>
                <span>{{
                  animateLike ? $t(animationTooltipTxt) : $t(likeTooltipTxt)
                }}</span>
              </v-tooltip>
            </v-btn>
          </v-hover>
        </div>
        <div>
          <v-hover v-slot="{ hover }">
            <v-btn
              :disabled="disabled"
              icon
              :class="{ 'rating-disabled': form.isLiked !== null }"
              @click.stop="
                form.isLiked === null ? handleRatingClick('dislike') : () => {}
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="
                      (hover && form.isLiked === null) || form.isLiked === false
                        ? '#21a7e0'
                        : '#000'
                    "
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{
                      form.isLiked === false
                        ? 'mdi-thumb-down'
                        : 'mdi-thumb-down-outline'
                    }}
                  </v-icon>
                </template>
                <span> {{ $t(dislikeTooltipTxt) }}</span>
              </v-tooltip>
            </v-btn>
          </v-hover>
        </div>
      </div>
    </template>
    <v-card>
      <v-btn
        elevation="0"
        icon
        class="rating-popover__close"
        @click="closeFeedback"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div v-if="feedbackSuccess" class="feedback-success">
        <img src="/assets/img/generate/feedback_thanks.png" />
        <span>Thanks for your feedback!</span>
      </div>
      <template v-else>
        <div class="rating-popover__content">
          <v-form ref="form" lazy-validation>
            <div>
              <label class="form__label">
                {{ $t('generate.feedbackIsInvaluable') }}
              </label>
              <v-textarea
                v-model="form.feedback"
                label="Type your feedback"
                solo
                :rows="4"
                class="form__textarea"
                required
                :hide-details="true"
              />
            </div>
            <div class="form__share">
              <v-checkbox v-model="form.shareDetails" :hide-details="true">
                <template #label>
                  <div class="share-label">
                    Share the deck details with our team to help improve future
                    results
                    <v-tooltip
                      bottom
                      max-width="250"
                      content-class="tooltip-content"
                      fixed
                      open-delay="500"
                      close-delay="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon :size="18" v-bind="attrs" v-on="on">
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>
                        We don't use confidential customer data for training ML
                        models
                      </span>
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
            </div>
          </v-form>
        </div>

        <v-card-actions class="form__action">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            style="text-transform: none !important"
            rounded
            class="primary-button"
            width="100"
            height="48"
            :disabled="form.feedback.length === 0"
            @click="submitRatings"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-menu>
</template>
<script>
import { mapState } from 'vuex';
import { trackSynthesisEvents } from '../../common/Analytics/SynthesisEvents';
import {
  TD_PREZNAME,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_TEMPLATE,
  TD_THUMBS_DOWN,
  TD_THUMBS_UP,
  TD_THUMB_SELECTED,
  TD_INSTRUCTIONS,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'Ratings',
  computed: {
    ...mapState('users', ['currentUser']),
    getRatingBtnText() {
      if (this.$route.path === '/home/synthesis') {
        return 'synthesis-rating-btn';
      }
      if (this.$route.path === '/home/redesign') {
        return 'redesign-rating-btn';
      }
      return '';
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
    },
    animationTooltipTxt: {
      type: String,
      default: 'synthesize.ratings.animationTooltip',
    },
    likeTooltipTxt: {
      type: String,
      default: 'synthesize.ratings.likeTooltip',
    },
    dislikeTooltipTxt: {
      type: String,
      default: 'synthesize.ratings.dislikeTooltip',
    },
    origin: {
      type: String,
      default: null,
    },
    fileName: {
      type: String,
      default: null,
    },
    templateSelected: {
      type: String,
      default: null,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.form = val;
      },
    },
  },
  data() {
    return {
      feedbackPopup: false,
      feedbackSuccess: false,
      form: {
        isLiked: null,
        feedback: '',
        shareDetails: true,
      },
      animateLike: false,
      likeTooltip: false,
    };
  },
  methods: {
    submitRatings() {
      this.feedbackSuccess = true;
      this.$emit('change', {
        type: 'feedback',
        isLiked: this.form.isLiked,
        feedback: this.form.feedback,
        shareDetails: this.form.shareDetails,
      });
      if (this.origin === 'synthesis') {
        const otherData = {
          [TD_COMMON_COLUMN_NAME]: TD_THUMB_SELECTED,
          [TD_COMMON_COLUMN_VALUE]: this.form.isLiked
            ? TD_THUMBS_UP
            : TD_THUMBS_DOWN,
          [TD_PREZNAME]: this.fileName,
          [TD_TEMPLATE]: this.templateSelected,
          [TD_INSTRUCTIONS]: this.form.feedback,
        };
        trackSynthesisEvents.synthesisFeedbackSubmitted(
          this.currentUser,
          otherData,
        );
      }
    },
    handleRatingClick(type) {
      this.form.feeedback = '';
      this.form.isLiked = type === 'like';
      this.$nextTick(() => {
        this.feedbackSuccess = false;
        setTimeout(() => {
          this.feedbackPopup = true;
        }, 300);
      });
      if (this.origin === 'synthesis') {
        const otherData = {
          [TD_COMMON_COLUMN_NAME]: TD_THUMB_SELECTED,
          [TD_COMMON_COLUMN_VALUE]: this.form.isLiked
            ? TD_THUMBS_UP
            : TD_THUMBS_DOWN,
          [TD_PREZNAME]: this.fileName,
          [TD_TEMPLATE]: this.templateSelected,
        };
        trackSynthesisEvents.synthesisFeedbackClick(
          this.currentUser,
          otherData,
        );
      }
      this.$emit('change', {
        type: 'rating',
        isLiked: this.form.isLiked,
        feedback: '',
        shareDetails: this.form.shareDetails,
      });
    },
    closeFeedback() {
      this.feedbackPopup = false;
    },
  },
  mounted() {
    setTimeout(() => {
      this.animateLike = true;
      this.likeTooltip = true;
      setTimeout(() => {
        this.likeTooltip = false;
        this.animateLike = false;
      }, 3000);
    }, 3000);
  },
  beforeDestroy() {},
};
</script>
<style scoped lang="scss">
.rating-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .rating-disabled {
    opacity: 0.8;
    cursor: default;
  }
}

.rating-popover {
  border-radius: 16px;
  width: 450px;
  margin-top: 16px;
  margin-left: 18px;

  .feedback-success {
    height: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      color: #000;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &__content {
    padding: 22px;
    padding-bottom: 0;
    padding-top: 42px;
  }
  &__close {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}

.form {
  &__label {
    font-weight: 600;
    margin-bottom: 6px;
    font-size: 16px;
    display: block;
  }

  &__share {
    .share-label {
      color: black;
      font-size: 16px;
    }
    .v-input {
      margin: 12px 0;
      padding-top: 0px;

      ::v-deep .v-input__slot {
        align-items: flex-start;
      }
    }
  }
  &__action {
    padding: 22px;
    padding-top: 0;

    .primary-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      gap: 8px;

      width: auto;
      height: 32px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: capitalize;
      letter-spacing: normal;
      line-height: 19px;

      /* Color Styles (Old)/White */

      color: #ffffff;

      background: #21a7e0;
      border-radius: 25px;
    }
  }
  &__textarea {
    border-radius: 8px;
  }
}

.bounce {
  animation-name: bounce;
  animation-timing-function: ease;
  animation-duration: 1.5s;
  animation-iteration-count: 2;
  transform-origin: bottom;
}
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-10px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-4px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
</style>
