var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"loader",class:{ large: _vm.large }},_vm._l((5),function(n){return _c('v-skeleton-loader',{key:n,staticClass:"skeleton-loader",attrs:{"type":"avatar"}})}),1):(_vm.randomUsers.length || _vm.count)?_c('div',{staticClass:"users-list",class:{ vertical: _vm.vertical }},[(_vm.randomUsers.length)?_c('div',{staticClass:"avatar-container",class:{ large: _vm.large }},_vm._l((_vm.randomUsers),function(user){return _c('div',{key:user.id,staticClass:"avatar",on:{"click":function($event){return _vm.audienceClicked(user, 'avatar')}}},[(user.profileImage === null || user.profileImage === '')?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"user-initials-avatar"},'div',attrs,false),on),[_vm._v(" "+_vm._s(("" + (user.firstName.charAt(0).toUpperCase()) + (user.lastName .charAt(0) .toUpperCase())))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(((user.firstName) + " " + (user.lastName)))+" ")])]):_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-img',{key:user.profileImageUrl,staticClass:"user-profile-pic",attrs:{"src":user.profileImageUrl,"contain":"","alt":((user.firstName) + " " + (user.lastName) + " avatar")}})],1)]}}],null,true)},[_c('div',[_c('span',{staticClass:"userWithPicNames"},[_vm._v(" "+_vm._s(((user.firstName) + " " + (user.lastName)))+" ")])])])],1)}),0):_vm._e(),(_vm.scope === 'global' || _vm.users.length === 0)?_c('div',{staticClass:"user-desc"},[_vm._v(" "+_vm._s(_vm.additionalUsers)+" ")]):_c('v-menu',{staticClass:"user-desc",attrs:{"bottom":"","open-on-hover":"","nudge-bottom":15},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"user-desc"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.additionalUsers)+" ")])]}}])},[_c('v-list',{staticClass:"user-menu"},_vm._l((_vm.users),function(user){return _c('v-list-item',{key:user.id,on:{"click":function($event){return _vm.audienceClicked(user, 'drop-down')}}},[_vm._v(_vm._s(user.firstName)+" "+_vm._s(user.lastName))])}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }