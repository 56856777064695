var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("flat-icons-library-wrapper " + (_vm.error.isError ? 'errorOrEmpty' : '') + " " + (_vm.isGenerate ? 'generate' : ''))},[(_vm.isLoading)?_c('v-container',[_c('v-text-field',{staticClass:"flat-icons-search-input",attrs:{"append-icon":"mdi-magnify","single-line":"","hide-details":"","placeholder":_vm.$t('generate.searchIcons'),"autocomplete":"off"}}),(_vm.isLoading)?_c('IconsPlaceHolder',{attrs:{"placeholderHeight":("" + (_vm.mainSlideHeight ? ((_vm.mainSlideHeight - 75) + "px") : ''))}}):_vm._e()],1):_c('v-container',{style:({
      height: ("" + (_vm.mainSlideHeight ? (_vm.mainSlideHeight + "px") : '')),
    })},[(!_vm.error.isError)?_c('v-text-field',{staticClass:"flat-icons-search-input",attrs:{"append-icon":"mdi-magnify","single-line":"","hide-details":"","placeholder":_vm.$t('generate.searchIcons'),"autocomplete":"off"},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}}):_vm._e(),(_vm.error.isError || _vm.error.isEmpty)?_c('div',{staticClass:"error-wrapper"},[_c('EmptyState',{attrs:{"img-url":_vm.error.imgUrl,"is-btn-visible":true,"handleCTA":_vm.loadLibraryIconsBeforeMount,"buttonName":_vm.$t('common.tryAgain')},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('p',{staticClass:"error-description"},[_vm._v(_vm._s(_vm.$t(_vm.error.message)))])]},proxy:true}],null,false,3104185704)})],1):_c('div',{ref:"flatIcons",staticClass:"icons",style:({
        height: ("" + (_vm.mainSlideHeight ? ((_vm.mainSlideHeight - 75) + "px") : '')),
      }),on:{"scroll":_vm.onScroll}},[[_c('div',{staticClass:"iconsWrapper"},_vm._l((_vm.icons),function(icon,index){return _c('div',{key:index,class:("iconContainer " + (icon.id === _vm.selectedIcon ? 'selected-icon' : '') + " library-icon"),style:({
              height: ((Number(
                Number(_vm.mainSlideHeight / 4).toFixed(3)
              )) + "px !important"),
            }),on:{"click":function($event){return _vm.onIconClick(icon)}}},[_c('img',{staticClass:"grey lighten-2",attrs:{"id":_vm.isImageReplaceWalkThrough && index === 0
                  ? 'walkthough-replace-img-id'
                  : undefined,"src":icon.thumbnails[0].url,"lazy-src":"","aspect-ratio":"1"}})])}),0),(_vm.moreIconsLoading)?_c('clip-loader',{staticClass:"cliploader",attrs:{"color":"#21a7e0","width":'24',"height":'24',"size":'24px'}}):_vm._e()]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }