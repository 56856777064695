<template>
  <div
    class="bestPractiseDraggable"
    v-if="point && Object.keys(point).length > 0"
  >
    <div
      class="editingExistingBsContainer"
      :style="{ 'flex-direction': `${isInRightHaf ? 'row-reverse' : 'row'}` }"
      v-if="point.isAdded"
    >
      <v-tooltip :left="movingInRightHalf" :right="!movingInRightHalf">
        <template v-slot:activator="{ on, attrs }">
          <div
            class="numberContainer"
            :style="{
              left: `${point.x}%`,
              top: `${point.y}%`,
              position: 'absolute',
            }"
            @mousedown="handleMouseDown"
            @mouseover="handleMouseOver"
            v-bind="attrs"
            v-on="on"
          >
            {{ point.name.replace(/#/g, '') }}
          </div>
        </template>
        <span>{{ point.tooltip }}</span>
      </v-tooltip>
      <div
        :style="{
          left: `${textBoxPos.textBoxAlignmentLeft}% !important`,
          top: `${textBoxPos.textBoxAlignmentTop}% !important`,
          position: 'absolute',
          width: '222px',
          'z-index': '2',
        }"
        v-if="
          !isDragging &&
          getBestPractiseSelected &&
          getBestPractiseSelected.name === point.name
        "
        class="editorOrDraggableOptions"
        @mousedown="(e) => e.stopPropagation()"
      >
        <div class="editorTextAndActions" v-if="!editing">
          <div class="text">{{ point.tooltip }}</div>
          <div class="actions">
            <v-icon @click="handleEnterEditingMode">{{ 'mdi-pencil' }}</v-icon>
            <v-icon @click="handleOpenBestPractiseRemoveDialog">{{
              'mdi-delete'
            }}</v-icon>
            <v-icon @click="handleCloseBestPractiseEditor" small>{{
              'mdi-close'
            }}</v-icon>
          </div>
        </div>
        <div class="bsUpdater" v-else>
          <v-text-field
            v-model="updateText"
            :placeholder="$t('userUploadPrezentation.bestpracticeText')"
            autofocus
            @keyup.enter="(e) => handleUpdateBestPractise(e, 'update')"
          >
            <template #append-outer>
              <v-icon
                @click="(e) => handleUpdateBestPractise(e, 'update')"
                :disabled="updateText === ''"
              >
                {{ 'mdi-play' }}
              </v-icon>
              <v-icon small @click="handleRemoveEditingMode">
                {{ 'mdi-close' }}
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </div>
    </div>
    <div
      v-else
      class="toBeAddedDraggable"
      :style="{
        'flex-direction': `${movingInRightHalf ? 'row-reverse' : 'row'}`,
      }"
    >
      <div
        class="bpNumber"
        @mousedown="handleMouseDown"
        :style="{
          left: `${point.x}%`,
          top: `${point.y}%`,
          position: 'absolute',
          'z-index': '2',
        }"
      >
        {{ point.name.replace(/#/g, '') }}
      </div>
      <div
        class="bestPractiseTextBox"
        @mousedown="(e) => e.stopPropagation()"
        v-if="!isDragging"
        :style="{
          left: `${textBoxPos.textBoxAlignmentLeft}%`,
          top: `${textBoxPos.textBoxAlignmentTop}%`,
          position: 'absolute',
          width: '225px',
        }"
      >
        <v-text-field
          v-model="bestPractiseText"
          :placeholder="$t('userUploadPrezentation.bestpracticeText')"
          autofocus
          @keyup.enter="(e) => handleUpdateBestPractise(e, 'add')"
        >
          <template #append-outer>
            <v-icon
              @click="(e) => handleUpdateBestPractise(e, 'add')"
              :disabled="bestPractiseText === ''"
            >
              {{ 'mdi-play' }}
            </v-icon>
            <v-icon @click="removeCurrentBestPractiseAdder" small>{{
              'mdi-close'
            }}</v-icon>
          </template>
        </v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents';
import RemoveBestPractiseDialog from './RemoveBestPractiseDialog.vue';

export default {
  inheritAttrs: true,
  name: 'BestPractisesDraggable',
  data() {
    return {
      bestPractiseText: '',
      parentRect: {},
      parentLeft: 0,
      parentTop: 0,
      parentHeight: 0,
      parentWidth: 0,
      isDragging: false,
      editing: false,
      updateText: '',
      point: {},
      pointX: 0,
      pointY: 0,
    };
  },
  props: {
    slideThumbnailRef: {
      type: DOMRect,
      default: () => ({}),
    },
    draggableLeft: {
      type: Number,
      default: 0,
    },
    bestPractisePoint: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  computed: {
    ...mapState('users', ['leftSideBarStatus', 'currentUser']),
    ...mapGetters('slidesStore', [
      'getBestPractisesOnSlide',
      'getBestPractiseSelected',
      'getActiveSlide',
    ]),

    draggableLeftWhenSideBarActive() {
      return this.leftSideBarStatus === true ? 0 : 4;
    },

    isInRightHaf() {
      const pointX = Number(this.point.x);
      const pointY = Number(this.point.y);
      if (pointX <= 50 && pointY <= 50) {
        return false;
      }
      if (pointX > 50 && pointY <= 50) {
        return true;
      }
      if (pointX <= 50 && pointY > 50) {
        return false;
      }
      return true;
    },

    movingInRightHalf() {
      const pointX = Number(this.point.x);
      const pointY = Number(this.point.y);
      if (pointX <= 50 && pointY <= 50) {
        return false;
      }
      if (pointX > 50 && pointY <= 50) {
        return true;
      }
      if (pointX <= 50 && pointY > 50) {
        return false;
      }
      return true;
    },

    textBoxPos() {
      const positions = {};
      // positioning the text editor
      if (this.pointX <= 50 && this.pointY <= 50) {
        // this.movingInRightHalf = false;
        positions.textBoxAlignmentLeft = this.pointX + 4;
        positions.textBoxAlignmentTop = this.pointY;
      } else if (this.pointX > 50 && this.pointY <= 50) {
        // this.movingInRightHalf = true;
        positions.textBoxAlignmentLeft =
          this.pointX -
          (this.draggableLeft + this.draggableLeftWhenSideBarActive);
        positions.textBoxAlignmentTop = this.pointY;
      } else if (this.pointX <= 50 && this.pointY > 50) {
        // this.movingInRightHalf = false;
        positions.textBoxAlignmentLeft = this.pointX + 4;
        positions.textBoxAlignmentTop = this.pointY - 10;
      } else {
        // this.movingInRightHalf = true;
        positions.textBoxAlignmentLeft =
          this.pointX -
          (this.draggableLeft + this.draggableLeftWhenSideBarActive);
        positions.textBoxAlignmentTop = this.pointY - 10;
      }
      return positions;
    },
  },
  methods: {
    ...mapActions('slidesStore', [
      'setBestPractisesOnSlide',
      'setBestPractiseSelected',
    ]),

    handleCloseBestPractiseEditor() {
      this.setBestPractiseSelected({});
    },

    handleEnterEditingMode() {
      this.editing = true;
      this.updateText = this.point.tooltip;
    },

    removeCurrentBestPractiseAdder(e) {
      const existingBps = [...this.getBestPractisesOnSlide].filter(
        (item) => item.name !== this.point.name,
      );
      this.setBestPractisesOnSlide(existingBps);
      e.preventDefault();
    },

    handleRemoveEditingMode() {
      this.editing = false;
      this.updateText = '';
    },

    handleOpenBestPractiseRemoveDialog() {
      this.$modal.show(
        RemoveBestPractiseDialog,
        {
          bestPrt: this.point,
        },
        {
          name: 'RemoveBestPractiseDialog',
          width: '500px',
          height: 'auto',
          'border-radius': '20px',
          styles: {
            'max-height': '50%',
            'max-width': '600px',
            overflow: 'auto',
            'border-radius': '20px',
          },
        },
      );
    },

    handleUpdateBestPractise(e, type) {
      const getCurrentBestParctises = [...this.getBestPractisesOnSlide].map(
        (item) => {
          if (item.name === this.point.name) {
            return {
              x: this.point.x,
              y: this.point.y,
              name: this.point.name,
              tooltip: type === 'add' ? this.bestPractiseText : this.updateText,
              isAdded: true,
              isNew: true,
            };
          }
          return {
            ...item,
          };
        },
      );
      this.setBestPractisesOnSlide(getCurrentBestParctises);
      this.setBestPractiseSelected({});
      this.bestPractiseText = '';
      this.updateText = '';
      this.isEditing = false;
      if (type === 'update')
        trackSlideEvents.slidesBestPracticesEdited(
          this.getActiveSlide,
          this.currentUser,
          {},
        );
      if (type === 'add')
        trackSlideEvents.slidesBestPracticesAdded(
          this.getActiveSlide,
          this.currentUser,
          {},
        );
      e.preventDefault();
    },

    handleBestPractiseDraggableMove(e) {
      if (this.parentRect) {
        window.requestAnimationFrame(() => {
          this.pointX =
            ((e.clientX - this.parentRect.left) / this.parentWidth) * 100;
          this.pointY =
            ((e.clientY - this.parentRect.top) / this.parentHeight) * 100;

          if (this.pointX < 96 && this.pointY < 93) {
            this.point = {
              ...this.point,
              x: this.pointX,
              y: this.pointY,
            };
          } else if (this.pointX >= 96 && this.pointY < 93) {
            this.point = {
              ...this.point,
              x: 96,
              y: this.pointY,
            };
            this.pointX = 96;
          } else if (this.pointX < 96 && this.pointY >= 93) {
            this.point = {
              ...this.point,
              x: this.pointX,
              y: 93,
            };
            this.pointY = 93;
          } else if (this.pointX >= 96 && this.pointY >= 93) {
            this.point.x = 96;
            this.point.y = 93;
            this.point = {
              ...this.point,
              x: 96,
              y: 93,
            };
            this.pointX = 96;
            this.pointY = 93;
          }

          const existingBp = [...this.getBestPractisesOnSlide].map((item) => {
            if (item.name === this.point.name) {
              return {
                ...item,
                x: this.pointX,
                y: this.pointY,
              };
            }
            return {
              ...item,
            };
          });
          this.setBestPractisesOnSlide(existingBp);
        });
      }
    },
    handleMouseOver() {
      trackSlideEvents.slidesBestPracticeHover(
        this.getActiveSlide,
        this.currentUser,
        {},
      );
    },
    handleMouseDown(e) {
      trackSlideEvents.slidesBestPracticesDragged(
        this.getActiveSlide,
        this.currentUser,
        {},
      );
      this.isDragging = true;
      if (
        this.getBestPractiseSelected &&
        this.getBestPractiseSelected.name &&
        this.point.name !== this.getBestPractiseSelected.name
      ) {
        this.setBestPractiseSelected({});
      }
      document
        .getElementById('main-slide-id')
        .addEventListener(
          'mousemove',
          this.handleBestPractiseDraggableMove,
          false,
        );
      document
        .getElementById('main-slide-id')
        .addEventListener('mouseup', this.handleMouseUp, false);
      document
        .getElementById('main-slide-id')
        .addEventListener('mouseleave', this.handleMouseUp, false);
      e.stopPropagation();
      e.preventDefault();
    },

    handleMouseUp(e) {
      this.isDragging = false;
      document
        .getElementById('main-slide-id')
        .removeEventListener(
          'mousemove',
          this.handleBestPractiseDraggableMove,
          false,
        );
      document
        .getElementById('main-slide-id')
        .removeEventListener('mouseleave', this.handleMouseUp, false);
      e.stopPropagation();
      e.preventDefault();
    },
  },
  watch: {
    slideThumbnailRef(val) {
      this.parentRect = val;
      this.parentLeft = this.parentRect.left;
      this.parentTop = this.parentRect.top;
      this.parentWidth = this.parentRect.width;
      this.parentHeight = this.parentRect.height;
    },

    bestPractisePoint(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.point = {
          ...newVal,
        };
        this.pointX = newVal.x;
        this.pointY = newVal.y;
      } else {
        this.point = {};
        this.pointX = 0;
        this.pointY = 0;
      }
    },

    getBestPractiseSelected(val) {
      if (val) {
        this.editing = false;
      }
    },
  },
  mounted() {
    this.parentRect = this.slideThumbnailRef;
    this.parentLeft = this.parentRect.left;
    this.parentTop = this.parentRect.top;
    this.parentWidth = this.parentRect.width;
    this.parentHeight = this.parentRect.height;
    this.point = this.bestPractisePoint;
    this.pointX = this.point.x;
    this.pointY = this.point.y;
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.bestPractiseDraggable {
  .editingExistingBsContainer {
    display: flex;
    .numberContainer {
      width: 28px;
      height: 28px;
      color: #ffffff;
      background-color: #ec9f0b;
      border: 2px solid #f5cf95;
      border-radius: 50%;
      text-align: center;
      margin: 0px 4px 0px 0px;
    }
    .editorOrDraggableOptions {
      .editorTextAndActions {
        display: flex;
        box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
        background: #ffffff;
        border-radius: 4px;
        padding: 8px;
        max-width: 300px;
        z-index: 999;
        .text {
          width: 80%;
        }
        .actions {
          display: flex;
          .v-icon {
            padding: 2px;
            cursor: pointer;
          }
        }
      }
      .bsUpdater {
        box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
        background: #ffffff;
        border-radius: 4px;
        padding: 8px;
        max-width: 300px;
        z-index: 999;
        ::v-deep .v-input {
          margin: 0;
          padding: 0;
          .v-input__control {
            .v-input__slot {
              margin: 0;
              &:before {
                border: none;
              }
              &:after {
                border: none;
              }
            }
            .v-text-field__details {
              display: none;
            }
          }
        }
      }
    }
  }
  .toBeAddedDraggable {
    display: flex;
    .bpNumber {
      width: 28px;
      height: 28px;
      color: #ffffff;
      background-color: #ec9f0b;
      border: 2px solid #f5cf95;
      border-radius: 50%;
      text-align: center;
      margin: 0px 4px 0px 0px;
      cursor: pointer;
      user-select: none;
    }
    .bestPractiseTextBox {
      box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
      background: #ffffff;
      ::v-deep .v-input {
        margin: 0;
        padding: 8px;
        .v-input__control {
          .v-input__slot {
            margin: 0;
            &:before {
              border: none;
            }
            &:after {
              border: none;
            }
          }
        }
        .v-text-field__details {
          display: none;
        }
      }
    }
  }
}
</style>
