<template>
  <v-container fluid>
    <div v-if="getIsLoadingInRepIcon" class="text-center loading-spinner">
      <v-progress-circular
        :size="50"
        :width="2"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-row no-gutters v-else>
      <template
        v-if="
          filteredIcons &&
          filteredIcons.length > 0 &&
          !isErrorLoadingIconReplaceData
        "
      >
        <v-col cols="12">
          <div class="replace-icon pl-1">
            <div class="header text-subtitle-1 text-left font-weight-bold">
              <span> {{ $t('replaceIcon.selectIconReplace') }} </span>
            </div>
            <div
              justify="center"
              align="center"
              :class="`select-icon my-1 ${
                isConvertingInProgress || isLocalIconUploading
                  ? 'item-disabled'
                  : ''
              } `"
              v-if="filteredIcons && filteredIcons.length > 0"
            >
              <v-slide-group v-model="model" show-arrows="always">
                <v-slide-item
                  v-for="item in filteredIcons"
                  :key="item.imageIndex"
                  v-slot="{ toggle }"
                  :class="`${
                    item.seq_id ===
                    (currentSelectedSlideIconData &&
                      currentSelectedSlideIconData.seq_id)
                      ? 'selected'
                      : ''
                  }`"
                >
                  <v-img
                    v-if="!item.invalid"
                    class="select-icon__item ma-2"
                    max-height="80"
                    max-width="100"
                    height="80"
                    contain
                    :src="item.imgUrl"
                    @click="onIconSelected(item, toggle)"
                    lazy-src="/assets/img/slides/placeholder-slide.svg"
                  ></v-img>
                </v-slide-item>
              </v-slide-group>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <IconLibrary
            v-if="!getIsLoadingInRepIcon && currentSelectedSlideIconData"
            @iconSelected="onReplaceIcon($event)"
            :currentSelectedSlideIconData="currentSelectedSlideIconData"
            :isConvertingInProgress="isConvertingInProgress"
            :key="iconLibraryComponentKey"
            @onIconSearch="onIconSearch"
            :slideData="selectedSlide"
          />
        </v-col>
      </template>
      <div v-else class="no-icon">
        <img
          :src="'/assets/img/generate/slide-with-no-visuals.svg'"
          alt="image"
          contain
        />
        <div class="desc">
          <div>{{ $t(errorMessageLoadingIconReplaceData) }}</div>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapActions, mapGetters } from 'vuex';
import IconLibrary from './IconLibrary.vue';
import { getFileURL } from '@/utils/calyrex';
import { getFileURLDownload } from '@/utils/calyrex-test';
import {
  // convertToPDFForIcons,
  replaceIcon,
  extractIcons,
  getSvgOfIcon,
} from '../../../utils/replace-image-api-helper';

import { convertPdfToImage } from '../../../utils/pdf-to-png';
import { calyrexConfig } from '../../../runtimeConfig';
import EventBus from '../../common/event-bus';
import IconNotSuitable from './IconNotSuitable.vue';
import {
  ICON_UPLOAD_FAILED,
  LOCAL_ICON_UPLOADED,
  LOCAL_ICON_UPLOADING,
} from './replaceIconConstants.js';
import { getThumbnail } from '../../../store/modules/helper';
import { convertPPTToPng } from '../../../utils/merge-slide-helper';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents';
import {
  TD_REPLACE_IMAGE_SOURCE,
  TD_QUERY,
  TD_COMPANY_OF_AUD_TAILORED_FOR,
  TD_AUD,
  TD_PLACEMENT,
  TD_SLIDES_LIBRARY,
  TD_LIBRARY,
  TD_COMMON_COLUMN_NAME,
  TD_TAB,
  TD_COMMON_COLUMN_VALUE,
  TD_TIME_SPENT_IN_SECS,
  getAudienceCompanyName,
  getAudienceId,
  TD_UPLOAD,
  TD_PREZENT,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'SlideIconReplacement',
  inject: {
    isImageReplaceWalkThrough: { default: false },
    query: { default: '' },
  },
  data() {
    return {
      convertedImage: null,
      model: 0,
      isConvertingInProgress: false,
      isLocalIconUploading: false,
      outputFileName: null,
      downloadUrl: null,
      iconToReplace: null,
      landscapeImageAsset: null,
      deckDetails: null,
      iconDetails: null,
      slideData: {},
      isAddedToCollection: false,
      filteredIcons: null,
      iconLibraryComponentKey: 1,
      currentSelectedSlideIconData: null,
      selectedIconSeqId: null,
      savedSlidePath: null,
      savedImagePath: null,
      payloadData: null,
      stepOne: {
        url: '/assets/img/slideImageReplacement/analyzing-content.svg',
        text: 'replaceIcon.analyzingIconContent',
      },
      stepTwo: {
        url: '/assets/img/slideImageReplacement/resizing-cleaning-image.svg',
        text: 'replaceIcon.cleaningTheIcon',
      },
      stepThree: {
        url: '/assets/img/slideImageReplacement/finalizing-slide.svg',
        text: 'replaceIcon.finalizingTheSlideDesign',
      },
      notIcons: ['image/x-wmf', 'image/x-emf'],
      newInput: {},
      selectIconReplaceThumbnailGuidMap: {},
      isErrorLoadingIconReplaceData: false,
      errorMessageLoadingIconReplaceData: 'replaceIcon.unableToReplaceIcon',
      isIconReplacementComplete: false,
      processingCollection: false,
    };
  },
  props: {
    selectedSlide: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    origin: {
      type: String,
      default: 'slides',
    },
    selectedAudience: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  components: {
    IconLibrary,
  },
  async mounted() {
    this.setIconSearchData({
      searchTerm: '',
      source: '',
    });

    EventBus.$on(LOCAL_ICON_UPLOADING, () => {
      this.isLocalIconUploading = true;
    });
    EventBus.$on(LOCAL_ICON_UPLOADED, () => {
      this.isLocalIconUploading = false;
    });
    EventBus.$on(ICON_UPLOAD_FAILED, (subTitle) => {
      this.isConvertingInProgress = false;
      this.isLocalIconUploading = false;

      this.$modal.show(
        IconNotSuitable,
        {
          subTitle,
        },
        {
          name: 'IconNotSuitable',
          width: '564',
          height: '200px',
        },
      );
    });

    await this.loadIconReplacementData();
  },
  beforeDestroy() {
    EventBus.$off('ICON_UPLOAD_FAILED');
  },
  computed: {
    ...mapGetters('slidesStore', [
      'getReplaceStackData',
      'getCurrAnimationImages',
      'getIsLoadingInRepIcon',
    ]),
    ...mapState('users', ['currentUser', 'currentTheme']),
    ...mapState('slidesCollection', ['tailorID']),
    // ...mapGetters('slidesCollection', ['isCollectionLimitReached']),
    ...mapGetters('replaceIconStore', ['getSearchTermIcon', 'getIsDirtyIcon']),

    // Computed flag to return if the replace icon component is rendering a BPL slide
    isBPLSlideView() {
      return this.$route.path.includes('best-practice-library');
    },

    getS3Bucket() {
      const { replacedSlideData, filename } = this.selectedSlide;
      if (
        !replacedSlideData &&
        filename.startsWith('/newImageReplaceOutput/output/')
      ) {
        return calyrexConfig.calyrex_bucket_name;
      }
      return replacedSlideData?.s3_bucket || calyrexConfig.slides_bucket_name;
    },

    getS3Path() {
      const { replacedSlideData, filename } = this.selectedSlide;
      if (this.selectedSlide.isIconReplaced) {
        return this.selectedSlide.file;
      }
      return replacedSlideData?.s3_path || filename;
    },
  },
  methods: {
    ...mapActions('slidesStore', [
      'setReplaceStackData',
      'setContentNotFound',
      'setCurrAnimationImages',
      'setShowLoadingOverlay',
      'setIsLoadingInRepIcon',
    ]),
    ...mapActions('replaceIconStore', ['makeDirtyIcon', 'setIconSearchData']),
    ...mapActions('slidesCollection', ['setCollectionData']),

    async loadIconReplacementData() {
      if (
        Object.keys(this.getReplaceStackData).length > 0 &&
        this.getReplaceStackData.convertedImage &&
        this.getReplaceStackData.convertedImage !== '' &&
        this.getReplaceStackData.filteredIcons
      ) {
        this.convertedImage = this.getReplaceStackData.convertedImage;
        this.filteredIcons = this.getReplaceStackData.filteredIcons;
        this.downloadUrl = this.getReplaceStackData.downloadUrl;
        this.isAddedToCollection = this.getReplaceStackData.isAddedToCollection;
        this.currentSelectedSlideIconData = null;
        this.savedImagePath = this.getReplaceStackData.savedImagePath;
        this.savedSlidePath = this.getReplaceStackData.savedSlidePath;
        this.newInput = this.getReplaceStackData.newInput;
        this.iconDetails = this.getReplaceStackData.filteredIcons;
        this.isIconReplacementComplete =
          !!this.getReplaceStackData.isIconReplacementComplete;

        this.setIsLoadingInRepIcon(true);

        this.iconDetails = await this.getDeckDetailsData();
        this.filteredIcons = this.iconDetails;
        if (this.iconDetails) {
          await this.loadIconsInDeck(this.iconDetails);
        }
        this.setIsLoadingInRepIcon(false);

        this.setReplaceStackData({
          ...this.getReplaceStackData,
          filteredIcons: this.filteredIcons,
          selectedIconData: this.currentSelectedSlideIconData,
        });
        // this.isLoading = false;
      } else if (
        Object.keys(this.getReplaceStackData).length > 0 &&
        this.getReplaceStackData.convertedImage &&
        this.getReplaceStackData.convertedImage !== '' &&
        !this.getReplaceStackData.filteredIcons
      ) {
        this.convertedImage = this.getReplaceStackData.convertedImage;
        this.downloadUrl = this.getReplaceStackData.downloadUrl;
        this.isAddedToCollection = this.getReplaceStackData.isAddedToCollection;
        this.savedImagePath = this.getReplaceStackData.savedImagePath;
        this.savedSlidePath = this.getReplaceStackData.savedSlidePath;
        this.newInput = this.getReplaceStackData.newInput;
        this.currentSelectedSlideIconData = null;
        this.isIconReplacementComplete =
          !!this.getReplaceStackData.isIconReplacementComplete;

        this.setIsLoadingInRepIcon(true);

        this.iconDetails = await this.getDeckDetailsData();
        this.filteredIcons = this.iconDetails;

        if (this.iconDetails) {
          await this.loadIconsInDeck(this.iconDetails);
        }
        this.setIsLoadingInRepIcon(false);

        this.setReplaceStackData({
          ...this.getReplaceStackData,
          filteredIcons: this.filteredIcons,
          selectedIconData: this.currentSelectedSlideIconData,
        });
      } else {
        // making calls on mount when no cached data exists
        this.setIsLoadingInRepIcon(true);

        this.currentSelectedSlideIconData = null;

        await this.loadAssets(this.selectedSlide);
        this.iconDetails = await this.getDeckDetailsData();
        this.filteredIcons = this.iconDetails;
        if (this.iconDetails) {
          await this.loadIconsInDeck(this.iconDetails);
        }
        this.setIsLoadingInRepIcon(false);

        // retaining some mergeable state with replace image to store
        this.setReplaceStackData({
          ...this.getReplaceStackData,
          convertedImage: this.convertedImage,
          filteredIcons: this.filteredIcons,
          isAddedToCollection: this.isAddedToCollection,
          selectedIconData: this.currentSelectedSlideIconData,
        });
      }
    },

    async getSvgOfAllIcons(icons) {
      const svgPromises = icons.map(async (icn) => {
        const path = `${icn.s3_path}`;
        icn.imgUrl = null; // Need this to allow <v-img to work
        const imgUrl = await this.getSignedFileUrlFromCalyrex(path);
        if (path.includes('.svg')) {
          icn.imgUrl = await getSvgOfIcon(imgUrl);
        } else {
          icn.imgUrl = imgUrl;
        }
        return {
          ...icn,
        };
      });
      const results = await Promise.all(svgPromises);
      return results;
    },

    async loadIconsInDeck(iconDetails) {
      const iconsWithSvgs = await this.getSvgOfAllIcons(iconDetails);
      this.iconDetails = [...iconsWithSvgs];
      this.filteredIcons = this.iconDetails;

      for (const icon of this.filteredIcons) {
        const deckIcon = icon;
        if (deckIcon) {
          if (this.selectIconReplaceThumbnailGuidMap[deckIcon.imageIndex]) {
            icon.imgUrl =
              this.selectIconReplaceThumbnailGuidMap[
                deckIcon.imageIndex
              ].imgUrl;
          }
          icon.seq_id = deckIcon.imageIndex || 0;

          icon.invalid = this.notIcons.includes(deckIcon.meta.image_type);

          if (this.iconToReplace?.seq_id === deckIcon?.seq_id) {
            icon.imgUrl = this.iconToReplace.id
              ? this.iconToReplace.url
              : await this.getSignedFileUrlFromCalyrex(this.iconToReplace.path);
            this.selectIconReplaceThumbnailGuidMap[deckIcon.imageIndex] = {
              imgUrl: icon.imgUrl,
            };
          }
          // }
        }
        if (
          !icon.invalid &&
          (icon.seq_id === this.selectedIconSeqId || icon.seq_id === 0)
        ) {
          this.setCurrentSelectedSlideIconData(icon);
        }
      }

      this.isErrorLoadingIconReplaceData = this.iconDetails.length === 0;
      if (this.isErrorLoadingIconReplaceData) {
        this.errorMessageLoadingIconReplaceData = 'replaceIcon.noIconFound';
      }

      if (!this.currentSelectedSlideIconData && this.iconDetails.length) {
        this.setCurrentSelectedSlideIconData(this.iconDetails[0]);
      }
    },

    setCurrentSelectedSlideIconData(icon) {
      this.currentSelectedSlideIconData = {
        ...icon,
        iconName: this.selectedSlide.name || this.selectedSlide.title,
      };
    },

    async onReplaceIcon(iconToReplace) {
      try {
        const startTime = new Date();
        this.setShowLoadingOverlay(true);
        this.iconToReplace = iconToReplace;
        this.iconUploading();
        await this.triggerReplaceIcon();
        this.setReplaceStackData({
          ...this.getReplaceStackData,
          isAddedToCollection: false,
          isIconReplacementComplete: true,
        });
        this.isAddedToCollection = false;
        const timeTaken = parseFloat(
          ((new Date() - startTime) / 1000).toFixed(1),
        );
        this.isIconReplacementComplete = true;
        if (this.getCurrAnimationImages === this.stepThree) {
          this.isConvertingInProgress = false;
          this.setShowLoadingOverlay(false);
        }
        this.makeDirtyIcon();
        try {
          const { searchTermIcon, sourceIcon } = this.getSearchTermIcon;
          let source = TD_UPLOAD;
          let tab = TD_UPLOAD;
          if (sourceIcon === 'brand-icons') {
            source = this.currentUser?.company?.displayName;
            tab = this.currentUser?.company?.displayName;
          } else if (sourceIcon === 'flat-icons') {
            source = TD_PREZENT;
            tab = TD_LIBRARY;
          }
          const imageReplaceTrackingData = {
            [TD_QUERY]: searchTermIcon,
            [TD_REPLACE_IMAGE_SOURCE]: source,
            [TD_PLACEMENT]: TD_SLIDES_LIBRARY,
            [TD_COMMON_COLUMN_NAME]: TD_TAB,
            [TD_COMMON_COLUMN_VALUE]: tab,
            [TD_TIME_SPENT_IN_SECS]: timeTaken,
          };
          trackSlideEvents.slideIconReplacement(
            this.selectedSlide,
            this.currentUser,
            imageReplaceTrackingData,
          );
        } catch (e) {
          this.setShowLoadingOverlay(false);
          console.log(e);
        }
      } catch (e) {
        console.log('triggerReplaceIcon error: ', e);
        this.setShowLoadingOverlay(false);
        EventBus.$emit('ICON_UPLOAD_FAILED');
      }
    },

    onIconSearch({ searchTerm, source }) {
      console.log(searchTerm, source, 'icon');
      this.setIconSearchData({
        searchTerm,
        source,
      });
      trackSlideEvents.slideIconReplacementSearch(
        this.selectedSlide,
        this.currentUser,
        {
          [TD_QUERY]: searchTerm,
          [TD_REPLACE_IMAGE_SOURCE]: source,
          [TD_PLACEMENT]: TD_SLIDES_LIBRARY,
          ...this.getCommonMatomoOtherData(),
        },
      );
    },

    getCommonMatomoOtherData() {
      return {
        [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
          this.selectedAudience,
          this.currentUser,
        ),
        [TD_AUD]: getAudienceId(this.selectedAudience),
      };
    },

    async triggerReplaceIcon() {
      this.savedSlidePath = null;
      this.savedImagePath = null;
      this.outputFileName = `${uuidv4()}.pptx`;

      const { meta, shapeType, imageIndex } = this.currentSelectedSlideIconData;

      let newImage = {
        imageIndex,
        s3_path: this.iconToReplace.s3_path,
        source: this.iconToReplace.source,
      };

      if (this.iconToReplace.source === 'flat-icons') {
        newImage = {
          imageIndex,
          source: this.iconToReplace.source,
          id: this.iconToReplace.id,
        };
      } else if (this.iconToReplace.source === 'upload') {
        newImage = {
          imageIndex,
          source: this.iconToReplace.source,
          image: this.iconToReplace.image,
          extension: this.iconToReplace.extension,
        };
      } else if (this.iconToReplace.source === 's3') {
        newImage = {
          imageIndex,
          source: this.iconToReplace.source,
          s3_path: `private${this.iconToReplace.s3_path}`,
          s3_bucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
        };
      }

      const s3Path = this.newInput?.s3_path
        ? this.newInput.s3_path
        : this.getS3Path;
      let s3Bucket = calyrexConfig.slides_bucket_name;

      if (s3Path.indexOf('newImageReplaceOutput/') !== -1) {
        s3Bucket = calyrexConfig.calyrex_bucket_name;
      }

      if (this.selectedSlide.prefs.source === 'uploaded') {
        s3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
      } else if (
        this.selectedSlide.prefs.source === 'generated' ||
        this.selectedSlide.prefs.source === 'synthesis' ||
        this.selectedSlide.prefs.source === 'redesign' ||
        this.selectedSlide.prefs.source === 'comply'
      ) {
        if (
          this.selectedSlide.landscape.startsWith(
            '/newImageReplaceOutput/output/',
          ) ||
          this.selectedSlide.landscape.startsWith('/output/pptx/') ||
          s3Path.indexOf('newImageReplaceOutput/') !== -1
        ) {
          s3Bucket = calyrexConfig.calyrex_bucket_name;
        } else {
          s3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
        }
      }

      if (this.getIsDirtyIcon === true) {
        s3Bucket = calyrexConfig.calyrex_bucket_name;
      }

      const body = {
        s3_bucket: s3Bucket,
        s3_path: s3Path,
        outputFormat: 'pptx',
        newImage,
        oldImage: { meta, shapeType },
        matchCoordinates: true,
        /*
          passing feature in replace_icon payload
          which triggered the replace icon in product
        */
        feature: this.isBPLSlideView ? 'BPL Slide' : 'Slide Library',
      };

      // const [resPdf, response] = await Promise.all([
      //   convertToPDFForIcons({ ...body, outputFormat: 'pdf' }),
      //   replaceIcon(body),
      // ]);
      const response = await replaceIcon(body);

      if (response?.s3_path) {
        console.log('Deck successfuly converted', response);

        if (response.input) {
          this.payloadData = {
            ...body,
            newImage: { ...response.input, imageIndex },
          };
        }
        this.deckDetails = response;
        this.newInput = response;
        if (this.deckDetails) {
          // this.currentSelectedSlideIconData = null;

          this.iconDetails = this.iconDetails.map((icon) => {
            if (icon !== undefined && icon.imageIndex === imageIndex) {
              icon.imgUrl = this.iconToReplace.url;
              icon.meta = response?.meta;
              icon.shapeType = response?.shapeType;
              this.currentSelectedSlideIconData = {
                iconName:
                  this.getReplaceStackData?.selectedIconData?.iconName || '',
                ...icon,
              };
            }
            return icon;
          });

          this.filteredIcons = this.iconDetails;
        }

        this.downloadUrl = await this.getSignedFileUrlFromCalyrex(
          response?.s3_path,
        );
        this.savedSlidePath = response?.s3_path;

        // await this.getLandscapeImage(resPdf?.s3_path);

        this.savedImagePath = await convertPPTToPng(
          response.s3_bucket,
          response.s3_path,
        );
        this.convertedImage = await this.getSignedFileUrlFromCalyrex(
          this.savedImagePath,
        );

        // updating shared replace state after each replace
        this.setReplaceStackData({
          ...this.getReplaceStackData,
          convertedImage: this.convertedImage,
          filteredIcons: this.filteredIcons,
          downloadUrl: this.downloadUrl,
          isAddedToCollection: this.isAddedToCollection,
          selectedIconData: this.currentSelectedSlideIconData,
          savedImagePath: this.savedImagePath,
          savedSlidePath: this.savedSlidePath,
          newInput: this.newInput,
          isIconReplacementComplete: this.isIconReplacementComplete,
        });
      } else {
        this.setShowLoadingOverlay(false);
        console.error(response);
        this.isErrorLoadingIconReplaceData = true;
        this.errorMessageLoadingIconReplaceData =
          'replaceIcon.replaceIconTimedOut';
      }
    },

    async getLandscapeImage(outputFilename) {
      this.convertedImage = await this.getSignedFileUrlFromCalyrex(
        outputFilename,
      );
      this.savedImagePath = outputFilename;
      this.convertedImage = await convertPdfToImage(this.convertedImage);
    },

    getSlideCategoryS3Bucket() {
      if (this.selectedSlide.isIconReplaced) {
        return calyrexConfig.calyrex_bucket_name;
      }

      if (this.selectedSlide.prefs.source === 'uploaded') {
        return process.env.VUE_APP_MVP_ASSETS_BUCKET;
      }
      if (
        this.selectedSlide.prefs.source === 'generated' ||
        this.selectedSlide.prefs.source === 'synthesis' ||
        this.selectedSlide.prefs.source === 'redesign' ||
        this.selectedSlide.prefs.source === 'comply'
      ) {
        if (
          this.selectedSlide.landscape.startsWith(
            '/newImageReplaceOutput/output/',
          ) ||
          this.selectedSlide.landscape.startsWith('/output/pptx/')
        ) {
          return calyrexConfig.calyrex_bucket_name;
        }
        return process.env.VUE_APP_MVP_ASSETS_BUCKET;
      }
      return this.getS3Bucket;
    },

    async getDeckDetailsData() {
      const body = {
        s3_bucket:
          this.getReplaceStackData &&
          this.getReplaceStackData?.newInput &&
          this.getReplaceStackData?.newInput?.s3_bucket
            ? this.getReplaceStackData?.newInput?.s3_bucket
            : this.getSlideCategoryS3Bucket(),
        s3_path:
          this.getReplaceStackData &&
          this.getReplaceStackData?.newInput &&
          this.getReplaceStackData?.newInput?.s3_path
            ? this.getReplaceStackData?.newInput?.s3_path
            : this.getS3Path,
        force_update: false,
      };
      const response = await extractIcons(body);

      if (response?.images && response?.images.length > 0) {
        return response.images;
      }
      console.log('Failed to get deck details', response);
      this.isErrorLoadingIconReplaceData = true;
      this.errorMessageLoadingIconReplaceData =
        response?.images && response?.images.length === 0
          ? 'replaceIcon.noIconFound'
          : 'replaceIcon.replaceIconTimedOut';
      // Making isIconNotFound true when the icon is not found.
      this.setContentNotFound({
        type: 'icon',
        value: !!(response?.images && response?.images.length === 0),
      });
      return null;
    },

    async loadAssets(slideData) {
      if (slideData.isIconReplaced) {
        slideData.landscape = slideData.assetId;
      }
      if (slideData.prefs.source === 'uploaded') {
        const uploadedLandscape = await getFileURL(
          this.currentUser.user.cognitoID,
          slideData.landscape,
          this.currentUser.userIp,
          'IOCUpload',
        );
        this.landscapeImageAsset = await convertPdfToImage(uploadedLandscape);
      } else if (
        slideData.prefs.source === 'generated' ||
        slideData.prefs.source === 'synthesis' ||
        slideData.prefs.source === 'redesign' ||
        slideData.prefs.source === 'comply'
      ) {
        if (
          slideData.landscape.startsWith('/newImageReplaceOutput/output/') ||
          slideData.landscape.startsWith('/output/pptx/')
        ) {
          this.landscapeImageAsset = await getThumbnail(slideData.landscape);
        } else {
          this.landscapeImageAsset = await getFileURL(
            this.currentUser.user.cognitoID,
            slideData.landscape,
            this.currentUser.userIp,
            'IOCUpload',
          );
        }
      } else if (
        slideData?.landscape &&
        (slideData?.landscape?.startsWith('/output/pptx/') ||
          slideData?.landscape?.startsWith('/newImageReplaceOutput/output/'))
      ) {
        this.landscapeImageAsset = await getFileURLDownload(
          this.currentUser.user.id,
          slideData?.landscape.startsWith('/')
            ? slideData.landscape
            : `/${slideData.landscape}`,
          this.currentUser.userIp,
        );

        if (slideData?.landscape?.indexOf('.pdf') !== -1) {
          this.landscapeImageAsset = await convertPdfToImage(
            this.landscapeImageAsset,
          );
        }
      } else if (
        !slideData?.landscape?.startsWith('https') &&
        !slideData.replacedSlideData
      ) {
        this.landscapeImageAsset = await getFileURL(
          this.currentUser.user.cognitoID,
          slideData.landscape.startsWith('/')
            ? slideData.landscape
            : `/${slideData.landscape}`,
          this.currentUser.userIp,
        );

        if (slideData?.landscape?.indexOf('.pdf') !== -1) {
          this.landscapeImageAsset = await convertPdfToImage(
            this.landscapeImageAsset,
          );
        }
      } else {
        this.landscapeImageAsset = slideData.landscape;
      }
    },

    onIconSelected(slideIcon, toggle) {
      toggle();
      this.currentSelectedSlideIconData = {
        ...slideIcon,
        iconName: this.selectedSlide.name,
      };
      this.selectedIconSeqId = slideIcon.seq_id;
      this.iconLibraryComponentKey++;
    },

    // Utility functions
    async getSignedFileUrlFromCalyrex(filePath) {
      if (filePath) {
        return await getFileURLDownload(
          this.currentUser.user.cognitoID,
          filePath.startsWith('/') ? filePath : `/${filePath}`,
          this.currentUser.userIp,
        );
      }
      return '';
    },

    iconUploading() {
      this.setCurrAnimationImages(this.stepOne);
      this.isConvertingInProgress = true;
      this.showLoadingAnimation();
    },

    showLoadingAnimation() {
      setTimeout(
        () => {
          if (this.getCurrAnimationImages === this.stepOne) {
            this.setCurrAnimationImages(this.stepTwo);
            this.showLoadingAnimation();
          } else if (this.getCurrAnimationImages === this.stepTwo) {
            this.setCurrAnimationImages(this.stepThree);
            this.showLoadingAnimation();
          } else if (this.savedImagePath) {
            this.isConvertingInProgress = false;
          }
        },
        this.getCurrAnimationImages === this.stepThree ? 1000 : 1500,
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.header {
  color: #000;
  font-family: 'Lato' !important;
  display: flex;
  justify-content: space-between;
}

.select-icon {
  align-items: center;
  display: flex;
  padding-top: 2px;
  max-width: 100%;
  padding: 20px 0px !important;

  .select-icon__item {
    border: solid $light-gray 1px;
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    min-width: 100px;

    &:hover {
      box-shadow: $item-box-shadow-hover;
      cursor: pointer;
    }
  }

  .selected {
    border: 2px solid $zen-blue-default;
  }
}

.no-icon {
  display: flex;
  gap: 24px;
  align-items: center;
  height: calc(100% - 42px);
  flex-direction: column;
  margin-top: 36px;
  width: 100%;

  .desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
  }

  .primary-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 8px;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: normal;
    line-height: 19px;

    /* Color Styles (Old)/White */

    color: #ffffff;

    background: #21a7e0;
    border-radius: 25px;
  }
}

.loading-spinner {
  padding: 40px;
}
</style>
