<template>
  <ImagesPlaceHolder v-if="!selectedImageData" />
  <div v-else class="image-upload__container">
    <v-container v-if="isUploading" style="height: 400px">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col cols="12">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="8"
          ></v-progress-linear>
        </v-col>
        <v-col class="text-subtitle-1 text-center" cols="12">
          {{ $t('userUploadPrezentation.uploading') }}
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-alert
        v-if="errorMsg"
        class="upload-alert body-2"
        icon="mdi-alert-outline"
      >
        {{ $t(errorMsg) }}
      </v-alert>
      <v-img
        v-if="!selectedFile"
        src="/assets/img/common/upload-image.svg"
        contain
      ></v-img>
      <div
        v-if="selectedFile"
        class="selected-file__wrapper subtitle-1 text-center"
      >
        <v-img
          class="image-placeholder"
          :src="imageFileUrl"
          max-width="200"
          max-height="200"
        ></v-img>
        <span v-if="uploadingError">
          {{ $t(uploadingError) }}
        </span>
        <v-tooltip bottom content-class="tooltip-active" max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              class="text-truncate selected-file__name pt-2"
            >
              {{ selectedFile.name }}
            </span>
          </template>
          <span>{{ selectedFile.name }}</span>
        </v-tooltip>
        <span v-if="!uploadingError" class="pt-2">{{
          $t('upload.uploadedSuccessfully')
        }}</span>
      </div>
      <div class="upload-btn" @click="handleFileImport">
        <v-icon color="primary">mdi-tray-arrow-up</v-icon>
        <div class="text-primary subtitle-1 text-center">
          {{
            selectedFile
              ? $t('userUploadPrezentation.reuploadImage')
              : $t('userUploadPrezentation.selectImage')
          }}
        </div>
      </div>
      <p class="grey--text text-center body-2">
        {{ $t('userUploadPrezentation.imageUpto4Mb') }}
      </p>

      <!-- Hidden File Input that will be triggered with JavaScript -->
      <input
        ref="uploader"
        class="d-none"
        type="file"
        :accept="supportedFormatsInString"
        @change="onFileChanged"
      />
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Tiff from 'tiff.js';
// import fileTypeChecker from 'file-type-checker';
import { fromBuffer } from 'file-type';
import ImagesPlaceHolder from './ImagesPlaceHolder.vue';
import EventBus from '../../common/event-bus';
import {
  ADOBE_IMAGE_SELECTED,
  FREEPIK_IMAGE_SELECTED,
  BRAND_IMAGE_SELECTED,
  IMAGE_UPLOAD_FAILED,
  LOCAL_IMAGE_SELECTED,
  LOCAL_IMAGE_UPLOADED,
  LOCAL_IMAGE_UPLOADING,
  IMAGE_FORMAT_PNG,
  IMAGE_FORMAT_JPEG,
  IMAGE_FORMAT_TIFF,
} from './constants';
import { TD_REPLACE_IMAGE_SOURCE_UPLOAD } from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'ImageUpload',
  props: {
    selectedImageData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isSelecting: false,
      selectedFile: null,
      imageFileUrl: null,
      isUploading: false,
      uploadingError: null,
      errorMsg: '',
      uploadFailureMessage: 'userUploadPrezentation.uploadADifferentImage',
      supportedFormats: [
        IMAGE_FORMAT_PNG,
        IMAGE_FORMAT_JPEG,
        IMAGE_FORMAT_TIFF,
      ],
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    supportedFormatsInString() {
      return this.supportedFormats.toLocaleString();
    },
    isImageTIFF() {
      return this.selectedFile?.type === IMAGE_FORMAT_TIFF;
    },
  },
  components: {
    ImagesPlaceHolder,
  },

  created() {
    EventBus.$on(ADOBE_IMAGE_SELECTED, () => {
      this.selectedFile = null;
    });
    EventBus.$on(BRAND_IMAGE_SELECTED, () => {
      this.selectedFile = null;
    });
    EventBus.$on(FREEPIK_IMAGE_SELECTED, () => {
      this.selectedFile = null;
    });
  },
  beforeDestroy() {
    EventBus.$off(ADOBE_IMAGE_SELECTED);
    EventBus.$off(BRAND_IMAGE_SELECTED);
    EventBus.$off(FREEPIK_IMAGE_SELECTED);
  },

  methods: {
    handleFileImport() {
      this.isSelecting = true;

      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true },
      );
      this.$refs.uploader.click();
    },

    convertTIFFToImage() {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        /** Convert array buffer to tiff object */
        const tiff = new Tiff({ buffer: fileReader.result });
        this.imageFileUrl = tiff.toDataURL();
      };
      /** Convert the File content to array buffer */
      fileReader.readAsArrayBuffer(this.selectedFile);
    },

    async onFileChanged(e) {
      // reset error flag
      this.errorMsg = '';
      this.selectedFile = null;

      // check if file is actually selected
      if (e.target.files.length === 0) return;

      // check the filesize
      const fileObj = e.target.files[0];

      if (fileObj?.size > 4000000) {
        this.errorMsg = 'userUploadPrezentation.imageSizeExceedsLimit';
        return;
      }

      if (!this.supportedFormats.includes(fileObj?.type)) {
        this.errorMsg = 'userUploadPrezentation.invalidFileFormat';
        return;
      }

      this.selectedFile = fileObj;
      if (this.isImageTIFF) {
        this.convertTIFFToImage();
      } else {
        this.imageFileUrl = URL.createObjectURL(this.selectedFile);
      }
      this.isUploading = true;
      EventBus.$emit(LOCAL_IMAGE_UPLOADING);
      const fileNameParams = this.selectedFile.name.split('.');

      let extension;
      if (fileNameParams && fileNameParams.length >= 2) {
        extension = this.selectedFile.name.split('.').pop();
      }

      // convert image to base64 and call lambda function
      const reader = new FileReader();
      try {
        this.uploadingError = null;
        reader.onloadend = async () => {
          const buf = Buffer.from(
            reader.result.replace(/^data:.*base64,/, ''),
            'base64',
          );

          const fileType = await fromBuffer(buf);
          // Check actual file type
          if (!this.supportedFormats.includes(fileType?.mime)) {
            this.errorMsg = 'userUploadPrezentation.invalidFileFormat';
            this.isUploading = false;
            this.selectedFile = null;
            EventBus.$emit(
              IMAGE_UPLOAD_FAILED,
              this.$t(this.uploadFailureMessage),
            );
            return;
          }

          this.$emit('imageSelected', {
            origin: TD_REPLACE_IMAGE_SOURCE_UPLOAD,
            source: 'upload',
            image: reader.result,
            url: this.imageFileUrl,
            extension,
          });
          EventBus.$emit(LOCAL_IMAGE_SELECTED);

          this.isUploading = false;
          EventBus.$emit(LOCAL_IMAGE_UPLOADED);
        };
        reader.readAsDataURL(this.selectedFile);
      } catch (err) {
        console.log('uploadLocalImage error: ', err);
        this.uploadingError = 'userUploadPrezentation.uploadFailed';
        EventBus.$emit(IMAGE_UPLOAD_FAILED, this.$t(this.uploadFailureMessage));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.image-upload__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upload-btn {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.image-placeholder {
  border-radius: 8px;
  border: 3px solid #21a7e0 !important;
  box-shadow: $item-box-shadow;
}

.selected-file__wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-top: 1rem;
}

.selected-file__name {
  max-width: 200px;
}

.upload-alert::v-deep .v-alert__wrapper .v-alert__icon.v-icon {
  color: #21a7e0;
  font-size: 32px;
}
</style>
