<template>
  <v-dialog v-model="show" max-width="550" @click:outside="onClose">
    <v-card class="confirm-popup">
      <v-card-title class="v-card-title">
        <v-btn class="close-btn" icon @click="onClose">
          <v-icon dark v-text="`mdi-close`" />
        </v-btn>
        <h3 class="title">{{ title }}</h3>
      </v-card-title>
      <v-card-text class="pt-4 v-card-text" v-if="noAccess">
        <div class="no-outline-access">
          <div class="align-center img">
            <img src="/assets/img/outline-no-access.png" alt />
          </div>
          <div class="no-access-text align-center" v-if="isDuplicate">
            {{ $t('noAccessModal.duplicateStorylineFailed') }}
          </div>
          <div class="no-access-text align-center" v-else>
            {{ $t('noAccessModal.updateStorylineFailed') }}
          </div>

          <div class="no-access-sub-text align-center">
            {{ $t('noAccessModal.accessToStorylineRevocked') }}
          </div>
          <div class="actions align-end">
            <v-btn
              rounded
              class="primary-button mb-3"
              color="#21a7e0"
              height="48"
              @click="handleNoAccess"
              :disabled="
                requestAccessBtnText === 'Request sent!' || accessRequestSending
              "
              :loading="accessRequestSending"
            >
              {{ requestAccessBtnText }}
            </v-btn>
          </div>
        </div>
      </v-card-text>
      <v-card-text class="pt-4 v-card-text" v-else>
        <div class="no-outline-access">
          <div class="align-center img">
            <img src="/assets/img/outline-no-data.png" alt />
          </div>
          <div class="no-access-text align-center">
            {{ $t('noAccessModal.unableToSaveEdits') }}
          </div>

          <div class="no-access-sub-text align-center">
            {{ $t('noAccessModal.noLongerExist') }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { requestStorylineAccess } from '../../../utils/api-helper';

export default {
  name: 'NoAccessModal',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hasNoData: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    noAccess: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    isDuplicate: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    onClose: {
      type: Function,
      required: false,
      default: () => {
        console.log('closinf popup');
      },
    },
    uuid: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data() {
    return {
      accessRequestSending: false,
      requestAccessBtnText: 'Request Access',
    };
  },
  methods: {
    onClickOutside() {
      this.onSecondaryClick();
    },
    handleNoAccess() {
      this.accessRequestSending = true;
      requestStorylineAccess(this.uuid)
        .then((res) => {
          console.log(res);
          this.requestAccessBtnText = 'Request sent!';
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.accessRequestSending = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';

::v-deep .v-dialog {
  border-radius: 20px !important;
}

.no-outline-access {
  .img {
    padding-bottom: 5px;
  }
  .no-access-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 5px;
  }
  .no-access-sub-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 5px;
  }
  .actions {
    padding: 5px;
  }

  .align-center {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .align-end {
    text-align: end;
    display: flex;
    justify-content: flex-end;
  }

  .primary-button {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    height: 40px !important;
    line-height: normal;
    letter-spacing: normal;
    min-width: 64px;
    text-transform: none !important;
  }
}
.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
</style>
