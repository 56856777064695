<template>
  <div class="compare-fing-container">
    <div class="avatars" v-if="!isClicked || audience">
      <template v-if="!audience">
        <div class="empty-circle">?</div>
        <div class="empty-circle">?</div>
      </template>
      <template v-else>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <img
                v-if="profileImageUrl"
                :src="profileImageUrl"
                class="logo-profile"
              />
              <span v-else class="logo-initial">
                {{ audienceInitials }}
              </span>
            </span>
          </template>
          <span>{{ audience.fullName }}</span>
        </v-tooltip>
        <img
          :src="fingerprintData.logo"
          alt="Fingerprint-Logo"
          class="logo-fingerprint"
        />
      </template>
    </div>
    <div class="name" v-if="(!audience && isClicked) || isAudienceLoading">
      <h4>{{ $t('fingerprint.compareFingAud.findAudience') }}</h4>
      <AudienceSelectorTypesense
        v-model="audience"
        :isTextFieldStyle="true"
        :showSearchIcon="false"
        :placeholder="$t('fingerprint.compareFingAud.searchByNameOrEmail')"
        :maxWidth="300"
        origin="zentrends"
        :extraFields="['personalPreference']"
        @add="handleAddAudience($event)"
        @change="handleAudienceChange($event)"
      />
    </div>
    <div class="fing-name" v-else-if="audience">
      <h5>
        {{
          currentAudd ? $t('fingerprint.compareFingAud.me') : audience.fullName
        }}
      </h5>
      <h5 class="compare-fingerprint-name">
        {{ audience.fingerPrint }}
      </h5>
    </div>
    <div class="name" v-else-if="!audience">
      <h5
        class="compare-fingerprint-name"
        @click="handleSearch"
        data-pendo-id="fingerprint-find-audience"
      >
        <v-icon color="#21a7e0" size="18">mdi-magnify</v-icon>
        {{ $t('fingerprint.compareFingAud.findAudience') }}
      </h5>
    </div>
    <div v-if="isCompared" class="compared-description">
      <div class="description" v-if="isShowMore">
        {{ fingerprintData.description }}
      </div>
      <hr />
      <div
        class="pref-item1"
        v-for="(item, index) in fingerprintData.preferences"
        :key="index"
      >
        <div class="pref-item">
          <div class="pref-cont">
            <div class="pref-heading">{{ $t(item.title) }}</div>
            <div class="pref-sub-heading">
              {{ item.preference }}
            </div>
          </div>
          <img :src="item.logo" width="50px" />
        </div>
        <div v-if="isShowMore">{{ item.oneLine }}</div>
      </div>
      <hr />
      <div class="left-personal-pref">
        <div class="heading-pref">
          {{ $t('fingerprint.personalPreference') }}
        </div>
        <div class="prefes">
          <div
            class="selection edit-mode"
            v-for="(selection, index) in allPrefss"
            :key="index"
          >
            <span>
              <v-img
                class="mx-2"
                :src="
                  selection.image
                    ? `/${selection.image}`
                    : '/assets/img/preferences-added_by_user.svg'
                "
                height="30px"
                width="30px"
              />
            </span>
            <span class="selection-title">
              {{ selection.title }}
            </span>
          </div>
        </div>
      </div>
      <hr v-if="isShowMore" />
      <div v-if="isShowMore" @click="goToExploreFP()" class="learn-more-btn">
        {{ $t('fingerprint.compareFingAud.learnMoreAbout') }}
        {{ audience.fingerPrint.charAt(0).toUpperCase()
        }}{{ audience.fingerPrint.slice(1).toLowerCase() }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { fpTypes, fpSelections } from '@/mock/fingerprint';
import AudienceSelectorTypesense from '../../common/AudienceSelectorTypesense.vue';
import { getInitials } from '../../../utils/common';
import { getFileURL } from '@/utils/calyrex';
import EventBus from '../../common/event-bus';

export default {
  name: 'CompareFigAud',
  props: {
    value: {
      type: Object,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    currentAudd: {
      type: Boolean,
      default: false,
    },
    isCompared: {
      type: Boolean,
      default: false,
    },
    isShowMore: {
      type: Boolean,
      default: false,
    },
    isReset: {
      type: Boolean,
      default: false,
    },
  },
  components: { AudienceSelectorTypesense },
  watch: {
    value(val) {
      if (val !== this.audience) {
        this.audience = val;
      }
    },
    audience: {
      immediate: true,
      handler(value) {
        if (value?.profileImage) {
          if (value.profileImage.startsWith('/assets/img/')) {
            this.profileImageUrl = value?.profileImage;
          } else {
            getFileURL(
              null,
              `public/${value.profileImage}`,
              null,
              'storage',
            ).then((url) => {
              this.profileImageUrl = url;
            });
          }
        } else {
          this.profileImageUrl = null;
        }
      },
    },
    isReset() {
      if (this.isReset) {
        this.isClicked = false;
      }
    },
  },
  data() {
    return {
      isClicked: false,
      audience: this.data,
      profileImageUrl: null,
    };
  },
  emits: ['addAudience'],
  computed: {
    ...mapState('users', ['currentUser']),
    allPrefss() {
      const prefes = [];
      const personalPref = this.audience.personalPreference;
      if (!personalPref) return prefes;
      for (let i = 0; i < personalPref.length; i++) {
        if (fpSelections.find((ele) => ele.title === personalPref[i])) {
          prefes.push(
            fpSelections.find((ele) => ele.title === personalPref[i]),
          );
        } else {
          prefes.push({
            value: personalPref[i].toLowerCase(),
            title: personalPref[i],
          });
        }
      }
      return prefes;
    },
    isCurrentAudience() {
      return this.value.id === this.currentUser.user.id;
    },
    isAudienceLoading() {
      return this.audience && this.audience.defaultAudience;
    },
    fingerprintData() {
      const fpData = fpTypes.find(
        (fprint) => fprint.title === this.audience?.fingerPrint?.toLowerCase(),
      );
      if (!fpData) {
        return fpTypes?.[0];
      }
      return fpData;
    },
    audienceInitials() {
      if (this.isAudienceLoading) {
        return null;
      }
      const groupName = this.audience.firstName.replace(/\s+/g, ' ').split(' ');
      const isGroup = this.audience?.lastName?.toLowerCase() === 'group';
      if (isGroup && groupName.length === 1) {
        return this.audience.firstName[0].toUpperCase();
      }
      if (isGroup && groupName.length >= 2) {
        return `${groupName[0][0].toUpperCase()}${groupName[1][0].toUpperCase()}`;
      }
      return getInitials(this.audience.firstName, this.audience.lastName);
    },
  },
  methods: {
    handleAudienceChange(val) {
      return this.$emit('input', val);
    },
    handleAddAudience(value) {
      if (value && !value.fingerPrint) {
        return this.$emit('openRemindModal', value);
      }
      return this.$emit('addAudience', value);
    },
    handleSearch() {
      this.isClicked = true;
    },
    goToExploreFP() {
      EventBus.$emit('EXPLORE_FP', this.fingerprintData);
    },
  },
};
</script>
<style scoped lang="scss">
.compare-fing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 10px 15px 10px;
  width: 100%;
  gap: 20px;
  .avatars {
    position: relative;
    display: flex;
    /* margin-bottom: 12px; */
    height: 90px;
    width: 160px;

    .empty-circle {
      position: absolute;
      border-radius: 50%;
      width: 90px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 21.3333px;
      line-height: 26px;
    }

    .empty-circle:first-child {
      border: 0.72px dashed #b6b6b6;
      color: #b6b6b6;
    }

    .empty-circle:last-child {
      border: 0.72px dashed #21a7e0;
      color: #21a7e0;
      left: 40%;
      background: white;
    }

    .logo-profile {
      border-radius: 50%;
      width: 90px;
      height: 90px;
    }

    .logo-initial {
      align-items: center;
      background: #21a7e0;
      border-radius: 100%;
      color: white;
      display: flex;
      font-size: 42px;
      line-height: 26px;
      height: 90px;
      justify-content: center;
      width: 90px;
      padding-right: 10px;
    }
    .logo-fingerprint {
      background: white;
      border-radius: 50%;
      height: 90px;
      margin-left: -20px;
    }
  }

  .name {
    width: 150px;
    height: 50px;
    text-align: center;
    .compare-fingerprint-name {
      color: #21a7e0;
      text-transform: capitalize;
      font-weight: normal;
      font-size: 14px;
      cursor: pointer;
    }

    ::v-deep .style-text-field .audience-select-dropdown {
      padding-top: 0;
    }
  }
  .fing-name {
    width: 100%;
    height: 25px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;

    h5 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .compare-fingerprint-name {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #21a7e0;
      text-transform: capitalize;
    }

    ::v-deep .style-text-field .audience-select-dropdown {
      padding-top: 0;
    }
  }
  .compared-description {
    text-align: start;
    font-size: 12px;
    hr {
      margin: 5px 0px;
    }
    .description {
      height: 150px;
    }
    .pref-item1 {
      padding: 0px;
      height: 80px;
      .pref-item {
        display: flex;
        justify-content: space-between;
        .pref-heading {
          color: #7b7b7b;
          font-size: 13px;
        }
        .pref-sub-heading {
          font-size: 13px;
        }
        .pref-item {
          display: flex;
          justify-content: space-between;
          padding: 5px;
        }
      }
    }
    .learn-more-btn {
      text-align: center;
      color: #21a7e0;
      cursor: pointer;
    }
    .left-personal-pref {
      height: 150px;
      .heading-pref {
        color: #7b7b7b;
        font-size: 13px;
        margin-bottom: 10px;
        .btn-edit {
          cursor: pointer;
        }
      }
      .prefes {
        font-family: 'Lato';
        overflow: scroll;
        height: 130px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        .selection {
          display: flex;
          margin: 0;
        }
      }
    }
  }
}
</style>
