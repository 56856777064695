var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("brand-icons-library-wrapper " + (_vm.error.isError ? 'errorOrEmpty' : '') + " " + (_vm.isGenerate ? 'generate' : ''))},[(_vm.isLoading)?_c('v-container',[(!_vm.error.isError && _vm.isSearchBarEnabled)?_c('v-text-field',{staticClass:"brand-icons-search-input",attrs:{"append-icon":"mdi-magnify","single-line":"","hide-details":"","placeholder":_vm.$t('generate.searchIcons'),"autocomplete":"off"},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}}):_vm._e(),(_vm.isLoading)?_c('IconsPlaceHolder',{attrs:{"placeholderHeight":("" + (_vm.mainSlideHeight ? ((_vm.mainSlideHeight - 75) + "px") : ''))}}):_vm._e()],1):_c('v-container',{style:({
      height: ("" + (_vm.mainSlideHeight ? (_vm.mainSlideHeight + "px") : '')),
    })},[(!_vm.error.isError && _vm.isSearchBarEnabled)?_c('v-text-field',{staticClass:"brand-icons-search-input",attrs:{"append-icon":"mdi-magnify","single-line":"","hide-details":"","placeholder":_vm.$t('generate.searchIcons'),"autocomplete":"off"},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}}):_vm._e(),(_vm.error.isError || _vm.error.isEmpty)?_c('div',{staticClass:"error-wrapper",style:({
        height: ("" + (_vm.mainSlideHeight
            ? ((_vm.mainSlideHeight - (_vm.isSearchBarEnabled ? 75 : 0)) + "px")
            : '')),
      })},[_c('EmptyState',{attrs:{"img-url":_vm.error.imgUrl,"is-btn-visible":_vm.error.isShowButton,"btnClass":_vm.error.emptyStateBtnClass,"buttonName":_vm.error.buttonName,"isBtnOutlined":_vm.error.btnOutlined,"handleCTA":_vm.error.handleCTA},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',[_c('p',{staticClass:"error-description"},[_vm._v(_vm._s(_vm.$t(_vm.error.message)))]),_c('p',{staticClass:"error-description"},[_vm._v(" "+_vm._s(_vm.$t(_vm.error.additionalDescription))+" ")])])]},proxy:true}],null,false,4215676039)})],1):_c('div',{ref:"companyIcons",class:("icons " + (_vm.isSearchBarEnabled ? 'search' : 'no-search')),style:({
        height: ("" + (_vm.mainSlideHeight
            ? ((_vm.mainSlideHeight - (_vm.isSearchBarEnabled ? 75 : 0)) + "px")
            : '')),
      }),on:{"scroll":_vm.onScroll}},[[_c('div',{staticClass:"iconsWrapper"},_vm._l((_vm.icons),function(icon,ind){return _c('div',{key:ind,class:("iconContainer " + (icon.id === _vm.selectedIcon ? 'selected-icon' : '') + " library-icon"),style:({
              height: ((Number(
                Number(_vm.mainSlideHeight / 4).toFixed(3)
              )) + "px !important"),
            }),on:{"click":function($event){return _vm.onIconClick(icon)}}},[_c('img',{key:icon.id,staticClass:"grey lighten-2",attrs:{"src":icon.url,"lazy-src":"/assets/img/slides/placeholder-slide.svg","contain":""}})])}),0),(_vm.moreIconsLoading && !_vm.allResultsLoaded)?_c('clip-loader',{staticClass:"cliploader",attrs:{"color":"#21a7e0","width":'24',"height":'24',"size":'24px'}}):_vm._e()]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }