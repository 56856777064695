export const LOCAL_IMAGE_UPLOADING = 'LOCAL_IMAGE_UPLOADING';
export const LOCAL_IMAGE_UPLOADED = 'LOCAL_IMAGE_UPLOADED';
export const LOCAL_IMAGE_SELECTED = 'LOCAL_IMAGE_SELECTED';
export const IMAGE_UPLOADING = 'IMAGE_UPLOADING';
export const IMAGE_UPLOAD_FAILED = 'IMAGE_UPLOAD_FAILED';
export const ADOBE_IMAGE_SELECTED = 'ADOBE_IMAGE_SELECTED';
export const FREEPIK_IMAGE_SELECTED = 'FREEPIK_IMAGE_SELECTED';
export const BRAND_IMAGE_SELECTED = 'BRAND_IMAGE_SELECTED';
export const IMAGE_FORMAT_PNG = 'image/png';
export const IMAGE_FORMAT_JPEG = 'image/jpeg';
export const IMAGE_FORMAT_BMP = 'image/bmp';
export const IMAGE_FORMAT_TIFF = 'image/tiff';
export const ADOBE = 'adobe';
export const BRAND = 'brand';
export const LOCAL = 'local';
export const CREATE = 'create';
