export const SummaryFormat = {
  ContextCore: 'Context Core-Question Next Steps',
  CurrentStatus: 'Current status Future goals Initiatives',
  SituationComplication: 'Situation Complication Resolution',
  What: 'What ,So What, Now What',
  SinceWeSpoke: 'Since we last spoke, Key topics for today, Next steps',
  SimplePointers: 'Simple pointers',
};

export const SlideTone = {
  Neutral: 'neutral',
  Factual: 'factual',
  Implicative: 'implicative',
};

export const SlideEmotion = {
  Optimistic: 'optimistic',
  Uplifting: 'uplifting',
  Assertive: 'assertive',
  Persuasive: 'persuasive',
  Cooperative: 'cooperative',
};

export const SynthesizeOperation = {
  ToneChange: 'tone_change',
  AudienceChange: 'audience_change',
  FormatChange: 'format_change',
  DataStyleChange: 'data_style_change',
  TemplateChange: 'template_change',
  NodeChange: 'node_change',
  LayoutChange: 'slide_change',
  EmotionChange: 'emotion_change',
  ReplaceVisualChange: 'replaceVisuals',
};

export const SummaryFormatMetadata = {
  [SummaryFormat.ContextCore]: {
    label: 'Context, Core Question, Next Steps',
    description: 'synthesize.formats.contextCore.description',
    thumbnail:
      '/assets/img/synthesize/synthesis_format_context_corequestion.jpeg',
  },
  [SummaryFormat.CurrentStatus]: {
    label: 'Current Stage, Future Expectations, Initiatives',
    description: 'synthesize.formats.currentStatus.description',
    thumbnail: '/assets/img/synthesize/synthesis_format_currentstage.jpeg',
  },
  [SummaryFormat.SituationComplication]: {
    label: 'Situation, Complication, Resolution',
    description: 'synthesize.formats.situationComplication.description',
    thumbnail:
      '/assets/img/synthesize/synthesis_format_situation_complication.jpeg',
  },
  [SummaryFormat.What]: {
    label: 'What, So what, Now what',
    description: 'synthesize.formats.what.description',
    thumbnail: '/assets/img/synthesize/synthesis_format_what_sowhat.jpeg',
  },
  [SummaryFormat.SinceWeSpoke]: {
    label: 'Since We last spoke, Key topics for today, Next steps',
    description: 'synthesize.formats.sinceWeSpoke.description',
    thumbnail:
      '/assets/img/synthesize/synthesis_format_since_we_last_spoke.jpeg',
  },
  [SummaryFormat.SimplePointers]: {
    label: 'Simple list',
    description: 'synthesize.formats.simplePointers.description',
    thumbnail: '/assets/img/synthesize/synthesis_format_simplelist.jpeg',
  },
};

export const SlideDataStyle = {
  Narrative: 'narrative',
  Objective: 'objective',
};

// "id" : audience.get('email'),
//         "has_image" : 1 if audience.get('question1') == 'leftImage' else 1,
//         "color_type" : "high" if audience.get('question2') == 'leftImage' else 'low',
//         "has_cartoon" : 0 if audience.get('question3') == 'leftImage' else 1,
//         "has_tables" : 1 if audience.get('question6') == 'leftImage' else 0,
//         "has_graphs" : 1 if audience.get('question6') == 'rightImage' else 0,
//         "audienceType" : audience.get('type'),
//         "audienceNumID" : audience.get('id'),
//         "audienceOwnerID" : audience.get('ownerID') if audience.get('type') == 'private_audience' else None
