<template>
  <div class="image-placeholder" :style="{ height: `${placeholderHeight}` }">
    <template>
      <masonry :gutter="10" :cols="2">
        <div v-for="n in 10" :key="n" :class="`${n === 3 ? 'long' : ''} block`">
          <img src="/assets/img/reload.svg" alt />
        </div>
      </masonry>
    </template>
  </div>
</template>

<script>
export default {
  name: 'IconsPlaceholder',
  props: {
    placeholderHeight: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.image-placeholder {
  // height: 412px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 14px 12px 0 0;
  position: relative;

  .block {
    align-items: center;
    background: #f2f2f2;
    box-shadow: 2px 0px 4px rgba(216, 216, 216, 0.5),
      0px 2px 4px rgba(216, 216, 216, 0.5);
    border-radius: 8px;
    display: flex;
    height: 131px;
    justify-content: center;
    margin-bottom: 10px;
  }
  .long {
    height: 262px;
  }
}
</style>
