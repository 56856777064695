var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root",class:(!_vm.isBothSlidesVisible || _vm.error || !_vm.loader.isIdle() || !_vm.slides.length) &&
    'root--single',attrs:{"data-pendo-id":!_vm.loader.isIdle() || !!_vm.error || _vm.slides.length === 0
      ? ''
      : 'synthesis-executive-summary'}},[(_vm.error)?[_c('div',{staticClass:"sdc__slide offset-top"},[_c('img',{staticClass:"sdc__image",attrs:{"src":_vm.errorLandscape}})])]:(!_vm.loader.isIdle())?[_c('div',{staticClass:"sdc__slide offset-top"},[_c('VideoLoader',{staticClass:"sdc__image",model:{value:(_vm.loader),callback:function ($$v) {_vm.loader=$$v},expression:"loader"}})],1)]:(_vm.slides.length)?[(_vm.visible.uploadedSlide)?_c('div',{staticClass:"sdc"},[_c('div',{staticClass:"sdc__header"},[_c('div',{staticClass:"sdc__header-left"},[_c('h4',{staticClass:"sdc__title"},[_vm._v(" "+_vm._s(_vm.$t('synthesize.viewer.uploadedSlide'))+" ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleVisibility(
                'executiveSlide',
                !_vm.isBothSlidesVisible,
                'MinimizeOrMaximize'
              )}}},[(_vm.isBothSlidesVisible)?_c('v-icon',{staticClass:"sdc__expend"},[_vm._v(" mdi-arrow-expand ")]):_c('v-icon',{staticClass:"sdc__expend"},[_vm._v("mdi-arrow-collapse")])],1)],1),_c('span'),(!_vm.isBothSlidesVisible)?_c('v-btn',{staticClass:"sdc__show",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.handleVisibility('executiveSlide', true, 'showExecutiveSummary')}}},[_vm._v(" "+_vm._s(_vm.$t('synthesize.viewer.showExecutiveSummary'))+" ")]):_vm._e(),_c('a',{attrs:{"href":"javascript:void(0)"}})],1),_c('div',{staticClass:"sdc__slide"},[_c('img',{staticClass:"sdc__image",attrs:{"src":_vm.slide.thumbnail_jpg.thumbnail}})])]):_vm._e(),(_vm.visible.executiveSlide)?_c('div',{staticClass:"sdc"},[_c('div',{staticClass:"sdc__header"},[_c('div',{staticClass:"sdc__header-left"},[_c('h4',{staticClass:"sdc__title"},[_vm._v(" "+_vm._s(_vm.$t('synthesize.viewer.executiveSummary'))+" ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleVisibility(
                'uploadedSlide',
                !_vm.isBothSlidesVisible,
                'MinimizeOrMaximize'
              )}}},[(_vm.isBothSlidesVisible)?_c('v-icon',{staticClass:"sdc__expend"},[_vm._v(" mdi-arrow-expand ")]):_c('v-icon',{staticClass:"sdc__expend"},[_vm._v("mdi-arrow-collapse")])],1)],1),_c('span'),(!_vm.isBothSlidesVisible)?_c('v-btn',{staticClass:"sdc__show",attrs:{"text":"","color":"primary","data-pendo-id":"synthesis-show-current-slide"},on:{"click":function($event){return _vm.handleVisibility('uploadedSlide', true, 'showUploadedSlide')}}},[_vm._v(" "+_vm._s(_vm.$t('synthesize.viewer.showUploadedSlide'))+" ")]):_vm._e()],1),_c('div',{staticClass:"sdc__slide"},[_c('img',{staticClass:"sdc__image",attrs:{"src":_vm.execSummary.landscape}})])]):_vm._e()]:[_c('div',{staticClass:"sdc__slide offset-top",attrs:{"id":"replace-main-image"}},[_c('img',{staticClass:"sdc__image",attrs:{"src":_vm.exampleLandscapeUrl}})])],_c('div',{staticClass:"root__disclaimer"},[_c('div',{staticClass:"root__disclaimer__sub"},[_vm._v(" "+_vm._s(_vm.$t('synthesize.viewer.warningAstrid'))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('synthesize.viewer.disclaimer'))+" "),_c('a',{attrs:{"href":"https://www.prezent.ai/security","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('synthesize.viewer.disclaimerMore')))])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }