<template>
  <v-dialog
    v-model="show"
    max-width="550"
    @click:outside="onClickOutside"
    @keydown.esc="onClickOutside"
  >
    <v-card>
      <v-spacer></v-spacer>
      <v-btn class="close-btn" icon @click="onClose()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-text class="pt-4">
        <div class="pa-12 title">
          {{ text }}
        </div>
        <div class="pa-12 subTitle" v-if="subText && subText !== ''">
          {{ subText }}
        </div>
        <div class="d-flex justify-center">
          <v-btn
            class="primary-btn ma-2 py-2"
            filled
            rounded
            color="primary"
            height="40"
            @click="onPrimaryClick"
          >
            {{ primaryBtnText }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ErrorDialogIcon',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    text: {
      type: String,
      required: false,
      default: () => 'Are you sure you want to do this ?',
    },
    primaryBtnText: {
      type: String,
      required: false,
      default: () => 'Ok',
    },
    onPrimaryClick: {
      type: Function,
      required: false,
      default: () => {
        console.log('primary clicked');
      },
    },
    onClose: {
      type: Function,
      required: true,
      default: () => {},
    },
    subText: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    onClickOutside() {
      console.log('clicked outside');
      // this.onPrimaryClick();
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';
.close-btn {
  position: absolute;
  right: 0px;
}

.primary-btn {
  font-size: 18px;
  // margin: 0 30px 0 0 !important;
  padding: 11px 42px 11px 43px !important;
  text-transform: none;
}

.title,
.subTitle {
  color: #000;
  font-family: 'Lato' !important;
  font-size: 20px !important;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal !important;
  line-height: normal;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 15px !important;
  text-align: center;
}

.title {
  padding-bottom: 15px !important;
}

.subTitle {
  padding-bottom: 28px !important;
}
</style>
