<template>
  <div class="un-subscribe-container">
    <div>
      <img src="/assets/img/prezent_logo.svg" alt width="14%" />
    </div>
    <div v-if="isLoading" class="loader-container">
      <LoadSpinner
        :id="'spinner'"
        :text="'Loading...'"
        :size="'60'"
        :bgcolor="'lightgray'"
        :speed="'0'"
        :color="'gray'"
        class="spinner"
      />
    </div>
    <div v-else class="body-content">
      <img src="/assets/img/unsubscribe-illustration.png" alt width="270px" />
      <div class="main-title">Let's keep in touch</div>
      <p v-if="isUnsubscribed" class="main-text">Subscribe to all</p>
      <p v-else class="main-text">Unsubscribe from all</p>

      <p v-if="isUnsubscribed" class="sub-text">
        You are currently unsubscribed to all Prezent emails.
        <br />
        If you wish to subscribe to ALL publications from Prezent, click the
        Subscribe to all button below.
      </p>
      <p v-else class="sub-text">
        You are currently subscribed to all Prezent emails.
        <br />
        If you wish to unsubscribe from ALL publications from Prezent, click the
        Unsubscribe from all button below.
      </p>
      <div class="button-container">
        <div v-if="!isSent">
          <v-btn
            v-if="isUnsubscribed"
            rounded
            color="primary"
            class="btn"
            @click="handleSubscribeAll"
            :loading="isSending"
            :disabled="isSending"
          >
            Subscribe to all
          </v-btn>
          <v-btn
            v-else
            rounded
            color="primary"
            class="btn"
            @click="handleUnSubscribeAll"
            :loading="isSending"
            :disabled="isSending"
          >
            Unsubscribe from all
          </v-btn>
        </div>
        <div v-if="isSent">
          <v-btn v-if="isUnsubscribed" rounded color="primary" class="btn">
            Subscribed!
          </v-btn>
          <v-btn v-else rounded color="primary" class="btn">
            Unsubscribed!
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  deleteUnSubscription,
  getUnSubscriptionDetails,
  postUnSubscriptionDetails,
} from '../../../utils/api-helper';
import LoadSpinner from '../../common/LoadSpinner.vue';

export default {
  name: 'UnSubscribe',
  components: {
    LoadSpinner,
  },
  data() {
    return {
      isLoading: true,
      isUnsubscribed: false,
      isSending: false,
      isSent: false,
    };
  },
  beforeMount() {
    this.getUserUnSubscribedOrNot();
  },
  methods: {
    handleSubscribeAll() {
      this.isSending = true;
      deleteUnSubscription()
        .then((res) => {
          console.log(res);
          this.isSent = true;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    handleUnSubscribeAll() {
      this.isSending = true;
      postUnSubscriptionDetails()
        .then((res) => {
          console.log(res);
          this.isSent = true;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    getUserUnSubscribedOrNot() {
      getUnSubscriptionDetails()
        .then((res) => {
          console.log(res);
          this.isUnsubscribed = res.data.isUnsubscribed;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.un-subscribe-container {
  padding: 2rem 7rem;

  .body-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      font-weight: 300;
      font-size: 48px;
      line-height: 58px;
      letter-spacing: -0.02em;
    }

    .main-text {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin-top: 48px;
    }

    .sub-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-top: 16px;
      text-align: center;
    }
  }

  .button-container {
    margin-top: 20px;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }

  .btn {
    font-size: 16px;
    text-transform: none;
    letter-spacing: normal;
    background-color: #21a7e0 !important;
    border-color: #21a7e0 !important;
  }
}
</style>
