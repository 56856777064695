<template>
  <div
    :class="`brand-images-library-wrapper ${
      error.isError ? 'errorOrEmpty' : ''
    } ${isGenerate ? 'generate' : ''}`"
    :style="{ height: `${mainSlideHeight ? `${mainSlideHeight}px` : ''}` }"
  >
    <v-container v-if="isLoading">
      <v-text-field
        v-if="!error.isError && isSearchBarEnabled"
        v-model="searchKey"
        append-icon="mdi-magnify"
        single-line
        hide-details
        :placeholder="$t('generate.searchImages')"
        class="brand-images-search-input"
        autocomplete="off"
      />
      <ImagesPlaceHolder v-if="isLoading" />
    </v-container>
    <v-container v-else>
      <v-text-field
        v-if="!error.isError && isSearchBarEnabled"
        v-model="searchKey"
        append-icon="mdi-magnify"
        single-line
        hide-details
        :placeholder="$t('generate.searchImages')"
        class="brand-images-search-input"
        autocomplete="off"
      />
      <div class="error-wrapper" v-if="error.isError || error.isEmpty">
        <EmptyState
          :img-url="error.imgUrl"
          :is-btn-visible="error.isShowButton"
          :btnClass="error.emptyStateBtnClass"
          :buttonName="error.buttonName"
          :isBtnOutlined="error.btnOutlined"
          :handleCTA="error.handleCTA"
        >
          <template v-slot:description>
            <div>
              <p class="error-description">{{ $t(error.message) }}</p>
              <p class="error-description">
                {{ $t(error.additionalDescription) }}
              </p>
            </div>
          </template>
        </EmptyState>
      </div>
      <div
        v-else
        :class="`images ${isSearchBarEnabled ? 'search' : 'no-search'}`"
        @scroll="onScroll"
        ref="companyImages"
      >
        <template>
          <masonry :gutter="10" :cols="2">
            <v-img
              v-for="image in images"
              :key="image.id"
              :src="image.url"
              lazy-src="/assets/img/slides/placeholder-slide.svg"
              class="grey lighten-2"
              contain
              @click="onImageClick(image)"
              :class="`${
                image.id === selectedImage ? 'selected-image' : ''
              } library-image`"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </masonry>
          <clip-loader
            v-if="moreImagesLoading && !allResultsLoaded"
            :color="`#21a7e0`"
            :width="'24'"
            :height="'24'"
            :size="'24px'"
            class="cliploader"
          />
        </template>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import EmptyState from '../../common/EmptyState.vue';
import ImagesPlaceHolder from './ImagesPlaceHolder.vue';
import EventBus from '../../common/event-bus';
import { miscConfig } from '../../../runtimeConfig';
import { getLoggedInUserToken } from '../../../utils/api';
import {
  ADOBE_IMAGE_SELECTED,
  FREEPIK_IMAGE_SELECTED,
  BRAND_IMAGE_SELECTED,
  IMAGE_UPLOADING,
  IMAGE_UPLOAD_FAILED,
  LOCAL_IMAGE_SELECTED,
} from './constants';
import { TypeSenseServer } from '../../../pages/components/TypeSenseInit';

import {
  handleDebounce,
  handleDebounceForOnScroll,
} from './slideImageReplacementUtils';
import {
  logSearchQuery,
  getSearchBrandImagesSolr,
} from '../../../utils/api-helper';
import {
  TD_REPLACE_IMAGE_SOURCE_BRAND,
  TD_PREZNAME,
  TD_TEMPLATE,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { trackComplyEvents } from '../../common/Analytics/ComplyEvents';

const SOMETHING_WRONG = 'prezentationGallery.havingTroubleAccessingBrandImages';
const NO_SEARCH_RESULTS = 'prezentationGallery.noResultText';
const ERROR_IMG_URL = '/assets/img/image-fetching-error.svg';
const NO_RESULTS_IMG_URL = '/assets/img/no-files-available.svg';
const NO_RESULTS_IMG_URL_TRY_NOW = '/assets/img/searching-for-image.svg';
const REPLACE_IMAGE_BRAND_SEARCHBAR = 'replace_image_brand_searchbar';

export default {
  name: 'BrandImages',
  props: {
    selectedImageData: {
      type: Object,
      required: false,
    },
    onTryAdobeSearch: {
      type: Function,
      default: () => {},
    },
    isAdobeEnabled: {
      type: Boolean,
      default: false,
    },
    slideData: {
      type: Object,
      required: false,
    },
    isGenerate: {
      type: Boolean,
      default: false,
    },
    defaultImages: {
      type: Array,
      default: () => [],
      required: false,
    },
    origin: {
      type: String,
      default: null,
    },
  },
  components: {
    EmptyState,
    ImagesPlaceHolder,
    ClipLoader,
  },
  watch: {
    searchKey(key) {
      this.onSearch(key);
    },
  },
  async beforeMount() {
    if (!this.selectedImageData) {
      return;
    }
    if (this.defaultImages && this.defaultImages.length > 1) {
      const defImages = this.defaultImages.slice(1);
      const defImagesWithThumbnails =
        await this.getDefaultImagesFromAwsAssetDistribution(defImages);
      this.defImages = [...defImagesWithThumbnails, ...this.images];
    }
    if (this.getDatafromSolr)
      await this.loadBrandImagesFromSolr({
        query: this.searchKey || this.defaultSearchKey,
        skip: this.skip,
      });
    else
      await this.loadBrandImages({
        query: this.searchKey || this.defaultSearchKey,
        page: this.page,
      });
    this.images = [...this.defImages, ...this.images];
  },
  created() {
    EventBus.$on(LOCAL_IMAGE_SELECTED, this.emptySelectedImage);
    EventBus.$on(ADOBE_IMAGE_SELECTED, this.emptySelectedImage);
    EventBus.$on(FREEPIK_IMAGE_SELECTED, this.emptySelectedImage);
  },
  beforeDestroy() {
    EventBus.$off(LOCAL_IMAGE_SELECTED, this.emptySelectedImage);
    EventBus.$off(ADOBE_IMAGE_SELECTED, this.emptySelectedImage);
    EventBus.$off(FREEPIK_IMAGE_SELECTED, this.emptySelectedImage);
  },
  data() {
    return {
      images: [],
      searchKey: '',
      selectedImage: '',
      isLoading: false,
      error: {},
      page: 1,
      skip: 0,
      limit: 30,
      mainSlideHeight: 0,
      defaultSearchKey: '*',
      typeSenseSearchData: {},
      solrSearchData: {},
      moreImagesLoading: false,
      getDatafromSolr: true,
      defImages: [],
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('comply', ['fileDetails', 'selectedTemplate']),
    isSearchBarEnabled() {
      const searchBar = this.currentUser?.features?.find(
        (f) => f.feature_name === REPLACE_IMAGE_BRAND_SEARCHBAR,
      );
      return searchBar?.enabled;
    },
    allResultsLoaded() {
      if (this.getDatafromSolr)
        return this.images?.length >= this.solrSearchData?.hits;
      return this.images?.length === this.typeSenseSearchData?.found;
    },
  },
  methods: {
    emptySelectedImage() {
      this.selectedImage = '';
    },
    async loadBrandImagesFromSolr({ query, skip }) {
      try {
        this.isLoading = true;
        const searchParams = {
          query,
          skip,
        };
        const searchResults = await this.searchBrandImagesFromSolr(
          searchParams,
        );
        this.images = await this.getBrandImagesFromAwsAssetDistribution(
          searchResults,
        );
        if (query && query !== '*') {
          const logPayload = {
            type: 'Image Replacement (Company)',
            searchTerm: query,
            searchTermResult: false,
          };
          if (this.images.length > 0) {
            logPayload.searchTermResult = true;
          }
          logSearchQuery(logPayload)
            .then()
            .catch((err) => console.log(err));
        }
        this.isLoading = false;
        this.checkDataAndSetState();
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        this.setErrorObject({
          isError: true,
          message: SOMETHING_WRONG,
          imgUrl: ERROR_IMG_URL,
        });
      }
    },
    async loadBrandImages({ query, page }) {
      try {
        this.isLoading = true;
        const searchParams = {
          query,
          page,
        };
        const searchResults = await this.searchBrandImages(searchParams);
        this.images = await this.getBrandImagesFromAwsAssetDistribution(
          searchResults,
        );
        this.isLoading = false;
        this.checkDataAndSetState();
        if (query && query !== '*') {
          const logPayload = {
            type: 'Image Replacement (Company)',
            searchTerm: query,
            searchTermResult: false,
          };
          if (this.images.length > 0) {
            logPayload.searchTermResult = true;
          }
          logSearchQuery(logPayload)
            .then()
            .catch((err) => console.log(err));
        }
      } catch (e) {
        this.isLoading = false;
        console.log(e);
        this.setErrorObject({
          isError: true,
          message: SOMETHING_WRONG,
          imgUrl: ERROR_IMG_URL,
        });
      }
    },

    async getDefaultImagesFromAwsAssetDistribution(files) {
      const token = await getLoggedInUserToken();
      return await files.map((file) => ({
        ...file,
        id: file.s3_path,
        url: `${miscConfig.aws_assets_distribution}/${file.s3_path}?accesskey=${token}`,
      }));
    },

    async getBrandImagesFromAwsAssetDistribution(files) {
      const token = await getLoggedInUserToken();
      return await files.map((file) => ({
        id: file.filepath,
        url: `${miscConfig.aws_assets_distribution}/private/${file.filepath}?accesskey=${token}`,
      }));
    },
    checkDataAndSetState() {
      if (!this.images.length) {
        this.$store.commit('comply/SET_HAS_BRAND_IMAGES', false);
        let erroObject = {
          isEmpty: true,
          message: NO_SEARCH_RESULTS,
          imgUrl: NO_RESULTS_IMG_URL,
        };
        if (this.isAdobeEnabled) {
          erroObject = {
            ...erroObject,
            emptyStateBtnClass: 'adobe-try-now',
            btnOutlined: true,
            buttonName: 'slides.tryNow',
            isShowButton: true,
            handleCTA: this.onTryAdobeSearch.bind(this, this.searchKey),
            imgUrl: NO_RESULTS_IMG_URL_TRY_NOW,
            message: `Looking for ${
              this.searchKey || this.slideData?.title
            } images ?`,
            additionalDescription:
              'prezentationGallery.trySearchingInOurLibrary',
          };
        }
        this.setErrorObject(erroObject);
      } else {
        this.$store.commit('comply/SET_HAS_BRAND_IMAGES', true);
        this.setErrorObject({
          isEmpty: false,
          message: '',
          imgUrl: '',
        });
      }
    },
    setErrorObject(errorObj) {
      this.error = errorObj;
    },
    async onImageClick(image) {
      this.selectedImage = image.id;
      EventBus.$emit(IMAGE_UPLOADING);
      try {
        this.$emit('imageSelected', {
          ...image,
          origin: TD_REPLACE_IMAGE_SOURCE_BRAND,
          s3_path:
            image.type && image.type === 'default'
              ? image.s3_path
              : `/${image.id}`,
          url: image.url,
          source: 'brand-images',
        });
        EventBus.$emit(BRAND_IMAGE_SELECTED);
      } catch (e) {
        console.log('uploadAdobeImage error: ', e);
        EventBus.$emit(IMAGE_UPLOAD_FAILED);
      }
      if (this.origin && this.origin === 'comply') {
        const otherData = {
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]: this.selectedTemplate,
        };
        trackComplyEvents.complianceCheckerImageChanged(
          this.currentUser,
          otherData,
        );
      }
    },
    async searchBrandImages({ query, page }) {
      const searchParameters = {
        q: query,
        query_by: 'tags',
        page,
        per_page: this.limit,
      };
      const tsClient = await TypeSenseServer.getClient();
      return tsClient
        .collections('brand_images')
        .documents()
        .search(searchParameters)
        .then((response) => {
          const { found, hits, page: currentSearchPage } = response;
          this.typeSenseSearchData = {
            found,
            currentSearchPage,
          };
          if (hits?.length) {
            return hits.map((image) => ({
              filepath: image?.document?.filepath,
            }));
          }
          return [];
        });
    },
    async searchBrandImagesFromSolr({ query, skip }) {
      const resp = await getSearchBrandImagesSolr({ query, skip });
      const hits = resp?.data?.hits;
      this.solrSearchData = {
        hits,
        currentLoaded: skip + 10,
      };
      if (resp?.data?.docs?.length) {
        return resp?.data?.docs.map((image) => ({
          filepath: image?.filepath,
        }));
      }
      return [];
    },
    async doSearch(searchTerm) {
      this.page = 1;
      await this.loadBrandImages({ query: searchTerm, page: this.page });
      if (searchTerm) {
        this.$emit('imageSearch', {
          searchTerm,
          source: TD_REPLACE_IMAGE_SOURCE_BRAND,
          noResultsFoundValue: this.images.length ? 0 : 1,
        });
      } else if (this.defImages.length > 0) {
        this.images = [...this.defImages, ...this.images];
      }
      if (this.$refs.companyImages) {
        this.$refs.companyImages.scrollTop = 0;
      }
    },
    async doSearchFromSolar(searchTerm) {
      this.skip = 0;
      await this.loadBrandImagesFromSolr({
        query: searchTerm,
        skip: this.skip,
      });
      if (searchTerm) {
        this.$emit('imageSearch', {
          searchTerm,
          source: TD_REPLACE_IMAGE_SOURCE_BRAND,
          noResultsFoundValue: this.images.length ? 0 : 1,
        });
      } else if (this.defImages.length > 0) {
        this.images = [...this.defImages, ...this.images];
      }
      if (this.$refs.companyImages) {
        this.$refs.companyImages.scrollTop = 0;
      }
    },
    onSearch(searchTerm) {
      if (this.getDatafromSolr)
        handleDebounce(() => this.doSearchFromSolar(searchTerm.trim()));
      else handleDebounce(() => this.doSearch(searchTerm.trim()));
    },
    onScroll(elementObject) {
      this.moreImagesLoading = true;
      if (this.getDatafromSolr)
        handleDebounce(() => this.actOnScrollSolr(elementObject));
      else handleDebounceForOnScroll(() => this.actOnScroll(elementObject));
    },
    async actOnScrollSolr({
      target: { scrollTop, clientHeight, scrollHeight },
    }) {
      if (scrollTop + clientHeight + 200 >= scrollHeight) {
        if (!this.error.isError && !this.allResultsLoaded) {
          try {
            this.skip += 10;
            const searchResults = await this.searchBrandImagesFromSolr({
              query: this.searchKey.trim() || this.defaultSearchKey,
              skip: this.skip,
            });
            const newImages = await this.getBrandImagesFromAwsAssetDistribution(
              searchResults,
            );
            this.images = [...this.images, ...newImages];
            this.moreImagesLoading = false;
          } catch (e) {
            console.log(e);
            this.moreImagesLoading = false;
            this.setErrorObject({
              isError: true,
              message: SOMETHING_WRONG,
              imgUrl: ERROR_IMG_URL,
            });
          }
        } else {
          this.moreImagesLoading = false;
        }
      }
      this.moreImagesLoading = false;
    },

    async actOnScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 200 >= scrollHeight) {
        if (!this.error.isError && !this.allResultsLoaded) {
          try {
            this.page += 1;
            const searchResults = await this.searchBrandImages({
              query: this.searchKey.trim() || this.defaultSearchKey,
              page: this.page,
            });
            const newImages = await this.getBrandImagesFromAwsAssetDistribution(
              searchResults,
            );
            this.images = [...this.images, ...newImages];
            this.moreImagesLoading = false;
          } catch (e) {
            console.log(e);
            this.moreImagesLoading = false;
            this.setErrorObject({
              isError: true,
              message: SOMETHING_WRONG,
              imgUrl: ERROR_IMG_URL,
            });
          }
        } else {
          this.moreImagesLoading = false;
        }
      }
      this.moreImagesLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.brand-images-library-wrapper {
  height: 456px;

  ::v-deep .adobe-try-now {
    padding-bottom: 0px;
  }
  .brand-images-search-input {
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
    height: 40px;
    margin-bottom: 14px;
    font-size: 14px;
    color: #464a4c;
    margin-top: unset;
    padding: 0.27rem 0.7rem;
    z-index: 3;
    ::v-deep .v-input__slot::before {
      border: unset;
    }
    ::v-deep .v-text-field__slot input::placeholder {
      color: #757575;
    }
  }

  .empty-state__container {
    margin: unset;
    padding: 3rem;
  }

  .error-description {
    color: #000;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    /** trying to remove the padding as shown in the below class -> .no-prez-description but not working as expected hence negative margin here*/
    margin-top: -10px;
    text-align: center;
  }
  .no-prez-description {
    padding-top: 0px;
  }

  .images,
  .error-wrapper {
    max-height: 392px;
    overflow-y: auto;
    padding-right: 12px;
  }

  .images.no-search {
    max-height: 464px;
  }

  .library-image {
    &:hover {
      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
        0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%) !important;
      cursor: pointer;
    }
    background-color: #e0e0e0 !important;
    border-color: #e0e0e0 !important;
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    margin-bottom: 10px;
  }

  .selected-image {
    border: 3px solid #21a7e0 !important;
    box-shadow: 2px 0px 4px rgba(216, 216, 216, 0.5),
      0px 2px 4px rgba(216, 216, 216, 0.5);
  }
}

.generate {
  height: auto;
  .images,
  .error-wrapper {
    max-height: calc(100vh - 390px);
  }
}
</style>
