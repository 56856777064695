<template>
  <div class="ds">
    <div class="ds__header">
      <h2 class="ds__title">{{ $t('synthesize.dataStyle.title') }}</h2>
      <p class="ds__subtitle">{{ $t('build.step3.poweredByZenSence') }}</p>
      <div class="ds__text">{{ $t('synthesize.dataStyle.description') }}</div>
    </div>
    <div class="ds__body">
      <v-radio-group v-model="selectedDataStyleIndex">
        <v-expansion-panels flat accordion v-model="panelValue">
          <v-expansion-panel v-for="(style, i) in dataStyles" :key="i">
            <v-expansion-panel-header class="ds-list__panel_header">
              <div class="ds-list__header">
                <v-radio
                  class="ds-list__input"
                  :value="i"
                  @click.stop
                  :ripple="false"
                ></v-radio>
                <div>
                  {{ $t(style.label) }}
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="ds-list__content">
                <p class="ds-list__description">{{ $t(style.description) }}</p>
                <img class="ds-list__image" :src="style.slide" />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-radio-group>
    </div>
    <div class="ds__footer">
      <v-btn
        rounded
        color="primary"
        class="ds__button"
        :disabled="initialDataStyleIndex === selectedDataStyleIndex"
        @click="resenthesize()"
        >Resynthesize</v-btn
      >
    </div>
  </div>
</template>

<script>
import { SynthesizeOperation, SlideDataStyle } from './SynthesizeUtills';

export default {
  name: 'DataStyle',
  data() {
    return {
      dataStyles: [
        {
          id: SlideDataStyle.Objective,
          label: 'synthesize.dataStyle.objective.label',
          description: 'synthesize.dataStyle.objective.text',
          slide: '/assets/img/synthesize/synthesis_data_style_objective.png',
        },
        {
          id: SlideDataStyle.Narrative,
          label: 'synthesize.dataStyle.narrative.label',
          description: 'synthesize.dataStyle.narrative.text',
          slide: '/assets/img/synthesize/synthesis_data_style_narrative.png',
        },
      ],
      selectedDataStyleIndex: 0,
      panelValue: 0,
    };
  },
  watch: {
    selectedDataStyleIndex(val) {
      this.panelValue = val;
    },
  },
  computed: {
    initialDataStyleIndex() {
      const idx = this.dataStyles.findIndex((i) => i.id === this.dataStyle);
      return idx > 0 ? idx : 0;
    },
  },
  props: {
    dataStyle: {
      type: String,
      default: '',
    },
  },
  methods: {
    resenthesize() {
      this.$emit('change', {
        type: SynthesizeOperation.DataStyleChange,
        data: this.dataStyles[this.selectedDataStyleIndex],
      });
    },
  },
  mounted() {
    this.selectedDataStyleIndex = this.initialDataStyleIndex;
    this.panelValue = this.selectedDataStyleIndex;
  },
};
</script>

<style lang="scss" scoped>
.ds {
  background: $white-default;
  padding: 20px;

  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;

  &__header {
    margin-bottom: 10px;
  }

  &__title {
    font-weight: 700;
    line-height: 24px;
  }
  &__subtitle {
    font-family: 'Lato';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #697077;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  &__text {
    font-size: 16px;
  }

  &__button {
    padding: 6px 16px;
    width: auto;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: normal;
    line-height: 19px;
    color: #ffffff;
    background: #21a7e0;
    border-radius: 25px;
  }
}

.ds-list {
  &__panel_header {
    padding: 16px 0;
  }
  &__header {
    display: flex;
    font-weight: 600;
    color: #075689 !important;
    align-items: center;
  }
  &__icon {
    margin-right: 8px;
  }
  &__input {
    margin-bottom: 0 !important;
  }
  &__content {
    padding: 0 22px;
  }
  &__description {
    font-size: 15px;
  }
  &__image {
    border: 1px solid #c1c7cd;
    border-radius: 10px;
    overflow: hidden;
  }
}
</style>
