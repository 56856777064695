<template>
  <div class="fingerprint-video-container">
    <v-icon @click="handleClose()" class="close-icon">mdi-close</v-icon>
    <video
      class="video-js vjs-default-skin video-content-section__video-player"
      data-setup="{'fluid': true}"
      ref="video"
      height="60%"
      :poster="fingerPrint.videoThumbnailURL"
      controls
    />
  </div>
</template>
<script>
import Hls from 'hls.js';
import { mapState } from 'vuex';
import { getFileURL } from '@/utils/calyrex';

export default {
  name: 'VideoModal',
  computed: {
    ...mapState('users', ['currentUser']),
  },
  data() {
    return {
      hls: new Hls(),
    };
  },
  props: {
    fingerPrint: {
      type: Object,
    },
  },
  methods: {
    handleClose() {
      this.$modal.hide('VideoModal');
      this.hls.destroy();
    },
    modifyVideoURL() {
      const language = this.currentUser?.user?.language || 'en';
      return this.fingerPrint.videos[language];
    },
  },
  mounted() {
    setTimeout(() => {
      getFileURL(
        this.currentUser.user.cognitoID,
        this.modifyVideoURL(),
        this.currentUser.userIp,
      ).then((videoUrl) => {
        console.log(videoUrl, '--------src--------');
        const { video } = this.$refs;
        video.onended = () => {
          setTimeout(() => {
            this.handleClose();
          }, 1000);
        };
        this.hls.loadSource(videoUrl);
        this.hls.attachMedia(video);
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
        });
        this.hls.startLoad();
      });
    }, 800);
  },
  beforeDestroy() {
    this.hls.destroy();
  },
};
</script>
<style scoped lang="scss">
.fingerprint-video-container {
  position: relative;
  video {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
  }
}
</style>
