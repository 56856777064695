<template>
  <div
    :class="`adobe-library-wrapper ${error.isError ? 'errorOrEmpty' : ''} ${
      isGenerate ? 'generate' : ''
    }`"
    :style="{ height: `${mainSlideHeight ? `${mainSlideHeight}px` : ''}` }"
  >
    <v-container v-if="isLoading">
      <v-text-field
        append-icon="mdi-magnify"
        single-line
        hide-details
        :placeholder="$t('generate.searchImages')"
        class="adobe-search-input"
        autocomplete="off"
      />
      <ImagesPlaceHolder v-if="isLoading" />
    </v-container>
    <v-container v-else>
      <v-text-field
        v-if="!error.isError"
        v-model="searchKey"
        append-icon="mdi-magnify"
        single-line
        hide-details
        :placeholder="$t('generate.searchImages')"
        class="adobe-search-input"
        autocomplete="off"
      />

      <div class="error-wrapper" v-if="error.isError || error.isEmpty">
        <EmptyState
          :img-url="error.imgUrl"
          :is-btn-visible="true"
          :handleCTA="loadLibraryImagesBeforeMount"
          :buttonName="$t('common.tryAgain')"
        >
          <template v-slot:description>
            <p class="error-description">{{ $t(error.message) }}</p>
          </template>
        </EmptyState>
      </div>
      <div
        v-else
        class="images"
        @scroll="onScroll"
        ref="adobeImages"
        :style="{ 'max-height': isGenerate ? 'auto' : '85%' }"
      >
        <template>
          <masonry :gutter="10" :cols="2">
            <v-img
              v-for="(image, index) in images"
              :id="
                isImageReplaceWalkThrough && index === 0
                  ? 'walkthough-replace-img-id'
                  : undefined
              "
              :key="`${image.id}${
                !getAdobeStockLibFullAccess ? `_${index}` : ''
              }`"
              :src="image.thumbnail_url"
              lazy-src=""
              :height="image.thumbnail_height"
              aspect-ratio="1"
              class="grey lighten-2"
              @click="onImageClick(image)"
              :class="`${
                image.id === selectedImage ? 'selected-image' : ''
              } library-image`"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </masonry>
          <clip-loader
            v-if="moreImagesLoading"
            :color="`#21a7e0`"
            :width="'24'"
            :height="'24'"
            :size="'24px'"
            class="cliploader"
          />
        </template>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import {
  imageStockSearch,
  updateStockLibraryHitsForImages,
} from '@/utils/api-helper';
import EmptyState from '../../common/EmptyState.vue';
import ImagesPlaceHolder from './ImagesPlaceHolder.vue';
import EventBus from '../../common/event-bus';
import { miscConfig } from '../../../runtimeConfig';
import {
  ADOBE_IMAGE_SELECTED,
  FREEPIK_IMAGE_SELECTED,
  BRAND_IMAGE_SELECTED,
  IMAGE_UPLOADING,
  IMAGE_UPLOAD_FAILED,
  LOCAL_IMAGE_SELECTED,
} from './constants';
import {
  handleDebounce,
  handleDebounceForOnScroll,
} from './slideImageReplacementUtils';
import { logSearchQuery } from '../../../utils/api-helper';
import { TD_REPLACE_IMAGE_SOURCE_LIBRARY } from '../../common/Analytics/MatomoTrackingDataHelper';

const SOMETHING_WRONG = 'prezentationGallery.troubleAccessingImages';
const NO_SEARCH_RESULTS = 'prezentationGallery.noResultText';
const ERROR_IMG_URL = '/assets/img/image-fetching-error.svg';
const NO_RESULTS_IMG_URL = '/assets/img/no-files-available.svg';

export default {
  name: 'AdobeLibrary',
  inject: {
    isImageReplaceWalkThrough: { default: false },
    query: { default: '' },
  },
  props: {
    selectedImageData: {
      type: Object,
      required: false,
    },
    isSearchActionTriggerredFromBrandImages: {
      type: Boolean,
      default: false,
    },
    brandImagesSearchKey: {
      type: String,
      default: '',
    },
    slideData: {
      type: Object,
      required: false,
    },
    isGenerate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EmptyState,
    ImagesPlaceHolder,
    ClipLoader,
  },
  watch: {
    searchKey(key) {
      this.onSearch(key);
    },
    isSearchActionTriggerredFromBrandImages(isSearch) {
      this.searchKey = isSearch ? this.brandImagesSearchKey : this.searchKey;
    },
  },
  async beforeMount() {
    if (!this.selectedImageData || this.isImageReplaceWalkThrough) {
      return;
    }
    await this.loadLibraryImagesBeforeMount();
    // let imageName = this.getImageName();
    // if (
    //   this.slideData?.prefs?.source === 'uploaded' ||
    //   this.slideData?.prefs?.source === 'synthesis' ||
    //   this.slideData?.prefs?.source === 'redesign' ||
    //   this.slideData?.prefs?.source === 'comply' ||
    //   this.slideData?.prefs?.source === 'generated'
    // ) {
    //   imageName =
    //     this.slideData.categories?.length > 0
    //       ? this.slideData.categories[0].replace(/_/g, ' ')
    //       : imageName;
    // }

    // if (imageName) {
    //   try {
    //     this.isLoading = true;
    //     const images = await imageStockSearch(
    //       imageName.toString(),
    //       this.limit,
    //       0,
    //     );
    //     this.images = images.data.files;
    //     this.isLoading = false;
    //     this.checkDataAndSetState();
    //   } catch (e) {
    //     this.isLoading = false;
    //     console.log(e);
    //     this.setErrorObject({
    //       isError: true,
    //       message: SOMETHING_WRONG,
    //       imgUrl: ERROR_IMG_URL,
    //     });
    //   }
    // }
  },
  mounted() {
    if (this.isImageReplaceWalkThrough) {
      this.searchKey = this.query;
    }
    if (this.isSearchActionTriggerredFromBrandImages) {
      this.searchKey = this.brandImagesSearchKey;
    }
    setTimeout(() => {
      if (window.ResizeObserver && !this.isGenerate) {
        const mainSlideSizeObserver = new ResizeObserver((data) => {
          this.mainSlideHeight =
            data && data[0] && data[0].contentRect.height + 12;
        });
        mainSlideSizeObserver.observe(
          document.getElementById('replace-main-image'),
        );
      }
    }, 1500);
  },
  created() {
    EventBus.$on(LOCAL_IMAGE_SELECTED, this.emptySelectedImage);
    EventBus.$on(BRAND_IMAGE_SELECTED, this.emptySelectedImage);
  },
  beforeDestroy() {
    EventBus.$off(LOCAL_IMAGE_SELECTED, this.emptySelectedImage);
    EventBus.$off(BRAND_IMAGE_SELECTED, this.emptySelectedImage);
  },
  data() {
    return {
      images: [],
      searchKey: '',
      selectedImage: '',
      isLoading: false,
      error: {},
      page: 0,
      limit: 30,
      mainSlideHeight: 0,
      imageNameMap: {
        Dates: 'Calendar',
        Cycle: 'project life cycle',
        Pilot: 'software pilot',
        '30-60-90 Day': 'Plan',
        Title: 'success',
      },
      moreImagesLoading: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    /*
      Relying on a feature flag 'adobe_stock_library', rather than config
      to determine the source of image,
      used in the payload of each replace image call.
    */
    ...mapGetters('users', ['getAdobeStockLibFullAccess']),

    isUsingFreepik() {
      return miscConfig?.image_replacemt_source === 'Freepik';
    },
  },
  methods: {
    async loadLibraryImagesBeforeMount() {
      let imageName = this.getImageName();
      if (
        this.slideData?.prefs?.source === 'uploaded' ||
        this.slideData?.prefs?.source === 'synthesis' ||
        this.slideData?.prefs?.source === 'redesign' ||
        this.slideData?.prefs?.source === 'comply' ||
        this.slideData?.prefs?.source === 'generated'
      ) {
        imageName =
          this.slideData.categories?.length > 0
            ? this.slideData.categories[0].replace(/_/g, ' ')
            : imageName;
      }

      if (imageName) {
        try {
          this.isLoading = true;
          const images = await imageStockSearch(
            imageName.toString(),
            this.limit,
            0,
          );
          this.images = images.data.files;
          this.isLoading = false;
          this.checkDataAndSetState();

          // updating vendor as adobe for stock library hits
          if (this.getAdobeStockLibFullAccess) {
            updateStockLibraryHitsForImages({
              vendor: 'adobe',
              hitForSearch: true,
            });
          }
        } catch (e) {
          this.isLoading = false;
          console.log(e);
          this.setErrorObject({
            isError: true,
            message: SOMETHING_WRONG,
            imgUrl: ERROR_IMG_URL,
          });
        }
      }
    },

    async handleTryAgain() {
      await this.loadLibraryImagesBeforeMount();
    },

    emptySelectedImage() {
      this.selectedImage = '';
    },
    getImageName() {
      let imageName =
        this.imageNameMap[this.selectedImageData.imageName] ||
        this.selectedImageData.imageName;
      if (imageName === 'Divider') imageName = 'Division Background';
      return imageName;
    },

    onScroll(elementObject) {
      this.moreImagesLoading = true;
      handleDebounceForOnScroll(() => this.actOnScroll(elementObject));
    },

    async actOnScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 200 >= scrollHeight) {
        if (!this.error.isError) {
          try {
            this.page += 1;
            const images = await imageStockSearch(
              this.searchKey.trim() || this.getImageName(),
              this.limit,
              !this.getAdobeStockLibFullAccess
                ? this.page
                : this.limit * this.page,
            );
            this.images = [...this.images, ...images.data.files];
            this.moreImagesLoading = false;

            // updating vendor as adobe for stock library hits
            if (this.getAdobeStockLibFullAccess) {
              updateStockLibraryHitsForImages({
                vendor: 'adobe',
                hitForSearch: true,
              });
            }
          } catch (e) {
            console.log(e);
            this.moreImagesLoading = false;
            this.setErrorObject({
              isError: true,
              message: SOMETHING_WRONG,
              imgUrl: ERROR_IMG_URL,
            });
          }
        } else {
          console.log('something went wrong while scrolling down', this.error);
        }
      }
      this.moreImagesLoading = false;
    },
    checkDataAndSetState() {
      if (!this.images.length) {
        this.setErrorObject({
          isEmpty: true,
          message: NO_SEARCH_RESULTS,
          imgUrl: NO_RESULTS_IMG_URL,
        });
        this.page = 0;
      } else {
        this.setErrorObject({
          isEmpty: false,
          message: '',
          imgUrl: '',
        });
      }
    },
    setErrorObject(errorObj) {
      this.error = errorObj;
    },
    async doSearch(searchTerm) {
      try {
        const images = await imageStockSearch(
          searchTerm || this.getImageName(),
          this.limit,
          0,
        );
        this.images = images.data.files;
        this.checkDataAndSetState();

        // updating vendor as adobe for stock library hits
        if (this.getAdobeStockLibFullAccess) {
          updateStockLibraryHitsForImages({
            vendor: 'adobe',
            hitForSearch: true,
          });
        }

        if (this.$refs.adobeImages) {
          this.$refs.adobeImages.scrollTop = 0;
        }
        if (searchTerm && searchTerm !== '*') {
          const logPayload = {
            type: 'Image Replacement (Adobe)',
            searchTerm,
            searchTermResult: false,
          };
          if (this.images.length > 0) {
            logPayload.searchTermResult = true;
          }
          logSearchQuery(logPayload)
            .then()
            .catch((err) => console.log(err));
          this.$emit('imageSearch', {
            searchTerm,
            source: TD_REPLACE_IMAGE_SOURCE_LIBRARY,
            noResultsFoundValue: this.images.length ? 0 : 1,
          });
        }
      } catch (e) {
        console.log(e);
        this.setErrorObject({
          isError: true,
          message: SOMETHING_WRONG,
          imgUrl: ERROR_IMG_URL,
        });
      }
    },
    onSearch(searchTerm) {
      handleDebounce(() => this.doSearch(searchTerm.trim()));
    },
    async onImageClick(image) {
      this.selectedImage = image.id;
      EventBus.$emit(IMAGE_UPLOADING);
      try {
        this.$emit('imageSelected', {
          origin: TD_REPLACE_IMAGE_SOURCE_LIBRARY,
          source: !this.getAdobeStockLibFullAccess ? 'freepik' : 'adobe',
          id: `${image.id}`,
          url: image.thumbnail_url,
        });
        EventBus.$emit(
          !this.getAdobeStockLibFullAccess
            ? FREEPIK_IMAGE_SELECTED
            : ADOBE_IMAGE_SELECTED,
        );
      } catch (e) {
        console.log(
          `${
            !this.getAdobeStockLibFullAccess
              ? 'uploadFreepikImage'
              : 'uploadAdobeImage'
          } error: `,
          e,
        );
        EventBus.$emit(IMAGE_UPLOAD_FAILED);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.adobe-library-wrapper {
  height: 460px;
  overflow: auto;
  .adobe-search-input {
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
    height: 40px;
    font-size: 14px;
    color: #464a4c;
    margin-bottom: 14px;
    margin-top: unset;
    padding: 0.27rem 0.7rem;
    z-index: 3;
    ::v-deep .v-input__slot::before {
      border: unset;
    }
    ::v-deep .v-text-field__slot input::placeholder {
      color: #757575;
    }
  }

  .empty-state__container {
    margin: unset;
    padding: 6rem;
  }

  .error-description {
    color: #000;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    /** trying to remove the padding as shown in the below class -> .no-prez-description but not working as expected hence negative margin here*/
    margin-top: -10px;
    text-align: center;
  }
  .no-prez-description {
    padding-top: 0px;
  }

  .images,
  .error-wrapper {
    overflow-y: auto;
    padding-right: 12px;

    .cliploader {
      margin-top: 10px;
    }
  }

  .library-image {
    &:hover {
      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
        0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%) !important;
      cursor: pointer;
    }
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    margin-bottom: 10px;
  }

  .selected-image {
    border: 3px solid #21a7e0 !important;
    box-shadow: 2px 0px 4px rgba(216, 216, 216, 0.5),
      0px 2px 4px rgba(216, 216, 216, 0.5);
  }
}

.generate {
  height: auto;
  .images,
  .error-wrapper {
    max-height: calc(100vh - 390px);
  }
}
</style>
