<template>
  <v-container fluid>
    <v-hover v-slot="{ hover }" v-if="isShowBackButton">
      <v-btn
        :style="{
          'background-color': `${hover ? '#fafafa' : ''}`,
          height: '24px',
          margin: '0 0 14px -8px',
        }"
        class="btn-back"
        color="primary"
        text
        :disabled="isConvertingInProgress"
        @click="$emit('toggleImageReplace')"
      >
        <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
        {{ $t('replaceImage.backToSlideText') }}
      </v-btn>
    </v-hover>
    <v-row
      no-gutters
      style="
        flex-wrap: nowrap;
        width: 100%;
        justify-content: space-between !important;
      "
    >
      <v-col cols="8" style="max-width: 59.46% !important">
        <div class="main-image">
          <div class="main-image-header">
            <div class="header text-h6 text-left font-weight-bold">
              {{ $t('replaceImage.replaceImage') }}
            </div>
            <div class="header text-subtitle-1 text-left mb-2">
              {{ $t('replaceImage.replaceImageSubText') }}
            </div>
          </div>
          <div v-if="isConvertingInProgress" class="pa-1 mt-1">
            <LoadingAnimation :animationImages="currentAnimationImages" />
          </div>
          <div v-else id="replace-main-image">
            <v-img
              :aspect-ratio="16 / 9"
              :src="convertedImage || landscapeImageAsset"
              lazy-src="/assets/img/slides/placeholder-slide.svg"
              class="image-wrapper"
              alt="image"
              contain
            />
          </div>
        </div>
        <div class="replace-image pl-1">
          <div class="header text-subtitle-1 text-left font-weight-bold mt-2">
            <span> {{ $t('replaceImage.selectImageReplace') }} </span>
            <div v-if="!isOriginPrezentation">
              <NewFeatureBadge
                module-name="collections"
                :feature-id="'collections_thumbnail_id'"
                :offsetX="'30'"
                :offsetY="'10'"
                :size="'medium'"
              >
                <v-btn
                  class="addRemvCollBtn"
                  color="primary"
                  :ripple="false"
                  text
                  :plain="isCollectionLimitReached && !isAddedToCollection"
                  @click="handleCollection()"
                  data-pendo-id="slides-detail-collection"
                  :loading="processingCollection"
                  :disabled="!convertedImage || isConvertingInProgress"
                >
                  <v-tooltip
                    top
                    content-class="tooltip-content"
                    max-width="200"
                    fixed
                    v-if="!isAddedToCollection && isCollectionLimitReached"
                    open-delay="500"
                    close-delay="300"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#bdbdbd" v-bind="attrs" v-on="on">
                        mdi-file-plus-outline
                      </v-icon>
                      <span
                        class="action-button-disable__text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t('replaceImage.addToCollection') }}
                      </span>
                    </template>
                    <span>{{ $t('replaceImage.addToCollectionTooltip') }}</span>
                  </v-tooltip>
                  <v-icon
                    v-if="!isAddedToCollection && !isCollectionLimitReached"
                    color="primary"
                  >
                    mdi-file-plus-outline
                  </v-icon>
                  <span
                    class="action-button__text"
                    v-if="!isAddedToCollection && !isCollectionLimitReached"
                    >{{ $t('replaceImage.addToCollection') }}</span
                  >
                  <v-icon color="primary" v-if="isAddedToCollection">
                    mdi-file-minus
                  </v-icon>
                  <span
                    class="action-button__text"
                    v-if="isAddedToCollection"
                    >{{ $t('slides.removeFromCollection') }}</span
                  >
                </v-btn>
                <!--
                <v-btn
                  text
                  class="secondary-button text-subtitle-1"
                  height="30"
                  color="primary"
                  @click="handleCollection()"
                  :ripple="false"
                  v-if="!isCollectionLimitReached"
                  :disabled="
                    !convertedImage ||
                    isConvertingInProgress ||
                    isAddedToCollection
                  "
                >
                  <v-icon
                    v-if="!isAddedToCollection && !isCollectionLimitReached"
                    color="primary"
                  >
                    mdi-file-plus-outline
                  </v-icon>
                  <span
                    class="action-button__text"
                    v-if="!isAddedToCollection && !isCollectionLimitReached"
                    >{{ $t('replaceImage.addToCollection') }}</span
                  >
                  <v-icon color="#bdbdbd" v-if="isAddedToCollection">
                    mdi-check
                  </v-icon>
                  <span
                    class="action-button-disable__text"
                    v-if="isAddedToCollection"
                    >{{ $t('replaceImage.addedToCollection') }}</span
                  >
                </v-btn>
                <v-tooltip
                  top
                  content-class="tooltip-content"
                  max-width="200"
                  fixed
                  v-else
                  :disabled="
                    !convertedImage ||
                    isConvertingInProgress ||
                    isAddedToCollection
                  "
                  open-delay="500"
                  close-delay="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      class="secondary-button text-subtitle-1"
                      height="30"
                      color="primary"
                      :ripple="false"
                      plain
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="#bdbdbd">mdi-file-plus-outline</v-icon>
                      <span class="action-button-disable__text">
                        {{ $t('replaceImage.addToCollection') }}
                      </span>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('replaceImage.addToCollectionTooltip') }}
                  </span>
                </v-tooltip>
                !-->
              </NewFeatureBadge>

              <v-btn
                v-if="
                  canReplaceIcons &&
                  !hideReplaceIcon &&
                  allowReplaceIconOverall &&
                  allowReplaceIconTabs
                "
                :disabled="
                  isConvertingInProgress || isLoading || getIsIconNotFound
                "
                class="secondary-button text-subtitle-1"
                text
                height="30"
                color="primary"
                @click="switchToReplaceIcons()"
              >
                <NewFeatureBadge
                  :module-name="'replace_icon'"
                  :feature-id="'replace_icon_id'"
                  :offsetX="'30'"
                  :offsetY="'20'"
                  :size="'medium'"
                >
                  <img
                    :src="`/assets/img/${
                      isConvertingInProgress || isLoading || getIsIconNotFound
                        ? 'replace-icon-disabled'
                        : 'image-replace'
                    }.svg`"
                    alt
                  />

                  <span class="action-button__text">
                    {{ $t('replaceImage.replaceIconCTAOption') }}
                  </span>
                </NewFeatureBadge>
              </v-btn>

              <v-btn
                class="secondary-button text-subtitle-1"
                text
                height="30"
                color="primary"
                @click="showReset"
                :disabled="!convertedImage || isConvertingInProgress"
              >
                <v-icon color="primary"> mdi-arrow-u-left-top </v-icon>
                <span class="action-button__text">
                  {{ $t('replaceImage.resetToDefaultBtn') }}
                </span>
              </v-btn>
            </div>
          </div>
          <div
            v-if="isLoading"
            class="text-center loading-spinner__select-image-container"
          >
            <v-progress-circular
              :size="34"
              :width="2"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div
            justify="center"
            align="center"
            :class="`select-image my-1 ${
              isConvertingInProgress || isLocalImageUploading
                ? 'item-disabled'
                : ''
            } `"
            v-if="!isLoading && filteredImages && filteredImages.length > 0"
          >
            <v-slide-group v-model="model" show-arrows="always">
              <v-slide-item
                v-for="item in filteredImages"
                :key="item.replace_ref"
                v-slot="{ toggle }"
                :class="`${
                  item.seq_id ===
                  (currentSelectedSlideImageData &&
                    currentSelectedSlideImageData.seq_id)
                    ? 'selected'
                    : ''
                }`"
              >
                <v-img
                  v-if="!item.invalid"
                  class="select-image__item ma-2"
                  max-height="80"
                  max-width="100"
                  height="80"
                  contain
                  :src="item.imgUrl"
                  @click="onImageSelected(item, toggle)"
                  lazy-src="/assets/img/slides/placeholder-slide.svg"
                ></v-img>
              </v-slide-item>
            </v-slide-group>
          </div>
        </div>
      </v-col>
      <v-col class="choose-image" cols="4">
        <div class="image-library ml-2">
          <div class="header text-h6 text-left font-weight-bold">
            {{ $t('replaceImage.chooseImageText') }}
          </div>
          <div
            v-if="isLoading"
            class="text-center loading-spinner__image-library-container"
          >
            <v-progress-circular
              :size="50"
              :width="2"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div
            :style="{
              height: `${mainSlideHeight ? `${mainSlideHeight}px` : '100%'}`,
            }"
          >
            <ImageLibary
              v-if="!isLoading && currentSelectedSlideImageData"
              @imageSelected="onReplaceImage($event)"
              :currentSelectedSlideImageData="currentSelectedSlideImageData"
              :isConvertingInProgress="isConvertingInProgress"
              :key="imageLibraryComponentKey"
              @onImageSearch="onImageSearch"
              :slideData="selectedSlide"
            />
          </div>
        </div>
        <div class="actions" style="min-height: 60px">
          <v-btn
            v-if="isOriginPrezentation"
            class="secondary-button"
            height="48"
            rounded
            outlined
            color="primary"
            @click="showReset"
            :disabled="getDisabled"
          >
            {{ $t('replaceImage.resetToDefaultBtn') }}
          </v-btn>
          <div v-if="isOriginPrezentation">
            <v-btn
              class="primary-button py-2 ma-2"
              height="48"
              rounded
              filled
              color="#21a7e0"
              :disabled="!convertedImage || isConvertingInProgress"
              @click="onAcceptNewSlide"
            >
              {{ $t('common.accept') }}
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              v-if="!convertedImage || isConvertingInProgress"
              class="primary-button py-2 ma-2"
              height="48"
              rounded
              filled
              color="#21a7e0"
              :disabled="!convertedImage || isConvertingInProgress"
            >
              {{ $t('common.download') }}
            </v-btn>
            <Download
              v-else
              :id="
                isImageReplaceWalkThrough
                  ? 'walkthough-download-btn-id'
                  : undefined
              "
              origin="slide"
              limitsKey="slide"
              :file-name="outputFileName"
              :item="selectedSlide"
              :count-first-time="1"
              :on-download="downloadLocal"
              :downloadUrl="downloadUrl"
              :log-download="logDownload"
              downloadType="SlideReplacement"
              :speedDialPosRight="'10'"
              :downloadIconType="
                getReplaceStackData.isIconReplacementComplete
                  ? 'SlideIconReplacement'
                  : ''
              "
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <ConfirmationDialog
      :show="dialogReset"
      :on-primary-click="onResetConfirm"
      :on-secondary-click="onResetCancel"
      :on-close="onResetCancel"
      :primary-btn-text="$t('common.yes')"
      :secondary-btn-text="$t('common.no')"
      :title="$t('replaceImage.confirmationDialogTitle')"
      :text="$t('replaceImage.confirmationDialogText')"
    />
    <ErrorDialog
      :show="isErrorLoadingImageReplaceData"
      :on-primary-click="onErrorDialogOk"
      :primary-btn-text="$t('common.ok')"
      :text="$t(errorMessageLoadingImageReplaceData)"
      :subText="
        getIsDirtyIcon ||
        (getReplaceStackData &&
          getReplaceStackData.filteredIcons &&
          getReplaceStackData.filteredIcons.length > 0)
          ? $t('replaceImage.goToReplaceIcon')
          : ''
      "
      :onClose="onErrorDialogClose"
    />
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapActions, mapGetters } from 'vuex';
import ImageLibary from './ImageLibrary.vue';
import LoadingAnimation from '../../common/LoadingAnimation.vue';
import { getFileURL } from '@/utils/calyrex';
import { getFileURLDownload } from '@/utils/calyrex-test';
import {
  replaceImage,
  extractImage,
} from '../../../utils/replace-image-api-helper';
import Download from '../../common/Download.vue';

import { convertPdfToImage } from '../../../utils/pdf-to-png';
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';
import ErrorDialog from './ErrorDialog.vue';
import { calyrexConfig } from '../../../runtimeConfig';
import EventBus from '../../common/event-bus';
// import calculateCoordinates from './calculateCoordinates';
import ImageNotSuitable from './ImageNotSuitable.vue';
import { downloadFileWithCustomName } from '../../../utils/common';
import {
  IMAGE_UPLOAD_FAILED,
  LOCAL_IMAGE_UPLOADED,
  LOCAL_IMAGE_UPLOADING,
} from './constants';
import { updateCollection, deleteCollection } from '../../../utils/api-helper';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import {
  getBuildPrezentationData,
  RESET_TO_DEFAULT,
  IMAGE_REPLACEMENT,
  MY_PREZENTATION,
  SLIDES,
  BUILD,
  TD_QUERY,
  TD_REPLACE_IMAGE_SOURCE,
  TD_PREZNAME,
  NA,
  TD_PREZTYPE,
  TD_ISDOWNLOAD,
  TD_DOWNLOADTYPE,
  TD_DOWNLOADTYPE_DIRECT,
  TD_AUD,
  isSlideFavorite,
  TD_ISFAVORITED,
  TD_COMPANY_OF_AUD_TAILORED_FOR,
  getAudienceCompanyName,
  getAudienceId,
  TD_NORESULTSFOUND,
  TD_EVENT_START,
  TD_EVENT_END,
  TD_TIME_SPENT,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { trackBuildEvents } from '../../common/Analytics/BuildEvents';
import { trackPrezentationEvents } from '../../common/Analytics/PrezentationEvents';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents';
import { trackCommonEvents } from '../../common/Analytics/CommonEvents';
import { trackTimeSpentEvents } from '../../common/Analytics/TimeSpentEvents';
import { getThumbnail } from '../../../store/modules/helper';
import { convertPPTToPng } from '../../../utils/merge-slide-helper';

export default {
  name: 'SlideImageReplacement',
  inject: {
    isImageReplaceWalkThrough: { default: false },
    query: { default: '' },
  },
  data() {
    return {
      mainSlideHeight: 0,
      convertedImage: null,
      model: 0,
      isLoading: true,
      isConvertingInProgress: false,
      isLocalImageUploading: false,
      outputFileName: null,
      downloadUrl: null,
      imageToReplace: null,
      landscapeImageAsset: null,
      deckDetails: null,
      imageDetails: null,
      slideData: {},
      deckImageRefers: null,
      isAddedToCollection: false,
      filteredImages: null,
      deckImages: null,
      imageLibraryComponentKey: 1,
      currentSelectedSlideImageData: null,
      selectedImageSeqId: null,
      currentAnimationImages: null,
      savedSlidePath: null,
      savedImagePath: null,
      payloadData: null,
      stepOne: {
        url: '/assets/img/slideImageReplacement/analyzing-content.svg',
        text: 'replaceImage.analyzingImageContent',
      },
      stepTwo: {
        url: '/assets/img/slideImageReplacement/resizing-cleaning-image.svg',
        text: 'replaceImage.cleaningTheImage',
      },
      stepThree: {
        url: '/assets/img/slideImageReplacement/finalizing-slide.svg',
        text: 'replaceImage.finalizingTheSlideDesign',
      },
      dialogReset: false,
      notImages: ['image/x-wmf', 'image/x-emf'],
      newInput: {},
      guidList: [],
      selectImageReplaceThumbnailGuidMap: {},
      isErrorLoadingImageReplaceData: false,
      errorMessageLoadingImageReplaceData: 'replaceImage.unableToReplaceImage',
      isImageReplacementComplete: false,
      processingCollection: false,
    };
  },
  props: {
    favoriteSlides: {
      type: Array,
    },
    selectedSlide: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    logDownload: {
      type: Function,
      required: false,
    },
    handleReset: {
      type: Function,
      required: true,
    },
    eventStart: {
      type: Date,
    },
    isShowBackButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    origin: {
      type: String,
      default: 'slides',
    },
    slideReplaceAccepted: {
      type: Function,
      default: () => {},
    },
    handleNewSlideAccepted: {
      type: Function,
      required: false,
    },
    prezentationObject: {
      type: Object,
      default: () => {},
    },
    selectedAudience: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    canReplaceIcons: {
      type: Boolean,
      default: false,
    },
    switchToReplaceIcons: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    ImageLibary,
    LoadingAnimation,
    ConfirmationDialog,
    Download,
    ErrorDialog,
    NewFeatureBadge,
  },
  async mounted() {
    this.setImageSearchData({
      searchTerm: '',
      source: '',
    });
    if (window.ResizeObserver) {
      const mainSlideSizeObserver = new ResizeObserver((data) => {
        this.mainSlideHeight = data && data[0] && data[0].contentRect.height;
      });
      mainSlideSizeObserver.observe(
        document.getElementById('replace-main-image'),
      );
    }
    EventBus.$emit('MINIMIZE_ADVANCED_FILTER');
    // EventBus.$on('IMAGE_UPLOADING', () => {
    //   this.imageUploading();
    // });
    EventBus.$on(LOCAL_IMAGE_UPLOADING, () => {
      this.isLocalImageUploading = true;
    });
    EventBus.$on(LOCAL_IMAGE_UPLOADED, () => {
      this.isLocalImageUploading = false;
    });
    EventBus.$on(IMAGE_UPLOAD_FAILED, (subTitle) => {
      this.isConvertingInProgress = false;
      this.isLocalImageUploading = false;

      this.$modal.show(
        ImageNotSuitable,
        {
          subTitle,
        },
        {
          name: 'ImageNotSuitable',
          width: '564',
          height: '200px',
        },
      );
    });
    await this.loadImageReplacementData();
  },
  beforeDestroy() {
    // destroy event
    EventBus.$off('IMAGE_UPLOAD_FAILED');
  },
  computed: {
    ...mapGetters('slidesStore', ['getReplaceStackData', 'getIsIconNotFound']),
    ...mapState('users', ['currentUser', 'sessionKey', 'currentTheme']),
    ...mapState('slidesCollection', ['tailorID']),
    ...mapGetters('slidesCollection', ['isCollectionLimitReached']),
    ...mapGetters('replaceImageStore', ['getSearchTerm', 'getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),
    ...mapState('prezentations', ['currNewPrezentation']),
    ...mapGetters('users', ['allowReplaceIconOverall', 'allowReplaceIconTabs']),
    slideType() {
      let type = '';
      if (this.selectedSlide && Object.keys(this.selectedSlide).length > 0) {
        const { source } = this.selectedSlide.prefs;
        if (source === 'prezent') {
          type = 'prezentSourcedSlide';
        } else if (source === 'generated') {
          type = 'userGeneratedSlide';
        } else if (source === 'uploaded') {
          type = 'userUploadedSlide';
        } else if (source === 'synthesis') {
          type = 'synthesisSlide';
        } else if (source === 'redesign') {
          type = 'userRedesignSlide';
        } else if (source === 'comply') {
          type = 'userComplySlide';
        } else {
          type = 'companyBrandedSlide';
        }
      }
      return type;
    },
    hideReplaceIcon() {
      return (
        // this.slideType === 'userRedesignSlide' ||
        // this.slideType === 'userComplySlide' ||
        // this.slideType === 'synthesisSlide' ||
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.selectedSlide.isOwner === false &&
          this.selectedSlide.permission === 'Viewer') ||
        this.selectedSlide.permission === 'Restricted'
      );
    },
    allowReplaceIconOverall() {
      const repIconOverallFeature = this.currentUser?.features?.find(
        (ft) => ft.feature_name === 'replace_icon_overall',
      );
      return repIconOverallFeature && repIconOverallFeature.enabled;
    },
    isOriginPrezentation() {
      return [BUILD, MY_PREZENTATION].includes(this.origin);
    },
    getS3Bucket() {
      const { replacedSlideData, filename } = this.selectedSlide;
      if (
        !replacedSlideData &&
        filename.startsWith('/newImageReplaceOutput/output/')
      ) {
        return calyrexConfig.calyrex_bucket_name;
      }
      return replacedSlideData?.s3_bucket || calyrexConfig.slides_bucket_name;
    },
    getS3Path() {
      const { replacedSlideData, filename } = this.selectedSlide;
      if (this.selectedSlide.isImageReplaced) {
        return this.selectedSlide.file;
      }
      return replacedSlideData?.s3_path || filename;
    },
    getDisabled() {
      if (this.origin === 'slides') {
        return !this.imageToReplace;
      }
      if (this.selectedSlide.isReplaced) {
        return !this.selectedSlide.isReplaced;
      }
      return !this.imageToReplace;
    },
  },
  methods: {
    ...mapActions('slidesStore', ['setReplaceStackData', 'setContentNotFound']),
    ...mapActions('replaceImageStore', [
      'reset',
      'resetReturn',
      'makeDirty',
      'setImageSearchData',
    ]),
    ...mapActions('replaceIconStore', ['resetIcon', 'resetReturnIcon']),
    ...mapActions('slidesCollection', ['setCollectionData']),
    async loadImageReplacementData() {
      if (
        Object.keys(this.getReplaceStackData).length > 0 &&
        this.getReplaceStackData.convertedImage &&
        this.getReplaceStackData.convertedImage !== '' &&
        this.getReplaceStackData.filteredImages &&
        this.getReplaceStackData.selectedImageData
      ) {
        this.convertedImage = this.getReplaceStackData.convertedImage;
        this.filteredImages = this.getReplaceStackData.filteredImages;
        this.downloadUrl = this.getReplaceStackData.downloadUrl;
        this.isAddedToCollection = this.getReplaceStackData.isAddedToCollection;
        this.currentSelectedSlideImageData =
          this.getReplaceStackData.selectedImageData;
        this.savedImagePath = this.getReplaceStackData.savedImagePath;
        this.savedSlidePath = this.getReplaceStackData.savedSlidePath;
        this.newInput = this.getReplaceStackData.newInput;
        this.imageDetails = this.getReplaceStackData.filteredImages;
        this.isImageReplacementComplete =
          !!this.getReplaceStackData.isImageReplacementComplete;

        this.imageDetails = await this.getDeckDetailsData();
        this.filteredImages = this.imageDetails;
        this.isLoading = true;
        if (this.imageDetails) {
          await this.loadImagesInDeck(this.imageDetails);
        }
        this.isLoading = false;

        this.setReplaceStackData({
          ...this.getReplaceStackData,
          filteredImages: this.filteredImages,
          selectedImageData: this.currentSelectedSlideImageData,
        });
        // this.isLoading = false;
      } else if (
        Object.keys(this.getReplaceStackData).length > 0 &&
        this.getReplaceStackData.convertedImage &&
        this.getReplaceStackData.convertedImage !== '' &&
        (!this.getReplaceStackData.filteredImages ||
          !this.getReplaceStackData.selectedImageData)
      ) {
        this.convertedImage = this.getReplaceStackData.convertedImage;
        this.downloadUrl = this.getReplaceStackData.downloadUrl;
        this.isAddedToCollection = this.getReplaceStackData.isAddedToCollection;
        this.savedImagePath = this.getReplaceStackData.savedImagePath;
        this.savedSlidePath = this.getReplaceStackData.savedSlidePath;
        this.newInput = this.getReplaceStackData.newInput;
        this.isImageReplacementComplete =
          !!this.getReplaceStackData.isImageReplacementComplete;

        this.imageDetails = await this.getDeckDetailsData();
        this.filteredImages = this.imageDetails;
        this.isLoading = true;
        if (this.imageDetails) {
          await this.loadImagesInDeck(this.imageDetails);
        }
        this.isLoading = false;

        this.setReplaceStackData({
          ...this.getReplaceStackData,
          filteredImages: this.filteredImages,
          selectedImageData: this.currentSelectedSlideImageData,
        });
      } else {
        // making calls on mount when no cached data exists
        await this.loadAssets(this.selectedSlide);
        this.imageDetails = await this.getDeckDetailsData();
        this.filteredImages = this.imageDetails;
        this.isLoading = true;
        if (this.imageDetails) {
          await this.loadImagesInDeck(this.imageDetails);
        }
        this.isLoading = false;

        // retaining some mergeable state with replace icon to store
        this.setReplaceStackData({
          ...this.getReplaceStackData,
          convertedImage: this.convertedImage,
          filteredImages: this.filteredImages,
          isAddedToCollection: this.isAddedToCollection,
          selectedImageData: this.currentSelectedSlideImageData,
        });
      }
    },
    async handleCollection() {
      if (this.isCollectionLimitReached && !this.isAddedToCollection) return;
      if (this.isAddedToCollection) {
        const params = {
          type: 'slide',
          slideID: String(this.selectedSlide.unique_id),
          templateID: this.currentTheme.id,
          slideFileName: this.savedSlidePath,
        };
        this.processingCollection = true;
        await deleteCollection(params)
          .then((resp) => {
            console.log(resp);
            this.setCollectionData(resp.data.collections);
            this.setReplaceStackData({
              ...this.getReplaceStackData,
              isAddedToCollection: false,
            });
            this.isAddedToCollection = false;
            // this.collections[indexOfSet].slides.splice(indexOfSlide, 1);
          })
          .catch((err) => {
            console.log(err);
          });
        this.processingCollection = false;
      } else {
        const params = {
          templateID: this.currentTheme.id,
          templateCode: this.currentTheme.code,
          templateName: this.currentTheme.name,
          slideFileName: this.savedSlidePath,
          slideThumbnail: this.savedImagePath,
          slideID: String(this.selectedSlide.unique_id),
          idea: this.selectedSlide.title,
          tailorID: this.tailorID || this.currentUser.user.id,
          construct: this.selectedSlide.prefs.construct,
          isImageReplaced: this.isImageReplacementComplete,
          isIconReplaced: !!this.getReplaceStackData.isIconReplacementComplete,
          source: this.selectedSlide.prefs.source,
          sanitizedLevel: this.selectedSlide.sanitizedLevel,
          acceptedRejectedCount: this.selectedSlide.acceptedRejectedCount,
        };
        this.processingCollection = true;
        await updateCollection(params)
          .then((resp) => {
            this.setCollectionData(resp.data.collections);
            this.setReplaceStackData({
              ...this.getReplaceStackData,
              isAddedToCollection:
                !this.getReplaceStackData.isAddedToCollection,
            });
            this.isAddedToCollection = !this.isAddedToCollection;
          })
          .catch((err) => {
            console.log(err);
          });
        this.processingCollection = false;
        trackSlideEvents.slidesImageReplacementAddToCollection(
          this.selectedSlide,
          this.currentUser,
          {
            [TD_AUD]: this.tailorID || this.currentUser.user.id,
            [TD_ISFAVORITED]: isSlideFavorite(
              this.selectedSlide,
              this.favoriteSlides,
            )
              ? 1
              : 0,
            [TD_REPLACE_IMAGE_SOURCE]: this.getSearchTerm?.source,
            [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
              this.selectedAudience,
              this.currentUser,
            ),
          },
        );
      }
    },
    async loadImagesInDeck(imageDetails) {
      this.deckImageRefers = imageDetails;
      this.deckImages = imageDetails;
      // this.deckStatsSlides = deckstats.slides;
      for (const image of this.deckImageRefers) {
        const path = `${image.s3_path}`;
        image.imgUrl = null; // Need this to allow <v-img to work
        const imgUrl = await this.getSignedFileUrlFromCalyrex(path);
        image.imgUrl = imgUrl;
        const deckImage = image;
        if (deckImage) {
          if (this.selectImageReplaceThumbnailGuidMap[deckImage.imageIndex]) {
            image.imgUrl =
              this.selectImageReplaceThumbnailGuidMap[
                deckImage.imageIndex
              ].imgUrl;
          }
          image.seq_id = deckImage.imageIndex || 0;

          image.invalid = this.notImages.includes(deckImage.meta.image_type);
          // image.guid = deckImage.imageIndex;
          // image.extents = deckImage.meta;
          // if (!this.guidList.includes(deckImage.guid)) {
          if (this.imageToReplace?.seq_id === deckImage?.seq_id) {
            image.imgUrl = this.imageToReplace.id
              ? this.imageToReplace.url
              : await this.getSignedFileUrlFromCalyrex(
                  this.imageToReplace.path,
                );
            this.selectImageReplaceThumbnailGuidMap[deckImage.imageIndex] = {
              imgUrl: image.imgUrl,
            };
          }
          // }
        }
        if (
          !image.invalid &&
          (image.seq_id === this.selectedImageSeqId || image.seq_id === 0)
        ) {
          this.setCurrentSelectedSlideImageData(image);
        }
      }

      this.isErrorLoadingImageReplaceData = this.imageDetails.length === 0;
      if (this.isErrorLoadingImageReplaceData) {
        this.errorMessageLoadingImageReplaceData = 'replaceImage.noImageFound';
        this.setContentNotFound({
          type: 'image',
          value: true,
        });
      }

      if (!this.currentSelectedSlideImageData && this.imageDetails.length) {
        this.setCurrentSelectedSlideImageData(this.imageDetails[0]);
      }
      // this.guidList = this.deckImages.map((image) => image.guid);
    },

    setCurrentSelectedSlideImageData(image) {
      this.currentSelectedSlideImageData = {
        ...image,
        imageName: this.selectedSlide.name || this.selectedSlide.title,
        // resizeData: this.getResizeImageData(image),
      };
    },

    // getResizeImageData(image) {
    //   const { height: originalHeight, width: originalWidth } = image.meta;

    //   const cropPercentages = {
    //     left: image.crop_left,
    //     right: image.crop_right,
    //     top: image.crop_top,
    //     bottom: image.crop_bottom,
    //   };
    //   const cropData = calculateCoordinates(
    //     'crop',
    //     originalHeight,
    //     originalWidth,
    //     cropPercentages,
    //   );
    //   console.log('crop data', cropData);
    //   return cropData;
    // },

    async onReplaceImage(imageToReplace) {
      try {
        this.imageToReplace = imageToReplace;
        this.imageUploading();
        await this.triggerReplaceImage();
        this.setReplaceStackData({
          ...this.getReplaceStackData,
          isAddedToCollection: false,
          isImageReplacementComplete: true,
        });
        this.isAddedToCollection = false;
        this.isImageReplacementComplete = true;
        if (this.currentAnimationImages === this.stepThree) {
          this.isConvertingInProgress = false;
        }
        this.makeDirty();
        try {
          this.postImageReplacementTrackingToMatomo();
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log('TriggerReplaceImage error: ', e);
        EventBus.$emit('IMAGE_UPLOAD_FAILED');
      }
    },
    onImageSearch({ searchTerm, source, noResultsFoundValue }) {
      const prezentationDataForTracking = {
        [TD_PREZNAME]: NA,
        [TD_PREZTYPE]: NA,
        [TD_NORESULTSFOUND]: noResultsFoundValue,
        ...this.getCommonMatomoOtherData(),
      };
      if (
        this.prezentationObject &&
        Object.keys(this.prezentationObject).length
      ) {
        const { name, type } = this.prezentationObject;
        prezentationDataForTracking[TD_PREZNAME] = name;
        prezentationDataForTracking[TD_PREZTYPE] = type;
      }
      trackCommonEvents.imageReplacementSearch(
        this.currentUser,
        this.selectedSlide,
        {
          [TD_QUERY]: searchTerm,
          [TD_REPLACE_IMAGE_SOURCE]: source,
          ...prezentationDataForTracking,
        },
        this.origin,
      );
      this.setImageSearchData({
        searchTerm,
        source,
      });
    },

    postImageReplacementTrackingToMatomo(flowType = 'IMAGE_REPLACEMENT') {
      const prezTrackingData = getBuildPrezentationData(
        this.prezentationObject,
      );
      const eventStart = this.eventStart ? this.eventStart : new Date();
      trackTimeSpentEvents.ReplacingAnImage(this.currentUser, {
        [TD_EVENT_START]: eventStart
          .toUTCString()
          .replaceAll(',', '')
          .replace('GMT', ''),
        [TD_EVENT_END]: new Date()
          .toUTCString()
          .replaceAll(',', '')
          .replace('GMT', ''),
        [TD_TIME_SPENT]: (new Date() - eventStart) / 1000,
      });
      const { searchTerm, source } = this.getSearchTerm;
      const imageReplaceTrackingData = {
        ...prezTrackingData,
        [TD_QUERY]: searchTerm,
        [TD_REPLACE_IMAGE_SOURCE]: source,
      };
      switch (flowType) {
        case IMAGE_REPLACEMENT:
          if (this.origin.toLowerCase() === BUILD) {
            trackBuildEvents.buildSlidesImageReplacement(
              this.selectedSlide,
              this.currentUser,
              imageReplaceTrackingData,
            );
          }
          if (this.origin.toLowerCase() === MY_PREZENTATION) {
            trackPrezentationEvents.prezentationSlidesImageReplacement(
              this.currentUser,
              imageReplaceTrackingData,
            );
          }
          if (this.origin.toLowerCase() === SLIDES && !this.isBPLSlideView) {
            trackSlideEvents.slideImageReplacement(
              this.selectedSlide,
              this.currentUser,
              imageReplaceTrackingData,
            );
          }
          break;
        case RESET_TO_DEFAULT:
          if (this.origin.toLowerCase() === BUILD) {
            trackBuildEvents.buildSlidesImageReplacementReset(
              this.selectedSlide,
              this.currentUser,
              imageReplaceTrackingData,
            );
          }
          if (this.origin.toLowerCase() === MY_PREZENTATION) {
            trackPrezentationEvents.prezentationImageReplacementResetToDefault(
              this.selectedSlide,
              this.currentUser,
              imageReplaceTrackingData,
            );
          }
          if (this.origin.toLowerCase() === SLIDES) {
            trackSlideEvents.slidesImageReplacementResetToDefault(
              this.selectedSlide,
              this.currentUser,
              imageReplaceTrackingData,
            );
          }
          break;
        default:
          break;
      }
    },

    async triggerReplaceImage() {
      this.savedSlidePath = null;
      this.savedImagePath = null;
      this.outputFileName = `${uuidv4()}.pptx`;

      const { meta, shapeType, imageIndex } =
        this.currentSelectedSlideImageData;

      let newImage = {
        imageIndex,
        s3_path: this.imageToReplace.s3_path,
        source: this.imageToReplace.source,
      };

      if (
        this.imageToReplace.source === 'adobe' ||
        this.imageToReplace.source === 'freepik'
      ) {
        newImage = {
          imageIndex,
          source: this.imageToReplace.source,
          id: this.imageToReplace.id,
        };
      } else if (this.imageToReplace.source === 'upload') {
        newImage = {
          imageIndex,
          source: this.imageToReplace.source,
          image: this.imageToReplace.image,
          extension: this.imageToReplace.extension,
        };
      }

      const s3Path = this.newInput.s3_path
        ? this.newInput.s3_path
        : this.getS3Path;
      let s3Bucket = calyrexConfig.slides_bucket_name;

      if (s3Path.indexOf('newImageReplaceOutput/') !== -1) {
        s3Bucket = calyrexConfig.calyrex_bucket_name;
      }

      if (this.selectedSlide.prefs.source === 'uploaded') {
        s3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
      } else if (
        this.selectedSlide.prefs.source === 'generated' ||
        this.selectedSlide.prefs.source === 'synthesis' ||
        this.selectedSlide.prefs.source === 'redesign' ||
        this.selectedSlide.prefs.source === 'comply'
      ) {
        if (
          this.selectedSlide.landscape.startsWith(
            '/newImageReplaceOutput/output/',
          ) ||
          this.selectedSlide.landscape.startsWith('/output/pptx/') ||
          s3Path.indexOf('newImageReplaceOutput/') !== -1
        ) {
          s3Bucket = calyrexConfig.calyrex_bucket_name;
        } else {
          s3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
        }
      }

      if (this.getIsDirty === true) {
        s3Bucket = calyrexConfig.calyrex_bucket_name;
      }

      const body = {
        s3_bucket: s3Bucket,
        s3_path: s3Path,
        outputFormat: 'pptx',
        newImage,
        oldImage: { meta, shapeType },
        matchCoordinates: true,
      };

      const response = await replaceImage(body);

      if (response?.s3_path) {
        console.log('Deck successfuly converted', response);

        if (response.input) {
          this.payloadData = {
            ...body,
            newImage: { ...response.input, imageIndex },
          };
        }
        this.deckDetails = response;
        this.newInput = response;
        if (this.deckDetails) {
          // this.currentSelectedSlideImageData = null;

          this.imageDetails = this.imageDetails.map((image) => {
            if (image !== undefined && image.imageIndex === imageIndex) {
              image.imgUrl = this.imageToReplace.url;
              this.currentSelectedSlideImageData = {
                imageName:
                  this.getReplaceStackData?.selectedImageData?.imageName || '',
                ...image,
              };
            }

            return image;
          });

          this.filteredImages = this.imageDetails;
        }
        this.downloadUrl = await this.getSignedFileUrlFromCalyrex(
          response?.s3_path,
        );
        this.savedSlidePath = response?.s3_path;

        // await this.getLandscapeImage(resPdf?.s3_path);

        this.savedImagePath = await convertPPTToPng(
          response.s3_bucket,
          response.s3_path,
        );
        this.convertedImage = await this.getSignedFileUrlFromCalyrex(
          this.savedImagePath,
        );

        // updating shared replace state after each replace
        this.setReplaceStackData({
          ...this.getReplaceStackData,
          convertedImage: this.convertedImage,
          filteredImages: this.filteredImages,
          downloadUrl: this.downloadUrl,
          isAddedToCollection: this.isAddedToCollection,
          selectedImageData: this.currentSelectedSlideImageData,
          savedImagePath: this.savedImagePath,
          savedSlidePath: this.savedSlidePath,
          newInput: this.newInput,
          isImageReplacementComplete: this.isImageReplacementComplete,
        });
      } else {
        console.error(response);
        this.isErrorLoadingImageReplaceData = true;
        this.errorMessageLoadingImageReplaceData =
          'replaceImage.replaceImageTimedOut';
      }
    },

    async getLandscapeImage(outputFilename) {
      this.convertedImage = await this.getSignedFileUrlFromCalyrex(
        outputFilename,
      );
      this.savedImagePath = outputFilename;
      this.convertedImage = await convertPdfToImage(this.convertedImage);
    },

    getSlideCategoryS3Bucket() {
      if (this.selectedSlide.isImageReplaced) {
        return calyrexConfig.calyrex_bucket_name;
      }

      if (this.selectedSlide.prefs.source === 'uploaded') {
        return process.env.VUE_APP_MVP_ASSETS_BUCKET;
      }
      if (
        this.selectedSlide.prefs.source === 'generated' ||
        this.selectedSlide.prefs.source === 'synthesis' ||
        this.selectedSlide.prefs.source === 'redesign' ||
        this.selectedSlide.prefs.source === 'comply'
      ) {
        if (
          this.selectedSlide.landscape.startsWith(
            '/newImageReplaceOutput/output/',
          ) ||
          this.selectedSlide.landscape.startsWith('/output/pptx/')
        ) {
          return calyrexConfig.calyrex_bucket_name;
        }
        return process.env.VUE_APP_MVP_ASSETS_BUCKET;
      }
      return this.getS3Bucket;
    },

    async getDeckDetailsData() {
      const body = {
        s3_bucket:
          this.getReplaceStackData &&
          this.getReplaceStackData?.newInput &&
          this.getReplaceStackData?.newInput?.s3_bucket
            ? this.getReplaceStackData?.newInput?.s3_bucket
            : this.getSlideCategoryS3Bucket(),
        s3_path:
          this.getReplaceStackData &&
          this.getReplaceStackData?.newInput &&
          this.getReplaceStackData?.newInput?.s3_path
            ? this.getReplaceStackData?.newInput?.s3_path
            : this.getS3Path,
        force_update: false,
      };
      const response = await extractImage(body);

      if (response?.images && response?.images.length > 0) {
        return response.images;
      }
      console.log('Failed to get deck details', response);
      this.isErrorLoadingImageReplaceData = true;
      this.errorMessageLoadingImageReplaceData =
        response?.images && response?.images.length === 0
          ? 'replaceImage.noImageFound'
          : 'replaceImage.replaceImageTimedOut';
      this.setContentNotFound({
        type: 'image',
        value: !!(response?.images && response?.images.length === 0),
      });
      return null;
    },

    onErrorDialogClose() {
      // this.isErrorLoadingImageReplaceData = false;
      this.onErrorDialogOk();
    },

    onErrorDialogOk() {
      this.isErrorLoadingImageReplaceData = false;
      this.errorMessageLoadingImageReplaceData = '';
      if (
        this.getIsDirtyIcon ||
        (this.getReplaceStackData &&
          this.getReplaceStackData.filteredIcons &&
          this.getReplaceStackData.filteredIcons.length > 0)
      ) {
        this.switchToReplaceIcons();
      } else {
        this.$emit('toggleImageReplace');
      }
    },

    async loadAssets(slideData) {
      if (slideData.isImageReplaced) {
        slideData.landscape = slideData.assetId;
      }
      if (slideData.prefs.source === 'uploaded') {
        const uploadedLandscape = await getFileURL(
          this.currentUser.user.cognitoID,
          slideData.landscape,
          this.currentUser.userIp,
          'IOCUpload',
        );
        this.landscapeImageAsset = await convertPdfToImage(uploadedLandscape);
      } else if (
        slideData.prefs.source === 'generated' ||
        slideData.prefs.source === 'synthesis' ||
        slideData.prefs.source === 'redesign' ||
        slideData.prefs.source === 'comply'
      ) {
        if (
          slideData.landscape.startsWith('/newImageReplaceOutput/output/') ||
          slideData.landscape.startsWith('/output/pptx/')
        ) {
          this.landscapeImageAsset = await getThumbnail(slideData.landscape);
        } else {
          this.landscapeImageAsset = await getFileURL(
            this.currentUser.user.cognitoID,
            slideData.landscape,
            this.currentUser.userIp,
            'IOCUpload',
          );
        }
      } else if (
        slideData?.landscape &&
        (slideData?.landscape?.startsWith('/output/pptx/') ||
          slideData?.landscape?.startsWith('/newImageReplaceOutput/output/'))
      ) {
        this.landscapeImageAsset = await getFileURLDownload(
          this.currentUser.user.id,
          slideData?.landscape.startsWith('/')
            ? slideData.landscape
            : `/${slideData.landscape}`,
          this.currentUser.userIp,
        );

        if (slideData?.landscape?.indexOf('.pdf') !== -1) {
          this.landscapeImageAsset = await convertPdfToImage(
            this.landscapeImageAsset,
          );
        }
      } else if (
        !slideData?.landscape?.startsWith('https') &&
        !slideData.replacedSlideData
      ) {
        this.landscapeImageAsset = await getFileURL(
          this.currentUser.user.cognitoID,
          slideData.landscape.startsWith('/')
            ? slideData.landscape
            : `/${slideData.landscape}`,
          this.currentUser.userIp,
        );

        if (slideData?.landscape?.indexOf('.pdf') !== -1) {
          this.landscapeImageAsset = await convertPdfToImage(
            this.landscapeImageAsset,
          );
        }
      } else {
        this.landscapeImageAsset = slideData.landscape;
      }
    },
    onImageSelected(slideImage, toggle) {
      toggle();
      this.currentSelectedSlideImageData = {
        ...slideImage,
        imageName: this.selectedSlide.name,
        // resizeData: this.getResizeImageData(slideImage),
      };
      this.selectedImageSeqId = slideImage.seq_id;
      this.imageLibraryComponentKey++;
    },

    showReset() {
      this.dialogReset = true;
    },

    onAcceptNewSlide() {
      this.reset();
      this.setReplaceStackData({});
      this.handleNewSlideAccepted({
        ...this.selectedSlide,
        thumbnail: this.selectedSlide.thumbnail,
        replacedInfo: this.payloadData,
      });
      this.slideReplaceAccepted({
        newSlide: this.savedSlidePath,
        currentThumbnail: this.savedImagePath,
        replacedInfo: this.payloadData,
      });
      const { searchTerm, source } = this.getSearchTerm;
      trackCommonEvents.imageReplacementAccept(
        this.currentUser,
        this.selectedSlide,
        {
          ...getBuildPrezentationData(this.prezentationObject),
          [TD_QUERY]: searchTerm,
          [TD_REPLACE_IMAGE_SOURCE]: source,
          ...this.getCommonMatomoOtherData(),
        },
        this.origin,
      );
    },

    async onResetConfirm() {
      this.handleReset();
      this.reset();
      this.resetReturn();

      this.resetIcon();
      this.resetReturnIcon();

      // resetting cached data before default reset on flow basis
      this.setReplaceStackData({});

      this.loadImageReplacementData();
      this.postImageReplacementTrackingToMatomo('RESET_TO_DEFAULT');
    },

    onResetCancel() {
      this.dialogReset = false;
    },

    // Utility functions
    async getSignedFileUrlFromCalyrex(filePath) {
      if (filePath) {
        return await getFileURLDownload(
          this.currentUser.user.cognitoID,
          filePath.startsWith('/') ? filePath : `/${filePath}`,
          this.currentUser.userIp,
        );
      }
      return '';
    },
    async downloadLocal() {
      let fileName = '';
      if (
        (this.selectedSlide.prefs.source === 'generated' ||
          this.selectedSlide.prefs.source === 'synthesis' ||
          this.selectedSlide.prefs.source === 'redesign' ||
          this.selectedSlide.prefs.source === 'uploaded' ||
          this.selectedSlide.prefs.source === 'comply') &&
        this.selectedSlide.name
      ) {
        fileName = this.selectedSlide.name.replace(/.pptx/g, '');
      } else if (this.selectedSlide.asset) {
        fileName = `${this.selectedSlide.asset.categories[0]}_${this.selectedSlide.asset.prefs.theme}`;
      } else {
        fileName = `${this.selectedSlide.categories[0]}_${this.selectedSlide.prefs.theme}`;
      }
      downloadFileWithCustomName(this.downloadUrl, fileName);
      this.logDownload(
        this.selectedSlide,
        this.isImageReplacementComplete,
        false,
        !!this.getReplaceStackData.isIconReplacementComplete,
      );
      if (this.origin.toLowerCase() === SLIDES && this.isAddedToCollection) {
        trackSlideEvents.slidesImageReplacementAddToCollectionDownload(
          this.selectedSlide,
          this.currentUser,
          {
            [TD_AUD]: this.tailorID || this.currentUser.user.id,
            [TD_ISFAVORITED]: isSlideFavorite(
              this.selectedSlide,
              this.favoriteSlides,
            )
              ? 1
              : 0,
            [TD_ISDOWNLOAD]: 1,
            [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
            [TD_REPLACE_IMAGE_SOURCE]: this.getSearchTerm?.source,
            [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
              this.selectedAudience,
              this.currentUser,
            ),
          },
        );
      }
    },

    imageUploading() {
      this.currentAnimationImages = this.stepOne;
      this.isConvertingInProgress = true;
      this.showLoadingAnimation();
    },
    showLoadingAnimation() {
      setTimeout(
        () => {
          if (this.currentAnimationImages === this.stepOne) {
            this.currentAnimationImages = this.stepTwo;
            this.showLoadingAnimation();
          } else if (this.currentAnimationImages === this.stepTwo) {
            this.currentAnimationImages = this.stepThree;
            this.showLoadingAnimation();
          } else if (this.savedImagePath) {
            this.isConvertingInProgress = false;
          }
        },
        this.currentAnimationImages === this.stepThree ? 1000 : 1500,
      );
    },
    getCommonMatomoOtherData() {
      return {
        [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
          this.selectedAudience,
          this.currentUser,
        ),
        [TD_AUD]: getAudienceId(this.selectedAudience),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.btn-back {
  letter-spacing: normal;
  padding: 0 !important;
  text-transform: none;
}

.header {
  color: #000;
  font-family: 'Lato' !important;
  display: flex;
  justify-content: space-between;
}

.main-image {
  align-items: flex-start;
  display: inline-block;
  flex-direction: column;
  font-family: 'Lato';
  justify-content: flex-start;
  width: 100%;

  .main-image-header {
    padding-bottom: 4px;
    // .text-subtitle-1 {
    //   margin: 10px 0 10px 0;
    // }
  }
  .image-wrapper {
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    overflow: hidden;
    position: relative;
  }
  .main-image__wrapper {
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    overflow: hidden;
    position: relative;
    max-height: 32rem;
    margin-left: 5px;

    .v-responsive {
      border-radius: 8px;
      object-fit: contain;
    }
    img {
      height: 100%;
      width: 100%;
    }
    :hover {
      box-shadow: $item-box-shadow-hover;
    }
    .main-image__img {
      border-radius: 8px;
    }
  }
}

.select-image {
  align-items: center;
  display: flex;
  padding-top: 2px;
  max-width: 100%;

  .select-image__item {
    border: solid $light-gray 1px;
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    min-width: 100px;

    &:hover {
      box-shadow: $item-box-shadow-hover;
      cursor: pointer;
    }
  }

  .selected {
    border: 2px solid $zen-blue-default;
  }
}

.choose-image {
  color: #000;
  max-height: 680px;
  padding: 5px !important;
  // position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.actions {
  align-items: center;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: flex-end;
  position: absolute;
  right: 0px;
}
.loading-spinner__select-image-container {
  padding: 40px;
}

.loading-spinner__image-library-container {
  padding-top: 200px;
}
.replace-image {
  .secondary-button {
    padding: 0 5px;
    height: 30px;
    ::v-deep .v-btn__content {
      opacity: 1 !important;
    }
  }
  .action-button-disable__text {
    color: #bdbdbd;
  }
  .addRemvCollBtn {
    cursor: pointer;
    text-transform: none;
    padding: 0 !important;

    .v-btn__content {
      // .v-icon {
      //   font-size: 16px !important;
      // }
      .action-button__text {
        font-size: 16px !important;
      }
      // img {
      //   width: 16px;
      //   height: 16px;
      // }
    }

    &:hover {
      background: #fff;
    }
    .action-button__text {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: normal;
      text-transform: none;
    }
    .action-button-disable__text {
      font-size: 16px;
      font-weight: bold;
      color: #bdbdbd;
      letter-spacing: normal;
      text-transform: none;
    }
  }
}
</style>
