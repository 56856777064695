<template>
  <div class="main-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark>mdi-close</v-icon>
    </v-btn>
    <div class="spinner-wrapper" v-if="isLoading">
      <LoadSpinner />
    </div>
    <div class="sharing-modal-main-wrapper" v-if="!isLoading">
      <div class="sharing-modal-container">
        <div class="sharing-modal__title font-weight-bold">
          {{ $t('storyline.shareStoryLine') }}
        </div>
        <v-radio-group v-model="shareOptionType" style="height: 67px">
          <v-radio
            color="#20a7e0"
            name="shareType"
            :label="$t('prezentationShareStatus.private')"
            value="me"
            @change="handleShareTypeChangeFn()"
          ></v-radio>
          <v-radio
            color="#20a7e0"
            name="shareType"
            :label="$t('profile.shareTemplateAccess.shareWith')"
            value="share"
            @change="handleShareTypeChangeFn()"
            style="padding-top: 3px"
          ></v-radio>
        </v-radio-group>
        <div class="share-container">
          <div class="radio-button_item">
            <v-checkbox
              v-model="permissionGroup"
              :label="$t('profile.shareTemplateAccess.myCompany')"
              color="#20a7e0"
              hide-details
              value="company"
              :disabled="shareOptionType !== 'share'"
            ></v-checkbox>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              dense
              color="rgba(0, 0, 0, 0.6)"
              v-show="permissionGroup.indexOf('company') > -1"
              item-text="text"
              item-value="text"
              :items="shareOptions"
              v-model="companyPermission"
              class="share-option-select_dropdown"
            >
              <template v-slot:selection="{ item }">
                <div>
                  <span>{{ item.text }}</span>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div lass="d-flex">
                  <div class="share-select-item__selected">
                    <v-icon
                      v-if="companyPermission === item.text"
                      color="primary"
                    >
                      mdi-check
                    </v-icon>
                  </div>
                </div>
                <div class="d-flex flex-column share-select-item">
                  <div>
                    {{ item.text }}
                  </div>
                  <div
                    class="text-caption share-select-item__description"
                    v-if="item.description"
                  >
                    {{ item.description }}
                  </div>
                </div>
              </template></v-select
            >
          </div>
          <div class="radio-button_item">
            <v-checkbox
              v-model="permissionGroup"
              :label="$t('profile.shareTemplateAccess.myTeam')"
              color="#20a7e0"
              hide-details
              value="team"
              :disabled="shareOptionType !== 'share'"
            ></v-checkbox>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              dense
              color="rgba(0, 0, 0, 0.6)"
              v-show="permissionGroup.indexOf('team') > -1"
              item-text="text"
              item-value="text"
              :items="shareOptions"
              v-model="teamPermission"
              class="share-option-select_dropdown"
            >
              <template v-slot:selection="{ item }">
                <div>
                  <span>{{ item.text }}</span>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div class="d-flex">
                  <div class="share-select-item__selected">
                    <v-icon v-if="teamPermission === item.text" color="primary">
                      mdi-check
                    </v-icon>
                  </div>
                </div>
                <div class="d-flex flex-column share-select-item">
                  <div>
                    {{ item.text }}
                  </div>
                  <div
                    class="text-caption share-select-item__description"
                    v-if="item.description"
                  >
                    {{ item.description }}
                  </div>
                </div>
              </template>
            </v-select>
          </div>
          <v-checkbox
            v-model="permissionGroup"
            :label="$t('profile.shareTemplateAccess.selectColleague')"
            color="#20a7e0"
            hide-details
            value="user"
            :disabled="shareOptionType !== 'share'"
          ></v-checkbox>
          <div
            v-if="permissionGroup.indexOf('user') > -1"
            :label="$t('profile.shareTemplateAccess.selectColleague')"
          >
            <UserSelector
              v-model="selectedUsers"
              filter="insideCompanyUsers"
              @change="onSelectedUsersChange($event)"
              class="select-colleagues-autocomplete"
            >
              <template v-slot:user-actions="{ item }">
                <v-list-item-action>
                  <v-select
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="rgba(0, 0, 0, 0.6)"
                    dense
                    item-text="text"
                    item-value="text"
                    v-model="item.permission_level"
                    :items="userShareOptions"
                    name="selectItem"
                    class="share-option-select_dropdown text-caption"
                  >
                    <template v-slot:selection="selectSlotItem">
                      {{ selectSlotItem.item.text }}
                    </template>
                    <template v-slot:item="selectSlotItem">
                      <div lass="d-flex">
                        <div class="share-select-item__selected">
                          <v-icon
                            v-if="
                              item.permission_level === selectSlotItem.item.text
                            "
                            color="primary"
                          >
                            mdi-check
                          </v-icon>
                        </div>
                      </div>
                      <div class="d-flex flex-column share-select-item">
                        <div>
                          {{ selectSlotItem.item.text }}
                        </div>
                        <div
                          class="text-caption share-select-item__description"
                          v-if="selectSlotItem.item.description"
                        >
                          {{ selectSlotItem.item.description }}
                        </div>
                      </div>
                    </template>
                  </v-select>
                </v-list-item-action>
              </template>
            </UserSelector>
          </div>
        </div>
      </div>
      <div class="actions">
        <v-btn
          class="primary-button"
          rounded
          color="#21a7e0"
          height="48"
          min-width="134"
          :disabled="isUpdating"
          @click="updateShareDetails"
        >
          {{ this.$t(this.updateBtnText) }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import LoadSpinner from '../../common/LoadSpinner';
import UserSelector from '../../common/UserSelector/UserSelector.vue';
import { getShareStorylines, shareStorylines } from '../../../utils/api-helper';
import {
  getSharedEntitiesTypes,
  TD_FINGERPRINT_SHARING_SETTINGS,
  TD_PREZTYPE,
  TD_STORYLINE_ORIGIN_DETAIL,
  TD_STORYLINE_ORIGIN_TABLE,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';

export default {
  name: 'ShareStoryline',
  props: {
    data: {
      type: Object,
      require: false,
    },
    onUpdate: {
      type: Function,
      required: false,
    },
    origin: {
      type: String,
      required: false,
    },
  },
  components: { LoadSpinner, UserSelector },
  data() {
    return {
      isLoading: true,
      updateBtnText: 'common.update',
      selectedUsers: [],
      autoUpdate: true,
      companyPermission: 'Viewer',
      teamPermission: 'Viewer',
      shareOptionType: '',
      permissionGroup: [],
      userLevelShareDetails: null,
      companyLevelShareDetails: null,
      teamLevelShareDetails: null,
      isUpdating: false,
      shareOptions: [
        {
          text: 'Viewer',
          description: 'Build prezentations, view storyline',
          value: '1',
        },
        {
          text: 'Editor',
          description: 'Build prezentations, view and update storyline',
          value: '2',
        },
      ],
      userShareOptions: [
        {
          text: 'Viewer',
          description: 'Build prezentations, view storyline',
          value: '1',
        },
        {
          text: 'Editor',
          description: 'Build prezentations, view and update storyline',
          value: '2',
        },
      ],
    };
  },
  async beforeMount() {
    this.isLoading = true;
    await getShareStorylines(this.data.uuid)
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          if (res.data.length === 0) {
            this.shareOptionType = 'me';
          } else {
            this.shareOptionType = 'share';

            const permissionGroup = [];
            const selectedUsers = [];
            res.data.forEach((item) => {
              permissionGroup.push(item.sharedEntityType);

              switch (item.sharedEntityType) {
                case 'company':
                  this.companyPermission = item.permissionLevel;
                  break;
                case 'team':
                  this.teamPermission = item.permissionLevel;
                  break;
                case 'user':
                  selectedUsers.push({
                    ...item,
                    isActive: true, // making users enabled by default
                    id: item.sharedEntityID,
                    permission_level: item.permissionLevel,
                  });
                  break;
                default:
                  break;
              }
            });
            this.selectedUsers = selectedUsers;
            this.permissionGroup = [...new Set(permissionGroup)];
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.isLoading = false;
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    onSelectedUsersChange(users) {
      this.selectedUsers = users.map((user) => {
        if (!user.permission_level) {
          user.permission_level = this.userShareOptions[0].text;
        }
        return user;
      });
    },
    handleClose() {
      this.$modal.hide('ShareStoryline');
    },

    updateShareDetails() {
      this.isUpdating = true;
      let shareEntities = [];
      const usersToPush = [];

      this.permissionGroup.forEach((entity) => {
        switch (entity) {
          case 'company':
            shareEntities.push({
              type: 'company',
              id: this.currentUser.user.companyID,
              permission: this.companyPermission,
            });
            break;
          case 'team':
            shareEntities.push({
              type: 'team',
              id: this.currentUser.user.teamID,
              permission: this.teamPermission,
            });
            break;
          case 'user':
            this.selectedUsers.forEach((user) => {
              const userInfo = {
                type: 'user',
                id: user.id,
                permission: user.permission_level,
              };
              usersToPush.push(userInfo);
            });
            shareEntities.push(...usersToPush);
            break;
          default:
            shareEntities = [];
            break;
        }
      });
      this.updateBtnText = 'common.updating';
      const otherData = {
        [TD_FINGERPRINT_SHARING_SETTINGS]:
          getSharedEntitiesTypes(shareEntities),
        [TD_PREZTYPE]: this.data?.type,
      };

      shareStorylines({
        outline_id: this.data.uuid,
        sharedEntities: shareEntities,
      })
        .then((res) => {
          this.updateBtnText = 'navbarActions.shareFingerprintvue.updated';
          console.log(res);
          if (this.origin === TD_STORYLINE_ORIGIN_TABLE) {
            trackProfileEvents.profileStorylinesShareUpdate(
              this.currentUser,
              otherData,
            );
          }
          if (this.origin === TD_STORYLINE_ORIGIN_DETAIL) {
            trackProfileEvents.profileStorylineviewShareUpdate(
              this.currentUser,
              otherData,
            );
          }

          setTimeout(this.callSetTimeout, 1300);
        })
        .catch((error) => {
          console.log(error);
          this.updateBtnText = 'common.update';
          this.isUpdating = false;
        });
    },

    callSetTimeout() {
      this.isUpdating = false;
      this.handleClose();
      this.onUpdate();
    },
    handleShareTypeChangeFn() {
      if (this.shareOptionType) {
        this.permissionGroup = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
.radio-button_item {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.share-option-select_dropdown {
  max-width: 120px;
  width: 120px;
}
.sharing-modal-main-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  height: 100%;
  justify-content: space-between;
  padding: 2rem;
  width: 100%;

  .sharing-modal-container {
    width: 100%;
    // min-height: 200px;
  }
  ::v-deep .v-input--selection-controls__input {
    margin-right: 4px;
  }

  ::v-deep .v-input--selection-controls .v-radio > .v-label {
    color: black;
  }
  ::v-deep.v-select__selection,
  .v-select__selection--comma,
  .v-select.v-text-field input {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .sharing-modal__title {
    font-size: 1.5em;
    font-weight: 500;
  }
  .sharing-modal__options {
    margin: 36px 0 0;
    width: 100%;
  }
  .sharing-modal__subtitle {
    font-size: 1.25em;
    font-weight: 500;
  }
  .sharing-modal__permissions {
    margin: 36px 0;
  }
  .edit-hint {
    color: $gray-dark-login-text;
    font-size: 0.875em;
    margin: 0px auto 0px 24px;
  }
  .actions {
    margin: 0 auto;
  }
}
.spinner-wrapper {
  left: 48%;
  margin: auto;
  position: absolute;
  top: 40%;
}
.share-select-item {
  padding: 5px;
  max-width: 220px;

  .share-select-item__description {
    color: gray;
    padding-top: 5px;
  }
}
.share-select-item__selected {
  width: 30px;
}

.share-container {
  margin-left: 25px;

  ::v-deep .v-label {
    color: black;
  }

  .v-input {
    margin-bottom: 10px;
    margin-top: 0;
    width: max-content;
  }
}
.pt-20 {
  padding-top: 20px;
}
.select-colleagues-autocomplete {
  margin-bottom: 0 !important;
  padding: 0 14px 5px 14px;
  width: 100% !important;
}

.main-wrapper {
  height: 100%;
  max-height: 85vh;
  min-height: 300px;
  overflow: auto;

  * {
    font-family: 'Lato', sans-serif;
  }
}
</style>
