<template>
  <div class="edit-preferences">
    <h2>{{ $t('fingerprint.editPreferences.editPersonalPreferences') }}</h2>
    <div>
      <div class="selections">
        <div
          :class="`selection edit-mode ${getClassName(selection)}`"
          v-for="(selection, index) in allPrefs"
          :key="index"
          @click="handleQuestion(selection)"
        >
          <span>
            <v-img
              class="mx-2"
              :src="
                selection.image
                  ? `/${selection.image}`
                  : '/assets/img/preferences-added_by_user.svg'
              "
              height="40px"
              width="40px"
            />
          </span>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span class="selection-title" v-on="on">
                {{ selection.title }}
              </span>
            </template>
            <span>{{ selection.title }}</span>
          </v-tooltip>
        </div>
      </div>
      <div class="add-preference-wrapper">
        <div id="addCustomPref" class="write-own-btn">
          <div class="write-own-header">
            <span class="pe-2">
              <v-img
                v-if="!inputFocused"
                src="/assets/img/preferences-Other.svg"
                height="30px"
                width="35px"
              />
              <v-img
                v-else
                src="/assets/img/preferences-other-filled.svg"
                height="30px"
                width="35px"
              />
            </span>
            <span>{{ $t('fingerprint.addYourOwn') }}</span>
          </div>
          <div class="new-pref-text">
            <v-textarea
              v-model="ownPreference"
              :placeholder="$t('fingerprint.haveAClearAgenda')"
              full-width
              solo
              two-line
              @focus="setBackgroundColour"
              @blur="removeBackgroundColour"
            />
          </div>
          <div v-if="ownPreference.length && isValidString" class="add-pref">
            <button @click.stop="onEnter()">
              <span>
                <v-icon class="icon" v-text="`mdi-plus`" />
              </span>
              <span>{{ $t('fingerprint.add') }}</span>
            </button>
          </div>
        </div>
        <div class="edit-buttons">
          <v-btn
            rounded
            outlined
            class="second-button"
            color="#21a7e0"
            height="32"
            min-width="80"
            @click="handleClose()"
          >
            {{ $t('common.close') }}
          </v-btn>
          <v-btn
            class="primary-button"
            rounded
            color="#21a7e0"
            height="32"
            min-width="80"
            @click="handleSavePreference"
            :disabled="personalPrefs.length < 1"
          >
            {{ $t('common.save') }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { findIndex, filter } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { fpSelections } from '@/mock/fingerprint';
import { trackFingerprintEvents } from '../../common/Analytics/FingerprintEvents';
import {
  TD_PLACEMENT,
  TD_FP_FEATURE,
} from '../../common/Analytics/MatomoTrackingDataHelper.js';

export default {
  name: 'EditPreferences',
  async mounted() {
    await this.initialize();
  },
  data() {
    return {
      allPrefs: [],
      ownPreference: '',
      personal: [],
      inputFocused: false,
    };
  },
  props: {
    personalPrefs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapActions('users', ['setCurrentUserPersonalPreferences']),
    getClassName(selection) {
      return this.personal.find((p) => p.title === selection.title)
        ? 'highlight'
        : '';
    },
    handleClose() {
      this.$modal.hide('EditPreferences');
    },
    async initialize() {
      this.allPrefs = [...fpSelections];
      if (this.personalPrefs) {
        this.personal = [];
        this.personalPrefs.forEach((pref) => {
          const p = {};
          p.key = pref;
          p.title = pref;
          this.personal.push(p);
          const found = this.allPrefs.find((element) => element.title === pref);
          if (!found) this.allPrefs.push(p);
        });
      }
    },
    setBackgroundColour() {
      this.inputFocused = true;
      document.getElementById('addCustomPref').style.background = '#f5fcff';
    },
    removeBackgroundColour() {
      this.inputFocused = false;
      document.getElementById('addCustomPref').style.background = '#FFFFFF';
    },
    handleSavePreference() {
      this.setCurrentUserPersonalPreferences(this.personal);
      this.handleClose();
      const otherData = {
        [TD_PLACEMENT]: TD_FP_FEATURE,
      };
      trackFingerprintEvents.fingerprintsMyFingerprintEditPersonalPreferences(
        this.currentUser,
        otherData,
      );
    },
    onEnter() {
      if (this.ownPreference.length) {
        this.ownPreference = this.ownPreference?.replace(/\s+/g, ' ').trim();
        if (!this.isExistingPref)
          this.allPrefs.push({
            value: this.ownPreference.toLowerCase(),
            title: this.ownPreference,
          });
        const item = {};
        item.key = this.ownPreference;
        item.title = this.ownPreference;
        if (!this.isAlreadySelectedPref) {
          this.handleQuestion(item);
          this.personalPrefs.push(this.ownPreference);
        }
        this.ownPreference = '';
      }
    },
    handleQuestion(item) {
      const initialValue = this.personal;
      if (findIndex(initialValue, { title: item.title }) > -1) {
        const updatedValue = filter(
          initialValue,
          (initial) => initial.title !== item.title,
        );
        this.personal = updatedValue;
      } else {
        initialValue.push(item);
        this.personal = initialValue;
      }
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    isValidString() {
      return this.ownPreference.trim() !== '';
    },
    isExistingPref() {
      return this.allPrefs.some(
        (pref) =>
          pref?.title === this.ownPreference?.replace(/\s+/g, ' ').trim(),
      );
    },
    isAlreadySelectedPref() {
      return this.personal.some(
        (pref) =>
          pref?.title === this.ownPreference?.replace(/\s+/g, ' ').trim(),
      );
    },
  },
};
</script>
<style scoped lang="scss">
.edit-preferences {
  padding: 24px;
  h2 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #212121;
    margin-bottom: 28px;
  }
  .selections {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;

    .selection {
      align-items: center;
      display: flex;
      box-shadow: 0.804348px 0.804348px 3.21739px rgba(0, 0, 0, 0.1),
        -0.804348px -0.804348px 3.21739px rgba(0, 0, 0, 0.1);
      border-radius: 9.65217px;
      justify-content: flex-start;
      color: #212121;
      font-size: 18px;
      font-weight: 700;
      height: 56px;
      padding: 8px 15px;
      width: 31%;
      &.edit-mode {
        cursor: pointer;
      }
      &.highlight {
        border: 3px solid #29b6f6;
      }
      .selection-title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        // display: flex;
        align-items: center;
        color: #212121;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 5px;
      }
    }
  }
  .add-preference-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;

    .write-own-btn {
      background: #ffffff;
      border-radius: 12px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
        -1px -1px 4px rgba(0, 0, 0, 0.1);
      width: 40%;
      padding: 8px 25px;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 16px;
      .write-own-header {
        margin-top: 10px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        gap: 8px;
        align-items: center;
        color: #000000;
      }
      .new-pref-text {
        background: #ffffff;
        border-radius: 4px;
        font-weight: 400;
        margin: 10px 0px;
        text-align: left;
        width: 100%;
        ::v-deep .v-input__slot {
          margin-bottom: 0px !important;
        }
        ::v-deep .v-text-field__details {
          display: none;
        }
      }
      .add-pref {
        color: #29b6f6;
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
        .v-icon.v-icon {
          color: #29b6f6;
        }
      }
    }
    .edit-buttons {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
      gap: 24px;
      .primary-button {
        color: white;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Lato';
        font-style: normal;
        line-height: 19px;
        border-radius: 25px;
        text-transform: capitalize;
        letter-spacing: 0;
        height: 32px !important;
      }
      .second-button {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #21a7e0;
        border: 1.5px solid #21a7e0;
        border-radius: 25px;
        text-transform: capitalize;
        margin-left: 5px;
      }
    }
  }
}
</style>
