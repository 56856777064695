<template>
  <div
    class="product"
    @click="handleRelatedProductClick({ product: itemData })"
  >
    <v-img
      contain
      lazy-src="/assets/img/slides/placeholder-slide.svg"
      v-model="itemData"
      :aspect-ratio="16 / 9"
      :src="itemData.poster || '/assets/img/slides/placeholder-slide.svg'"
      loading="lazy"
    />
  </div>
</template>
<script>
import inViewport from 'vue-in-viewport-mixin';
import { mapState } from 'vuex';
import { getFileURL } from '@/utils/calyrex';

export default {
  mixins: [inViewport],
  name: 'RelatedSlide',
  data() {
    return {
      itemData: {},
    };
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'assetVotes',
      'searchTerms',
      'assetFolder',
      'currentTheme',
      'currentUserFavorites',
      'sessionKey',
    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    'inViewport.now': async function (visible) {
      if (visible) {
        const obj = {
          poster: '/assets/img/404.png',
          landscape: '/assets/img/404.png',
        };
        const slide = this.product;
        obj.asset = this.product;
        obj.asset.name = obj.asset.title;
        obj.asset.file = obj.asset.filename;
        obj.asset.pptFile = obj.asset.file;
        obj.isTrending = false;
        obj.isFavorite = !!this.currentUserFavorites.find(
          (element) => element.assetID === slide.unique_id,
        );
        obj.rating = 4.2;
        obj.thumbsUp = this.getThumbsUpCount(obj.asset);
        obj.thumbsDown = this.getThumbsDownCount(obj.asset);

        const posterPath = obj.asset.poster;
        const landscapePath = obj.asset.landscape;
        obj.poster = await getFileURL(
          this.currentUser.user.cognitoID,
          posterPath,
          this.currentUser.userIp,
        );
        obj.landscape = await getFileURL(
          this.currentUser.user.cognitoID,
          landscapePath,
          this.currentUser.userIp,
        );
        this.itemData = obj;
      }
    },
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    handleRelatedProductClick: {
      type: Function,
      default: () => ({}),
    },
    getThumbsUpCount: {
      type: Function,
      default: () => ({}),
    },
    getThumbsDownCount: {
      type: Function,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';
.related-products {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 95%;
  margin-left: 15px;
  overflow-y: auto;
  padding: 10px;
  .related-products-header {
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 26px;
    margin-left: 20px;
  }
  .product {
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    cursor: pointer;
    margin-bottom: 18px;
    padding: 5px;
    transition: all ease 0.5s;
    width: 100%;

    .v-responsive {
      border-radius: 8px;
      object-fit: contain;
    }

    img {
      border-radius: 8px;
      height: 100%;
      width: 100%;
    }
  }
  .product:hover {
    box-shadow: $item-box-shadow-hover;
  }
}
</style>
