<template>
  <div
    class="group-insights-container"
    ref="groupInsightEl"
    data-pendo-id="group-insights"
    :key="componentkey"
  >
    <div class="gp-heading">
      {{ $t('fingerprint.groupInsights.title') }}
    </div>
    <div class="gp-desc">
      {{ $t('fingerprint.groupInsights.groupInsightsText') }}
    </div>
    <template v-if="!groupInsight">
      <div class="gp-content">
        <div class="gp-name">
          <div>{{ $t('fingerprint.groupInsights.groupName') }}</div>
          <v-text-field
            :placeholder="$t('fingerprint.groupInsights.marketingExecutives')"
            v-model="groupName"
            :rules="groupNameRules"
            :disabled="!editable"
          />
          <img
            src="/assets/img/transition-analyzing-similar-profiles.svg"
            alt="analyzing similar profile image"
          />
        </div>
        <div class="gp-audience">
          <div>{{ $t('fingerprint.groupInsights.groupAudienceMembers') }}</div>
          <div class="gp-audience-list">
            <div v-for="(member, i) in groupMembers" :key="i">
              <GroupInsightMember
                v-model="member.audience"
                @addAudience="showDialogFn"
                @openRemindModal="openRemindModal($event)"
                @input="audienceSelected"
              ></GroupInsightMember>
              <v-icon v-if="groupMembers.length > 1" @click="removeAudience(i)">
                mdi-close
              </v-icon>
            </div>
          </div>
          <v-btn
            text
            class="add-audience-button"
            height="30"
            color="primary"
            @click="addAudience()"
            :ripple="false"
          >
            <v-icon color="primary" :size="18"> mdi-plus </v-icon>
            {{ $t('fingerprint.groupInsights.addAudience') }}
          </v-btn>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="insights-content">
        <div class="insights-members">
          <div>{{ $t('fingerprint.groupInsights.groupAudienceMembers') }}</div>
          <div class="insights-members-list">
            <div v-for="(member, i) in groupMembers" :key="i">
              <GroupInsightMember
                v-model="member.audience"
                :readOnly="true"
              ></GroupInsightMember>
            </div>
          </div>
          <div class="edit-members-btn">
            <v-btn
              text
              class="add-audience-button"
              height="30"
              color="primary"
              @click="onEditMembers()"
              :ripple="false"
            >
              {{ $t('fingerprint.groupInsights.editMembers') }}
            </v-btn>
          </div>
        </div>
        <div class="insights-info-container">
          <div>
            {{ groupName }} {{ $t('fingerprint.groupInsights.insights') }}
          </div>
          <div class="insights-pref-container">
            <div
              class="pref"
              v-for="pref in fingerprint.preferences"
              :key="pref.title"
            >
              <v-progress-circular
                :rotate="-90"
                :size="110"
                :width="16"
                :value="groupInsight[mapping[pref.title]].percentage"
                :color="'#78C9EB'"
              >
                <img class="pref-img" :src="pref.logo" />
              </v-progress-circular>
              <div>
                {{ groupInsight[mapping[pref.title]].percentage }}%
                {{ pref.preference }}
              </div>
              <div>{{ pref.oneLine }}</div>
            </div>
          </div>
          <div>
            <div class="insights-dos-dont-heading">
              {{ $t('fingerprint.groupInsights.whenPresenting') }}
            </div>
            <div class="insights-dos-dont-container">
              <div v-for="ind in 3" :key="ind" class="dos-donts-row">
                <div class="do-dos-item">
                  <img :src="'/assets/img/dos-icon.svg'" />
                  <div>{{ fingerprint.dos[ind - 1] }}</div>
                </div>
                <div class="do-dos-item">
                  <img :src="'/assets/img/dont-icon.svg'" />
                  <div>{{ fingerprint.donts[ind - 1] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="gp-actions">
      <template v-if="!groupInsight">
        <v-btn
          rounded
          outlined
          class="generate-insights-btn"
          height="30"
          color="primary"
          :disabled="isInValid"
          @click="generateInsights()"
        >
          {{ $t('fingerprint.groupInsights.generateInsights') }}
        </v-btn>
        <v-btn
          v-if="!editable"
          text
          class="generate-insights-btn"
          height="30"
          color="primary"
          @click="reset()"
        >
          {{ $t('common.reset') }}
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          text
          class="generate-insights-btn"
          height="30"
          color="primary"
          @click="generateFingerprint()"
        >
          {{ $t('fingerprint.groupInsights.generateFingerprint') }}
        </v-btn>
        <v-btn
          text
          class="generate-insights-btn"
          height="30"
          color="primary"
          @click="shareGift()"
        >
          {{ $t('fingerprint.compareFingerprint.shareTheGiftOfFingerprint') }}
        </v-btn>
        <v-btn
          rounded
          outlined
          class="generate-insights-btn"
          height="30"
          color="primary"
          @click="reset()"
        >
          {{ $t('common.reset') }}
        </v-btn>
      </template>
    </div>
    <ConfirmationDialog
      :show="showDialog"
      :on-primary-click="onDialogClose"
      :on-secondary-click="onDialogConfirm"
      :on-close="onDialogClose"
      :primary-btn-text="$t('common.cancel')"
      :secondary-btn-text="$t('fingerprint.groupInsights.yesSure')"
      :title="$t('fingerprint.groupInsights.areYouSure')"
      :text="$t('fingerprint.groupInsights.dialogText')"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';
import {
  calculateGroupInsights,
  createGroupInsightFingerprint,
  getGroupInsights,
} from '../../../utils/api-helper';
import GroupInsightMember from './GroupInsightMember.vue';
import { fpTypes } from '@/mock/fingerprint';
// import { zentrendsService } from './ZentrendsService';
import EventBus from '../../common/event-bus';
import { isEmojiExist } from '../../utils';
import ShareFreeTrailNew from '../../navbar/ShareFreeTrailNew.vue';
import AddCollegueDetail from '../NewPrezentation/AddCollegueDetail.vue';
import { AddNewAudienceModalProps } from '../AddNewAudience/AddNewAudienceModalProps';
import ReminderDialog from '../MyPrezentation/ReminderDialog.vue';
import { trackFingerprintEvents } from '../../common/Analytics/FingerprintEvents';
import {
  TD_AUD,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_COUNT_OF_AUDIENCES_COMPARED,
  TD_FINGERPRINT_ADD_AUDIENCE_GROUP,
  TD_PLACEMENT,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { trackGeneralEvents } from '../../common/Analytics/GeneralEvents';

export default {
  name: 'GroupInsights',
  data() {
    return {
      componentkey: 0,
      groupMembers: [
        { audience: null },
        { audience: null },
        { audience: null },
      ],
      gpInsightGenerated: false,
      groupName: '',
      groupInsight: null,
      fingerprint: null,
      editable: true,
      groupNameRules: [
        (v) => !!v || this.$t('warnings.groupInsightsWarning'),
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
      ],
      mapping: {
        'fingerprint.visualPreference': 'visualPreference',
        'fingerprint.dataStyle': 'dataPreference',
        'fingerprint.storyChoice': 'storyPreference',
      },
      showDialog: false,
    };
  },
  emits: ['audienceClicked'],
  components: { GroupInsightMember, ConfirmationDialog },
  computed: {
    ...mapState('users', ['currentUser', 'startLoadingSlidesFlag']),
    isInValid() {
      console.log(
        this.groupMembers.every((aud) => !aud.audience),
        this.groupNameRules.filter((i) => i(this.groupName) === true).length,
      );
      return (
        this.groupMembers.every((aud) => !aud.audience) ||
        !!this.groupNameRules.filter((i) => !(i(this.groupName) === true))
          .length
      );
    },
  },
  watch: {
    startLoadingSlidesFlag(val) {
      if (val) {
        if (this.$route.query?.editGroup) {
          getGroupInsights(this.$route.query.editGroup).then((resp) => {
            console.log(resp);
            this.fingerprint = null;
            this.editable = false;
            this.groupName = resp.data.fingerPrint.firstName;
            this.groupMembers = resp.data.members.map((member) => {
              console.log(this.audienceType(member));
              return {
                audience: {
                  defaultAudience: `${member.memberID}-${member.memberType}`,
                },
              };
            });
          });
        }
        EventBus.$on('GENERATE_INSIGHTS', this.populateGroupInsights);
      }
    },
    // eslint-disable-next-line func-names
    'currentUser.user.language': function () {
      this.componentkey += 1;
    },
  },
  methods: {
    removeAudience(index) {
      this.groupMembers.splice(index, 1);
    },
    addAudience() {
      this.groupMembers.push({ audience: null });
    },
    audienceSelected(val) {
      trackFingerprintEvents.fingerprintsAddaudienceSelected(this.currentUser, {
        [TD_PLACEMENT]: TD_FINGERPRINT_ADD_AUDIENCE_GROUP,
        [TD_AUD]: val?.id,
      });
    },
    openRemindModal(currentAudience) {
      this.$modal.show(
        ReminderDialog,
        {
          currentAudience,
          isUser: currentAudience.type === 'user',
        },
        {
          name: 'ReminderDialog',
          width: '649px',
          height: '80%',
        },
      );
    },
    showDialogFn() {
      this.showDialog = true;
    },
    onDialogClose() {
      this.showDialog = false;
    },
    onDialogConfirm() {
      this.showDialog = false;
      this.$modal.show(
        AddCollegueDetail,
        {
          addColleague: this.addColleague,
          origin: 'zentrends',
        },
        AddNewAudienceModalProps,
      );
    },
    shareGift() {
      trackGeneralEvents.sharetrialsIconClick(this.currentUser, {
        [TD_PLACEMENT]: 'Generate Inisghts',
      });
      this.$modal.show(
        ShareFreeTrailNew,
        {
          name: 'RightSidebarModalComponent',
          close: this.closeModal,
          origin: 'Generate Inisghts',
        },
        {
          name: 'ShareFreeTrailNew',
          classes: 'v-application',
          width: 800,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px', padding: '20px' },
        },
      );
    },
    closeModal() {
      this.$modal.hide('ShareFreeTrailNew');
    },
    generateInsights() {
      this.groupMembers = this.groupMembers.filter((aud) => aud.audience);
      calculateGroupInsights(
        this.groupMembers.map((i) => ({
          id: i.audience.uuid || i.audience.id,
          type: i.audience.audienceType,
        })),
      )
        .then((resp) => {
          console.log(resp);
          this.groupInsight = resp.data;
          this.getFingerprint();
        })
        .catch((err) => {
          console.log(err);
        });
      trackFingerprintEvents.fingerprintsGroupinsightsGenerateInsights(
        this.currentUser,
        {
          [TD_COMMON_COLUMN_NAME]: [TD_COUNT_OF_AUDIENCES_COMPARED],
          [TD_COMMON_COLUMN_VALUE]: this.groupMembers?.length,
        },
      );
    },
    audienceType(member) {
      if (member.memberType === 'standard') {
        return `${member.memberID}-standard`;
      }
      if (member.memberType === 'private_audience') {
        return `${member.memberID}${
          member.shared ? 'shared_' : '-'
        }private_audience`;
      }
      if (member.memberType === 'audience') {
        return `${member.memberID}${member.shared ? 'shared_' : '-'}audience`;
      }
      return `${member.memberID}${member.shared ? 'shared_' : '-'}user`;
    },
    getRandomString(bytes) {
      const randomValues = new Uint8Array(bytes);
      window.crypto.getRandomValues(randomValues);
      return Array.from(randomValues).map(this.intToHex).join('');
    },
    intToHex(nr) {
      return nr.toString(16).padStart(2, '0');
    },
    getFingerprint() {
      this.fingerprint = fpTypes.find(
        (ftype) =>
          ftype.preferences[0].preference.toLowerCase() ===
            this.groupInsight.visualPreference.preference.toLowerCase() &&
          ftype.preferences[1].preference.toLowerCase() ===
            this.groupInsight.storyPreference.preference.toLowerCase() &&
          ftype.preferences[2].preference.toLowerCase() ===
            this.groupInsight.dataPreference.preference.toLowerCase(),
      );
    },
    generateFingerprint() {
      const body = {
        members: this.groupMembers
          .filter((i) => i.audience)
          .map((i) => ({
            id: i.audience.uuid || i.audience.id,
            type: i.audience.audienceType,
            audType: i.audience.type,
            shared: i.audience.shared,
            companyDomain: i.audience.id.split('@')[1],
          })),
        fpPreferences: this.groupInsight,
        fingerPrint: `${this.fingerprint.title
          .charAt(0)
          .toUpperCase()}${this.fingerprint.title.slice(1)}`,
        firstName: this.groupName,
        emailEntered: false,
        lastName: 'Group',
        id: `${this.getRandomString(6)}@${
          this.currentUser.user.id.split('@')[1]
        }`,
      };
      if (!this.editable) {
        body.uuid = this.$route.query.editGroup;
        body.id = this.$route.query.userID;
      }
      createGroupInsightFingerprint(body).then((resp) => {
        this.reset(false);
        this.$emit('audienceClicked', {
          audienceType: 'private_audience',
          id: resp.data.uuid,
          shared: false,
        });
      });
      trackFingerprintEvents.fingerprintsGroupinsightsGenerateInsightsGenerateFingerprint(
        this.currentUser,
        {
          [TD_COMMON_COLUMN_NAME]: [TD_COUNT_OF_AUDIENCES_COMPARED],
          [TD_COMMON_COLUMN_VALUE]: this.groupMembers?.length,
        },
      );
    },
    reset(isDirectlyCalled = true) {
      this.groupName = '';
      this.groupMembers = [
        { audience: null },
        { audience: null },
        { audience: null },
      ];
      this.groupInsight = null;
      this.fingerprint = null;
      this.editable = true;
      if (isDirectlyCalled) {
        trackFingerprintEvents.fingerprintsComparefgGroupInisghtsReset(
          this.currentUser,
          {
            [TD_PLACEMENT]: TD_FINGERPRINT_ADD_AUDIENCE_GROUP,
          },
        );
      }
    },
    populateGroupInsights(obj) {
      console.log(obj);
      this.groupName = obj.name;
      this.editable = obj.edit;
      this.groupMembers = obj.groupMembers;
      this.$nextTick(() => {
        const { groupInsightEl } = this.$refs;
        if (groupInsightEl) {
          groupInsightEl.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      });
    },
    onEditMembers() {
      this.groupInsight = null;
      trackFingerprintEvents.fingerprintsGroupinsightsEditMembers(
        this.currentUser,
      );
    },
  },
  mounted() {
    if (this.startLoadingSlidesFlag) {
      // zentrendsService.calculateGroupInsights().subscribe((val) => {
      //   console.log(val, 'valll');
      // });
      if (this.$route.query?.editGroup) {
        getGroupInsights(this.$route.query.editGroup).then((resp) => {
          this.populateGroupInsights({
            name: resp.data.fingerPrint.firstName,
            edit: false,
            groupMembers: resp.data.members.map((member) => ({
              audience: {
                defaultAudience: `${member.memberID}-${member.memberType}`,
              },
            })),
          });
        });
      }
      EventBus.$on('GENERATE_INSIGHTS', this.populateGroupInsights);
    }
  },
};
</script>
<style scoped lang="scss">
@import '@/scss/variables.scss', '@/scss/app.scss';
.group-insights-container {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 24px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;

  .gp-heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 12px;
  }

  .gp-desc {
    margin-bottom: 24px;
  }

  .gp-content {
    display: flex;
    justify-content: space-evenly;
    height: 350px;

    .gp-name {
      width: 315px;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      div:first-child {
        font-weight: 600;
      }
    }

    .gp-audience {
      height: 100%;
      width: 450px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      > div:first-child {
        font-weight: 600;
      }
      .gp-audience-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-height: 300px;
        overflow-y: auto;
        width: 100%;

        > div {
          display: flex;
          justify-content: space-around;
          overflow: hidden;
          min-height: 48px;
          > div {
            width: calc(100% - 40px);
          }
        }
      }
    }
  }

  .add-audience-button {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #21a7e0;
    text-transform: capitalize;
  }

  .gp-actions {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    gap: 20px;

    .generate-insights-btn {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #21a7e0;
      text-transform: capitalize;
    }
  }

  .insights-content {
    display: flex;
    gap: 20px;

    .insights-info-container > div:first-child,
    .insights-members > div:first-child {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    .insights-members {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      .insights-members-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-height: 435px;
        overflow: auto;
      }
    }
    .insights-info-container {
      width: 75%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .insights-pref-container {
        display: flex;

        .pref {
          display: flex;
          flex-direction: column;
          flex-basis: 33.33%;
          align-items: center;

          ::v-deep .v-progress-circular__underlay {
            stroke: #68788c;
            stroke-opacity: 0.6;
            stroke-width: 4px;
            z-index: 1;
          }

          .pref-img {
            width: 50px;
            height: 36px;
          }

          & > div:nth-child(2) {
            margin-top: 10px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #7b7b7b;
            margin-bottom: 4px;
          }

          & > div:nth-child(3) {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #7b7b7b;
          }
        }
      }

      .insights-dos-dont-heading {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #7b7b7b;
        margin-bottom: 10px;
      }

      .insights-dos-dont-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .dos-donts-row {
          display: flex;
          gap: 10px;

          .do-dos-item {
            display: flex;
            flex-basis: 50%;
            align-items: center;
            gap: 8px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #212121;

            img {
              width: 52px;
              height: 60px;
            }
          }
        }
      }
    }
  }
}
</style>
