<template>
  <div class="not-suitable-wrapper">
    <div class="title"><br />{{ title }} <br />{{ subTitle }}</div>
    <v-btn class="secondary-button" outlined rounded @click="handleClose">
      {{ $t('common.ok') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ImageNotSuitable',
  props: {
    title: {
      props: String,
      default: 'Oops! Your selected image is not suited for this slide layout.',
    },
    subTitle: {
      props: String,
      default: 'Please select a different option.',
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$modal.hide('ImageNotSuitable');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.not-suitable-wrapper {
  height: 100%;
  text-align: center;
  width: 100%;

  button {
    width: 134px;
  }

  .title {
    color: #000;
    font-family: 'Lato' !important;
    font-size: 20px !important;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal !important;
    line-height: normal;
    padding-bottom: 28px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 30px !important;
  }
}
</style>
