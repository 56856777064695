<template>
  <div>
    <AudienceItem
      v-if="item.type === 'People'"
      :itemClass="'user-template'"
      :maxWidth="448"
      :isInComplete="!item.item.fingerPrint"
      :fullName="item.item.fullName"
      :firstName="item.item.firstName"
      :lastName="item.item.lastName"
      :profileImgS3Path="item.item.profileImage"
      :email="audienceEmail"
      :creator="item.item.creator"
      :emailEntered="item.item.emailEntered"
      :ownerId="item.item.ownerID"
      :companyName="item.item.companyName"
      :isPrivate="item.item.isPrivate"
      :isShared="item.item.isShared"
      :isDifferentCompany="item.item.companyID !== currentUser.user.companyID"
      :isPublicLimited="item.item.isPublicLimited"
      :isGroup="item.item.isGroup"
      :fingerprintType="
        item.item.fingerprintType
          ? item.item.fingerprintType.toLowerCase()
          : 'default'
      "
      :status="item.type === 'People' && !item.item.isActive ? 'Disabled' : ''"
    >
      <div class="dot">·</div>
      <div class="type">
        {{ renameTypes(item.type) }}
      </div>
    </AudienceItem>
    <div v-else-if="item.type === 'Fingerprint'" class="item-template">
      <div class="image">
        <img width="26" :src="`${item.item.logo}`" alt="" />
      </div>
      <div class="name">
        {{ item.name }}
      </div>
      <div class="dot">·</div>
      <div class="type">
        {{ renameTypes(item.type) }}
      </div>
    </div>
    <div v-else class="item-template">
      <div class="image">
        <img
          width="26"
          :src="typeIcons[item.type] || typeIcons.Slides"
          alt=""
        />
      </div>
      <div class="name">
        {{ item.name || item.display_name }}
      </div>
      <div class="dot">·</div>
      <div class="type">
        {{ renameTypes(item.type) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AudienceItem from '../../common/AudienceItem.vue';
import { isGeneratedEmail } from '../../../utils/common';

export default {
  components: {
    AudienceItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    audienceEmail() {
      if (this.item?.item) {
        return isGeneratedEmail(this.item?.item)
          ? null
          : this.item?.item?.email;
      }
      return this.item?.item?.email;
    },
  },
  methods: {
    isOwner(item) {
      if (item?.ownerID) {
        return item?.ownerID === this.currentUser?.user?.id;
      }
      return item?.userID === this.currentUser?.user?.id;
    },
    renameTypes(item) {
      switch (item) {
        case 'Slides (Uploads)':
          return 'Slide Library (Uploads)';

        case 'Slides':
          return 'Slide Library';

        case 'Generate':
          return 'Auto Generator';

        case 'Learn':
          return 'Courses';

        case 'Prezentations':
          return 'Prezentation Library';

        case 'Build':
          return 'Story Builder';

        default:
          return item;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item-template {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: flex-start;
  padding: 0 1rem;

  .image {
    margin-right: 0.5rem;
  }

  .name {
    width: 321px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
  }
}

.dot {
  margin-left: 10px;
  margin-right: 5px;
  opacity: 0.5;
  text-align: right;
}
.type {
  font-size: 14px;
  opacity: 0.5;
}
</style>
