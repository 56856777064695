<template>
  <div class="se">
    <div class="se__header">
      <h2 class="se__title">{{ $t('synthesize.emotion.title') }}</h2>
      <p class="se__subtitle">{{ $t('build.step3.poweredByZenSence') }}</p>
      <div class="se__text">
        {{ $t('synthesize.emotion.description') }}
      </div>
    </div>
    <div class="se__body">
      <v-radio-group v-model="selectedEmotion">
        <v-expansion-panels flat accordion v-model="panelValue">
          <v-expansion-panel v-for="(emotion, i) in emotions" :key="i">
            <v-expansion-panel-header class="se-list__panel_header">
              <div class="se-list__header">
                <v-radio
                  class="se-list__input"
                  :value="i"
                  @click.stop
                  :ripple="false"
                ></v-radio>
                <img
                  class="se-list__icon"
                  :src="`/assets/img/synthesize/emotion-${emotion.id}.svg`"
                />
                <div>
                  {{ $t(emotion.label) }}
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="se-list__content">
                <p class="se-list__description">
                  {{ $t(emotion.description) }}
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-radio-group>
    </div>
    <div class="se__footer">
      <v-btn
        rounded
        color="primary"
        class="se__button"
        :disabled="intialEmotion === selectedEmotion"
        @click="resynthesize()"
        >Resynthesize</v-btn
      >
    </div>
  </div>
</template>

<script>
import { SlideEmotion, SynthesizeOperation } from './SynthesizeUtills';

export default {
  name: 'Emotion',
  data() {
    return {
      emotions: [
        {
          id: SlideEmotion.Optimistic,
          label: 'synthesize.emotion.optimistic.label',
          description: 'synthesize.emotion.optimistic.text',
        },
        {
          id: SlideEmotion.Uplifting,
          label: 'synthesize.emotion.uplifting.label',
          description: 'synthesize.emotion.uplifting.text',
        },
        {
          id: SlideEmotion.Assertive,
          label: 'synthesize.emotion.assertive.label',
          description: 'synthesize.emotion.assertive.text',
        },
        {
          id: SlideEmotion.Persuasive,
          label: 'synthesize.emotion.persuasive.label',
          description: 'synthesize.emotion.persuasive.text',
        },
        {
          id: SlideEmotion.Cooperative,
          label: 'synthesize.emotion.cooperative.label',
          description: 'synthesize.emotion.cooperative.text',
        },
      ],
      selectedEmotion: 0,
      panelValue: 0,
    };
  },
  props: {
    emotion: {
      type: String,
      default: SlideEmotion.Optimistic,
    },
  },
  watch: {
    selectedEmotion() {
      this.panelValue = this.selectedEmotion;
    },
  },
  computed: {
    intialEmotion() {
      const idx = this.emotions.findIndex((i) => i.id === this.emotion);
      return idx > 0 ? idx : 0;
    },
  },
  methods: {
    resynthesize() {
      this.$emit('change', {
        type: SynthesizeOperation.EmotionChange,
        data: this.emotions[this.selectedEmotion],
      });
    },
  },
  mounted() {
    this.selectedEmotion = this.intialEmotion;
    this.panelValue = this.selectedEmotion;
  },
};
</script>

<style lang="scss" scoped>
.se {
  background: $white-default;
  padding: 20px;

  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;

  &__header {
    margin-bottom: 10px;
  }

  &__title {
    font-weight: 700;
    line-height: 24px;
  }
  &__subtitle {
    font-family: 'Lato';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #697077;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  &__text {
    font-size: 16px;
  }

  &__button {
    padding: 6px 16px;
    width: auto;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: normal;
    line-height: 19px;
    color: #ffffff;
    background: #21a7e0;
    border-radius: 25px;
  }
}

.se-list {
  &__panel_header {
    padding: 16px 0;
  }
  &__header {
    display: flex;
    font-weight: 600;
    color: #075689 !important;
    align-items: center;
  }
  &__icon {
    margin-right: 8px;
  }
  &__input {
    margin-bottom: 0 !important;
  }
  &__content {
    padding: 0 22px;
  }
  &__description {
    font-size: 15px;
  }
}
</style>
