<template>
  <div class="slide-detail-node-count-container">
    <div class="heading">
      {{ $t('slideDetails.editNodeCountCaps') }}
      <!-- <div class="sub">{{ $t('slideDetails.compSubHeaderAstridTM') }}</div> -->
    </div>
    <div class="sub-heading">
      {{ $t('slideDetails.selectNodeCountInfo') }}
    </div>
    <div v-if="isLoading">
      <clip-loader
        :loading="isLoading"
        :color="`#21a7e0`"
        :width="40"
        :height="40"
      />
    </div>
    <div class="node-content" v-if="!isLoading">
      <div
        v-for="(slide, index) in filteredSibilings"
        class="slide"
        :key="index"
        @click="handleNodeChange(slide)"
      >
        <v-img
          contain
          lazy-src="/assets/img/slides/placeholder-slide.svg"
          :aspect-ratio="16 / 9"
          :src="slide.poster || '/assets/img/slides/placeholder-slide.svg'"
          loading="lazy"
          class="node-image"
          :class="{
            current: slide.asset.prefs.node.toString() === nodeCount.toString(),
          }"
        />
        <div class="node-count">
          {{ $t('slideDetails.nodeCount') }}: {{ slide.asset.prefs.node }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { getSiblings } from '../../../../utils/api-helper';

export default {
  name: 'SlideEditNodeCount',
  components: {
    ClipLoader,
  },
  props: {
    updateSlide: {
      type: Function,
      default: () => {},
    },
    selectedSlide: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      nodeCount: '',
      siblings: [],
      slideType: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    filteredSibilings() {
      // if (['thank_you', 'discussion_topic'].includes(this.slideType)) {
      //   return this.siblings.filter(
      //     // eslint-disable-next-line eqeqeq
      //     (slide) => slide.asset.prefs.node == this.nodeCount,
      //   );
      // }
      return this.siblings;
    },
  },
  methods: {
    ...mapActions('slidesStore', ['setFlowActive']),

    handleNodeChange(slide) {
      this.nodeCount = slide.asset.prefs.node;
      if (
        this.nodeCount.toString() !== this.selectedSlide.prefs.node.toString()
      ) {
        this.updateSlide({
          // node: slide.asset.prefs.node,
          ...slide.asset,
        });
      }
    },

    async getSiblingsData() {
      this.isLoading = true;
      try {
        this.nodeCount = this.selectedSlide?.prefs.node;
        this.slideType = this.selectedSlide.categories[0]
          ? this.selectedSlide?.categories[0]
          : this.selectedSlide?.category;

        const uniqueId = this.selectedSlide?.parent?.unique_id
          ? this.selectedSlide?.parent?.unique_id
          : this.selectedSlide?.unique_id;
        const sid = this.selectedSlide?.parent?.sid;
        const theme = this.selectedSlide?.prefs?.theme
          ? this.selectedSlide?.prefs?.theme
          : this.currentUser?.theme?.code;
        this.siblings = await getSiblings(
          uniqueId,
          sid,
          this.currentUser,
          theme,
        );
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
  },
  // watch: {
  //   selectedSlide() {
  //     this.getSiblingsData();
  //   },
  // },
  mounted() {
    this.setFlowActive('nodeCountChange');

    this.getSiblingsData();
  },
};
</script>

<style lang="scss" scoped>
.slide-detail-node-count-container {
  height: 100%;

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 15px;
    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 16px;
  }
  .node-content {
    height: calc(100vh - 240px);
    overflow: auto;
    padding: 5px;
    .slide {
      width: calc(50% - 20px);
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 20px;
      .node-image {
        filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.1))
          drop-shadow(-1px -1px 4px rgba(0, 0, 0, 0.1));
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 10px;
        cursor: pointer;

        &.current {
          border: 2.5px solid #21a7e0;
          cursor: default;
        }

        &:hover {
          box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  .node-count {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
