<template>
  <v-container fluid>
    <v-hover v-slot="{ hover }" v-if="isShowBackButton">
      <v-btn
        :style="{
          'background-color': `${hover ? '#fafafa' : ''}`,
          height: '24px',
          margin: '0 0 14px -8px',
        }"
        class="btn-back"
        color="primary"
        text
        :disabled="isConvertingInProgress"
        @click="$emit('toggleIconReplace')"
      >
        <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
        {{ $t('replaceIcon.backToSlideText') }}
      </v-btn>
    </v-hover>
    <v-row
      no-gutters
      style="
        flex-wrap: nowrap;
        width: 100%;
        justify-content: space-between !important;
      "
    >
      <v-col cols="8" style="max-width: 59.46% !important">
        <div class="main-image">
          <div class="main-image-header">
            <div class="header text-h6 text-left font-weight-bold">
              {{ $t('replaceIcon.replaceIconTitle') }}
            </div>
            <div class="header text-subtitle-1 text-left mb-2">
              {{ $t('replaceIcon.replaceIconSubText') }}
            </div>
          </div>
          <div v-if="isConvertingInProgress" class="pa-1 mt-1">
            <LoadingAnimation :animationImages="currentAnimationImages" />
          </div>
          <div v-else id="replace-main-icon">
            <v-img
              :aspect-ratio="16 / 9"
              :src="convertedImage || landscapeImageAsset"
              lazy-src="/assets/img/slides/placeholder-slide.svg"
              class="image-wrapper"
              alt="image"
              contain
            />
          </div>
        </div>
        <div class="replace-image pl-1">
          <div class="header text-subtitle-1 text-left font-weight-bold mt-2">
            <span> {{ $t('replaceIcon.selectIconReplace') }} </span>
            <div v-if="!isOriginPrezentation">
              <NewFeatureBadge
                module-name="collections"
                :feature-id="'collections_thumbnail_id'"
                :offsetX="'30'"
                :offsetY="'10'"
                :size="'medium'"
              >
                <v-btn
                  class="addRemvCollBtn"
                  color="primary"
                  :ripple="false"
                  text
                  :plain="isCollectionLimitReached && !isAddedToCollection"
                  @click="handleCollection()"
                  data-pendo-id="slides-detail-collection"
                  :loading="processingCollection"
                  :disabled="!convertedImage || isConvertingInProgress"
                >
                  <v-tooltip
                    top
                    content-class="tooltip-content"
                    max-width="200"
                    fixed
                    v-if="!isAddedToCollection && isCollectionLimitReached"
                    open-delay="500"
                    close-delay="300"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#bdbdbd" v-bind="attrs" v-on="on">
                        mdi-file-plus-outline
                      </v-icon>
                      <span
                        class="action-button-disable__text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t('replaceImage.addToCollection') }}
                      </span>
                    </template>
                    <span>{{ $t('replaceImage.addToCollectionTooltip') }}</span>
                  </v-tooltip>
                  <v-icon
                    v-if="!isAddedToCollection && !isCollectionLimitReached"
                    color="primary"
                  >
                    mdi-file-plus-outline
                  </v-icon>
                  <span
                    class="action-button__text"
                    v-if="!isAddedToCollection && !isCollectionLimitReached"
                    >{{ $t('replaceImage.addToCollection') }}</span
                  >
                  <v-icon color="primary" v-if="isAddedToCollection">
                    mdi-file-minus
                  </v-icon>
                  <span
                    class="action-button__text"
                    v-if="isAddedToCollection"
                    >{{ $t('slides.removeFromCollection') }}</span
                  >
                </v-btn>
                <!--
                <v-btn
                  text
                  class="secondary-button text-subtitle-1"
                  height="30"
                  color="primary"
                  @click="handleCollection()"
                  :ripple="false"
                  v-if="!isCollectionLimitReached"
                  :disabled="
                    !convertedImage ||
                    isConvertingInProgress ||
                    isAddedToCollection
                  "
                >
                  <v-icon
                    v-if="!isAddedToCollection && !isCollectionLimitReached"
                    color="primary"
                  >
                    mdi-file-plus-outline
                  </v-icon>
                  <span
                    class="action-button__text"
                    v-if="!isAddedToCollection && !isCollectionLimitReached"
                    >{{ $t('replaceIcon.addToCollection') }}</span
                  >
                  <v-icon color="#bdbdbd" v-if="isAddedToCollection">
                    mdi-check
                  </v-icon>
                  <span
                    class="action-button-disable__text"
                    v-if="isAddedToCollection"
                    >{{ $t('replaceIcon.addedToCollection') }}</span
                  >
                </v-btn>
                <v-tooltip
                  top
                  content-class="tooltip-content"
                  max-width="200"
                  fixed
                  v-else
                  :disabled="
                    !convertedImage ||
                    isConvertingInProgress ||
                    isAddedToCollection
                  "
                  open-delay="500"
                  close-delay="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      class="secondary-button text-subtitle-1"
                      height="30"
                      color="primary"
                      :ripple="false"
                      plain
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="#bdbdbd">mdi-file-plus-outline</v-icon>
                      <span class="action-button-disable__text">
                        {{ $t('replaceIcon.addToCollection') }}
                      </span>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('replaceIcon.addToCollectionTooltip') }}
                  </span>
                </v-tooltip>
                !-->
              </NewFeatureBadge>
              <v-btn
                v-if="
                  canReplaceImage &&
                  !hideReplaceImage &&
                  allowReplaceImageOverall &&
                  allowReplaceImageTabs
                "
                :disabled="
                  isConvertingInProgress || isLoading || getIsImageNotFound
                "
                class="secondary-button text-subtitle-1"
                text
                height="30"
                color="primary"
                @click="switchToReplaceImage()"
              >
                <v-icon color="primary">{{ 'mdi-image-outline' }}</v-icon>
                <span class="action-button__text">
                  {{ $t('replaceIcon.replaceImageCTAOption') }}
                </span>
              </v-btn>
              <v-btn
                class="secondary-button text-subtitle-1"
                text
                height="30"
                color="primary"
                @click="showReset"
                :disabled="!convertedImage || isConvertingInProgress"
              >
                <v-icon color="primary"> mdi-arrow-u-left-top </v-icon>
                <span class="action-button__text">
                  {{ $t('replaceIcon.resetToDefaultBtn') }}
                </span>
              </v-btn>
            </div>
          </div>
          <div
            v-if="isLoading"
            class="text-center loading-spinner__select-image-container"
          >
            <v-progress-circular
              :size="34"
              :width="2"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div
            justify="center"
            align="center"
            :class="`select-image my-1 ${
              isConvertingInProgress || isLocalIconUploading
                ? 'item-disabled'
                : ''
            } `"
            v-if="!isLoading && filteredIcons && filteredIcons.length > 0"
          >
            <v-slide-group v-model="model" show-arrows="always">
              <v-slide-item
                v-for="item in filteredIcons"
                :key="item.imageIndex"
                v-slot="{ toggle }"
                :class="`${
                  item.seq_id ===
                  (currentSelectedSlideIconData &&
                    currentSelectedSlideIconData.seq_id)
                    ? 'selected'
                    : ''
                }`"
              >
                <v-img
                  v-if="!item.invalid"
                  class="select-image__item ma-2"
                  max-height="80"
                  max-width="100"
                  height="80"
                  contain
                  :src="item.imgUrl"
                  @click="onIconSelected(item, toggle)"
                  lazy-src="/assets/img/slides/placeholder-slide.svg"
                ></v-img>
              </v-slide-item>
            </v-slide-group>
          </div>
        </div>
      </v-col>
      <v-col class="choose-icon" cols="4">
        <div class="image-library ml-2">
          <div class="header text-h6 text-left font-weight-bold">
            {{ $t('replaceIcon.chooseIconText') }}
          </div>
          <div
            v-if="isLoading"
            class="text-center loading-spinner__image-library-container"
          >
            <v-progress-circular
              :size="50"
              :width="2"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div>
            <IconLibrary
              v-if="!isLoading && currentSelectedSlideIconData"
              @iconSelected="onReplaceIcon($event)"
              :currentSelectedSlideIconData="currentSelectedSlideIconData"
              :isConvertingInProgress="isConvertingInProgress"
              :key="iconLibraryComponentKey"
              @onIconSearch="onIconSearch"
              :slideData="selectedSlide"
            />
          </div>
        </div>
        <div class="actions" style="min-height: 60px">
          <!--
          <v-btn
            v-if="isOriginPrezentation"
            class="secondary-button"
            height="48"
            rounded
            outlined
            color="primary"
            @click="showReset"
            :disabled="getDisabled"
          >
            {{ $t('replaceImage.resetToDefaultBtn') }}
          </v-btn>
          <div v-if="isOriginPrezentation">
            <v-btn
              class="primary-button py-2 ma-2"
              height="48"
              rounded
              filled
              color="#21a7e0"
              :disabled="!convertedImage || isConvertingInProgress"
              @click="onAcceptNewSlide"
            >
              {{ $t('common.accept') }}
            </v-btn>
          </div>
          !-->
          <div>
            <v-btn
              v-if="!convertedImage || isConvertingInProgress"
              class="primary-button py-2 ma-2"
              height="48"
              rounded
              filled
              color="#21a7e0"
              :disabled="!convertedImage || isConvertingInProgress"
            >
              {{ $t('common.download') }}
            </v-btn>
            <Download
              v-else
              :id="
                isImageReplaceWalkThrough
                  ? 'walkthough-download-btn-id'
                  : undefined
              "
              origin="slide"
              limitsKey="slide"
              :file-name="outputFileName"
              :item="selectedSlide"
              :count-first-time="1"
              :on-download="downloadLocal"
              :downloadUrl="downloadUrl"
              :log-download="logDownload"
              :speedDialPosRight="'10'"
              :downloadType="
                getReplaceStackData.isImageReplacementComplete
                  ? 'SlideReplacement'
                  : ''
              "
              downloadIconType="SlideIconReplacement"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <ConfirmationDialog
      :show="dialogReset"
      :on-primary-click="onResetConfirm"
      :on-secondary-click="onResetCancel"
      :on-close="onResetCancel"
      :primary-btn-text="$t('common.yes')"
      :secondary-btn-text="$t('common.no')"
      :title="$t('replaceIcon.confirmationDialogTitle')"
      :text="$t('replaceIcon.confirmationDialogText')"
    />
    <ErrorDialogIcon
      :show="isErrorLoadingIconReplaceData"
      :on-primary-click="onErrorDialogOk"
      :primary-btn-text="$t('common.ok')"
      :text="$t(errorMessageLoadingIconReplaceData)"
      :subText="
        getIsDirty ||
        (getReplaceStackData &&
          getReplaceStackData.filteredImages &&
          getReplaceStackData.filteredImages.length > 0)
          ? $t('replaceIcon.goToReplaceImage')
          : ''
      "
      :onClose="onErrorDialogClose"
    />
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapActions, mapGetters } from 'vuex';
import IconLibrary from './IconLibrary.vue';
import LoadingAnimation from '../../common/LoadingAnimation.vue';
import { getFileURL } from '@/utils/calyrex';
import { getFileURLDownload } from '@/utils/calyrex-test';
import {
  convertToPDFForIcons,
  replaceIcon,
  extractIcons,
  getSvgOfIcon,
} from '../../../utils/replace-image-api-helper';
import Download from '../../common/Download.vue';

import { convertPdfToImage } from '../../../utils/pdf-to-png';
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';
import ErrorDialogIcon from './ErrorDialogIcon.vue';
import { calyrexConfig } from '../../../runtimeConfig';
import EventBus from '../../common/event-bus';
// import calculateCoordinates from './calculateCoordinates';
import IconNotSuitable from './IconNotSuitable.vue';
import { downloadFileWithCustomName } from '../../../utils/common';
import {
  ICON_UPLOAD_FAILED,
  LOCAL_ICON_UPLOADED,
  LOCAL_ICON_UPLOADING,
} from './replaceIconConstants.js';
import { updateCollection, deleteCollection } from '../../../utils/api-helper';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import {
  // getBuildPrezentationData,
  // RESET_TO_DEFAULT,
  // IMAGE_REPLACEMENT,
  MY_PREZENTATION,
  // SLIDES,
  BUILD,
  TD_TIME_SPENT_IN_SECS,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_TAB,
  TD_PLACEMENT,
  SLIDES,
  TD_SLIDES_LIBRARY,
  TD_PREZENTATION_LIBRARY,
  TD_QUERY,
  TD_AUD,
  TD_ISFAVORITED,
  TD_COMPANY_OF_AUD_TAILORED_FOR,
  // TD_QUERY,
  // TD_REPLACE_IMAGE_SOURCE,
  // TD_PREZNAME,
  // NA,
  // TD_PREZTYPE,
  // TD_ISDOWNLOAD,
  // TD_DOWNLOADTYPE,
  // TD_DOWNLOADTYPE_DIRECT,
  // TD_AUD,
  isSlideFavorite,
  // TD_ISFAVORITED,
  // TD_COMPANY_OF_AUD_TAILORED_FOR,
  getAudienceCompanyName,
  TD_EXPANDED_DETAIL_VIEW,
  // getAudienceId,
  // TD_NORESULTSFOUND,
  // TD_EVENT_START,
  // TD_EVENT_END,
  // TD_TIME_SPENT,
} from '../../common/Analytics/MatomoTrackingDataHelper';
// import { trackBuildEvents } from '../../common/Analytics/BuildEvents';
// import { trackPrezentationEvents } from '../../common/Analytics/PrezentationEvents';
// import { trackSlideEvents } from '../../common/Analytics/SlideEvents';
// import { trackCommonEvents } from '../../common/Analytics/CommonEvents';
// import { trackTimeSpentEvents } from '../../common/Analytics/TimeSpentEvents';
import { getThumbnail } from '../../../store/modules/helper';
import { trackIconReplacementEvents } from '../../common/Analytics/IconReplacementEvents';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents';

export default {
  name: 'SlideIconReplacement',
  inject: {
    isImageReplaceWalkThrough: { default: false },
    query: { default: '' },
  },
  data() {
    return {
      mainSlideHeight: 0,
      convertedImage: null,
      model: 0,
      isLoading: true,
      isConvertingInProgress: false,
      isLocalIconUploading: false,
      outputFileName: null,
      downloadUrl: null,
      iconToReplace: null,
      landscapeImageAsset: null,
      deckDetails: null,
      iconDetails: null,
      slideData: {},
      isAddedToCollection: false,
      filteredIcons: null,
      iconLibraryComponentKey: 1,
      currentSelectedSlideIconData: null,
      selectedIconSeqId: null,
      currentAnimationImages: null,
      savedSlidePath: null,
      savedImagePath: null,
      payloadData: null,
      stepOne: {
        url: '/assets/img/slideImageReplacement/analyzing-content.svg',
        text: 'replaceIcon.analyzingIconContent',
      },
      stepTwo: {
        url: '/assets/img/slideImageReplacement/resizing-cleaning-image.svg',
        text: 'replaceIcon.cleaningTheIcon',
      },
      stepThree: {
        url: '/assets/img/slideImageReplacement/finalizing-slide.svg',
        text: 'replaceIcon.finalizingTheSlideDesign',
      },
      dialogReset: false,
      notIcons: ['image/x-wmf', 'image/x-emf'],
      newInput: {},
      guidList: [],
      selectIconReplaceThumbnailGuidMap: {},
      isErrorLoadingIconReplaceData: false,
      errorMessageLoadingIconReplaceData: 'replaceIcon.unableToReplaceIcon',
      isIconReplacementComplete: false,
      assetWidthRequired: 140.0,
      assetHeightRequired: 140.0,
      processingCollection: false,
    };
  },
  props: {
    favoriteSlides: {
      type: Array,
    },
    selectedSlide: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    logDownload: {
      type: Function,
      required: false,
    },
    handleReset: {
      type: Function,
      required: true,
    },
    eventStart: {
      type: Date,
    },
    isShowBackButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    origin: {
      type: String,
      default: 'slides',
    },
    slideReplaceAccepted: {
      type: Function,
      default: () => {},
    },
    handleNewSlideAccepted: {
      type: Function,
      required: false,
    },
    prezentationObject: {
      type: Object,
      default: () => {},
    },
    selectedAudience: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    canReplaceImage: {
      type: Boolean,
      default: false,
    },
    switchToReplaceImage: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    IconLibrary,
    LoadingAnimation,
    ConfirmationDialog,
    Download,
    ErrorDialogIcon,
    NewFeatureBadge,
  },
  async mounted() {
    this.setIconSearchData({
      searchTerm: '',
      source: '',
    });
    if (window.ResizeObserver) {
      const mainSlideSizeObserver = new ResizeObserver((data) => {
        this.mainSlideHeight = data && data[0] && data[0].contentRect.height;
      });
      mainSlideSizeObserver.observe(
        document.getElementById('replace-main-icon'),
      );
    }
    EventBus.$emit('MINIMIZE_ADVANCED_FILTER');
    // EventBus.$on('IMAGE_UPLOADING', () => {
    //   this.iconUploading();
    // });
    EventBus.$on(LOCAL_ICON_UPLOADING, () => {
      this.isLocalIconUploading = true;
    });
    EventBus.$on(LOCAL_ICON_UPLOADED, () => {
      this.isLocalIconUploading = false;
    });
    EventBus.$on(ICON_UPLOAD_FAILED, (subTitle) => {
      this.isConvertingInProgress = false;
      this.isLocalIconUploading = false;

      this.$modal.show(
        IconNotSuitable,
        {
          subTitle,
        },
        {
          name: 'IconNotSuitable',
          width: '564',
          height: '200px',
        },
      );
    });

    await this.loadIconReplacementData();
  },
  beforeDestroy() {
    // destroy event
    EventBus.$off('ICON_UPLOAD_FAILED');
  },
  computed: {
    ...mapGetters('slidesStore', ['getReplaceStackData', 'getIsImageNotFound']),
    ...mapState('users', ['currentUser', 'sessionKey', 'currentTheme']),
    ...mapState('slidesCollection', ['tailorID']),
    ...mapGetters('slidesCollection', ['isCollectionLimitReached']),
    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('replaceIconStore', ['getSearchTermIcon', 'getIsDirtyIcon']),
    ...mapState('prezentations', ['currNewPrezentation']),
    ...mapGetters('users', [
      'allowReplaceImageOverall',
      'allowReplaceImageTabs',
    ]),
    slideType() {
      let type = '';
      if (this.selectedSlide && Object.keys(this.selectedSlide).length > 0) {
        const { source } = this.selectedSlide.prefs;
        if (source === 'prezent') {
          type = 'prezentSourcedSlide';
        } else if (source === 'generated') {
          type = 'userGeneratedSlide';
        } else if (source === 'uploaded') {
          type = 'userUploadedSlide';
        } else if (source === 'synthesis') {
          type = 'synthesisSlide';
        } else if (source === 'redesign') {
          type = 'userRedesignSlide';
        } else if (source === 'comply') {
          type = 'userComplySlide';
        } else {
          type = 'companyBrandedSlide';
        }
      }
      return type;
    },
    hideReplaceImage() {
      return (
        [
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
        this.selectedSlide.isOwner === false &&
        this.selectedSlide.permission === 'Viewer'
      );
    },
    isOriginPrezentation() {
      return [BUILD, MY_PREZENTATION].includes(this.origin);
    },
    getS3Bucket() {
      const { replacedSlideData, filename } = this.selectedSlide;
      if (
        !replacedSlideData &&
        filename.startsWith('/newImageReplaceOutput/output/')
      ) {
        return calyrexConfig.calyrex_bucket_name;
      }
      return replacedSlideData?.s3_bucket || calyrexConfig.slides_bucket_name;
    },
    getS3Path() {
      const { replacedSlideData, filename } = this.selectedSlide;
      if (this.selectedSlide.isIconReplaced) {
        return this.selectedSlide.file;
      }
      return replacedSlideData?.s3_path || filename;
    },
    getDisabled() {
      if (this.origin === 'slides') {
        return !this.iconToReplace;
      }
      if (this.selectedSlide.isReplaced) {
        return !this.selectedSlide.isReplaced;
      }
      return !this.iconToReplace;
    },
  },
  methods: {
    ...mapActions('slidesStore', ['setReplaceStackData', 'setContentNotFound']),
    ...mapActions('replaceIconStore', [
      'resetIcon',
      'resetReturnIcon',
      'makeDirtyIcon',
      'setIconSearchData',
    ]),
    ...mapActions('replaceImageStore', ['reset', 'resetReturn']),
    ...mapActions('slidesCollection', ['setCollectionData']),
    async loadIconReplacementData() {
      if (
        Object.keys(this.getReplaceStackData).length > 0 &&
        this.getReplaceStackData.convertedImage &&
        this.getReplaceStackData.convertedImage !== '' &&
        this.getReplaceStackData.filteredIcons &&
        this.getReplaceStackData.selectedIconData
      ) {
        this.convertedImage = this.getReplaceStackData.convertedImage;
        this.filteredIcons = this.getReplaceStackData.filteredIcons;
        this.downloadUrl = this.getReplaceStackData.downloadUrl;
        this.isAddedToCollection = this.getReplaceStackData.isAddedToCollection;
        this.currentSelectedSlideIconData =
          this.getReplaceStackData.selectedIconData;
        this.savedImagePath = this.getReplaceStackData.savedImagePath;
        this.savedSlidePath = this.getReplaceStackData.savedSlidePath;
        this.newInput = this.getReplaceStackData.newInput;
        this.iconDetails = this.getReplaceStackData.filteredIcons;
        this.isIconReplacementComplete =
          !!this.getReplaceStackData.isIconReplacementComplete;

        this.iconDetails = await this.getDeckDetailsData();
        this.filteredIcons = this.iconDetails;
        this.isLoading = true;
        if (this.iconDetails) {
          await this.loadIconsInDeck(this.iconDetails);
        }
        this.isLoading = false;

        this.setReplaceStackData({
          ...this.getReplaceStackData,
          filteredIcons: this.filteredIcons,
          selectedIconData: this.currentSelectedSlideIconData,
        });
        // this.isLoading = false;
      } else if (
        Object.keys(this.getReplaceStackData).length > 0 &&
        this.getReplaceStackData.convertedImage &&
        this.getReplaceStackData.convertedImage !== '' &&
        (!this.getReplaceStackData.filteredIcons ||
          !this.getReplaceStackData.selectedIconData)
      ) {
        this.convertedImage = this.getReplaceStackData.convertedImage;
        this.downloadUrl = this.getReplaceStackData.downloadUrl;
        this.isAddedToCollection = this.getReplaceStackData.isAddedToCollection;
        this.savedImagePath = this.getReplaceStackData.savedImagePath;
        this.savedSlidePath = this.getReplaceStackData.savedSlidePath;
        this.newInput = this.getReplaceStackData.newInput;
        this.isIconReplacementComplete =
          !!this.getReplaceStackData.isIconReplacementComplete;

        this.iconDetails = await this.getDeckDetailsData();
        this.filteredIcons = this.iconDetails;
        this.isLoading = true;
        if (this.iconDetails) {
          await this.loadIconsInDeck(this.iconDetails);
        }
        this.isLoading = false;

        this.setReplaceStackData({
          ...this.getReplaceStackData,
          filteredIcons: this.filteredIcons,
          selectedIconData: this.currentSelectedSlideIconData,
        });
      } else {
        // making calls on mount when no cached data exists
        await this.loadAssets(this.selectedSlide);
        this.iconDetails = await this.getDeckDetailsData();
        this.filteredIcons = this.iconDetails;
        this.isLoading = true;
        if (this.iconDetails) {
          await this.loadIconsInDeck(this.iconDetails);
        }
        this.isLoading = false;

        // retaining some mergeable state with replace image to store
        this.setReplaceStackData({
          ...this.getReplaceStackData,
          convertedImage: this.convertedImage,
          filteredIcons: this.filteredIcons,
          isAddedToCollection: this.isAddedToCollection,
          selectedIconData: this.currentSelectedSlideIconData,
        });
      }
    },
    async handleCollection() {
      if (this.isCollectionLimitReached && !this.isAddedToCollection) return;
      if (this.isAddedToCollection) {
        const params = {
          type: 'slide',
          slideID: String(this.selectedSlide.unique_id),
          templateID: this.currentTheme.id,
          slideFileName: this.savedSlidePath,
        };
        this.processingCollection = true;
        await deleteCollection(params)
          .then((resp) => {
            console.log(resp);
            this.setCollectionData(resp.data.collections);
            this.setReplaceStackData({
              ...this.getReplaceStackData,
              isAddedToCollection: false,
            });
            this.isAddedToCollection = false;
            // this.collections[indexOfSet].slides.splice(indexOfSlide, 1);
          })
          .catch((err) => {
            console.log(err);
          });
        this.processingCollection = false;
      } else {
        const params = {
          templateID: this.currentTheme.id,
          templateCode: this.currentTheme.code,
          templateName: this.currentTheme.name,
          slideFileName: this.savedSlidePath,
          slideThumbnail: this.savedImagePath,
          slideID: String(this.selectedSlide.unique_id),
          idea: this.selectedSlide.title,
          tailorID: this.tailorID || this.currentUser.user.id,
          construct: this.selectedSlide.prefs.construct,
          isImageReplaced:
            !!this.getReplaceStackData.isImageReplacementComplete,
          isIconReplaced: this.isIconReplacementComplete,
          source: this.selectedSlide.prefs.source,
          sanitizedLevel: this.selectedSlide.sanitizedLevel,
          acceptedRejectedCount: this.selectedSlide.acceptedRejectedCount,
        };
        this.processingCollection = true;
        await updateCollection(params)
          .then((resp) => {
            this.setCollectionData(resp.data.collections);
            this.setReplaceStackData({
              ...this.getReplaceStackData,
              isAddedToCollection: true,
            });
            this.isAddedToCollection = true;
          })
          .catch((err) => {
            console.log(err);
          });
        this.processingCollection = false;
        trackSlideEvents.slidesAddToCollection(
          this.selectedSlide,
          this.currentUser,
          {
            [TD_AUD]: this.tailorID || this.currentUser.user.id,
            [TD_ISFAVORITED]: isSlideFavorite(
              this.selectedSlide,
              this.favoriteSlides,
            )
              ? 1
              : 0,
            [TD_PLACEMENT]: TD_EXPANDED_DETAIL_VIEW,
            [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
              this.selectedAudience,
              this.currentUser,
            ),
          },
        );
      }
    },
    async getSvgOfAllIcons(icons) {
      const svgPromises = icons.map(async (icn) => {
        const path = `${icn.s3_path}`;
        icn.imgUrl = null; // Need this to allow <v-img to work
        const imgUrl = await this.getSignedFileUrlFromCalyrex(path);
        if (path.includes('.svg')) {
          icn.imgUrl = await getSvgOfIcon(imgUrl);
        } else {
          icn.imgUrl = imgUrl;
        }
        return {
          ...icn,
        };
      });
      const results = await Promise.all(svgPromises);
      return results;
    },
    async loadIconsInDeck(iconDetails) {
      const iconsWithSvgs = await this.getSvgOfAllIcons(iconDetails);
      this.iconDetails = [...iconsWithSvgs];
      this.filteredIcons = this.iconDetails;
      // this.deckStatsSlides = deckstats.slides;
      for (const icon of this.filteredIcons) {
        // const path = `${icon.s3_path}`;
        // icon.imgUrl = null; // Need this to allow <v-img to work
        // const imgUrl = await this.getSignedFileUrlFromCalyrex(path);
        // icon.imgUrl = await getSvgOfIcon(imgUrl);
        const deckIcon = icon;
        if (deckIcon) {
          if (this.selectIconReplaceThumbnailGuidMap[deckIcon.imageIndex]) {
            icon.imgUrl =
              this.selectIconReplaceThumbnailGuidMap[
                deckIcon.imageIndex
              ].imgUrl;
          }
          icon.seq_id = deckIcon.imageIndex || 0;

          icon.invalid = this.notIcons.includes(deckIcon.meta.image_type);
          // image.guid = deckImage.imageIndex;
          // image.extents = deckImage.meta;
          // if (!this.guidList.includes(deckImage.guid)) {
          if (this.iconToReplace?.seq_id === deckIcon?.seq_id) {
            icon.imgUrl = this.iconToReplace.id
              ? this.iconToReplace.url
              : await this.getSignedFileUrlFromCalyrex(this.iconToReplace.path);
            this.selectIconReplaceThumbnailGuidMap[deckIcon.imageIndex] = {
              imgUrl: icon.imgUrl,
            };
          }
          // }
        }
        if (
          !icon.invalid &&
          (icon.seq_id === this.selectedIconSeqId || icon.seq_id === 0)
        ) {
          this.setCurrentSelectedSlideIconData(icon);
        }
      }

      this.isErrorLoadingIconReplaceData = this.iconDetails.length === 0;
      if (this.isErrorLoadingIconReplaceData) {
        this.errorMessageLoadingIconReplaceData = 'replaceIcon.noIconFound';
      }

      if (!this.currentSelectedSlideIconData && this.iconDetails.length) {
        this.setCurrentSelectedSlideIconData(this.iconDetails[0]);
      }
    },

    setCurrentSelectedSlideIconData(icon) {
      this.currentSelectedSlideIconData = {
        ...icon,
        iconName: this.selectedSlide.name || this.selectedSlide.title,
        // resizeData: this.getResizeImageData(image),
      };
    },

    // getResizeImageData(image) {
    //   const { height: originalHeight, width: originalWidth } = image.meta;

    //   const cropPercentages = {
    //     left: image.crop_left,
    //     right: image.crop_right,
    //     top: image.crop_top,
    //     bottom: image.crop_bottom,
    //   };
    //   const cropData = calculateCoordinates(
    //     'crop',
    //     originalHeight,
    //     originalWidth,
    //     cropPercentages,
    //   );
    //   console.log('crop data', cropData);
    //   return cropData;
    // },

    async onReplaceIcon(iconToReplace) {
      try {
        this.iconToReplace = iconToReplace;
        this.iconUploading();
        await this.triggerReplaceIcon();
        this.setReplaceStackData({
          ...this.getReplaceStackData,
          isAddedToCollection: false,
          isIconReplacementComplete: true,
        });
        this.isAddedToCollection = false;
        this.isIconReplacementComplete = true;
        if (this.currentAnimationImages === this.stepThree) {
          this.isConvertingInProgress = false;
        }
        this.makeDirtyIcon();
        try {
          this.postIconReplacementTrackingToMatomo();
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log('triggerReplaceIcon error: ', e);
        EventBus.$emit('ICON_UPLOAD_FAILED');
      }
    },
    onIconSearch({ searchTerm, source }) {
      trackIconReplacementEvents.iconReplacementIconSearch(
        this.selectedSlide,
        this.currentUser,
        {
          [TD_QUERY]: searchTerm,
          [TD_PLACEMENT]:
            this.origin === SLIDES
              ? TD_SLIDES_LIBRARY
              : TD_PREZENTATION_LIBRARY,
        },
      );
      this.setIconSearchData({
        searchTerm,
        source,
      });
    },
    getSourceIcon(source) {
      switch (source) {
        case 'flat-icons':
          return 'Library';
        case 'brand-icons':
          return this.currentUser.company.displayName;
        default:
          return 'Upload';
      }
    },
    postIconReplacementTrackingToMatomo(flowType = 'ICON_REPLACEMENT') {
      const eventStart = this.eventStart ? this.eventStart : new Date();
      const { sourceIcon } = this.getSearchTermIcon;
      const imageReplaceTrackingData = {
        [TD_TIME_SPENT_IN_SECS]: (new Date() - eventStart) / 1000,
        [TD_COMMON_COLUMN_NAME]: TD_TAB,
        [TD_COMMON_COLUMN_VALUE]: this.getSourceIcon(sourceIcon),
        [TD_PLACEMENT]:
          this.origin === SLIDES ? TD_SLIDES_LIBRARY : TD_PREZENTATION_LIBRARY,
      };
      switch (flowType) {
        case 'ICON_REPLACEMENT':
          trackIconReplacementEvents.iconReplacementSuccess(
            this.selectedSlide,
            this.currentUser,
            imageReplaceTrackingData,
          );
          break;
        case 'RESET_TO_DEFAULT':
          trackIconReplacementEvents.iconReplacementResettodefault(
            this.selectedSlide,
            this.currentUser,
            {
              [TD_PLACEMENT]:
                this.origin === SLIDES
                  ? TD_SLIDES_LIBRARY
                  : TD_PREZENTATION_LIBRARY,
            },
          );
          break;
        default:
          break;
      }
    },

    async triggerReplaceIcon() {
      this.savedSlidePath = null;
      this.savedImagePath = null;
      this.outputFileName = `${uuidv4()}.pptx`;

      const { meta, shapeType, imageIndex } = this.currentSelectedSlideIconData;

      let newImage = {
        imageIndex,
        s3_path: this.iconToReplace.s3_path,
        source: this.iconToReplace.source,
      };

      if (this.iconToReplace.source === 'flat-icons') {
        newImage = {
          imageIndex,
          source: this.iconToReplace.source,
          id: this.iconToReplace.id,
        };
      } else if (this.iconToReplace.source === 'upload') {
        newImage = {
          imageIndex,
          source: this.iconToReplace.source,
          image: this.iconToReplace.image,
          extension: this.iconToReplace.extension,
        };
      } else if (this.iconToReplace.source === 's3') {
        newImage = {
          imageIndex,
          source: this.iconToReplace.source,
          s3_path: `private${this.iconToReplace.s3_path}`,
          s3_bucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
        };
      }

      const s3Path = this.newInput?.s3_path
        ? this.newInput.s3_path
        : this.getS3Path;
      let s3Bucket = calyrexConfig.slides_bucket_name;

      if (s3Path.indexOf('newImageReplaceOutput/') !== -1) {
        s3Bucket = calyrexConfig.calyrex_bucket_name;
      }

      if (this.selectedSlide.prefs.source === 'uploaded') {
        s3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
      } else if (
        this.selectedSlide.prefs.source === 'generated' ||
        this.selectedSlide.prefs.source === 'synthesis' ||
        this.selectedSlide.prefs.source === 'redesign' ||
        this.selectedSlide.prefs.source === 'comply'
      ) {
        if (
          this.selectedSlide.landscape.startsWith(
            '/newImageReplaceOutput/output/',
          ) ||
          this.selectedSlide.landscape.startsWith('/output/pptx/') ||
          s3Path.indexOf('newImageReplaceOutput/') !== -1
        ) {
          s3Bucket = calyrexConfig.calyrex_bucket_name;
        } else {
          s3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
        }
      }

      if (this.getIsDirtyIcon === true) {
        s3Bucket = calyrexConfig.calyrex_bucket_name;
      }

      const body = {
        s3_bucket: s3Bucket,
        s3_path: s3Path,
        outputFormat: 'pptx',
        newImage,
        oldImage: { meta, shapeType },
        matchCoordinates: true,
      };

      const [resPdf, response] = await Promise.all([
        convertToPDFForIcons({ ...body, outputFormat: 'pdf' }),
        replaceIcon(body),
      ]);

      if (response?.s3_path && resPdf?.s3_path) {
        console.log('Deck successfuly converted', response);

        if (response.input) {
          this.payloadData = {
            ...body,
            newImage: { ...response.input, imageIndex },
          };
        }
        this.deckDetails = response;
        this.newInput = response;
        if (this.deckDetails) {
          // this.currentSelectedSlideIconData = null;

          this.iconDetails = this.iconDetails.map((icon) => {
            if (icon !== undefined && icon.imageIndex === imageIndex) {
              icon.imgUrl = this.iconToReplace.url;
              this.currentSelectedSlideIconData = {
                iconName:
                  this.getReplaceStackData?.selectedIconData?.iconName || '',
                ...icon,
              };
            }

            return icon;
          });

          this.filteredIcons = this.iconDetails;
        }

        this.downloadUrl = await this.getSignedFileUrlFromCalyrex(
          response?.s3_path,
        );
        this.savedSlidePath = response?.s3_path;

        await this.getLandscapeImage(resPdf?.s3_path);

        // updating shared replace state after each replace
        this.setReplaceStackData({
          ...this.getReplaceStackData,
          convertedImage: this.convertedImage,
          filteredIcons: this.filteredIcons,
          downloadUrl: this.downloadUrl,
          isAddedToCollection: this.isAddedToCollection,
          selectedIconData: this.currentSelectedSlideIconData,
          savedImagePath: this.savedImagePath,
          savedSlidePath: this.savedSlidePath,
          newInput: this.newInput,
          isIconReplacementComplete: this.isIconReplacementComplete,
        });
      } else {
        console.error(response);
        this.isErrorLoadingIconReplaceData = true;
        this.errorMessageLoadingIconReplaceData =
          'replaceIcon.replaceIconTimedOut';
      }
    },

    async getLandscapeImage(outputFilename) {
      this.convertedImage = await this.getSignedFileUrlFromCalyrex(
        outputFilename,
      );
      this.savedImagePath = outputFilename;
      this.convertedImage = await convertPdfToImage(this.convertedImage);
    },

    getSlideCategoryS3Bucket() {
      if (this.selectedSlide.isIconReplaced) {
        return calyrexConfig.calyrex_bucket_name;
      }

      if (this.selectedSlide.prefs.source === 'uploaded') {
        return process.env.VUE_APP_MVP_ASSETS_BUCKET;
      }
      if (
        this.selectedSlide.prefs.source === 'generated' ||
        this.selectedSlide.prefs.source === 'synthesis' ||
        this.selectedSlide.prefs.source === 'redesign' ||
        this.selectedSlide.prefs.source === 'comply'
      ) {
        if (
          this.selectedSlide.landscape.startsWith(
            '/newImageReplaceOutput/output/',
          ) ||
          this.selectedSlide.landscape.startsWith('/output/pptx/')
        ) {
          return calyrexConfig.calyrex_bucket_name;
        }
        return process.env.VUE_APP_MVP_ASSETS_BUCKET;
      }
      return this.getS3Bucket;
    },

    async getDeckDetailsData() {
      const body = {
        s3_bucket:
          this.getReplaceStackData &&
          this.getReplaceStackData?.newInput &&
          this.getReplaceStackData?.newInput?.s3_bucket
            ? this.getReplaceStackData?.newInput?.s3_bucket
            : this.getSlideCategoryS3Bucket(),
        s3_path:
          this.getReplaceStackData &&
          this.getReplaceStackData?.newInput &&
          this.getReplaceStackData?.newInput?.s3_path
            ? this.getReplaceStackData?.newInput?.s3_path
            : this.getS3Path,
        force_update: false,
      };
      const response = await extractIcons(body);

      if (response?.images && response?.images.length > 0) {
        return response.images;
      }
      console.log('Failed to get deck details', response);
      this.isErrorLoadingIconReplaceData = true;
      this.errorMessageLoadingIconReplaceData =
        response?.images && response?.images.length === 0
          ? 'replaceIcon.noIconFound'
          : 'replaceIcon.replaceIconTimedOut';
      // Making isIconNotFound true when the icon is not found.
      this.setContentNotFound({
        type: 'icon',
        value: !!(response?.images && response?.images.length === 0),
      });
      return null;
    },

    onErrorDialogClose() {
      // this.isErrorLoadingIconReplaceData = false;
      this.onErrorDialogOk();
    },

    onErrorDialogOk() {
      this.isErrorLoadingIconReplaceData = false;
      this.errorMessageLoadingIconReplaceData = '';
      if (
        this.getIsDirty ||
        (this.getReplaceStackData &&
          this.getReplaceStackData.filteredImages &&
          this.getReplaceStackData.filteredImages.length > 0)
      ) {
        this.switchToReplaceImage();
      } else {
        this.$emit('toggleIconReplace');
      }
    },

    async loadAssets(slideData) {
      if (slideData.isIconReplaced) {
        slideData.landscape = slideData.assetId;
      }
      if (slideData.prefs.source === 'uploaded') {
        const uploadedLandscape = await getFileURL(
          this.currentUser.user.cognitoID,
          slideData.landscape,
          this.currentUser.userIp,
          'IOCUpload',
        );
        this.landscapeImageAsset = await convertPdfToImage(uploadedLandscape);
      } else if (
        slideData.prefs.source === 'generated' ||
        slideData.prefs.source === 'synthesis' ||
        slideData.prefs.source === 'redesign' ||
        slideData.prefs.source === 'comply'
      ) {
        if (
          slideData.landscape.startsWith('/newImageReplaceOutput/output/') ||
          slideData.landscape.startsWith('/output/pptx/')
        ) {
          this.landscapeImageAsset = await getThumbnail(slideData.landscape);
        } else {
          this.landscapeImageAsset = await getFileURL(
            this.currentUser.user.cognitoID,
            slideData.landscape,
            this.currentUser.userIp,
            'IOCUpload',
          );
        }
      } else if (
        slideData?.landscape &&
        (slideData?.landscape?.startsWith('/output/pptx/') ||
          slideData?.landscape?.startsWith('/newImageReplaceOutput/output/'))
      ) {
        this.landscapeImageAsset = await getFileURLDownload(
          this.currentUser.user.id,
          slideData?.landscape.startsWith('/')
            ? slideData.landscape
            : `/${slideData.landscape}`,
          this.currentUser.userIp,
        );

        if (slideData?.landscape?.indexOf('.pdf') !== -1) {
          this.landscapeImageAsset = await convertPdfToImage(
            this.landscapeImageAsset,
          );
        }
      } else if (
        !slideData?.landscape?.startsWith('https') &&
        !slideData.replacedSlideData
      ) {
        this.landscapeImageAsset = await getFileURL(
          this.currentUser.user.cognitoID,
          slideData.landscape.startsWith('/')
            ? slideData.landscape
            : `/${slideData.landscape}`,
          this.currentUser.userIp,
        );

        if (slideData?.landscape?.indexOf('.pdf') !== -1) {
          this.landscapeImageAsset = await convertPdfToImage(
            this.landscapeImageAsset,
          );
        }
      } else {
        this.landscapeImageAsset = slideData.landscape;
      }
    },
    onIconSelected(slideIcon, toggle) {
      toggle();
      this.currentSelectedSlideIconData = {
        ...slideIcon,
        iconName: this.selectedSlide.name,
        // resizeData: this.getResizeImageData(slideImage),
      };
      this.selectedIconSeqId = slideIcon.seq_id;
      this.iconLibraryComponentKey++;
    },

    showReset() {
      this.dialogReset = true;
    },

    // onAcceptNewSlide() {
    //   this.reset();
    //   this.handleNewSlideAccepted({
    //     ...this.selectedSlide,
    //     thumbnail: this.selectedSlide.thumbnail,
    //     replacedInfo: this.payloadData,
    //   });
    //   this.slideReplaceAccepted({
    //     newSlide: this.savedSlidePath,
    //     currentThumbnail: this.savedImagePath,
    //     replacedInfo: this.payloadData,
    //   });
    //   const { searchTerm, source } = this.getSearchTermIcon;
    //   trackCommonEvents.imageReplacementAccept(
    //     this.currentUser,
    //     this.selectedSlide,
    //     {
    //       ...getBuildPrezentationData(this.prezentationObject),
    //       [TD_QUERY]: searchTerm,
    //       [TD_REPLACE_IMAGE_SOURCE]: source,
    //       ...this.getCommonMatomoOtherData(),
    //     },
    //     this.origin,
    //   );
    // },

    async onResetConfirm() {
      this.handleReset();
      this.resetIcon();
      this.resetReturnIcon();
      this.reset();
      this.resetReturn();

      // resetting cached data before default reset on flow basis
      this.setReplaceStackData({});

      this.loadIconReplacementData();
      this.postIconReplacementTrackingToMatomo('RESET_TO_DEFAULT');
    },

    onResetCancel() {
      this.dialogReset = false;
    },

    // Utility functions
    async getSignedFileUrlFromCalyrex(filePath) {
      if (filePath) {
        return await getFileURLDownload(
          this.currentUser.user.cognitoID,
          filePath.startsWith('/') ? filePath : `/${filePath}`,
          this.currentUser.userIp,
        );
      }
      return '';
    },
    async downloadLocal() {
      let fileName = '';
      if (
        (this.selectedSlide.prefs.source === 'generated' ||
          this.selectedSlide.prefs.source === 'synthesis' ||
          this.selectedSlide.prefs.source === 'redesign' ||
          this.selectedSlide.prefs.source === 'uploaded' ||
          this.selectedSlide.prefs.source === 'comply') &&
        this.selectedSlide.name
      ) {
        fileName = this.selectedSlide.name.replace(/.pptx/g, '');
      } else if (this.selectedSlide.asset) {
        fileName = `${this.selectedSlide.asset.categories[0]}_${this.selectedSlide.asset.prefs.theme}`;
      } else {
        fileName = `${this.selectedSlide.categories[0]}_${this.selectedSlide.prefs.theme}`;
      }
      downloadFileWithCustomName(this.downloadUrl, fileName);
      this.logDownload(
        this.selectedSlide,
        !!this.getReplaceStackData.isImageReplacementComplete,
        false,
        this.isIconReplacementComplete,
      );
      // if (this.origin.toLowerCase() === SLIDES && this.isAddedToCollection) {
      //   trackSlideEvents.slidesImageReplacementAddToCollectionDownload(
      //     this.selectedSlide,
      //     this.currentUser,
      //     {
      //       [TD_AUD]: this.tailorID || this.currentUser.user.id,
      //       [TD_ISFAVORITED]: isSlideFavorite(
      //         this.selectedSlide,
      //         this.favoriteSlides,
      //       )
      //         ? 1
      //         : 0,
      //       [TD_ISDOWNLOAD]: 1,
      //       [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
      //       [TD_REPLACE_IMAGE_SOURCE]: this.getSearchTermIcon?.source,
      //       [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
      //         this.selectedAudience,
      //         this.currentUser,
      //       ),
      //     },
      //   );
      // }
    },

    iconUploading() {
      this.currentAnimationImages = this.stepOne;
      this.isConvertingInProgress = true;
      this.showLoadingAnimation();
    },
    showLoadingAnimation() {
      setTimeout(
        () => {
          if (this.currentAnimationImages === this.stepOne) {
            this.currentAnimationImages = this.stepTwo;
            this.showLoadingAnimation();
          } else if (this.currentAnimationImages === this.stepTwo) {
            this.currentAnimationImages = this.stepThree;
            this.showLoadingAnimation();
          } else if (this.savedImagePath) {
            this.isConvertingInProgress = false;
          }
        },
        this.currentAnimationImages === this.stepThree ? 1000 : 1500,
      );
    },
    // getCommonMatomoOtherData() {
    //   return {
    //     [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
    //       this.selectedAudience,
    //       this.currentUser,
    //     ),
    //     [TD_AUD]: getAudienceId(this.selectedAudience),
    //   };
    // },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.btn-back {
  letter-spacing: normal;
  padding: 0 !important;
  text-transform: none;
}

.header {
  color: #000;
  font-family: 'Lato' !important;
  display: flex;
  justify-content: space-between;
}

.main-image {
  align-items: flex-start;
  display: inline-block;
  flex-direction: column;
  font-family: 'Lato';
  justify-content: flex-start;
  width: 100%;

  .main-image-header {
    padding-bottom: 4px;
    // .text-subtitle-1 {
    //   margin: 10px 0 10px 0;
    // }
  }
  .image-wrapper {
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    overflow: hidden;
    position: relative;
  }
  .main-image__wrapper {
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    overflow: hidden;
    position: relative;
    max-height: 32rem;
    margin-left: 5px;

    .v-responsive {
      border-radius: 8px;
      object-fit: contain;
    }
    img {
      height: 100%;
      width: 100%;
    }
    :hover {
      box-shadow: $item-box-shadow-hover;
    }
    .main-image__img {
      border-radius: 8px;
    }
  }
}

.select-image {
  align-items: center;
  display: flex;
  padding-top: 2px;
  max-width: 100%;

  .select-image__item {
    border: solid $light-gray 1px;
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    min-width: 100px;

    &:hover {
      box-shadow: $item-box-shadow-hover;
      cursor: pointer;
    }
  }

  .selected {
    border: 2px solid $zen-blue-default;
  }
}

.choose-icon {
  color: #000;
  max-height: 680px;
  padding: 5px !important;
  // position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.actions {
  align-items: center;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: flex-end;
  position: absolute;
  right: 0px;
}
.loading-spinner__select-image-container {
  padding: 40px;
}

.loading-spinner__image-library-container {
  padding-top: 200px;
}
.replace-image {
  .secondary-button {
    padding: 0 5px;
    height: 30px;
    ::v-deep .v-btn__content {
      opacity: 1 !important;
    }
  }
  .action-button-disable__text {
    color: #bdbdbd;
  }
  .addRemvCollBtn {
    cursor: pointer;
    text-transform: none;
    padding: 0 !important;

    .v-btn__content {
      // .v-icon {
      //   font-size: 16px !important;
      // }
      .action-button__text {
        font-size: 16px !important;
      }
      // img {
      //   width: 16px;
      //   height: 16px;
      // }
    }

    &:hover {
      background: #fff;
    }
    .action-button__text {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: normal;
      text-transform: none;
    }
    .action-button-disable__text {
      font-size: 16px;
      font-weight: bold;
      color: #bdbdbd;
      letter-spacing: normal;
      text-transform: none;
    }
  }
}
</style>
