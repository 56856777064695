<template>
  <div>
    <div
      v-if="(audience || showSearchScreen) && progressScreenIndex === -1"
      class="back-button"
      @click="handleBackButton()"
    >
      <v-icon color="primary" size="12">mdi-less-than</v-icon>
      {{
        audience && showSearchScreen
          ? $t('fingerprint.backToSearch')
          : $t('fingerprint.backToFingerprints')
      }}
    </div>
    <div v-if="!loading && !audience" class="zentrends-container">
      <div
        class="left-section"
        v-if="!showSearchScreen && !leftNavExpanded"
        data-pendo-id="fingerprint-left-panel"
      >
        <div class="left-profile">
          <h4>{{ $t('fingerprint.myFingerprintText') }}</h4>
          <img
            :src="fingerprintData.logo"
            alt="Fingerprint-Logo"
            class="logo-fingerprint"
            @click="exploreFingerprint(fingerprintData)"
          />
          <h3 class="fingerprint-text-logo">
            {{ currentUser.user.fingerPrint }}
          </h3>
          <div class="user-donation-section">
            <div class="user-donation-section--list">
              <OtherColleagues
                :scope="'company'"
                :fingerprint="currentUser.user.fingerPrint"
                :vertical="true"
                @audienceClicked="handleAudienceClicked"
                origin="zentrends"
              ></OtherColleagues>
            </div>
          </div>
        </div>
        <div class="left-pref">
          <div
            class="pref-item"
            v-for="(item, index) in fingerprintData.preferences"
            :key="index"
          >
            <div class="pref-cont">
              <div class="pref-heading">{{ $t(item.title) }}</div>
              <div class="pref-sub-heading">
                {{ item.preference }}
              </div>
            </div>
            <img :src="item.logo" width="50px" />
          </div>
        </div>
        <div class="left-personal-pref">
          <div class="heading-pref">
            {{ $t('fingerprint.personalPreference') }}
            <span class="btn btn-edit" @click="handleEditPref">
              <v-icon color="black">mdi-pencil-outline</v-icon>
            </span>
          </div>
          <div class="prefes">
            <div
              class="selection edit-mode"
              v-for="(selection, index) in allPrefs"
              :key="index"
            >
              <span>
                <v-img
                  class="mx-2"
                  :src="
                    selection.image
                      ? `/${selection.image}`
                      : '/assets/img/preferences-added_by_user.svg'
                  "
                  height="30px"
                  width="30px"
                />
              </span>
              <span class="selection-title">
                {{ selection.title }}
              </span>
            </div>
          </div>
        </div>
        <div class="left-actions">
          <ShareFPOnLinkedIn
            :fpData="fingerprintData"
            :origin="'Fingerprints Feature'"
          />
          <div @click="shareFingerBtn()">
            <v-icon :size="20" color="#21a7e0"
              >mdi-account-multiple-plus-outline</v-icon
            >
            {{ $t('fingerprint.shareMyFingerprint') }}
          </div>
          <div @click="goToTest()">
            <v-icon :size="20" color="#21a7e0">mdi-sync</v-icon>
            {{ $t('fingerprint.retakeFingerprintTest') }}
          </div>
          <div @click="goToProfile()">
            <v-icon :size="20" color="#21a7e0">mdi-open-in-new</v-icon>
            {{ $t('fingerprint.profile') }}
          </div>
        </div>
      </div>
      <div class="right-section">
        <div
          class="right-section-container"
          :class="{
            'nav-expanded': leftNavExpanded && !showSearchScreen,
            'search-padding': showSearchScreen,
          }"
        >
          <div class="search-container">
            <div v-if="!showSearchScreen && leftNavExpanded" class="profile">
              <img
                :src="fingerprintData.logo"
                alt="Fingerprint-Logo"
                class="logo-fingerprint"
                @click="expandLeftNav()"
              />
              <div class="fp-info">
                <div class="fp-title">
                  {{ $t('fingerprint.myFingerprintText') }}
                </div>
                <div class="fingerprint-text-logo">
                  {{ currentUser.user.fingerPrint }}
                </div>
              </div>
            </div>
            <Search
              @onEnterPress="handleEnterPressed"
              @audienceClicked="handleAudienceClicked"
            ></Search>
          </div>
          <template v-if="!showSearchScreen">
            <ExploreFingerprint
              @audienceClicked="handleAudienceClicked"
            ></ExploreFingerprint>
            <CompareFingerprint></CompareFingerprint>
            <GroupInsights
              @audienceClicked="handleAudienceClicked($event, true)"
            ></GroupInsights>
          </template>
          <SearchPage
            v-else
            :itemsFiltered="itemsFiltered"
            @audienceClicked="handleAudienceClicked"
          ></SearchPage>
        </div>
      </div>
    </div>
    <div class="fing-section" v-else>
      <div v-if="progressScreenIndex !== -1">
        <template v-for="(progress, index) of progressScreens">
          <template>
            <div
              v-show="index === progressScreenIndex"
              class="generate-fing-loading"
              :key="index"
            >
              <div class="loading-header">
                {{ $t('fingerprint.generatingYourGroupFingerprint') }}
              </div>
              <img :src="progress.banner" />
            </div>
          </template>
        </template>
      </div>
      <v-row align="center" justify="center" v-else-if="loading" class="mt-9">
        <LoadSpinner
          :id="'spinner'"
          :text="'Loading...'"
          :size="'60'"
          :bgcolor="'lightgray'"
          :speed="'0'"
          :color="'gray'"
          class="loadspinner"
        />
      </v-row>
      <FingerPrintVue
        v-else-if="audience.fingerPrint"
        :fingerprint="audience.fingerPrint"
        :audience="audience"
        :personalPrefs="personalPrefs"
        :creator="creatorName"
        :isPrivateAudience="isPrivateAudience"
        :prefEditable="isPrefEditable"
        :allPrefs="selections"
        :shareable="creatorName === 'me' || audience.id === currentUser.user.id"
        :isDifferentCompany="formattedAudience.isDifferentCompany"
        :isGroup="formattedAudience.isGroup"
        :currentCompany="company"
        origin="zentrends"
        :isAlreadyShared="isAlreadyShared"
        @handleClickShare="handleShareFingerPrint"
        @handleQuestion="onHandleQuestion"
        @handleSavePreference="handleSavePreference"
      />
      <div v-else-if="limitedAccess">
        <FingerPrintVue
          :audience="audience"
          :locked="true"
          :fingerprint="'locked'"
          :currentCompany="company"
          origin="zentrends"
        />
        <div class="d-flex justify-center">
          <v-btn
            class="send-assessment"
            rounded
            color="#20a7e0"
            width="262"
            height="50"
            @click="handleRequestAccess"
            :loading="requestAccessLoading"
            :disabled="requestAccessDisabled || requestAccessLoading"
          >
            {{
              requestAccessDisabled
                ? $t('fingerprint.requestSent')
                : $t('build.step3.requestAccess')
            }}
          </v-btn>
        </div>
      </div>
      <template v-else>
        <div class="fp-not-found">
          <div>
            <img src="/assets/img/fingerprints-not-found.svg" alt />
          </div>
          <div class="fp-not-found-descriptions">
            <div>
              {{ $t('overnightPresentations.colleagueNotYetTakenAssessment') }}
            </div>
          </div>
          <div class="send-reminder-container">
            <v-btn
              class="send-assessment"
              rounded
              color="#20a7e0"
              width="262"
              height="50"
              @click="handleSendAssessment"
              :loading="reminderLoading"
              :disabled="isSent"
            >
              {{
                isSent
                  ? $t('overnightPresentations.reminderSent')
                  : $t('overnightPresentations.remind')
              }}
            </v-btn>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { findIndex, filter } from 'lodash';
import { fpTypes, fpSelections } from '@/mock/fingerprint';
import ExploreFingerprint from './ExploreFingerprint.vue';
import LoadSpinner from '../../common/LoadSpinner.vue';
// import ShareFingerPrintDetail from '../Profile/ShareFingerPrintDetail.vue';
import GroupInsights from './GroupInsights.vue';
import OtherColleagues from './OtherColleagues.vue';
import Search from './Search.vue';
import EditPreferences from './EditPreferences.vue';
import FingerPrintVue from '../FingerPrints/FingerPrint.vue';
import CompareFingerprint from './CompareFingerprint.vue';
import ShareFingerPrint from '../FingerPrints/ShareFingerPrint.vue';
import SearchPage from './SearchPage.vue';
import EventBus from '../../common/event-bus';
import {
  getFingerprint,
  getFingerprintShareDetails,
  sendFPReminder,
  updatePrivateAudience,
} from '../../../utils/api-helper';
import audienceApi from '../../../API/audience-api';
import {
  capitalizeFirstLetter,
  getCommonPrivateAudienceData,
} from '../../../utils/common';
import ShareFingerPrintDetail from '../Profile/ShareFingerPrintDetail.vue';
import { trackFingerprintEvents } from '../../common/Analytics/FingerprintEvents';
import { TD_SHARE_FINGERPRINT_ZENTRENDS } from '../../common/Analytics/MatomoTrackingDataHelper';
import { AnalyticsHandler } from '../../common/Analytics/AnalyticsHandler';
import ShareFPOnLinkedIn from '../../common/ShareFPOnLinkedIn.vue';

export default {
  name: 'FingerPrint',
  components: {
    ExploreFingerprint,
    GroupInsights,
    FingerPrintVue,
    Search,
    OtherColleagues,
    CompareFingerprint,
    SearchPage,
    LoadSpinner,
    ShareFPOnLinkedIn,
  },
  data() {
    return {
      progressScreens: [
        {
          banner: '/assets/img/fingerPrintTest/generating_fingerprint_01.svg',
          waitTime: 1000,
        },
        {
          banner: '/assets/img/fingerPrintTest/generating_fingerprint_02.svg',
          waitTime: 1000,
        },
        {
          banner: '/assets/img/fingerPrintTest/generating_fingerprint_03.svg',
          waitTime: 1000,
        },
        {
          banner: '/assets/img/fingerPrintTest/generating_fingerprint_04.svg',
          waitTime: 1000,
        },
      ],
      progressScreenIndex: -1,
      isExpanded: false,
      isPrefEditMode: false,
      ownPreference: [],
      itemsFiltered: [],
      showSearchScreen: false,
      audience: null,
      searchTerm: '',
      loading: false,
      leftNavExpanded: false,
      isAlreadyShared: false,
      creatorName: '',
      isSent: false,
      reminderLoading: false,
      limitedAccess: false,
      requestAccessDisabled: false,
      requestAccessLoading: true,
      isPrivateAudience: false,
      isPrefEditable: false,
      selections: [],
      personal: [],
    };
  },
  watch: {
    '$route.query.id': {
      handler(newVal, oldVal) {
        if (oldVal === newVal) return;
        if (this.$route.query.id) {
          this.getFingerprintDetails(
            this.$route.query.audienceType,
            this.$route.query.id,
            this.$route.query.shared,
            false,
          );
        }
        setTimeout(() => {
          EventBus.$emit('MINIMIZE_LEFTNAV');
        }, 50);
      },
      deep: true,
      immediate: true,
    },
    '$route.query': {
      handler(newVal, oldVal) {
        if (oldVal === newVal) return;
        this.audience = null;
        this.showSearchScreen = false;
      },
      deep: true,
      immediate: true,
    },
    audience: {
      immediate: true,
      async handler(audience) {
        this.isSent = false;
        this.reminderLoading = false;

        if (audience?.isPublicLimited) {
          this.limitedAccess = true;
          const resp = await audienceApi.methods.getAccessRequestIsDisabled(
            audience.type,
            audience.type === 'user' ? this.audience.id : this.audience.uuid,
          );
          this.requestAccessDisabled = resp;
          this.requestAccessLoading = false;
        }

        if (!audience?.userID) {
          return;
        }

        if (this.audience.userID === this.currentUser.user.id) {
          this.creatorName = 'me';
          return;
        }

        const user = await audienceApi.methods.getTypesenseAudience(
          this.audience.userID,
          'user',
        );

        this.creatorName = user?.document.fullName || '';
      },
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    fingerprintData() {
      const fpData = fpTypes.find(
        (fprint) =>
          fprint.title === this.currentUser?.user?.fingerPrint?.toLowerCase(),
      );
      if (!fpData) {
        return fpTypes?.[0];
      }
      return fpData;
    },
    formattedAudience() {
      if (this.audience) {
        const { companyName, displayName } = this.audience;
        const audienceObj = {
          ...this.audience,
          companyName: companyName || displayName,
        };
        return getCommonPrivateAudienceData(audienceObj, this.currentUser);
      }
      return {};
    },
    company() {
      if (this.currentUser.company?.displayName) {
        return this.currentUser.company.displayName;
      }
      return capitalizeFirstLetter(this.currentUser.company.name);
    },
    allPrefs() {
      const prefes = [];
      const personalPref = this.currentUser.user.personalPreference;
      for (let i = 0; i < personalPref.length; i++) {
        if (fpSelections.find((ele) => ele.title === personalPref[i])) {
          prefes.push(
            fpSelections.find((ele) => ele.title === personalPref[i]),
          );
        } else if (personalPref[i]) {
          prefes.push({
            value: personalPref[i].toLowerCase(),
            title: personalPref[i],
          });
        }
      }
      return prefes;
    },
    personalPrefs() {
      return this.personal.map((item) => item.title);
    },
  },
  methods: {
    ...mapActions('users', ['setCurrentUserPersonalPreferences']),
    setIsPrivateAudience() {
      if (
        (!this.audience.type &&
          this.audience.fingerprintType.toLowerCase() === 'standard') ||
        this.audience.type === 'private_audience'
      ) {
        this.isPrivateAudience = true;
      } else {
        this.isPrivateAudience = false;
      }
    },
    handleShareFingerPrint() {
      this.$modal.show(
        ShareFingerPrintDetail,
        {
          entityID: this.audience.uuid,
          share: this.checkSharedStatus,
        },
        {
          name: 'ShareFingerPrintDetail',
          width: 700,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    async checkSharedStatus() {
      try {
        if (this.audience.uuid) {
          const { data } = await getFingerprintShareDetails(this.audience.uuid);
          this.isAlreadyShared = data.length > 0;
        }
      } catch (e) {
        this.isAlreadyShared = this.audience?.shared;
      }
    },
    expandLeftNav() {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    },
    handleAudienceClicked(audience, generateFP = false) {
      this.getFingerprintDetails(
        audience.audienceType,
        audience.id,
        audience.shared,
        generateFP,
      );
    },
    handleEditPref() {
      this.$modal.show(
        EditPreferences,
        {
          type: 'modal',
          personalPrefs: this.currentUser?.user?.personalPreference,
        },
        {
          name: 'EditPreferences',
          width: 1000,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    handleBackButton() {
      if (this.audience) {
        this.audience = null;
        this.isAlreadyShared = false;
        this.isPrefEditable = false;
      } else {
        this.showSearchScreen = false;
      }
      trackFingerprintEvents.fingerprintScreenBackToFeature(this.currentUser);
    },
    handleEnterPressed(val) {
      console.log(val);
      this.itemsFiltered = [...val];
      this.showSearchScreen = true;
    },
    handleSmallFing() {
      setTimeout(() => {
        EventBus.$emit('MINIMIZE_LEFTNAV');
      }, 50);
    },
    onHandleQuestion(item) {
      this.handleQuestion(item);
    },
    handleQuestion(item) {
      const initialValue = this.personal;
      if (findIndex(initialValue, { title: item.title }) > -1) {
        const updatedValue = filter(
          initialValue,
          (initial) => initial.title !== item.title,
        );
        this.personal = updatedValue;
      } else {
        initialValue.push(item);
        this.personal = initialValue;
      }
    },
    async handleSavePreference() {
      if (this.audience.id === this.currentUser.user.id) {
        this.setCurrentUserPersonalPreferences(this.personal);
      } else {
        const tempPrefs = [];
        this.personal.forEach((item) => {
          tempPrefs.push(item.title);
        });
        const payload = {
          id: this.audience.id,
          userID: this.audience.userID
            ? this.audience.userID
            : this.currentUser.user.id,
          personalPreference: tempPrefs,
        };
        await updatePrivateAudience(payload);
      }

      this.audience.personalPreference = [
        ...this.personal.map((item) => item.title),
      ];
    },
    async initialize() {
      // reset fingerprints and selections
      this.fpQuestions = [...fpTypes];
      this.selections = [...fpSelections];

      if (this.audience) {
        this.personal = [];
        this.audience.personalPreference.forEach((pref) => {
          const p = {};
          p.key = pref;
          p.title = pref;
          this.personal.push(p);
          const found = this.selections.find(
            (element) => element.title === pref,
          );
          if (!found) this.selections.push(p);
        });
      }
      this.isPrefEditable =
        this.audience.userID === this.currentUser.user.id ||
        this.audience.id === this.currentUser.user.id ||
        this.audience.companyID === this.currentUser.company.id;
    },
    exploreFingerprint(fpData) {
      console.log(fpData);
      EventBus.$emit('EXPLORE_FP', fpData);
      trackFingerprintEvents.fingerprintsMyFingerprintOwnFingerprintClick(
        this.currentUser,
      );
    },
    getClassName(selection) {
      return this.currentUser.user.personalPreference.includes(selection.title)
        ? 'highlight'
        : '';
    },
    goToProfile() {
      this.$router.push('/profile?tab=fingerprint&from=zentrends');
      trackFingerprintEvents.fingerprintsMyFingerprintGoToProfile(
        this.currentUser,
      );
    },
    goToTest() {
      this.$router.push('/welcome?initialIndex=1&origin=zentrends');
      trackFingerprintEvents.fingerprintsMyFingerprintRetakeFingerprintTest(
        this.currentUser,
      );
    },
    shareFingerBtn() {
      this.$modal.show(
        ShareFingerPrint,
        {
          type: 'modal',
          isAudience: false,
          origin: TD_SHARE_FINGERPRINT_ZENTRENDS,
        },
        {
          name: 'ShareFingerPrint',
          width: 600,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    loadProgressScreens(index) {
      if (index === 3) {
        this.progressScreenIndex = -1;
        return;
      }
      setTimeout(() => {
        if (this.loading) {
          this.loadProgressScreens(
            index === 2 ? 2 : this.progressScreenIndex++,
          );
        } else {
          this.loadProgressScreens(this.progressScreenIndex++);
        }
      }, this.progressScreens[this.progressScreenIndex].waitTime);
    },
    async getFingerprintDetails(type, id, shared = false, generateFP = false) {
      console.log(type, id, shared, 'detaiilllllllssss');
      if (generateFP) {
        this.progressScreenIndex = 0;
        this.loadProgressScreens();
      }
      this.loading = true;

      if (type === 'standard') {
        this.audience = await getFingerprint(id, type);
        this.audience.firstName = (
          this.audience.firstName
            ? this.audience.firstName
            : this.audience.fullName
        )
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substr(1))
          .join(' ');
        this.setIsPrivateAudience();
        this.loading = false;
        this.isAlreadyShared = false;
        this.initialize();
      } else if (type === 'user') {
        const audience = await getFingerprint(id, type);
        audience.type = 'user';
        this.audience = audience;
        this.setIsPrivateAudience();
        this.loading = false;
        this.isAlreadyShared = this.audience.shared;
        this.initialize();
      } else {
        const audience = await audienceApi.methods.getSharedFingerprintDetails(
          id,
          type,
        );
        audience.type = type;
        this.audience = audience;
        this.setIsPrivateAudience();
        this.isAlreadyShared = this.audience.shared;
        this.loading = false;
        this.initialize();
      }
    },
    async handleSendAssessment() {
      const incompleteFingerprintData = this.audience;
      if (incompleteFingerprintData) {
        this.isSent = true;
        this.reminderLoading = true;
        sendFPReminder(
          this.currentUser,
          incompleteFingerprintData,
          this.audience.type === 'user',
        )
          .then((response) =>
            console.log('Email Fingerprint Success : ', response),
          )
          .catch((err) => console.log(err))
          .finally(() => {
            this.reminderLoading = false;
          });
        AnalyticsHandler.fingerprintEmailedLink(
          this.currentUser,
          this.audience.email,
          this.audience.firstName,
          this.audience.lastName,
        );
      }
    },
    async handleRequestAccess() {
      this.requestAccessLoading = true;
      audienceApi.methods
        .requestFingerprintAccess({
          type: this.audience.type,
          id:
            this.audience.type === 'user'
              ? this.audience.id
              : this.audience.uuid,
        })
        .then((data) => {
          console.log('requestFingerprintAccess', data);
          this.requestAccessDisabled = true;
          this.requestAccessLoading = false;
        })
        .catch((err) => console.error('requestFingerprintAccess', err));
    },
  },
  // watch: {
  //   EventBus.$on('MINIMIZE_LEFTNAV') {
  //     this.isExpanded = true;
  //   }
  // },
  mounted() {
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    EventBus.$on('LEFTNAV_EXPANDED', () => {
      this.leftNavExpanded = true;
    });
    EventBus.$on('LEFTNAV_MINIMIZED', () => {
      this.leftNavExpanded = false;
    });
    console.log('minimize');
  },
  beforeDestroy() {
    EventBus.$off('LEFTNAV_EXPANDED');
    EventBus.$off('LEFTNAV_MINIMIZED');
  },
};
</script>
<style lang="scss" scoped>
.back-button {
  margin: 30px;
  color: #21a7e0;
  display: flex;
  align-items: baseline;
  gap: 5px;
  cursor: pointer;
  display: inline-flex;
}
.zentrends-container {
  width: 100%;
  display: flex;

  .left-section {
    margin: 20px 0px 10px 15px;
    width: 205px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .right-section {
    width: 100%;
    height: calc(100vh - 68px);
    overflow: auto;

    .right-section-container {
      display: flex;
      gap: 36px;
      flex-direction: column;
      padding: 25px 15px 25px 15px;
      width: 100%;
      margin: auto;

      &.search-padding {
        padding: 0px 15px 25px 25px;
      }

      &.nav-expanded {
        max-width: 1600px;
      }
      .search-container {
        width: 100%;
        justify-content: space-between;
        display: flex;
        align-items: center;

        .profile {
          display: flex;
          gap: 6px;

          .logo-fingerprint {
            cursor: pointer;
            height: 44px;
            width: 44px;
            border-radius: 50%;
            cursor: pointer;
          }
          .logo-fingerprint:hover {
            transform: scale(1.1);
            box-shadow: 0 2px 5px 3px rgb(64 60 67 / 16%);
          }

          .fp-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 44px;
            gap: 2px;
            .fp-title {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: #000000;
            }

            .fingerprint-text-logo {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: 0.01em;
              color: #21a7e0;
            }
          }
        }
      }
    }
  }
}
.left-profile {
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;

  h4 {
    font-size: 16px;
  }
  .fingerprint-text-logo {
    color: #21a7e0;
  }
  .logo-fingerprint {
    height: 92px;
    width: 92px;
    margin-top: 15px;
    margin-bottom: 3px;
    cursor: pointer;
    border-radius: 50%;
  }
  .logo-fingerprint:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 5px 3px rgb(64 60 67 / 16%);
  }
  .user-donation-section {
    cursor: pointer;
    &--list {
      margin-top: 8px;
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;
    }
    &--item {
      border: 2px solid white !important;
      margin-left: -20px;
    }
    &--item__border {
      border: 2px solid white !important;
    }
    &--user-count {
      color: #7b7b7b;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      margin-left: 0px;
    }
  }
}
.left-pref {
  height: 180px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  justify-content: space-between;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  .pref-heading {
    color: #7b7b7b;
    font-size: 14px;
  }
  .pref-sub-heading {
    font-size: 14px;
  }
  .pref-item {
    display: flex;
    justify-content: space-between;
  }
}
.left-personal-pref {
  height: 180px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0px 10px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  .heading-pref {
    display: flex;
    color: #7b7b7b;
    justify-content: space-between;
    .btn-edit {
      cursor: pointer;
    }
  }
  .prefes {
    overflow: scroll;
    margin-top: 10px;
    width: calc(100% + 10px);
    .selection {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      color: #212121;
      margin: 0;
    }
  }
}
.fing-section {
  width: 100%;
}
.left-actions {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  row-gap: 10px;
  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #21a7e0;
  }
}

.generate-fing-loading {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  .loading-header {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    margin-bottom: 30px;
  }

  img {
    width: 283px;
    height: 283px;
  }
}

.fp-not-found {
  padding-top: 70px;
  align-items: center;
  text-align: center;

  .fp-not-found-descriptions {
    margin-top: 20px;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}
.send-assessment {
  border-radius: 25px;
  text-transform: none;
  color: white !important;
  background: $zen-blue-default;
  font-size: 1.3vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  min-width: 125px;
  width: auto !important;
}
.send-reminder-container {
  margin: 20px 0;
}
</style>
