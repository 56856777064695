var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slideChangeLayout"},[_c('v-row',{staticStyle:{"width":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"changelayoutHeader text-h6 text-left font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('bestPracticesGuide.knowDetails.similarSlides'))+" ")])]),_c('div',{staticClass:"iconsRow"},[(_vm.filteredSlideByNode.length)?_c('div',{staticClass:"icon-container"},[_c('div',{on:{"click":function($event){return _vm.handleView(false)}}},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('slideDetails.gridView'),
              placement: 'top-center',
            }),expression:"{\n              content: $t('slideDetails.gridView'),\n              placement: 'top-center',\n            }"}],attrs:{"color":_vm.isColumnView ? '#000' : '#21A7E0'}},[_vm._v(" "+_vm._s(_vm.isColumnView ? 'mdi-view-grid-outline' : 'mdi-view-grid')+" ")])],1),_c('div',{on:{"click":function($event){return _vm.handleView(true)}}},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('slideDetails.columnView'),
              placement: 'top-center',
            }),expression:"{\n              content: $t('slideDetails.columnView'),\n              placement: 'top-center',\n            }"}],attrs:{"color":_vm.isColumnView ? '#21A7E0' : '#000'}},[_vm._v(" "+_vm._s(_vm.isColumnView ? 'mdi-view-agenda' : 'mdi-view-agenda-outline')+" ")])],1)]):_vm._e()]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"related-products"},[(_vm.loadingRelatedSlides)?_c('div',{staticClass:"relatedSlidesLoaderContainer"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":30,"color":"primary"}})],1):_c('div',{staticClass:"related-products-thumbnails"},[(
              _vm.filteredSlideByNode.length && _vm.filteredSlideByNode.length > 0
            )?_c('div',{key:_vm.viewKey,class:_vm.isColumnView ? 'column' : 'grid',style:({ padding: '8px 0px 0px 0px' })},_vm._l((_vm.filteredSlideByNode),function(product,index){return _c('RelatedSlide',{key:index,attrs:{"in-viewport-once":true,"in-viewport-root-margin":'0% 0%',"handle-related-product-click":_vm.handleRelatedProductClick,"product":product,"data-pendo-id":index === 0 ? 'bpe-similar-slides-card' : ''}})}),1):_c('div',{staticClass:"noRelatedSlidesIllustration"},[_c('div',{staticClass:"imgContainer"},[_c('img',{attrs:{"src":"/assets/img/no-files-available.svg"}})]),_c('div',{staticClass:"relatedSlidesError"},[_vm._v(" "+_vm._s(("" + (_vm.isRelatedSlidesFetchError ? _vm.$t('slideDetails.somethingWentWrong') : _vm.$t('slideDetails.noSimilarSlidesFound'))))+" ")])])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }