<template>
  <div
    :class="`icon-library-wrapper  ${
      isConvertingInProgress || localIconUploading ? 'item-disabled' : ''
    }`"
  >
    <v-tabs
      :class="`library-tabs ${this.noOfTabsEnabled}`"
      centered
      grow
      mobile-breakpoint
      v-model="currentTab"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            v-if="isFlatIconsLibraryEnabled"
            class="tab-item flat_icons-library-tab--auto"
            :href="'#' + flat_icons"
            @click="setTab(flat_icons)"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('generate.library') }}
          </v-tab>
        </template>
        <span>{{ $t('generate.library') }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            v-if="isBrandIconsEnabled"
            class="tab-item company-sourced-tab--auto"
            :href="'#' + brand"
            @click="setTab(brand)"
            v-bind="attrs"
            v-on="on"
          >
            {{ companyDisplayName }}
          </v-tab>
        </template>
        <span>{{ companyDisplayName }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            v-if="isCustomIconUploadEnabled"
            class="tab-item upload-image-tab--auto"
            :href="'#' + local"
            @click="setTab(local)"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('common.upload') }}
          </v-tab>
        </template>
        <span>{{ $t('common.upload') }}</span>
      </v-tooltip>
      <!-- <v-tooltip top v-if="isGenerate">
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            v-if="isGenerate"
            class="tab-item upload-image-tab--auto"
            :href="'#' + create"
            @click="setTab(local)"
            v-bind="attrs"
            v-on="on"
          >
            Create
          </v-tab>
        </template>
        <span>Create</span>
      </v-tooltip> -->
      <v-tabs-items v-model="currentTab">
        <v-tab-item v-if="isFlatIconsLibraryEnabled" :value="flat_icons">
          <FlatIconsLibrary
            class="icon-library-item ml-2"
            :selectedIconData="currentSelectedSlideIconData"
            :slideData="slideData"
            @iconSelected="iconSelected($event)"
            :isSearchActionTriggerredFromBrandIcons="
              isSearchActionTriggerredFromBrandIcons
            "
            :brandIconsSearchKey="brandIconsSearchKey"
            @iconSearch="iconSearch($event)"
            :isGenerate="isGenerate"
          >
          </FlatIconsLibrary>
        </v-tab-item>
        <v-tab-item v-if="isBrandIconsEnabled" :value="brand">
          <BrandIcons
            class="icon-library-item ml-2"
            :selectedIconData="currentSelectedSlideIconData"
            @iconSelected="iconSelected($event)"
            :onTryFlatIconsSearch="onTryFlatIconsSearch"
            :isFlatIconsLibraryEnabled="isFlatIconsLibraryEnabled"
            @iconSearch="iconSearch($event)"
            :slideData="slideData"
            :isGenerate="isGenerate"
          >
          </BrandIcons>
        </v-tab-item>
        <v-tab-item v-if="isCustomIconUploadEnabled" :value="local">
          <IconUpload
            @iconSelected="iconSelected($event)"
            class="icon-library-item ml-2"
            :selectedIconData="currentSelectedSlideIconData"
          >
          </IconUpload>
        </v-tab-item>
        <!-- <v-tab-item v-if="isGenerate" :value="create">
          <div>Create Image</div>
        </v-tab-item> -->
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import FlatIconsLibrary from './FlatIconsLibrary.vue';
import IconUpload from './IconUpload.vue';
import BrandIcons from './BrandIcons.vue';
import EventBus from '../../common/event-bus';
import {
  LOCAL_ICON_UPLOADING,
  LOCAL_ICON_UPLOADED,
  ICON_UPLOAD_FAILED,
  FLAT_ICONS,
  BRAND,
  LOCAL,
} from './replaceIconConstants.js';

export default {
  name: 'IconLibrary',
  data() {
    return {
      selectedIcon: null,
      localIconUploading: false,
      flat_icons: FLAT_ICONS,
      brand: BRAND,
      local: LOCAL,
      currentTab: FLAT_ICONS,
      isSearchActionTriggerredFromBrandIcons: false,
      brandIconsSearchKey: '',
    };
  },
  mounted() {
    EventBus.$on(LOCAL_ICON_UPLOADING, () => {
      this.localIconUploading = true;
    });
    EventBus.$on(LOCAL_ICON_UPLOADED, () => {
      this.localIconUploading = false;
    });
    EventBus.$on(ICON_UPLOAD_FAILED, () => {
      this.localIconUploading = false;
    });
    this.currentTab = this.getInitialCurrentTab();
  },
  props: {
    // Defines currently selected image that needs to be replaced
    currentSelectedSlideIconData: {
      type: Object,
      required: false,
    },
    isConvertingInProgress: {
      type: Boolean,
      required: false,
    },
    onIconSearch: {
      type: Function,
      default: () => {},
    },
    isGenerate: {
      type: Boolean,
      default: false,
    },
    slideData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('replaceIconStore', ['getSearchTermIcon']),
    isFlatIconsLibraryEnabled() {
      const flatIconLibrary = this.findFeatureBy(
        'replace_icon_prezent_library',
      );
      return flatIconLibrary?.enabled;
      // return false; // Disabling till further clarifications on falt icons api library intergation
    },

    isBrandIconsEnabled() {
      const brandImages = this.findFeatureBy('replace_icon_brand_library');
      return brandImages?.enabled;
    },
    isCustomIconUploadEnabled() {
      const customImage = this.findFeatureBy(
        'replace_icon_user_uploaded_icons',
      );
      return customImage?.enabled;
    },
    companyDisplayName() {
      return this.currentUser?.company?.displayName;
    },
    noOfTabsEnabled() {
      const numberMap = {
        1: 'one',
        2: 'two',
        3: 'three',
      };
      let noOfTabs = 0;
      if (this.isFlatIconsLibraryEnabled) {
        noOfTabs++;
      }
      if (this.isBrandIconsEnabled) {
        noOfTabs++;
      }
      if (this.isCustomIconUploadEnabled) {
        noOfTabs++;
      }
      return numberMap[noOfTabs];
    },
  },
  components: {
    FlatIconsLibrary,
    BrandIcons,
    IconUpload,
  },
  methods: {
    ...mapActions('replaceIconStore', ['setIconSearchData']),
    iconSelected(selectedIcon) {
      this.setIconSearchData({
        source: selectedIcon?.origin,
        searchTerm: this.getSearchTerm?.searchTerm,
      });
      this.selectedIcon = selectedIcon;
      this.$emit('iconSelected', {
        ...selectedIcon,
        seq_id: this.currentSelectedSlideIconData?.seq_id,
      });
    },
    iconSearch(searchData) {
      this.$emit('onIconSearch', searchData);
    },
    findFeatureBy(key) {
      return this.currentUser?.features?.find((f) => f.feature_name === key);
    },
    setTab(tab) {
      this.currentTab = tab;
      this.unSetBrandIconsSearchKeyForFlatIcons();
    },
    getInitialCurrentTab() {
      if (this.isFlatIconsLibraryEnabled) {
        return FLAT_ICONS;
      }
      if (this.isBrandIconsEnabled) {
        return BRAND;
      }
      if (this.isCustomIconUploadEnabled) {
        return LOCAL;
      }
      return '';
    },
    onTryFlatIconsSearch(searchKey) {
      this.brandIconsSearchKey = searchKey;
      this.isSearchActionTriggerredFromBrandIcons = true;
      this.currentTab = FLAT_ICONS;
    },
    unSetBrandIconsSearchKeyForFlatIcons() {
      this.brandIconsSearchKey = '';
      this.isSearchActionTriggerredFromBrandIcons = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-library-wrapper {
  .tab-item {
    display: block;
    font-size: 1rem;
    letter-spacing: normal;
    overflow: hidden;
    padding-top: 10px;
    text-decoration: none;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: nowrap !important;
    width: 90px;
  }

  .tab-content {
    letter-spacing: normal;
    text-decoration: none;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: nowrap !important;
    width: 90px;
  }

  .library-tabs.two {
    ::v-deep .v-tabs-slider-wrapper {
      max-width: 50%;
    }
  }
  .library-tabs.three {
    ::v-deep .v-tabs-slider-wrapper {
      max-width: 33%;
    }
  }
}
</style>
