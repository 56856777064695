<template>
  <div v-if="loading" class="loader" :class="{ large }">
    <v-skeleton-loader
      v-for="n in 5"
      :key="n"
      type="avatar"
      class="skeleton-loader"
    ></v-skeleton-loader>
  </div>
  <div
    v-else-if="randomUsers.length || count"
    class="users-list"
    :class="{ vertical: vertical }"
  >
    <div class="avatar-container" :class="{ large }" v-if="randomUsers.length">
      <div
        v-for="user in randomUsers"
        :key="user.id"
        class="avatar"
        @click="audienceClicked(user, 'avatar')"
      >
        <v-tooltip
          bottom
          max-width="200"
          content-class="tooltip-content"
          fixed
          v-if="user.profileImage === null || user.profileImage === ''"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="user-initials-avatar">
              {{
                `${user.firstName.charAt(0).toUpperCase()}${user.lastName
                  .charAt(0)
                  .toUpperCase()}`
              }}
            </div>
          </template>
          <span>
            {{ `${user.firstName} ${user.lastName}` }}
          </span>
        </v-tooltip>
        <v-tooltip
          bottom
          max-width="200"
          content-class="tooltip-content"
          fixed
          v-else
        >
          <template eager v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-img
                :key="user.profileImageUrl"
                :src="user.profileImageUrl"
                contain
                :alt="`${user.firstName} ${user.lastName} avatar`"
                class="user-profile-pic"
              />
            </div>
          </template>
          <div>
            <span class="userWithPicNames">
              {{ `${user.firstName} ${user.lastName}` }}
            </span>
          </div>
        </v-tooltip>
      </div>
    </div>
    <div class="user-desc" v-if="scope === 'global' || users.length === 0">
      {{ additionalUsers }}
    </div>
    <v-menu class="user-desc" bottom open-on-hover :nudge-bottom="15" v-else>
      <template v-slot:activator="{ on, attrs }">
        <div class="user-desc" v-bind="attrs" v-on="on">
          {{ additionalUsers }}
        </div>
      </template>
      <v-list class="user-menu">
        <v-list-item
          v-for="user in users"
          :key="user.id"
          @click="audienceClicked(user, 'drop-down')"
          >{{ user.firstName }} {{ user.lastName }}</v-list-item
        >
      </v-list>
    </v-menu>
  </div>
</template>
<script>
// import { mapGetters, mapState } from 'vuex';
// import { fpTypes } from '@/mock/fingerprint';
// import { getInitials } from '../../../utils/common';
import { mapState } from 'vuex';
import { getFileURL } from '@/utils/calyrex';
import { getFingerprintUsersInfo } from '../../../utils/api-helper';
import { trackFingerprintEvents } from '../../common/Analytics/FingerprintEvents';

export default {
  name: 'OtherColleagues',
  props: {
    scope: {
      type: String,
      default: 'global',
    },
    fingerprint: {
      type: String,
      default: 'director',
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      default: '',
    },
  },
  emits: ['audienceClicked', 'userCount'],
  watch: {
    scope() {
      this.getFPUserInfo();
    },
    fingerprint() {
      this.getFPUserInfo();
    },
    randomUsers(val) {
      val.forEach((user, i) => {
        if (i >= 4) {
          return;
        }
        user.profileImageUrl = '';
        if (user.profileImage && !user.profileImageUrl) {
          getFileURL(null, `public/${user.profileImage}`, null, 'storage').then(
            (s3obj) => {
              user.profileImageUrl = s3obj;
            },
          );
        }
      });
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    additionalUsers() {
      if (this.scope === 'global') {
        return this.getText(
          this.randomUsers.length,
          this.count - this.randomUsers.length,
          this.$t('fingerprint.audiencesAcross'),
          this.fingerprint.charAt(0).toUpperCase() + this.fingerprint.slice(1),
          this.randomUsers.length,
        );
      }
      return this.getText(
        this.randomUsers.length,
        this.users.length,
        this.scope === 'team'
          ? `team member${this.users.length > 1 ? 's' : ''}`
          : `colleague${this.users.length > 1 ? 's' : ''}`,
        this.fingerprint.charAt(0).toUpperCase() + this.fingerprint.slice(1),
      );
    },
  },
  data() {
    return {
      loading: true,
      users: [],
      count: 0,
      randomUsers: [],
    };
  },
  methods: {
    getText(avatarCount, additionalCount, scopeText, fp, showPlus = true) {
      let text = '';
      if (additionalCount > 0) {
        if (showPlus) {
          text += '+';
        }
        text += `${additionalCount} ${scopeText} `;
      }
      text +=
        additionalCount <= 1 && avatarCount <= 1
          ? this.$t('fingerprint.is', { fingerprints: fp })
          : this.$t('fingerprint.are', { fingerprints: fp });

      return text;
    },
    audienceClicked(user, clickType) {
      const item = user;
      if (this.origin === 'zentrends') {
        this.$router.push(
          `/home/fingerprints?username=${user.id}&type=user`,
          () => {},
        );
        if (clickType === 'avatar') {
          trackFingerprintEvents.fingerprintsMyFingerprintSimilarFingerprintsSelected(
            this.currentUser,
          );
        }
        if (clickType === 'drop-down') {
          trackFingerprintEvents.fingerprintsMyFingerprintSimilarFingerprintsDropdwonSelected(
            this.currentUser,
          );
        }
      } else {
        if (item?.fingerprintType?.toLowerCase() === 'standard') {
          this.$router
            .push({
              name: 'fingerprints',
              query: {
                username: item.id,
                type: 'standard',
              },
            })
            .catch(() => {});
          return;
        }
        if (item.isShared && item.audienceType !== 'user') {
          this.$router
            .push({
              name: 'fingerprints',
              query: {
                uuid: item.uuid,
                type:
                  item.audienceType === 'audience'
                    ? 'audience'
                    : 'private_audience',
              },
            })
            .catch(() => {});
          return;
        }
        if (item.audienceType === 'user' && item.isShared) {
          this.$router
            .push({
              name: 'fingerprints',
              query: {
                username: item.id,
                type: 'user',
              },
            })
            .catch(() => {});
          return;
        }
        if (item.isShared) {
          this.$router
            .push({
              name: 'fingerprints',
              query: {
                uuid: item.uuid,
              },
            })
            .catch(() => {});
          return;
        }
        if (item.userID) {
          // audience has userID
          if (item.isPrivateAudience) {
            this.$router.push({
              name: 'fingerprints',
              query: {
                username: item.id,
                type: 'private_audience',
              },
            });
          } else {
            this.$router.push({
              name: 'fingerprints',
              query: {
                username: item.id,
                type: 'audience',
              },
            });
          }
        } else {
          this.$router.push({
            name: 'fingerprints',
            query: {
              username: item.id,
              type: 'user',
            },
          });
        }
      }
    },
    getFPUserInfo() {
      this.count = 0;
      this.loading = true;
      if (this.scope === 'global') {
        Promise.all([
          getFingerprintUsersInfo(this.scope, this.fingerprint),
          getFingerprintUsersInfo('company', this.fingerprint),
        ]).then((resp) => {
          const users = resp[1].data.filter(
            (user) => user.id !== this.currentUser.user.id,
          );
          this.shuffle(users);
          this.users = users;
          this.count = resp[0].data.count;
          this.loading = false;
        });
      } else {
        getFingerprintUsersInfo(this.scope, this.fingerprint).then((resp) => {
          const users = resp.data.filter(
            (user) => user.id !== this.currentUser.user.id,
          );
          this.users = users;
          this.$emit('userCount', this.users.length);
          this.shuffle(users);
          this.loading = false;
        });
      }
    },
    shuffle(unshuffled) {
      if (unshuffled.length <= 5) {
        this.randomUsers = unshuffled
          .splice(0, unshuffled.length)
          .sort(() => 0.5 - Math.random());
        return;
      }
      const users = [];
      while (users.length < 5) {
        users.push(
          // eslint-disable-next-line no-bitwise
          unshuffled.splice((Math.random() * unshuffled.length) | 0, 1)[0],
        );
      }
      this.randomUsers = users;
    },
  },
  mounted() {
    this.getFPUserInfo();
  },
};
</script>
<style scoped lang="scss">
.user-menu {
  max-height: 300px;
  overflow: auto;
  padding: 12px 0px;
  gap: 4px;
  display: flex;
  flex-direction: column;
}
.loader {
  position: relative;
  display: flex;
  justify-content: flex-start;
  min-height: 36px;
  margin-left: 10px;

  &.large {
    margin-left: 20px;

    .skeleton-loader {
      margin-left: -20px;
      width: 52px;
      height: 52px;

      ::v-deep .v-skeleton-loader__avatar {
        width: 52px;
        height: 52px;
      }
    }
  }

  .skeleton-loader {
    margin-left: -10px;
    width: 26px;
    height: 26px;

    ::v-deep .v-skeleton-loader__avatar {
      background: rgb(209 209 209 / 81%) !important;
      border: 1px solid #bcbcbccc;
      width: 26px;
      height: 26px;
    }
  }
}

.users-list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  min-height: 36px;

  &.vertical {
    flex-direction: column;
    gap: 2px;

    .user-desc {
      text-align: center;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .avatar-container {
    display: flex;
    margin-left: 10px;

    &.large {
      margin-left: 20px;

      .avatar,
      .user-profile-pic,
      .user-initials-avatar {
        width: 52px;
        height: 52px;
        font-size: 20px;
      }

      .avatar {
        margin-left: -20px;
      }
    }
    .avatar {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      border: 0.650001px solid white;
      margin-left: -10px;
      background: #21a7e0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 3;
    }
    .user-initials-avatar {
      width: 26px;
      height: 26px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 11.627px;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }

    .user-profile-pic {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: white;
    }
  }
  .user-desc {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #7b7b7b;
  }
}
</style>
