<template>
  <div class="sf">
    <div class="sf__header">
      <h2 class="sf__title">{{ $t('synthesize.formats.title') }}</h2>
      <p class="sf__subtitle">{{ $t('build.step3.poweredByZenSence') }}</p>
      <div class="sf__text">{{ $t('synthesize.formats.description') }}</div>
    </div>
    <div class="sf__body">
      <v-radio-group v-model="selectedIndex">
        <v-expansion-panels flat accordion v-model="panelValue">
          <v-expansion-panel v-for="(format, i) in formats" :key="i">
            <v-expansion-panel-header class="sf-list__panel_header">
              <div class="sf-list__header">
                <v-radio
                  class="sf-list__input"
                  :value="i"
                  @click.stop
                  :ripple="false"
                ></v-radio>
                <div>
                  {{ metadata[format.key].label }}
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="sf-list__content">
                <p class="sf-list__description">
                  {{ $t(metadata[format.key].description) }}
                </p>
                <img
                  class="sf-list__image"
                  :src="metadata[format.key].thumbnail"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-radio-group>
    </div>
    <div class="sf__footer">
      <v-btn
        rounded
        color="primary"
        class="sf__button"
        :disabled="
          buttonText !== 'Synthesize' && initialIndex === selectedIndex
        "
        @click="handleSummaryChange"
        >{{ buttonText }}</v-btn
      >
    </div>
  </div>
</template>

<script>
// import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { SummaryFormatMetadata, SummaryFormat } from './SynthesizeUtills';

export default {
  name: 'SummaryFormats',
  data() {
    return {
      metadata: SummaryFormatMetadata,
      formats: [],
      selectedIndex: 0,
      panelValue: 0,
    };
  },
  props: {
    selectedSummary: {
      type: String,
      default: '',
    },

    buttonText: {
      type: String,
      default: 'Resynthesize',
    },
  },
  inject: ['getSummaryFormats'],
  // components: { ClipLoader },
  computed: {
    summaryFormats() {
      return this.getSummaryFormats();
    },
    initialIndex() {
      const idx = this.formats.findIndex((i) => i.key === this.selectedSummary);
      return idx > 0 ? idx : 0;
    },
  },
  watch: {
    summaryFormats: {
      immediate: true,
      deep: true,
      handler() {
        this.formats = [
          SummaryFormat.SimplePointers,
          SummaryFormat.ContextCore,
          SummaryFormat.CurrentStatus,
          SummaryFormat.SituationComplication,
          SummaryFormat.What,
          SummaryFormat.SinceWeSpoke,
        ].map((format) => {
          const slide = this.summaryFormats[format];
          return {
            key: format,
            slide,
          };
        });
        console.log('formats', this.formats);
      },
    },
    selectedIndex() {
      this.panelValue = this.selectedIndex;
    },
  },
  methods: {
    handleSummaryChange() {
      this.$emit('change', {
        type: 'format_change',
        value: this.formats[this.selectedIndex].key,
      });
    },
  },
  mounted() {
    this.selectedIndex = this.initialIndex;
    this.panelValue = this.selectedIndex;
  },
};
</script>

<style lang="scss" scoped>
.sf {
  background: $white-default;
  padding: 20px;

  &__header {
    margin-bottom: 10px;
  }

  &__title {
    font-weight: 700;
    line-height: 24px;
  }
  &__subtitle {
    font-family: 'Lato';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #697077;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  &__text {
    font-size: 16px;
  }
  &__button {
    padding: 6px 16px;
    width: auto;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: normal;
    line-height: 19px;
    color: #ffffff;
    background: #21a7e0;
    border-radius: 25px;
  }
}

.sf-list {
  &__panel_header {
    padding: 16px 0;
  }
  &__header {
    display: flex;
    font-weight: 600;
    color: #075689 !important;
    align-items: center;
  }
  &__input {
    margin-bottom: 0 !important;
  }
  &__content {
    padding: 0 22px;
  }
  &__description {
    font-size: 15px;
  }
  &__image {
    border: 1px solid #c1c7cd;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    aspect-ratio: 16/9;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
</style>
