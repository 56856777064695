<template>
  <v-container fluid>
    <div class="slide-detail-wrapper">
      <div class="slide-detail-inner">
        <SlideDetail
          ref="slideDetailWrapper"
          :favorite-slides="favoriteSlides"
          :handleFavorite="handleFavorite"
          :handleUpdateActiveSlide="handleUpdateActiveSlide"
          :audience="audience"
          :handleDownload="handleDownload"
          :logDownload="logDownload"
          :savedQueryPayload="savedQueryPayload"
          :dialog-content="selectedSlide"
          :from="origin"
          :slideType="slideType"
          :processingFavSlide="processingFavSlide"
          :downloadingSlide="downloadingSlide"
          :slideViewOrigin="slideViewOrigin"
          :relatedWord="relatedWord"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
// import SlideDetail from '@/components/common/SlideDetail';
import SlideDetail from './slide-detail/SlideDetailV2';
import { SLIDES } from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'SlideDetailWrapper',
  provide() {
    return {
      isImageReplaceWalkThrough: this.isImageReplaceWalkThrough,
    };
  },
  data() {
    return {
      origin: SLIDES,
    };
  },
  components: {
    SlideDetail,
  },
  computed: {},
  props: {
    favoriteSlides: {
      type: Array,
    },
    audience: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    savedQueryPayload: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    handleDownload: {
      type: Function,
      required: true,
    },
    handleFavorite: {
      type: Function,
      required: true,
    },
    handleUpdateActiveSlide: {
      type: Function,
      required: true,
    },
    logDownload: {
      type: Function,
      required: true,
    },
    selectedSlide: {
      type: Object,
    },
    slideViewOrigin: {
      type: String,
      default: '',
    },
    slideType: {
      type: String,
      default: '',
    },
    processingFavSlide: {
      type: Boolean,
      default: false,
    },
    downloadingSlide: {
      type: Boolean,
      default: false,
    },
    relatedWord: {
      type: String,
      default: '',
    },
  },
  mounted() {},
  methods: {
    leaveComponent(next) {
      this.$refs.slideDetailWrapper?.leaveComponent(next);
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-detail-wrapper {
  border-radius: 10px;
  // box-shadow: $item-box-shadow;
  height: 100%;
  min-width: 922px;
  overflow: auto;
  padding: 14px;
  position: relative;
  width: 100%;
}

.slide-detail-inner {
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 8px;

  & > div {
    height: 100%;
  }
}
</style>
