var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.type === 'modal' ? 'main-wrapper-modal' : 'main-wrapper'},[(_vm.type === 'modal')?_c('v-btn',{staticClass:"close-btn",attrs:{"icon":""},on:{"click":_vm.handleClose}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s('mdi-close'))])],1):_vm._e(),(_vm.isLoading)?_c('div',{class:_vm.type === 'modal' ? 'spinner-wrapper-on-modal' : 'spinner-wrapper'},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.type === 'modal' ? '#21a7e0' : 'primary',"size":35}})],1):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"sharing-modal-main-wrapper",style:(_vm.type === 'modal'
        ? {}
        : { height: (_vm.mainSlideHeight + "px"), overflow: 'auto', padding: '0px' })},[_c('div',{staticClass:"generalTemplateShare"},[_c('div',{staticClass:"step1"},[_c('div',{staticClass:"sharing-modal-container"},[(_vm.type === 'modal')?_c('div',{staticClass:"sharing-modal__title font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('slideDetails.shareWithColleague'))+" ")]):_vm._e(),_c('v-radio-group',{staticStyle:{"height":"67px"},model:{value:(_vm.shareOptionType),callback:function ($$v) {_vm.shareOptionType=$$v},expression:"shareOptionType"}},[_c('v-radio',{attrs:{"color":"#20a7e0","name":"shareType","label":_vm.$t('slideDetails.private'),"value":"me"}}),_c('v-radio',{staticStyle:{"padding-top":"3px"},attrs:{"color":"#20a7e0","name":"shareType","label":_vm.$t('slideDetails.shareWith'),"value":"share"}})],1),_c('div',{staticClass:"share-container"},[_c('div',{staticClass:"radio-button_item"},[_c('v-checkbox',{attrs:{"label":_vm.$t('slideDetails.myCompany'),"color":"#20a7e0","hide-details":"","value":"company","disabled":_vm.shareOptionType !== 'share'},model:{value:(_vm.companyCheckPermission),callback:function ($$v) {_vm.companyCheckPermission=$$v},expression:"companyCheckPermission"}}),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.companyCheckPermission === 'company'),expression:"companyCheckPermission === 'company'"}],staticClass:"share-option-select_dropdown",attrs:{"menu-props":{ bottom: true, offsetY: true },"dense":"","color":"rgba(0, 0, 0, 0.6)","item-text":"text","item-value":"value","items":_vm.shareOptions},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.getText(item.text))))])])]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"share-select-item__selected"},[(_vm.companyPermission === item.text)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-check ")]):_vm._e()],1)]),(item.text === 'Restricted')?_c('div',{staticClass:"d-flex flex-column share-select-item"},[_c('NewFeatureBadge',{attrs:{"module-name":'restricted',"feature-id":'restricted_id',"offsetX":'140',"offsetY":'15',"size":'medium'}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(_vm.getText(item.text)))+" ")])]),(item.description)?_c('div',{staticClass:"text-caption share-select-item__description"},[_vm._v(" "+_vm._s(_vm.$t(item.description))+" ")]):_vm._e()],1):_c('div',{staticClass:"d-flex flex-column share-select-item"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(_vm.getText(item.text)))+" ")]),(item.description)?_c('div',{staticClass:"text-caption share-select-item__description"},[_vm._v(" "+_vm._s(_vm.$t(item.description))+" ")]):_vm._e()])]}}],null,false,1975131779),model:{value:(_vm.companyPermission),callback:function ($$v) {_vm.companyPermission=$$v},expression:"companyPermission"}})],1),_c('div',{staticClass:"radio-button_item"},[_c('v-checkbox',{attrs:{"label":_vm.$t('slideDetails.myTeam'),"color":"#20a7e0","hide-details":"","value":"team","disabled":_vm.shareOptionType !== 'share'},model:{value:(_vm.teamCheckPermission),callback:function ($$v) {_vm.teamCheckPermission=$$v},expression:"teamCheckPermission"}}),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.teamCheckPermission === 'team'),expression:"teamCheckPermission === 'team'"}],staticClass:"share-option-select_dropdown",attrs:{"menu-props":{ bottom: true, offsetY: true },"dense":"","color":"rgba(0, 0, 0, 0.6)","item-text":"text","item-value":"value","items":_vm.shareOptions},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.getText(item.text))))])])]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"share-select-item__selected"},[(_vm.teamPermission === item.text)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-check ")]):_vm._e()],1)]),(item.text === 'Restricted')?_c('div',{staticClass:"d-flex flex-column share-select-item"},[_c('NewFeatureBadge',{attrs:{"module-name":'restricted',"feature-id":'restricted_id',"offsetX":'140',"offsetY":'15',"size":'medium'}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(_vm.getText(item.text)))+" ")])]),(item.description)?_c('div',{staticClass:"text-caption share-select-item__description"},[_vm._v(" "+_vm._s(_vm.$t(item.description))+" ")]):_vm._e()],1):_c('div',{staticClass:"d-flex flex-column share-select-item"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(_vm.getText(item.text)))+" ")]),(item.description)?_c('div',{staticClass:"text-caption share-select-item__description"},[_vm._v(" "+_vm._s(_vm.$t(item.description))+" ")]):_vm._e()])]}}],null,false,2555503257),model:{value:(_vm.teamPermission),callback:function ($$v) {_vm.teamPermission=$$v},expression:"teamPermission"}})],1),_c('v-checkbox',{attrs:{"label":_vm.$t('slideDetails.selectColleague'),"color":"#20a7e0","hide-details":"","value":"user","disabled":_vm.shareOptionType !== 'share'},model:{value:(_vm.userCheckPermission),callback:function ($$v) {_vm.userCheckPermission=$$v},expression:"userCheckPermission"}}),(_vm.userCheckPermission === 'user')?_c('div',{attrs:{"label":_vm.$t('slideDetails.selectColleague')}},[_c('UserSelector',{staticClass:"select-colleagues-autocomplete",attrs:{"filter":"insideCompanyUsers","disabled":_vm.shareOptionType !== 'share'},on:{"change":function($event){return _vm.onSelectedUsersChange($event)}},scopedSlots:_vm._u([{key:"user-actions",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-action',{class:_vm.currentUser.user.id === item.id
                        ? 'currentUserRemove'
                        : 'colleaguesRemove'},[_c('v-select',{staticClass:"share-option-select_dropdown text-caption",attrs:{"menu-props":{ bottom: true, offsetY: true },"color":"rgba(0, 0, 0, 0.6)","dense":"","item-text":"text","item-value":"value","items":_vm.userShareOptions,"name":"selectItem","disabled":_vm.currentUser.user.id === item.id},scopedSlots:_vm._u([{key:"selection",fn:function(selectSlotItem){return [_vm._v(" "+_vm._s(_vm.$t(_vm.getText(selectSlotItem.item.text)))+" ")]}},{key:"item",fn:function(selectSlotItem){return [_c('div',{attrs:{"lass":"d-flex"}},[_c('div',{staticClass:"share-select-item__selected"},[(
                                item.permissionLevel ===
                                selectSlotItem.item.text
                              )?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-check ")]):_vm._e()],1)]),(selectSlotItem.item.text === 'Restricted')?_c('div',{staticClass:"d-flex flex-column share-select-item"},[_c('NewFeatureBadge',{attrs:{"module-name":'restricted',"feature-id":'restricted_id',"offsetX":'140',"offsetY":'15',"size":'medium'}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(_vm.getText(selectSlotItem.item.text)))+" ")])]),(selectSlotItem.item.description)?_c('div',{staticClass:"text-caption share-select-item__description"},[_vm._v(" "+_vm._s(_vm.$t(selectSlotItem.item.description))+" ")]):_vm._e()],1):_c('div',{staticClass:"d-flex flex-column share-select-item"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(_vm.getText(selectSlotItem.item.text)))+" ")]),(selectSlotItem.item.description)?_c('div',{staticClass:"text-caption share-select-item__description"},[_vm._v(" "+_vm._s(_vm.$t(selectSlotItem.item.description))+" ")]):_vm._e()])]}}],null,true),model:{value:(item.permissionLevel),callback:function ($$v) {_vm.$set(item, "permissionLevel", $$v)},expression:"item.permissionLevel"}})],1)]}}],null,false,580923538),model:{value:(_vm.usersWithPermissions),callback:function ($$v) {_vm.usersWithPermissions=$$v},expression:"usersWithPermissions"}})],1):_vm._e()],1)],1),(_vm.type === 'modal')?_c('div',{staticClass:"actions"},[(_vm.updateWithParentCallback)?_c('v-btn',{staticClass:"primary-button",attrs:{"rounded":"","color":"#21a7e0","height":"48","min-width":"134","loading":_vm.sharingSlidePermissions,"disabled":_vm.disableUpdateBtn},on:{"click":_vm.handleShareFromParent}},[_vm._v(" "+_vm._s(_vm.$t('slideDetails.update'))+" ")]):_c('v-btn',{staticClass:"primary-button",attrs:{"rounded":"","color":"#21a7e0","height":"48","min-width":"134","loading":_vm.updating,"disabled":_vm.disableUpdateBtn},on:{"click":_vm.updateTemplateShareInfo}},[_vm._v(" "+_vm._s(_vm.$t('slideDetails.update'))+" ")])],1):_vm._e()])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }