<template>
  <div class="sdv" :class="{ 'right-panel-open': rightPanelOpen }">
    <SynthesizeDeckThumbnails
      :slides="slides"
      :error="error"
      :loading="!loader.isIdle()"
      :slideIndex="slideIndex"
      @slideIndexChange="handleIndexChange"
    />
    <SynthesizeDeckContent
      :slides="slides"
      :error="error"
      :loader="loader"
      :filename="filename"
      :templateName="templateName"
      :slideIndex="slideIndex"
      @endLoading="endLoading"
    />
  </div>
</template>

<script>
import SynthesizeDeckThumbnails from './SynthesizeDeckThumbnails.vue';
import SynthesizeDeckContent from './SynthesizeDeckContent.vue';

export default {
  name: 'SynthesizeDeckViewer',
  components: {
    SynthesizeDeckThumbnails,
    SynthesizeDeckContent,
  },
  data() {
    return {
      slideIndex: 0,
    };
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    loader: {
      type: Object,
      required: true,
    },
    rightPanelOpen: {
      type: Boolean,
    },
    filename: {
      type: Array,
      default: () => [],
    },
    templateName: {
      type: Object,
      default: null,
    },
  },
  methods: {
    handleIndexChange(index) {
      this.slideIndex = index;
    },
    endLoading() {
      this.$emit('endLoading');
    },
  },
};
</script>

<style lang="scss" scoped>
.sdv {
  display: grid;
  grid-template-columns: 364px 1fr;
  height: 100%;
  gap: 20px;
  // overflow: hidden;
  transition: 0.4s;

  &.right-panel-open {
    grid-template-columns: 140px 1fr;
    gap: 10px;
  }
}
</style>
