<template>
  <div class="deleteDialogContainer">
    <div class="closeIconContainer">
      <v-icon @click="handleClose">{{ 'mdi-close' }}</v-icon>
    </div>
    <div class="headingContainer">
      {{ $t('build.step3.deleteSlide') }}
    </div>
    <div class="msgContainer">
      <span>
        {{ $t('slides.deleteWarning') }}
        <b>{{ slide.name }}</b>
        {{ '?' }}
      </span>
    </div>
    <div class="actionsContainer">
      <div class="buttonsContainer">
        <v-btn @click="handleClose" class="cnclBtn">{{
          $t('common.cancel')
        }}</v-btn>
        <v-btn @click="handleConfirm" class="delBtn" :loading="deleting">{{
          $t(btnText)
        }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteSlide } from '../../../utils/api-helper';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents';

export default {
  name: 'SlideDeleteDialog',
  data() {
    return {
      deleting: false,
      btnText: 'common.delete',
    };
  },
  props: {
    slide: {
      type: Object,
      default: () => ({}),
    },
    removeSlideOnDelete: {
      type: Function,
      default: () => {},
    },
    currentUser: {
      type: Object,
      default: () => ({}),
    },
    slideOrigin: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClose() {
      this.$modal.hide('SlideDeleteDialog');
    },
    async handleConfirm() {
      this.deleting = true;
      await deleteSlide(this.slide.unique_id)
        .then(() => {
          this.deleting = false;
          this.btnText = 'common.deleted';
          setTimeout(() => {
            this.removeSlideOnDelete(this.slide, this.slideOrigin);
            this.handleClose();
            this.btnText = 'common.delete';
          }, 1000);
          trackSlideEvents.slidesDeleteDelete(this.slide, this.currentUser, {});
        })
        .catch((err) => {
          this.deleting = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.deleteDialogContainer {
  padding: 24px;
  .closeIconContainer {
    position: absolute;
    top: 5px;
    right: 10px;
  }
  .headingContainer {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: normal;
    margin: 0px 0px 20px 0px;
  }
  .msgContainer {
    font-size: 16px;
    margin: 0px 0px 20px 0px;
  }
  .actionsContainer {
    display: flex;
    justify-content: flex-end;
    .buttonsContainer {
      width: 38%;
      display: flex;
      justify-content: space-between;
      .cnclBtn,
      .delBtn {
        box-shadow: none;
        border-radius: 20px;
        letter-spacing: normal;
        text-transform: none;
      }
      .cnclBtn {
        background: #21a7e0;
        color: #ffffff;
      }
      .delBtn {
        background: #ffffff;
        color: #21a7e0;
        border: 1px solid #21a7e0;
      }
    }
  }
}
</style>
