var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bestPractisesContainer",style:({
    height: ("" + (_vm.mainSlideHeight ? (_vm.mainSlideHeight + "px") : '100%')),
  })},[_c('div',{staticClass:"bestPractisesListAndBtnContainer"},[(_vm.getBestPractisesOnSlide.length > 0)?_c('div',{staticClass:"bestPractisesListContainer"},_vm._l((_vm.getBestPractisesOnSlide.filter(
          function (item) { return item.isAdded; }
        )),function(bs,index){return _c('div',{key:index,class:("" + (_vm.getBestPractiseSelected.name === bs.name &&
          (_vm.isOwner || bs.isNew) &&
          _vm.isBestPractiseToggleActive
            ? 'bestPractiseListItemSelected'
            : 'bestPractiseListItem')),on:{"click":function($event){(_vm.isOwner || bs.isNew) && _vm.isBestPractiseToggleActive
            ? _vm.handlerSetBestPractiseSelected(bs)
            : null}}},[_c('div',{staticClass:"bestPractiseNumber"},[_vm._v(_vm._s(bs.name))]),(!bs.isEditing)?_c('div',{staticClass:"d-flex justify-space-between align-center bestPractiseContent"},[_c('p',{staticClass:"bsText"},[_vm._v(_vm._s(bs.tooltip))]),_c('div',{staticClass:"actions"},[_c('v-icon',{on:{"click":function($event){return _vm.handleEnterEditingMode(bs)}}},[_vm._v(_vm._s('mdi-pencil'))]),_c('v-icon',{on:{"click":function($event){return _vm.handleOpenBestPractiseRemoveDialog(bs)}}},[_vm._v(_vm._s('mdi-delete'))])],1)]):_c('v-text-field',{staticClass:"editBsText",attrs:{"autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (e) { return _vm.handleUpdateBestPractise(e, bs); }).apply(null, arguments)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"disabled":bs.tooltip === ''},on:{"click":function (e) { return _vm.handleUpdateBestPractise(e, bs); }}},[_vm._v(" "+_vm._s('mdi-play')+" ")]),_c('v-icon',{staticClass:"pr-3",attrs:{"small":""},on:{"click":function($event){return _vm.handleRemoveEditingMode(bs)}}},[_vm._v(" "+_vm._s('mdi-close')+" ")])]},proxy:true}],null,true),model:{value:(bs.tooltip),callback:function ($$v) {_vm.$set(bs, "tooltip", $$v)},expression:"bs.tooltip"}})],1)}),0):_vm._e(),_c('div',{staticClass:"addBestPractiseBtnContainer"},[_c('v-btn',{attrs:{"disabled":_vm.getBestPractisesOnSlide.length >= 5 ||
          !_vm.isBestPractiseToggleActive ||
          _vm.getBestPractisesOnSlide.filter(function (item) { return !item.isAdded; }).length > 0},on:{"click":_vm.handleAddBestPractiseClick}},[_c('v-icon',[_vm._v(_vm._s('mdi-plus'))]),_vm._v(" "+_vm._s(_vm.$t('userUploadPrezentation.addBestPractice'))+" ")],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }