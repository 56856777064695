<template>
  <div class="explore-section" ref="explore">
    <div class="header-section">
      <div class="title-section">
        <h2>{{ $t('fingerprint.exploreFingerprint.exploreFingerprints') }}</h2>
        <div>
          {{ $t('fingerprint.exploreFingerprint.exploreFingerprintText') }}
        </div>
      </div>
      <div class="category">
        <v-radio-group
          v-model="level"
          row
          @change="scopeChanged()"
          data-pendo-id="fingerprint-distribution"
        >
          <v-radio
            :label="$t('fingerprint.exploreFingerprint.global')"
            value="global"
            dense
            :ripple="false"
          ></v-radio>
          <v-radio
            :label="$t('fingerprint.exploreFingerprint.company')"
            value="company"
            dense
            :ripple="false"
          ></v-radio>
          <v-radio
            :label="$t('fingerprint.exploreFingerprint.team')"
            value="team"
            dense
            :ripple="false"
          ></v-radio>
        </v-radio-group>
      </div>
    </div>
    <div
      class="learn-more"
      id="explore-section"
      @click="handleLearnMoreFingerprints()"
      data-pendo-id="fingerprint-learn"
    >
      {{ $t('fingerprint.exploreFingerprint.learnMoreAboutFingerprints') }}
    </div>
    <div class="fingerprints-header">
      {{ $t('fingerprint.exploreFingerprint.fingerprintDistribution') }}
      {{ $t(verbiage[level]) }}
    </div>
    <div class="fingerprints-distribution">
      <div
        v-for="ftype in fingerprintTypes"
        :key="ftype.title"
        class="fingerprint-item"
        :data-pendo-id="ftype.title"
      >
        <div class="fingerprint-name">{{ ftype.title }}</div>

        <div
          class="logo-fingerprint"
          :class="{
            'logo-fingerprint-selected':
              ftype.title ===
              (selectedFingerprint && selectedFingerprint.title),
          }"
          @click="selectFingerprint(ftype, true)"
        >
          <svg viewBox="0.5 0.5 35 35" class="circular-chart">
            <path
              class="circle-bg"
              d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              v-if="distribution[ftype.title] > 0"
              class="circle"
              :stroke-dasharray="`${distribution[ftype.title]}, 100`"
              d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <image
              :href="`${ftype.logo_transparent}`"
              height="30"
              width="30"
              x="3"
              y="3"
            />
          </svg>
        </div>
        <div>{{ distribution[ftype.title] }}%</div>
      </div>
    </div>
    <Transition name="fadeHeight">
      <div class="selected-fingerprint-container" v-if="selectedFingerprint">
        <v-icon @click="selectedFingerprint = null" class="close-icon">
          mdi-close
        </v-icon>
        <div class="fingerprint-info" data-pendo-id="fingerprint-info">
          <div class="header">
            <img
              :src="selectedFingerprint.logo"
              alt="Fingerprint-Logo"
              class="logo-fingerprint"
            />
            <div class="fp-info">
              <div class="fp-title">{{ selectedFingerprint.title }}s</div>
              <div class="fp-description">
                {{ selectedFingerprint.description }}
              </div>
              <OtherColleagues
                :scope="this.level"
                :fingerprint="selectedFingerprint.title"
                @audienceClicked="handleAudienceClicked"
              ></OtherColleagues>
            </div>
          </div>
          <div class="fp-preferences">
            <div
              class="preference"
              v-for="pref in selectedFingerprint.preferences"
              :key="pref.title"
            >
              <img
                :src="pref.logo"
                alt="Fingerprint-pref"
                class="preference-logo"
              />
              <div>{{ $t(pref.title) }}</div>
              <div>{{ pref.preference }}</div>
            </div>
          </div>
          <div class="fp-dos-container">
            <div class="fp-dos-heading">
              {{ $t('fingerprint.exploreFingerprint.howToPresent')
              }}{{
                selectedFingerprint.title.toLowerCase() === 'architect'
                  ? $t('fingerprint.exploreFingerprint.n')
                  : ''
              }}
              <span class="capitalize">{{ selectedFingerprint.title }}</span
              >?
            </div>
            <div
              v-for="(doItem, i) in selectedFingerprint.dos"
              :key="i"
              class="fp-dos-item"
            >
              <img
                :src="'/assets/img/dos-icon.svg'"
                alt="dos-icon"
                class="dos-icon"
              />
              <div>{{ doItem }}</div>
            </div>
          </div>
          <div class="fp-dos-container">
            <div class="fp-dos-heading">
              {{ $t('fingerprint.exploreFingerprint.howToNotPresent')
              }}{{
                selectedFingerprint.title.toLowerCase() === 'architect'
                  ? $t('fingerprint.exploreFingerprint.n')
                  : ''
              }}
              <span class="capitalize">{{ selectedFingerprint.title }}</span
              >?
            </div>
            <div
              v-for="(doItem, i) in selectedFingerprint.donts"
              :key="i"
              class="fp-dos-item"
            >
              <img
                :src="'/assets/img/dont-icon.svg'"
                alt="dos-icon"
                class="dos-icon"
              />
              <div>{{ doItem }}</div>
            </div>
          </div>
        </div>
        <div
          class="fingerprint-video-container"
          data-pendo-id="fingerprint-content"
        >
          <video
            :key="selectedFingerprint.title"
            class="video-js vjs-default-skin video-content-section__video-player"
            data-setup="{'fluid': true}"
            ref="video"
            height="60%"
            :poster="selectedFingerprint.videoThumbnailURL"
            controls
            @volumechange="updateVolume"
            @play="onVideoPlay"
          />
          <div class="fp-scope-info">
            <div class="fp-scope-info__item">
              <img :src="'/assets/img/fp-global.svg'" alt="global-icon" />
              <div class="fp-scope-info__item__value">
                {{ distribution[selectedFingerprint.title] }}%
              </div>
              <div class="fp-scope-info__item__descp">
                {{ fpInfo.distribution }}
              </div>
            </div>
            <div class="fp-scope-info__item">
              <img
                :src="'/assets/img/fp-slides.svg'"
                alt="slides-downloaded-icon"
              />
              <div class="fp-scope-info__item__value">
                {{ formatCounts(count.slidesCount) }}
              </div>
              <div class="fp-scope-info__item__descp">{{ fpInfo.slides }}</div>
            </div>
            <div class="fp-scope-info__item">
              <img
                :src="'/assets/img/fp-prezentations.svg'"
                alt="prezentations-Count-icon"
              />
              <div class="fp-scope-info__item__value">
                {{ formatCounts(count.prezentationsCount) }}
              </div>
              <div class="fp-scope-info__item__descp">
                {{ fpInfo.prezentations }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
import Hls from 'hls.js';
import { mapState, mapActions } from 'vuex';
import { fpTypes, fpLearnFingerprints } from '@/mock/fingerprint';
import {
  getFingerprintDistribution,
  getFingerprintInfo,
} from '../../../utils/api-helper';
import OtherColleagues from './OtherColleagues.vue';
import { getFileURL } from '@/utils/calyrex';
import EventBus from '../../common/event-bus';
import VideoModal from './VideoModal.vue';
import { trackFingerprintEvents } from '../../common/Analytics/FingerprintEvents';
import {
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_FINGERPRINT_TYPE,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import usersApi from '../../../API/users-api.js';

export default {
  name: 'ExploreFingerprint',
  data() {
    return {
      level: 'global',
      distribution: {},
      selectedFingerprint: null,
      hls: new Hls(),
      count: {},
      verbiage: {
        global: 'fingerprint.exploreFingerprint.acrossPrezent',
        team: 'fingerprint.exploreFingerprint.inYourTeam',
        company: 'fingerprint.exploreFingerprint.inYourCompany',
      },
    };
  },
  components: { OtherColleagues },
  computed: {
    ...mapState('users', ['currentUser']),
    fingerprintTypes() {
      return fpTypes
        .filter((i) => i.public)
        .sort((a, b) => a.title.localeCompare(b.title));
    },
    fpInfo() {
      if (!this.selectedFingerprint) {
        return { distribution: '', slides: '', prezentations: '' };
      }
      return {
        distribution: this.$t('fingerprint.exploreFingerprint.distribution', {
          audience: this.$t(this.verbiage[this.level]),
          title:
            this.selectedFingerprint.title.charAt(0).toUpperCase() +
            this.selectedFingerprint.title.slice(1),
        }),
        slides: this.$t('fingerprint.exploreFingerprint.slides', {
          audience: this.$t(this.verbiage[this.level]),
          title:
            this.selectedFingerprint.title.charAt(0).toUpperCase() +
            this.selectedFingerprint.title.slice(1),
        }),
        prezentations: this.$t('fingerprint.exploreFingerprint.prezentations', {
          audience: this.$t(this.verbiage[this.level]),
          title:
            this.selectedFingerprint.title.charAt(0).toUpperCase() +
            this.selectedFingerprint.title.slice(1),
        }),
      };
    },
  },
  emits: ['audienceClicked'],
  methods: {
    ...mapActions('users', ['setCurrentUser']),
    updateVolume() {
      usersApi.methods
        .updateUserProfile({
          volume: this.$refs.video.volume,
          volumeMuted: this.$refs.video.muted,
        })
        .then(() => {
          this.setCurrentUser({
            ...this.currentUser,
            user: {
              ...this.currentUser.user,
              volume: this.$refs.video.volume,
              volumeMuted: this.$refs.video.muted,
            },
          });
        });
    },
    formatCounts(count) {
      let result = '';
      if (count < 1000) {
        result = Number(count).toString();
      } else if (count >= 1000 && count <= 1.0e6) {
        result = `${(Math.abs(Number(count)) / 1.0e3).toFixed(2)}K`;
      } else if (count >= 1.0e6) {
        result = `${(Math.abs(Number(count)) / 1.0e6).toFixed(2)}M`;
      }
      return result;
    },
    modifyVideoURL() {
      const language = this.currentUser?.user?.language || 'en';
      return this.selectedFingerprint.videos[language];
    },
    handleLearnMoreFingerprints() {
      const { video } = this.$refs;
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
      this.$modal.show(
        VideoModal,
        {
          fingerPrint: fpLearnFingerprints,
        },
        {
          name: 'VideoModal',
          width: '1280px',
          height: '720px',
        },
      );
      trackFingerprintEvents.fingerprintsLearnMoreAboutFingerprintsClick(
        this.currentUser,
      );
    },

    onVideoPlay() {
      trackFingerprintEvents.fingerprintsIndividualFingerpintPlay(
        this.currentUser,
        {
          [TD_COMMON_COLUMN_NAME]: TD_FINGERPRINT_TYPE,
          [TD_COMMON_COLUMN_VALUE]: this.selectedFingerprint?.title,
        },
      );
    },
    selectFingerprint(fingerprintType, isClicked) {
      if (!fingerprintType) {
        return;
      }
      if (isClicked) {
        trackFingerprintEvents.fingerprintsIndividualFingerpintClick(
          this.currentUser,
          {
            [TD_COMMON_COLUMN_NAME]: TD_FINGERPRINT_TYPE,
            [TD_COMMON_COLUMN_VALUE]: fingerprintType?.title,
          },
        );
      }
      if (fingerprintType?.title === this.selectedFingerprint?.title) {
        this.$nextTick(() => {
          const { explore } = this.$refs;
          if (explore) {
            explore.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }
        });
        return;
      }
      this.$nextTick(() => {
        const { explore } = this.$refs;
        if (explore) {
          explore.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      });
      this.selectedFingerprint = null;
      if (this.hls) {
        this.hls.destroy();
        this.hls = new Hls();
      }
      setTimeout(async () => {
        this.selectedFingerprint = fingerprintType;
        getFingerprintInfo(this.level, this.selectedFingerprint.title).then(
          (resp) => {
            console.log(resp);
            this.count = resp.data;
          },
        );
        getFileURL(
          this.currentUser.user.cognitoID,
          this.modifyVideoURL(),
          this.currentUser.userIp,
        ).then((videoUrl) => {
          console.log(videoUrl, '--------src--------');
          const { video } = this.$refs;
          this.hls.loadSource(videoUrl);
          this.hls.attachMedia(video);
          this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
            // video.play();
          });
          this.hls.startLoad();
          this.$refs.video.volume = this.currentUser.user.volume || 1;
          this.$refs.video.muted = this.currentUser.user.volumeMuted;
        });
      }, 500);
    },
    scopeChanged() {
      trackFingerprintEvents.fingerprintsRadiobuttonclick(
        this.currentUser,
        this.level,
      );
      this.distribution = {};
      getFingerprintDistribution(this.level).then((resp) => {
        console.log(resp, 'distribution');
        this.distribution = resp.data;
        if (this.selectedFingerprint) {
          getFingerprintInfo(this.level, this.selectedFingerprint.title).then(
            (response) => {
              console.log(response);
              this.count = response.data;
            },
          );
        }
      });
    },
    handleAudienceClicked(audience) {
      this.$emit('audienceClicked', audience);
    },
  },
  watch: {
    '$route.query.learn': {
      async handler(newVal) {
        this.selectFingerprint(fpTypes.find((i) => i.title === newVal));
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.$route.query?.learn) {
      this.selectFingerprint(
        fpTypes.find((i) => i.title === this.$route.query?.learn),
      );
    }
    EventBus.$on('EXPLORE_FP', this.selectFingerprint);
    getFingerprintDistribution('global').then((resp) => {
      console.log(resp, 'distribution');
      this.distribution = resp.data;
    });
  },
  beforeDestroy() {
    EventBus.$off('EXPLORE_FP', this.selectFingerprint);
    this.hls.destroy();
  },
};
</script>
<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
}

.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: max-height 0.6s ease-in-out, opacity 0.2s ease-in-out 0.3s;
  max-height: 650px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  transition: max-height 0.5s ease-in-out, opacity 0.2s ease-in-out;
  opacity: 0;
  max-height: 0px;
  padding: 0;
}
.explore-section {
  font-family: 'Lato';
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  padding: 24px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  .header-section {
    display: flex;
    justify-content: space-between;

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.01em;

      color: #000000;
      margin-bottom: 12px;
    }
  }

  .category {
    width: 205px;
  }

  .learn-more {
    color: #21a7e0;
    cursor: pointer;
    margin-top: 10px;
    display: inline-block;
  }

  .fingerprints-header {
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .fingerprints-distribution {
    display: flex;
    justify-content: space-between;

    .fingerprint-item {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 16px;
      color: #21a7e0;

      .logo-fingerprint {
        background-position: center;
        background-size: contain;
        width: 90px;
        height: 90px;
        margin: 10px 0;
        cursor: pointer;
      }
    }
  }

  .selected-fingerprint-container {
    font-family: 'Lato';
    font-style: normal;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 24px;
    margin-top: 24px;
    display: flex;
    gap: 20px;
    position: relative;

    .close-icon {
      position: absolute;
      right: 24px;
      top: 15px;
    }

    .fingerprint-info {
      width: 33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px;
      margin-top: 20px;
      gap: 24px;

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;

        .logo-fingerprint {
          width: 90px;
          height: 90px;
        }

        .fp-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0px;
          gap: 12px;

          .fp-title {
            text-transform: capitalize;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #21a7e0;
          }

          .fp-description {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #212121;
          }
        }
      }

      .fp-preferences {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 0px;
        justify-content: space-between;
        width: 100%;

        .preference {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 33.33%;
          img {
            width: 50px;
            height: 35px;
          }

          div:nth-child(2) {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #7b7b7b;
            margin-bottom: 2px;
            margin-top: 12px;
          }

          div:nth-child(3) {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #21a7e0;
          }
        }
      }

      .fp-dos-container {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #7b7b7b;

        .fp-dos-heading {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 4px;
        }

        .fp-dos-item {
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          .dos-icon {
            width: 20px;
            height: 20px;
            margin: 0 5px;
          }
        }
      }
    }

    .fingerprint-video-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: center;
      height: 100%;
      width: calc(67% - 20px);

      video {
        margin-top: 25px;
        box-shadow: 0px 2.41372px 6.03431px 1.20686px rgba(64, 60, 67, 0.16);
        border-radius: 4.10797px;
        width: 100%;
        aspect-ratio: 16 / 9;
      }

      .fp-scope-info {
        display: flex;
        gap: 16px;

        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;

          img {
            width: 48px;
            height: 48px;
          }

          &__value {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #21a7e0;
          }

          &__descp {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.01em;
            color: #7b7b7b;
          }
        }
      }
    }
  }

  ::v-deep .v-input--selection-controls__input {
    margin-right: 3px;
  }

  ::v-deep .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 8px;
  }

  ::v-deep .v-input--selection-controls__input {
    height: 13px;
    width: 13px;
  }

  ::v-deep .v-icon__svg {
    height: 13px;
    width: 13px;
  }

  ::v-deep .v-label {
    font-size: 14px;
  }

  .circular-chart {
    border-radius: 50%;
    display: block;
    stroke: #1075ab;
  }

  .circle-bg {
    fill: none;
    stroke: #b3e4f5;
    stroke-width: 2.8;
  }

  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .logo-fingerprint-selected {
    .circular-chart {
      display: block;
      stroke: #075689;
    }

    .circle-bg {
      fill: #e1f5fb;
      stroke: #57c1e7;
      stroke-width: 2.8;
    }
  }
  .circular-chart:hover {
    box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
  }
}
</style>
