<template>
  <div>
    <div :key="searchKey" class="search-bar">
      <VueSuggestions
        :items="itemFiltered"
        v-model="item"
        :set-label="setLabel"
        :item-template="headerItemTemplate"
        @changed="debounceInputChange"
        @click="onClickHandle"
        @selected="itemSelected($event)"
        @enter="onEnterKeyPress"
        :placeholder="$t('fingerprint.search.searchPeopleOrFingerprints')"
        :disabled="!startLoadingSlidesFlag"
        :loading="loading"
        :values="searchValue"
      >
        <template #searchSlot>
          <v-icon
            class="icon"
            v-text="`mdi-close`"
            @click="clearSearch"
            name="global-cross-icon"
            color="#21a7e0"
            :disabled="isTrialExpired"
            v-show="crossButton"
          />
          <v-icon
            class="icon"
            v-text="`mdi-magnify`"
            @click="onEnterKeyPress"
            name="global-search-icon"
            :color="isSearchColor"
            :disabled="isTrialExpired"
          />
        </template>
      </VueSuggestions>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import EventBus from '../../common/event-bus';
import VueSuggestions from '../../vue-suggestions.vue';
import headerItemTemplate from './headerItemTemplate.vue';
import { fpTypes } from '@/mock/fingerprint';
import { logSearchQuery } from '../../../utils/api-helper';
import audienceApi from '../../../API/audience-api';
import {
  replacePrivateInParenthesis,
  getIsGroup,
  formatUserForPrivateAudienceItem,
} from '../../../utils/common';

export default {
  name: 'Search',
  components: {
    VueSuggestions,
  },
  watch: {
    itemFiltered() {
      this.$emit('itemChange', this.itemFiltered);
    },

    startLoadingSlidesFlag(val) {
      if (val === true) {
        this.itemFiltered = this.updatePeopleOnceAudiencesLoaded(
          this.itemFiltered,
        );
      }
    },
  },
  props: {
    minimize: {
      type: Function,
      default: () => ({}),
    },
    isPeopleClicked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onEnterPress', 'audienceClicked'],
  data() {
    return {
      searchKey: 0,
      crossButton: false,
      username: '',
      isSearchColor: 'gray',
      itemFiltered: [],
      audienceList: [],
      audience: null,
      item: {},
      headerItemTemplate,
      searchResults: [],
      searchTerms: [],
      loading: false,
      searchValue: '',
    };
  },
  mounted() {
    EventBus.$on('GOTO_FINGERPRINTS', async (payload) => {
      this.searchValue = payload;
      await this.handleGoToFingerprint(payload);
      // this.onEnterKeyPress();
    });
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'isTrialExpired',
      'startLoadingSlidesFlag',
      'typesenseRealtimeAudiences',
    ]),
    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),
    ...mapGetters('slidesStore', [
      'getIsSlideDetailsDirty',
      'getIsSlideACLDirty',
      'getIsSlideBestPractisesDirty',
    ]),
  },
  methods: {
    ...mapActions('search', ['updateRecentQueries']),
    ...mapActions('slidesStore', [
      'setRouteToNavigate',
      'saveSlidesStateSnapshot',
      'setSlideViewConfirmationModalState',
    ]),
    clearSearch() {
      this.crossButton = false;
      this.item = null;
      this.searchValue = '';
      this.searchKey++;
    },
    onClickHandle(check) {
      if (check) {
        this.isSearchColor = '#21a7e0';
      } else {
        this.isSearchColor = 'gray';
      }
    },
    onEnterKeyPress() {
      if (!this.searchValue) return;
      console.log(this.itemFiltered, 'in serach');

      this.$emit('onEnterPress', this.itemFiltered);

      logSearchQuery({
        type: 'Hybrid - Fingerprint',
        searchTerm: this.searchValue,
        searchTermResult: this.itemFiltered.length > 0,
      })
        .then()
        .catch((err) => console.log(err));
    },
    itemSelected(item) {
      this.item = item;
      const logPayload = {
        type: 'People - Fingerprint',
        searchTerm: item.name,
        searchTermResult: true,
      };
      if (this.item.type === 'People') {
        this.$emit('audienceClicked', {
          shared: this.item.isShared,
          audienceType: this.item.item.audienceType,
          id: this.item.item.uuid || this.item.item.id,
        });

        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(err));
      } else {
        this.$emit('onEnterPress', this.itemFiltered);

        logPayload.type = 'Fingerprint';

        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(err));
      }
    },
    setLabel(item) {
      return item.name;
    },
    serializeParam(params) {
      return Object.entries({ ...params })
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    },
    // eslint-disable-next-line func-names
    debounceInputChange: _.debounce(function (searchText) {
      this.inputChange(searchText);
      if (searchText.length) {
        this.crossButton = true;
      } else {
        this.crossButton = false;
      }
    }, 300),

    updatePeopleOnceAudiencesLoaded(allPeople) {
      let loadedPeople = [...allPeople];

      const audienceList = [...allPeople];

      audienceList.forEach((audience) => {
        this.audienceList.push({ ...audience, active: true });
      });

      // if private audience then append (Private) to the name
      // for private aud, display_name has "(Private)" as suffix
      // and phaedrus username = "<Priv aud ddb id>_<Priv aud ddb userid>"
      loadedPeople.forEach((user) => {
        let audience = null;
        if (user.item.display_name?.split(' ').slice(-1)[0] === '(Private)') {
          audience = this.typesenseRealtimeAudiences.find(
            (a) =>
              a.email.concat('_', a.userID).toLowerCase() ===
                user.item.name.toLowerCase() && a.fingerPrint,
          );
          if (audience) {
            audience.lastName = replacePrivateInParenthesis(audience.lastName);
            audience.isPrivateAudience = true;
            audience = formatUserForPrivateAudienceItem(
              audience,
              this.currentUser,
            );
          }
        } else {
          audience = this.audienceList.find(
            (a) =>
              a.id?.toLowerCase().includes(user.item?.name?.toLowerCase()) ||
              (a.cognitoID && a.cognitoID === user?.item?.name),
          );
          if (!audience) {
            audience = [...this.typesenseRealtimeAudiences].find(
              (sUser) => sUser.id === user.item.id,
            );
            if (audience) {
              audience.isShared = true;
              audience.active = true;
              audience = {
                ...audience,
              };
            }
          }
        }
        if (audience) {
          if (getIsGroup(audience)) {
            audience.lastName = '';
          }
          loadedPeople = [
            ...loadedPeople,
            {
              item: { ...audience },
              isShared: true,
              name: audience.fullName,
              type: 'People',
            },
          ];
        }
      });

      return loadedPeople;
    },

    async inputChange(text) {
      this.searchValue = text;

      if (!text?.length) {
        return;
      }

      this.loading = true;

      this.itemFiltered = [];
      const itemFiltered = [];

      fpTypes.forEach((fp) => {
        const isMatched = fp.title.toLowerCase().includes(text.toLowerCase());
        if (isMatched) {
          itemFiltered.push({
            item: fp,
            name: fp.title,
            type: 'Fingerprint',
          });
        }
      });

      const audiences = await audienceApi.methods.audienceSearch({
        query: text,
        companyId: this.currentUser?.user?.companyID,
        userId: this.currentUser?.user?.id,
        extraFields: ['uuid'],
        limit: 250,
        // PM-9675 Fix, to sync local search results with global search
        // filterByCollection: {
        //   private_audiences: 'hasFingerPrint:=1',
        // },
      });

      audiences.forEach((audience) => {
        audience.isPublicLimited = !(
          audience.accessible_to.includes('a__0') ||
          audience.accessible_to.includes(
            `c__${this.currentUser.company.num_id}`,
          )
        );
        itemFiltered.push({
          name: audience.fullName,
          type: 'People',
          isShared: audience.shared,
          item: {
            ...audience,
          },
        });
      });

      if (this.startLoadingSlidesFlag) {
        this.itemFiltered = this.updatePeopleOnceAudiencesLoaded(itemFiltered);
      } else {
        this.itemFiltered = itemFiltered;
      }

      this.loading = false;
    },

    // This code is to handle the search when we click on go to fingerprints in global search and to avoid suggestion list
    async handleGoToFingerprint(text) {
      if (!text?.length) {
        return;
      }

      let itemFiltered = [];

      fpTypes.forEach((fp) => {
        const isMatched = fp.title.toLowerCase().includes(text.toLowerCase());
        if (isMatched) {
          itemFiltered.push({
            item: fp,
            name: fp.title,
            type: 'Fingerprint',
          });
        }
      });

      const audiences = await audienceApi.methods.audienceSearch({
        query: text,
        companyId: this.currentUser?.user?.companyID,
        userId: this.currentUser?.user?.id,
        extraFields: ['uuid'],
        limit: 250,
        // PM-9675 Fix, to sync local search results with global search
        // filterByCollection: {
        //   private_audiences: 'hasFingerPrint:=1',
        // },
      });

      audiences.forEach((audience) => {
        audience.isPublicLimited = !(
          audience.accessible_to.includes('a__0') ||
          audience.accessible_to.includes(
            `c__${this.currentUser.company.num_id}`,
          )
        );
        itemFiltered.push({
          name: audience.fullName,
          type: 'People',
          isShared: audience.shared,
          item: {
            ...audience,
          },
        });
      });
      if (this.startLoadingSlidesFlag) {
        itemFiltered = this.updatePeopleOnceAudiencesLoaded(itemFiltered);
      }
      this.$emit('onEnterPress', itemFiltered);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.back-button {
  color: #21a7e0;
  font: 12px;
  cursor: pointer;
  margin-bottom: 10px;
  font-family: 'Lato';
  v-icon {
    padding-bottom: 5px;
  }
}
.chipgroup {
  padding: 10px 0px 0px 20px;
}
::v-deep {
  .search-bar {
    width: 560px;
    z-index: 3;
  }
}
</style>
