<template>
  <div class="removeBestPractiseContainer">
    <div class="closeIconContainer">
      <v-icon @click="handleClose">{{ 'mdi-close' }}</v-icon>
    </div>
    <div class="headingContainer">
      {{ $t('slides.deleteBestPractise') }}
    </div>
    <div class="msgContainer">
      {{ $t('slides.dontWantToDeleteBestPractise') }}
    </div>
    <div class="actionsContainer">
      <div class="buttonsContainer">
        <v-btn @click="handleClose" class="cnclBtn">{{
          $t('common.cancel')
        }}</v-btn>
        <v-btn @click="handleConfirm" class="delBtn">
          {{ $t('common.delete') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents';

export default {
  name: 'RemoveBestPractiseDialog',
  data() {
    return {};
  },
  props: {
    bestPrt: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('slidesStore', ['getBestPractisesOnSlide', 'getActiveSlide']),
  },
  methods: {
    ...mapActions('slidesStore', [
      'setBestPractisesOnSlide',
      'setBestPractiseSelected',
    ]),

    handleClose() {
      this.$modal.hide('RemoveBestPractiseDialog');
    },
    handleConfirm() {
      const existingBs = [...this.getBestPractisesOnSlide];
      const filteredBs = existingBs.filter(
        (item) => item.name !== this.bestPrt.name,
      );
      const renamedBestPractises = filteredBs.map((item, i) => ({
        ...item,
        name: `#${i + 1}`,
      }));
      this.setBestPractisesOnSlide(renamedBestPractises);
      this.setBestPractiseSelected({});
      trackSlideEvents.slidesBestPracticesDeleteDelete(
        this.getActiveSlide,
        this.currentUser,
        {},
      );
      this.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.removeBestPractiseContainer {
  padding: 24px;
  .closeIconContainer {
    position: absolute;
    top: 5px;
    right: 10px;
  }
  .headingContainer {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: normal;
    margin: 0px 0px 20px 0px;
  }
  .msgContainer {
    font-size: 16px;
    margin: 0px 0px 20px 0px;
  }
  .actionsContainer {
    display: flex;
    justify-content: flex-end;
    .buttonsContainer {
      width: 38%;
      display: flex;
      justify-content: space-between;
      .cnclBtn,
      .delBtn {
        box-shadow: none;
        border-radius: 20px;
        letter-spacing: normal;
        text-transform: none;
      }
      .cnclBtn {
        background: #21a7e0;
        color: #ffffff;
      }
      .delBtn {
        background: #ffffff;
        color: #21a7e0;
        border: 1px solid #21a7e0;
      }
    }
  }
}
</style>
