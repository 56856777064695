<template>
  <div class="slideChangeLayout">
    <v-row no-gutters style="width: 100%">
      <v-col cols="12">
        <div class="changelayoutHeader text-h6 text-left font-weight-bold">
          {{ $t('slideDetails.smartLayout') }}
        </div>
        <v-radio-group class="similar-slides-radiobtns" v-model="type">
          <v-row>
            <v-col cols="4">
              <v-radio :label="$t('slideDetails.idea')" value="idea" />
            </v-col>
            <v-col cols="4">
              <v-radio :label="$t('slideDetails.diagram')" value="diagram" />
            </v-col>
          </v-row>
        </v-radio-group>
      </v-col>
      <div class="iconsRow">
        <div class="icon-container" v-if="filteredSlideByNode.length">
          <div @click="handleView(false)">
            <v-icon
              :color="isColumnView ? '#000' : '#21A7E0'"
              v-tooltip="{
                content: $t('slideDetails.gridView'),
                placement: 'top-center',
              }"
            >
              {{ isColumnView ? 'mdi-view-grid-outline' : 'mdi-view-grid' }}
            </v-icon>
          </div>

          <div @click="handleView(true)">
            <v-icon
              :color="isColumnView ? '#21A7E0' : '#000'"
              v-tooltip="{
                content: $t('slideDetails.columnView'),
                placement: 'top-center',
              }"
            >
              {{ isColumnView ? 'mdi-view-agenda' : 'mdi-view-agenda-outline' }}
            </v-icon>
          </div>
        </div>
      </div>
      <v-col cols="12">
        <div class="related-products">
          <div class="relatedSlidesLoaderContainer" v-if="loadingRelatedSlides">
            <v-progress-circular indeterminate :size="30" color="primary" />
          </div>
          <div class="related-products-thumbnails" v-else>
            <div
              :style="{ padding: '8px 0px 0px 0px' }"
              v-if="
                filteredSlideByNode.length && filteredSlideByNode.length > 0
              "
              :class="isColumnView ? 'column' : 'grid'"
              :key="viewKey"
            >
              <RelatedSlide
                v-for="(product, index) in filteredSlideByNode"
                :in-viewport-once="true"
                :in-viewport-root-margin="'0% 0%'"
                :key="index"
                :handle-related-product-click="handleRelatedProductClick"
                :product="product"
                :data-pendo-id="index === 0 ? 'similar-slides-card' : ''"
              />
            </div>
            <div class="noRelatedSlidesIllustration" v-else>
              <div class="imgContainer">
                <img src="/assets/img/no-files-available.svg" />
              </div>
              <div class="relatedSlidesError">
                {{
                  `${
                    isRelatedSlidesFetchError
                      ? $t('slideDetails.somethingWentWrong')
                      : $t('slideDetails.noSimilarSlidesFound')
                  }`
                }}
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { graphqlOperation } from 'aws-amplify';
import { performSearch } from '@/graphql/queries';
import { GraphQLService } from '../../../../services/GraphQLService';
import RelatedSlide from '@/components/Main/Slides/RelatedSlideV2';

export default {
  name: 'SlideChangeLayout',
  data() {
    return {
      type: 'idea',
      loadingRelatedSlides: false,
      isRelatedSlidesFetchError: false,
      filteredSlideByNode: [],
      isColumnView: true,
      viewKey: 0,
    };
  },
  components: {
    RelatedSlide,
  },
  watch: {
    async type() {
      await this.handleLoadRelatedSlides();
    },
  },
  props: {
    dialogContent: {
      type: Object,
      default: () => ({}),
    },
    audience: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    result: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    handleRelatedProductClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('users', ['sessionKey', 'currentUser', 'currentTheme']),
  },
  methods: {
    ...mapActions('slidesStore', ['setFlowActive']),

    handleView(val) {
      this.isColumnView = val;
      this.viewKey++;
    },

    getChoice(fingerprint) {
      let choice = { visual: 6.0, data: 5.0 };
      switch (fingerprint.toLowerCase()) {
        case 'director':
          choice = { visual: 6.0, data: 5.0 };
          return choice;

        case 'performer':
          choice = { visual: 6.0, data: 0.0 };
          return choice;

        case 'navigator':
          choice = { visual: 6.0, data: 0.0 };
          return choice;

        case 'surgeon':
          choice = { visual: 0.0, data: 5.0 };
          return choice;

        case 'architect':
          choice = { visual: 0.0, data: 5.0 };
          return choice;

        case 'scholar':
          choice = { visual: 6.0, data: 5.0 };
          return choice;

        case 'scientist':
          choice = { visual: 0.0, data: 5.0 };
          return choice;
        default:
          return choice;
      }
    },

    async handleLoadRelatedSlides() {
      const category = this.result.categories[0];
      const payload = {};
      const fingerprint = this.audience.fingerPrint
        ? this.audience.fingerPrint.toLowerCase()
        : 'director';
      payload.choice = this.getChoice(fingerprint);
      payload.category = category;
      payload.username = this.currentUser.user.cognitoID;
      payload.limit = 24;
      payload.skip = 0;
      payload.morprefs = {};
      payload.morprefs.construct =
        this.type === 'diagram' ? [this.result.prefs.construct] : [];
      payload.morprefs.node = this.result.prefs.node
        ? [this.result.prefs.node.toString()]
        : [];
      payload.similar = this.result.unique_id;

      if (this.type === 'diagram') {
        delete payload.category;
      }

      this.filteredSlideByNode = [];

      this.loadingRelatedSlides = true;
      await this.getRecommendedSlides(
        payload,
        this.sessionKey,
        this.currentTheme,
      )
        .then((slides) => {
          const relSlides = slides.filter(
            (e) => e.unique_id !== this.result.unique_id,
          );
          this.filteredSlideByNode = JSON.parse(JSON.stringify(relSlides));
          this.loadingRelatedSlides = false;
        })
        .catch((err) => {
          this.loadingRelatedSlides = false;
          this.isRelatedSlidesFetchError = true;
          console.log(err);
        });
    },

    async getRecommendedSlides(data) {
      const payload = {
        ...data,
      };

      const getSlides = async (resolve, reject) => {
        if (!payload.andprefs) {
          payload.andprefs = {};
        }
        payload.andprefs.theme = this.dialogContent?.prefs?.theme;
        const params = {
          username: payload.username,
          query: payload.query ? payload.query : '',
          category: payload.category,
          orderby: 'CHOICE_DESC',
          choice: payload.choice,
          morprefs: payload.morprefs ? payload.morprefs : [],
          prefs: payload.prefs ? payload.prefs : [],
          andprefs: payload.andprefs ? payload.andprefs : [],
          // notprefs: payload.notprefs ? payload.notprefs : [],
          // company: this.currentUser.companyName.toLowerCase(),
          limit: payload.limit,
          skip: payload.skip >= 0 ? payload.skip : 0,
        };
        const serialized = JSON.stringify(params);

        await GraphQLService.requestWithToken(
          graphqlOperation(performSearch, {
            parameters: serialized,
            sessionkey: this.sessionKey,
          }),
        )
          .then((res) => {
            const result = JSON.parse(res.data.performSearch);
            if (result.statusCode === 200) {
              const slides = result.body.payloads;
              if (!slides || slides.length < 1) {
                resolve([]);
              } else {
                resolve(slides);
              }
            } else {
              reject(result);
            }
          })
          .catch((err) => {
            reject(err);
          });
      };
      return new Promise(getSlides);
    },
  },
  async mounted() {
    this.setFlowActive('changeLayout');

    await this.handleLoadRelatedSlides();
  },
};
</script>

<style lang="scss" scoped>
.slideChangeLayout {
  .changelayoutHeader {
    font-family: 'Lato' !important;
    display: flex;
    overflow-wrap: anywhere;
  }
  .similar-slides-radiobtns {
    ::v-deep .v-input__control {
      .v-messages {
        display: none !important;
      }
    }
    ::v-deep .v-label {
      color: black;
    }
  }
  .iconsRow {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
    padding: 16px 0px 24px 0px;
    .icon-container {
      display: flex;
      gap: 12px;
    }
  }
  .related-products {
    height: calc(100vh - 275px);
    .relatedSlidesLoaderContainer {
      text-align: center;
      .v-progress-circular {
        margin: 100px 0px 0px 0px;
      }
    }

    .related-products-header {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      letter-spacing: normal;
      line-height: normal;
      margin-bottom: 0.5rem;
    }
    .related-products-thumbnails {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0.2rem;
      padding-top: 0px;
      .noRelatedSlidesIllustration {
        width: 90%;
        height: 90%;
        margin: 0 auto;
        .imgContainer {
          padding: 48px 48px 16px 48px;
          display: flex;
          justify-content: center;
          img {
            width: 266px;
          }
        }
        .relatedSlidesError {
          text-align: center;
          font-size: 16px;
        }
      }
      .grid {
        display: flex;
        flex-wrap: wrap;
        gap: 18px;
        .product {
          border-radius: 8px;
          box-shadow: $item-box-shadow;
          cursor: pointer;
          margin-bottom: 18px;
          padding: 5px;
          transition: all ease 0.5s;
          width: 48%;
          img {
            height: 100%;
            width: 100%;
          }
        }
        .product:hover {
          box-shadow: $item-box-shadow-hover;
        }
      }
      .column {
        .product {
          border-radius: 8px;
          box-shadow: $item-box-shadow;
          cursor: pointer;
          margin-bottom: 18px;
          padding: 5px;
          transition: all ease 0.5s;
          width: 100%;
          img {
            height: 100%;
            width: 100%;
          }
        }
        .product:hover {
          box-shadow: $item-box-shadow-hover;
        }
      }
    }
  }
}
</style>
