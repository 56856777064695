<template>
  <div
    :class="`image-library-wrapper ${
      isConvertingInProgress || localImageUploading ? 'item-disabled' : ''
    }`"
  >
    <v-tabs
      :class="`library-tabs ${this.noOfTabsEnabled}`"
      centered
      grow
      mobile-breakpoint
      v-model="currentTab"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            v-if="isAdobeLibraryEnabled"
            class="tab-item adobe-library-tab--auto"
            :href="'#' + adobe"
            @click="setTab(adobe)"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('generate.library') }}
          </v-tab>
        </template>
        <span>{{ $t('generate.library') }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            v-if="isBrandImagesEnabled"
            class="tab-item company-sourced-tab--auto"
            :href="'#' + brand"
            @click="setTab(brand)"
            v-bind="attrs"
            v-on="on"
          >
            {{ companyDisplayName }}
          </v-tab>
        </template>
        <span>{{ companyDisplayName }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            v-if="origin === 'generate'"
            class="tab-item upload-image-tab--auto"
            :href="'#' + extracted"
            @click="setTab(extracted)"
            v-bind="attrs"
            v-on="on"
          >
            {{ 'Context' }}
          </v-tab>
        </template>
        <span>{{ 'Context' }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            v-if="isCustomImageUploadEnabled"
            class="tab-item upload-image-tab--auto"
            :href="'#' + local"
            @click="setTab(local)"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('common.upload') }}
          </v-tab>
        </template>
        <span>{{ $t('common.upload') }}</span>
      </v-tooltip>
      <!-- <v-tooltip top v-if="isGenerate">
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            v-if="isGenerate"
            class="tab-item upload-image-tab--auto"
            :href="'#' + create"
            @click="setTab(local)"
            v-bind="attrs"
            v-on="on"
          >
            Create
          </v-tab>
        </template>
        <span>Create</span>
      </v-tooltip> -->
      <v-tabs-items v-model="currentTab">
        <v-tab-item v-if="isAdobeLibraryEnabled" :value="adobe">
          <AdobeLibrary
            class="image-library-item ml-2"
            :selectedImageData="currentSelectedSlideImageData"
            :slideData="slideData"
            @imageSelected="imageSelected($event)"
            :isSearchActionTriggerredFromBrandImages="
              isSearchActionTriggerredFromBrandImages
            "
            :brandImagesSearchKey="brandImagesSearchKey"
            @imageSearch="imageSearch($event)"
            :isGenerate="isGenerate"
          >
          </AdobeLibrary>
        </v-tab-item>
        <v-tab-item v-if="isBrandImagesEnabled" :value="brand">
          <BrandImages
            class="image-library-item ml-2"
            :selectedImageData="currentSelectedSlideImageData"
            @imageSelected="imageSelected($event)"
            :onTryAdobeSearch="onTryAdobeSearch"
            :isAdobeEnabled="isAdobeLibraryEnabled"
            @imageSearch="imageSearch($event)"
            :slideData="slideData"
            :isGenerate="isGenerate"
          >
          </BrandImages>
        </v-tab-item>
        <v-tab-item v-if="origin === 'generate'" :value="extracted">
          <ExtractedImages
            class="image-library-item ml-2"
            :images="extractedImages"
            @imageSelected="imageSelected($event)"
            :isGenerate="isGenerate"
            v-on="$listeners"
          >
          </ExtractedImages>
        </v-tab-item>
        <v-tab-item v-if="isCustomImageUploadEnabled" :value="local">
          <ImageUpload
            @imageSelected="imageSelected($event)"
            class="image-library-item ml-2"
            :selectedImageData="currentSelectedSlideImageData"
          >
          </ImageUpload>
        </v-tab-item>
        <!-- <v-tab-item v-if="isGenerate" :value="create">
          <div>Create Image</div>
        </v-tab-item> -->
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import AdobeLibrary from './AdobeLibrary.vue';
import ImageUpload from './ImageUpload.vue';
import BrandImages from './BrandImages.vue';
import ExtractedImages from './ExtractedImages.vue';
import EventBus from '../../common/event-bus';
import {
  LOCAL_IMAGE_UPLOADING,
  LOCAL_IMAGE_UPLOADED,
  IMAGE_UPLOAD_FAILED,
  ADOBE,
  BRAND,
  LOCAL,
} from './constants';

export default {
  name: 'ImageLibrary',
  data() {
    return {
      selectedImage: null,
      localImageUploading: false,
      adobe: ADOBE,
      brand: BRAND,
      local: LOCAL,
      currentTab: ADOBE,
      extracted: 'extracted',
      isSearchActionTriggerredFromBrandImages: false,
      brandImagesSearchKey: '',
    };
  },
  mounted() {
    EventBus.$on(LOCAL_IMAGE_UPLOADING, () => {
      this.localImageUploading = true;
    });
    EventBus.$on(LOCAL_IMAGE_UPLOADED, () => {
      this.localImageUploading = false;
    });
    EventBus.$on(IMAGE_UPLOAD_FAILED, () => {
      this.localImageUploading = false;
    });
    this.currentTab = this.getInitialCurrentTab();
  },
  props: {
    // Defines currently selected image that needs to be replaced
    currentSelectedSlideImageData: {
      type: Object,
      required: false,
    },
    isConvertingInProgress: {
      type: Boolean,
      required: false,
    },
    onImageSearch: {
      type: Function,
      default: () => {},
    },
    isGenerate: {
      type: Boolean,
      default: false,
    },
    slideData: {
      type: Object,
      default: () => {},
    },
    extractedImages: {
      type: Array,
      default: () => [],
    },
    origin: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('replaceImageStore', ['getSearchTerm']),
    isAdobeLibraryEnabled() {
      const adobeLibrary = this.findFeatureBy('replace_image_adobe_library');
      return adobeLibrary?.enabled;
    },

    isBrandImagesEnabled() {
      const brandImages = this.findFeatureBy('replace_image_brand');
      return brandImages?.enabled;
    },
    isCustomImageUploadEnabled() {
      const customImage = this.findFeatureBy('replace_image_custom_upload');
      return customImage?.enabled;
    },
    companyDisplayName() {
      return this.currentUser?.company?.displayName;
    },
    noOfTabsEnabled() {
      const numberMap = {
        1: 'one',
        2: 'two',
        3: 'three',
        4: 'four',
      };
      let noOfTabs = 0;
      if (this.isAdobeLibraryEnabled) {
        noOfTabs++;
      }
      if (this.isBrandImagesEnabled) {
        noOfTabs++;
      }
      if (this.isCustomImageUploadEnabled) {
        noOfTabs++;
      }
      if (this.origin === 'generate') {
        noOfTabs++;
      }
      return numberMap[noOfTabs];
    },
  },
  components: {
    AdobeLibrary,
    BrandImages,
    ImageUpload,
    ExtractedImages,
  },
  methods: {
    ...mapActions('replaceImageStore', ['setImageSearchData']),
    imageSelected(selectedImage) {
      this.setImageSearchData({
        source: selectedImage?.origin,
        searchTerm: this.getSearchTerm?.searchTerm,
      });
      this.selectedImage = selectedImage;
      this.$emit('imageSelected', {
        ...selectedImage,
        seq_id: this.currentSelectedSlideImageData?.seq_id,
      });
    },
    imageSearch(searchData) {
      this.$emit('onImageSearch', searchData);
    },
    findFeatureBy(key) {
      return this.currentUser?.features?.find((f) => f.feature_name === key);
    },
    setTab(tab) {
      this.currentTab = tab;
      this.unSetBrandImagesSearchKeyForAdobe();
    },
    getInitialCurrentTab() {
      if (this.isAdobeLibraryEnabled) {
        return ADOBE;
      }
      if (this.isBrandImagesEnabled) {
        return BRAND;
      }
      if (this.isCustomImageUploadEnabled) {
        return LOCAL;
      }
      return '';
    },
    onTryAdobeSearch(searchKey) {
      this.brandImagesSearchKey = searchKey;
      this.isSearchActionTriggerredFromBrandImages = true;
      this.currentTab = ADOBE;
    },
    unSetBrandImagesSearchKeyForAdobe() {
      this.brandImagesSearchKey = '';
      this.isSearchActionTriggerredFromBrandImages = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.image-library-wrapper {
  .tab-item {
    display: block;
    font-size: 1rem;
    letter-spacing: normal;
    overflow: hidden;
    padding-top: 10px;
    text-decoration: none;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: nowrap !important;
    width: 90px;
  }

  .tab-content {
    letter-spacing: normal;
    text-decoration: none;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: nowrap !important;
    width: 90px;
  }

  .library-tabs.two {
    ::v-deep .v-tabs-slider-wrapper {
      max-width: 50%;
    }
  }
  .library-tabs.three {
    ::v-deep .v-tabs-slider-wrapper {
      max-width: 33%;
    }
  }
  .library-tabs.four {
    ::v-deep .v-tabs-slider-wrapper {
      max-width: 25%;
    }
  }
}
</style>
