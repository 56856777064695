var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"storyline-view"},[(_vm.hasAccess && !_vm.hasNoData)?_c('div',[(_vm.isLoading)?_c('div',{staticClass:"spinner-container"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"btn-back",on:{"click":_vm.goToSearchLandingPage}},[_c('v-icon',{staticClass:"icon",attrs:{"color":"primary"}},[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v(_vm._s(_vm.$t( _vm.previousRoute === 'search' ? 'slides.backToSearch' : 'storyline.backToStorylines' )))])],1)],1),_c('v-col',{staticClass:"actions-container",style:({ 'max-width': ("" + (_vm.leftNavExpanded ? '50%' : '22%')) }),attrs:{"cols":"6"}},[_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"copy-storyline--auto",attrs:{"color":"primary"},on:{"click":_vm.handleCopyStoryline}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,false,3649441673)},[_c('span',[_vm._v(_vm._s(_vm.copiedFPId === _vm.storylineData.id ? _vm.$t('common.copied') : _vm.$t('userUploadPrezentation.copyLink')))])]),(_vm.storylineData.isOwner)?_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"share-storyline--auto",attrs:{"color":"primary"},on:{"click":_vm.handleShare}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-multiple-plus-outline ")])]}}],null,false,3661935075)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.share')))])]):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,false,917566369)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.handleDuplicateStoryline}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"duplicate-storyline--auto"},[_vm._v(" mdi-plus-box-multiple-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('common.duplicate')))])],1)],1),(_vm.storylineData.permissionLevel !== 'Viewer')?_c('v-list-item',{on:{"click":_vm.handleEditStoryline}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"edit-storyline--auto"},[_vm._v(" mdi-pencil-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('profile.storylinesTabContent.edit')))])],1)],1):_vm._e(),(
                  _vm.storylineData.isOwner &&
                  _vm.storylineData.permissionLevel !== 'Viewer'
                )?_c('v-list-item',{on:{"click":_vm.handleDeleteStoryline}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"delete-storyline--auto"},[_vm._v(" mdi-delete-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('common.delete')))])],1)],1):_vm._e()],1)],1)],1)]),_c('v-col',{staticClass:"main-body",attrs:{"cols":"12"}},[_c('div',{staticClass:"content"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('StorylineContainer',{ref:"storylineContainer",attrs:{"storylineData":_vm.storylineData,"origin":'viewStoryline',"renderFor":"storyline"}})],1),_c('div',{staticClass:"footer",class:{ expanded: _vm.leftNavExpanded }},[_c('div',{staticClass:"footer-container"},[_c('div',{staticClass:"accept-outline"},[_c('v-btn',{staticClass:"primary-button mb-3",attrs:{"rounded":"","color":"#21a7e0","height":"48","id":"accept-outline-button","disabled":_vm.disableAcceptStoryLine || _vm.isStorylineUpdating},on:{"click":function () { return _vm.handleBuildStoryline('build'); }}},[_vm._v(" "+_vm._s(_vm.$t('storylinesView.buildCTA'))+" ")])],1),(_vm.storylineData.permissionLevel !== 'Viewer')?_c('div',{staticClass:"accept-outline"},[_c('v-checkbox',{attrs:{"label":_vm.$t('storyline.updateExistingStoryline'),"disabled":_vm.disableAcceptStoryLine},model:{value:(_vm.updateExistingStoryline),callback:function ($$v) {_vm.updateExistingStoryline=$$v},expression:"updateExistingStoryline"}})],1):_vm._e(),(_vm.updateExistingStoryline)?_c('div',{staticClass:"accept-outline"},[_c('v-btn',{staticClass:"accept-btn",attrs:{"text":"","color":"primary","id":"accept-and-save-outline-button","disabled":_vm.disableAcceptStoryLine ||
                      _vm.saveStorylineText === 'storyline.storyLineSaved',"loading":_vm.isStorylineUpdating &&
                      _vm.saveStorylineText !== 'storyline.storyLineSaved'},on:{"click":_vm.handleAcceptAndSave}},[_vm._v(" "+_vm._s(_vm.$t(_vm.saveStorylineText))+" ")])],1):_c('div',{staticClass:"accept-outline"},[_c('v-btn',{staticClass:"accept-btn",attrs:{"text":"","color":"primary","id":"accept-and-save-outline-button","disabled":_vm.disableAcceptStoryLine,"loading":_vm.isStorylineUpdating},on:{"click":_vm.handleSaveAs}},[_vm._v(" "+_vm._s(_vm.$t('storyline.saveAsNewStoryline'))+" ")])],1)])])],1)],1)])],1),(_vm.showEdit)?_c('StorylineModal',{attrs:{"show":_vm.showEdit,"title":"profile.storylinesTabContent.editStorylineDetails","text":"profile.storylinesTabContent.editStorylineDetailsText","primaryBtnText":"common.cancel","secondaryBtnText":_vm.editButtonText,"type":_vm.storylinePopupEditData.type,"description":_vm.storylinePopupEditData.description,"onPrimaryClick":function () {
          _vm.showEdit = false;
          _vm.resetPopupData();
        },"onSecondaryClick":_vm.handleEditOutline,"isSecondaryloading":_vm.isUpdating,"onChange":_vm.handleInputChange,"onClose":function () {
          _vm.editError = false;
          _vm.showEdit = false;
          _vm.resetPopupData();
        }}}):_vm._e(),(_vm.showSaveAs)?_c('StorylineModal',{attrs:{"show":_vm.showSaveAs,"title":"storyline.saveAsNewstorline","text":"build.step2.saveThisStoryline","primaryBtnText":"","secondaryBtnText":"common.save","type":"","description":"","onSecondaryClick":function () { return _vm.handleDuplicateOutline(true); },"isSecondaryloading":_vm.isUpdating,"onChange":function (key, value) { return _vm.handleInputChange(key, value, true); },"onClose":function () {
          _vm.showSaveAs = false;
          _vm.resetPopupData();
        }}}):_vm._e(),(_vm.showDuplicate)?_c('StorylineModal',{attrs:{"show":_vm.showDuplicate,"title":"profile.storylinesTabContent.duplicateStoryline","text":"profile.storylinesTabContent.duplicateStorylineText","secondaryBtnText":"common.duplicate","primaryBtnText":"common.cancel","type":_vm.storylinePopupEditData.type,"description":_vm.storylinePopupEditData.description,"onPrimaryClick":function () {
          _vm.showDuplicate = false;
          _vm.resetPopupData();
        },"onSecondaryClick":function () { return _vm.handleDuplicateOutline(); },"isSecondaryloading":_vm.isCreating,"onChange":function (key, value) { return _vm.handleInputChange(key, value, true); },"onClose":function () {
          _vm.showDuplicate = false;
          _vm.resetPopupData();
        }}}):_vm._e(),(_vm.showDuplicateConfirm || _vm.showSaveAsConfirm)?_c('ConfirmationModal',{attrs:{"show":_vm.showDuplicateConfirm || _vm.showSaveAsConfirm,"title":_vm.showSaveAsConfirm
          ? 'storyline.saveAsNewstoryline'
          : 'profile.storylinesTabContent.duplicateStoryline',"text":_vm.$t('storyline.storylineCreatedCarefully', { type: _vm.newStoryline.type }),"primaryBtnText":"storyline.stayOnExistingStoryline","secondaryBtnText":"storyline.goToNewStoryLine","onPrimaryClick":function () {
          if (_vm.showDuplicateConfirm) {
            _vm.trackProfileEvents.profileStorylineviewDuplicateStayOnExistingStoryline(
              _vm.currentUser,
              _vm.getOtherDataForMatomo()
            );
          }
          if (_vm.showSaveAsConfirm) {
            _vm.trackProfileEvents.profileStorylineviewSaveAsNewStorylineSaveStayOnExistingStoryline(
              _vm.currentUser,
              _vm.getOtherDataForMatomo()
            );
          }
          _vm.showDuplicateConfirm = false;
          _vm.showSaveAsConfirm = false;
          _vm.resetPopupData();
        },"onSecondaryClick":function () {
          _vm.resetPopupData();
          if (_vm.showDuplicateConfirm) {
            _vm.trackProfileEvents.profileStorylineviewDuplicateGotoNewStoryline(
              _vm.currentUser,
              _vm.getOtherDataForMatomo()
            );
          }
          if (_vm.showSaveAsConfirm) {
            _vm.trackProfileEvents.profileStorylineviewSaveAsNewStorylineSaveGotoNewStoryline(
              _vm.currentUser,
              _vm.getOtherDataForMatomo()
            );
          }
          _vm.showDuplicateConfirm = false;
          _vm.showSaveAsConfirm = false;
          this$1.$router.push(("/home/storyline/" + (_vm.newStoryline.uuid)));
          _vm.uuid = _vm.newStoryline.uuid;
          _vm.getData();
        },"onClose":function () {
          _vm.showDuplicateConfirm = false;
          _vm.showSaveAsConfirm = false;
        }}}):_vm._e(),(_vm.showDelete)?_c('ConfirmationModal',{attrs:{"show":_vm.showDelete,"title":"profile.storylinesTabContent.deleteStoryline","text":_vm.$t('profile.storylinesTabContent.deleteStorylineText'),"onPrimaryClick":function () { return (_vm.showDelete = false); },"onSecondaryClick":_vm.handleDeleteOutline,"isConfirming":_vm.isDeleting,"onClose":function () { return (_vm.showDelete = false); }}}):_vm._e(),(_vm.showConfirm)?_c('ConfirmationModal',{attrs:{"show":_vm.showConfirm,"title":_vm.confirmFrom === 'build'
          ? 'storyline.buildPrezentation'
          : 'storyline.backToStorylines',"text":_vm.$t('storyline.areYouSureyouWantToContinue'),"onPrimaryClick":function () { return (_vm.showConfirm = false); },"onSecondaryClick":function () { return _vm.handleConfirmClick(); },"onClose":function () { return (_vm.showConfirm = false); }}}):_vm._e(),(_vm.editError)?_c('ConfirmationModal',{attrs:{"show":_vm.editError,"title":"profile.storylinesTabContent.editStorylineDetails","text":_vm.$t('profile.storylinesTabContent.unableToUpdateStoryline'),"primaryBtnText":'',"secondaryBtnText":'',"onClose":function () { return (_vm.editError = false); }}}):_vm._e(),(_vm.showNoAccess)?_c('NoAccessModal',{attrs:{"noAccess":_vm.noAccess,"show":_vm.showNoAccess,"uuid":_vm.uuid,"isDuplicate":_vm.isDuplicate,"onClose":function () {
          _vm.showNoAccess = false;
          _vm.noAccess = false;
          _vm.isDuplicate = false;
        }}}):_vm._e()],1):(!_vm.hasAccess && _vm.hasNoData)?_c('div',{staticClass:"no-outline-access"},[_vm._m(0),_c('div',{staticClass:"no-access-text"},[_vm._v(" "+_vm._s(_vm.$t('storyline.thisStorylineDoesNotExist'))+" ")])]):_c('div',{staticClass:"no-outline-access"},[_vm._m(1),_c('div',{staticClass:"no-access-text"},[_vm._v(" "+_vm._s(_vm.$t('storyline.thisStorylineDoesNotShared'))+" ")]),_c('div',[_c('v-btn',{staticClass:"primary-button mb-3",attrs:{"rounded":"","color":"#21a7e0","height":"48","disabled":_vm.requestAccessBtnText === 'storyline.requestSent',"loading":_vm.accessRequestSending},on:{"click":_vm.handleNoAccess}},[_vm._v(" "+_vm._s(_vm.$t(_vm.requestAccessBtnText))+" ")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":"/assets/img/outline-no-data.png","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":"/assets/img/outline-no-access.png","alt":""}})])}]

export { render, staticRenderFns }