var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.point && Object.keys(_vm.point).length > 0)?_c('div',{staticClass:"bestPractiseDraggable"},[(_vm.point.isAdded)?_c('div',{staticClass:"editingExistingBsContainer",style:({ 'flex-direction': ("" + (_vm.isInRightHaf ? 'row-reverse' : 'row')) })},[_c('v-tooltip',{attrs:{"left":_vm.movingInRightHalf,"right":!_vm.movingInRightHalf},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"numberContainer",style:({
            left: ((_vm.point.x) + "%"),
            top: ((_vm.point.y) + "%"),
            position: 'absolute',
          }),on:{"mousedown":_vm.handleMouseDown,"mouseover":_vm.handleMouseOver}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.point.name.replace(/#/g, ''))+" ")])]}}],null,false,1373071686)},[_c('span',[_vm._v(_vm._s(_vm.point.tooltip))])]),(
        !_vm.isDragging &&
        _vm.getBestPractiseSelected &&
        _vm.getBestPractiseSelected.name === _vm.point.name
      )?_c('div',{staticClass:"editorOrDraggableOptions",style:({
        left: ((_vm.textBoxPos.textBoxAlignmentLeft) + "% !important"),
        top: ((_vm.textBoxPos.textBoxAlignmentTop) + "% !important"),
        position: 'absolute',
        width: '222px',
        'z-index': '2',
      }),on:{"mousedown":function (e) { return e.stopPropagation(); }}},[(!_vm.editing)?_c('div',{staticClass:"editorTextAndActions"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.point.tooltip))]),_c('div',{staticClass:"actions"},[_c('v-icon',{on:{"click":_vm.handleEnterEditingMode}},[_vm._v(_vm._s('mdi-pencil'))]),_c('v-icon',{on:{"click":_vm.handleOpenBestPractiseRemoveDialog}},[_vm._v(_vm._s('mdi-delete'))]),_c('v-icon',{attrs:{"small":""},on:{"click":_vm.handleCloseBestPractiseEditor}},[_vm._v(_vm._s('mdi-close'))])],1)]):_c('div',{staticClass:"bsUpdater"},[_c('v-text-field',{attrs:{"placeholder":_vm.$t('userUploadPrezentation.bestpracticeText'),"autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (e) { return _vm.handleUpdateBestPractise(e, 'update'); }).apply(null, arguments)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"disabled":_vm.updateText === ''},on:{"click":function (e) { return _vm.handleUpdateBestPractise(e, 'update'); }}},[_vm._v(" "+_vm._s('mdi-play')+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":_vm.handleRemoveEditingMode}},[_vm._v(" "+_vm._s('mdi-close')+" ")])]},proxy:true}],null,false,1623093554),model:{value:(_vm.updateText),callback:function ($$v) {_vm.updateText=$$v},expression:"updateText"}})],1)]):_vm._e()],1):_c('div',{staticClass:"toBeAddedDraggable",style:({
      'flex-direction': ("" + (_vm.movingInRightHalf ? 'row-reverse' : 'row')),
    })},[_c('div',{staticClass:"bpNumber",style:({
        left: ((_vm.point.x) + "%"),
        top: ((_vm.point.y) + "%"),
        position: 'absolute',
        'z-index': '2',
      }),on:{"mousedown":_vm.handleMouseDown}},[_vm._v(" "+_vm._s(_vm.point.name.replace(/#/g, ''))+" ")]),(!_vm.isDragging)?_c('div',{staticClass:"bestPractiseTextBox",style:({
        left: ((_vm.textBoxPos.textBoxAlignmentLeft) + "%"),
        top: ((_vm.textBoxPos.textBoxAlignmentTop) + "%"),
        position: 'absolute',
        width: '225px',
      }),on:{"mousedown":function (e) { return e.stopPropagation(); }}},[_c('v-text-field',{attrs:{"placeholder":_vm.$t('userUploadPrezentation.bestpracticeText'),"autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (e) { return _vm.handleUpdateBestPractise(e, 'add'); }).apply(null, arguments)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"disabled":_vm.bestPractiseText === ''},on:{"click":function (e) { return _vm.handleUpdateBestPractise(e, 'add'); }}},[_vm._v(" "+_vm._s('mdi-play')+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":_vm.removeCurrentBestPractiseAdder}},[_vm._v(_vm._s('mdi-close'))])]},proxy:true}],null,false,525542585),model:{value:(_vm.bestPractiseText),callback:function ($$v) {_vm.bestPractiseText=$$v},expression:"bestPractiseText"}})],1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }