<template>
  <div :class="type === 'modal' ? 'main-wrapper-modal' : 'main-wrapper'">
    <v-btn v-if="type === 'modal'" class="close-btn" icon @click="handleClose">
      <v-icon dark>mdi-close</v-icon>
    </v-btn>
    <div
      :class="type === 'modal' ? 'spinner-wrapper-on-modal' : 'spinner-wrapper'"
      v-if="isLoading"
    >
      <v-progress-circular
        indeterminate
        :color="type === 'modal' ? '#21a7e0' : 'primary'"
        :size="35"
      />
    </div>
    <div
      class="sharing-modal-main-wrapper"
      :style="
        type === 'modal'
          ? {}
          : { height: `${mainSlideHeight}px`, overflow: 'auto', padding: '0px' }
      "
      v-if="!isLoading"
    >
      <div class="generalTemplateShare">
        <div class="step1">
          <div class="sharing-modal-container">
            <div
              class="sharing-modal__title font-weight-bold"
              v-if="type === 'modal'"
            >
              {{ $t('profile.shareTemplateAccess.shareWithColleague') }}
            </div>
            <v-radio-group v-model="shareOptionType" style="height: 67px">
              <v-radio
                color="#20a7e0"
                name="shareType"
                :label="$t('prezentationShareStatus.private')"
                value="me"
              />
              <v-radio
                color="#20a7e0"
                name="shareType"
                :label="$t('profile.shareTemplateAccess.shareWith')"
                value="share"
                style="padding-top: 3px"
              />
            </v-radio-group>
            <div class="share-container">
              <div class="radio-button_item">
                <v-checkbox
                  v-model="companyCheckPermission"
                  :label="$t('profile.shareTemplateAccess.myCompany')"
                  color="#20a7e0"
                  hide-details
                  value="company"
                  :disabled="shareOptionType !== 'share'"
                />
                <v-select
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  color="rgba(0, 0, 0, 0.6)"
                  v-show="companyCheckPermission === 'company'"
                  item-text="text"
                  item-value="value"
                  :items="shareOptions"
                  v-model="companyPermission"
                  class="share-option-select_dropdown"
                >
                  <template v-slot:selection="{ item }">
                    <div>
                      <span>{{ item.text }}</span>
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div class="d-flex">
                      <div class="share-select-item__selected">
                        <v-icon
                          v-if="companyPermission === item.text"
                          color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column share-select-item"
                      v-if="item.text === 'Restricted'"
                    >
                      <NewFeatureBadge
                        :module-name="'restricted'"
                        :feature-id="'restricted_id'"
                        :offsetX="'140'"
                        :offsetY="'15'"
                        :size="'medium'"
                      >
                        <div>
                          {{ item.text }}
                        </div>
                      </NewFeatureBadge>
                      <div
                        class="text-caption share-select-item__description"
                        v-if="item.description"
                      >
                        {{ item.description }}
                      </div>
                    </div>
                    <div class="d-flex flex-column share-select-item" v-else>
                      <div>
                        {{ item.text }}
                      </div>
                      <div
                        class="text-caption share-select-item__description"
                        v-if="item.description"
                      >
                        {{ item.description }}
                      </div>
                    </div>
                  </template></v-select
                >
              </div>
              <div class="radio-button_item">
                <v-checkbox
                  v-model="teamCheckPermission"
                  :label="$t('profile.shareTemplateAccess.myTeam')"
                  color="#20a7e0"
                  hide-details
                  value="team"
                  :disabled="shareOptionType !== 'share'"
                />
                <v-select
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  color="rgba(0, 0, 0, 0.6)"
                  v-show="teamCheckPermission === 'team'"
                  item-text="text"
                  item-value="value"
                  :items="shareOptions"
                  v-model="teamPermission"
                  class="share-option-select_dropdown"
                >
                  <template v-slot:selection="{ item }">
                    <div>
                      <span>{{ item.text }}</span>
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div class="d-flex">
                      <div class="share-select-item__selected">
                        <v-icon
                          v-if="teamPermission === item.text"
                          color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column share-select-item"
                      v-if="item.text === 'Restricted'"
                    >
                      <NewFeatureBadge
                        :module-name="'restricted'"
                        :feature-id="'restricted_id'"
                        :offsetX="'140'"
                        :offsetY="'15'"
                        :size="'medium'"
                      >
                        <div>
                          {{ item.text }}
                        </div>
                      </NewFeatureBadge>
                      <div
                        class="text-caption share-select-item__description"
                        v-if="item.description"
                      >
                        {{ item.description }}
                      </div>
                    </div>
                    <div class="d-flex flex-column share-select-item" v-else>
                      <div>
                        {{ item.text }}
                      </div>
                      <div
                        class="text-caption share-select-item__description"
                        v-if="item.description"
                      >
                        {{ item.description }}
                      </div>
                    </div>
                  </template>
                </v-select>
              </div>
              <v-checkbox
                v-model="userCheckPermission"
                :label="$t('profile.shareTemplateAccess.selectColleague')"
                color="#20a7e0"
                hide-details
                value="user"
                :disabled="shareOptionType !== 'share'"
              />
              <div
                v-if="userCheckPermission === 'user'"
                :label="$t('profile.shareTemplateAccess.selectColleague')"
              >
                <UserSelector
                  v-model="usersWithPermissions"
                  filter="insideCompanyUsers"
                  class="select-colleagues-autocomplete"
                  :disabled="shareOptionType !== 'share'"
                  @change="onSelectedUsersChange($event)"
                >
                  <template v-slot:user-actions="{ item }">
                    <v-list-item-action
                      :class="
                        currentUser.user.id === item.id
                          ? 'currentUserRemove'
                          : 'colleaguesRemove'
                      "
                    >
                      <v-select
                        :menu-props="{ bottom: true, offsetY: true }"
                        color="rgba(0, 0, 0, 0.6)"
                        dense
                        item-text="text"
                        item-value="value"
                        v-model="item.permissionLevel"
                        :items="userShareOptions"
                        name="selectItem"
                        class="share-option-select_dropdown text-caption"
                        :disabled="currentUser.user.id === item.id"
                      >
                        <template v-slot:selection="selectSlotItem">
                          {{ selectSlotItem.item.text }}
                        </template>
                        <template v-slot:item="selectSlotItem">
                          <div lass="d-flex">
                            <div class="share-select-item__selected">
                              <v-icon
                                v-if="
                                  item.permissionLevel ===
                                  selectSlotItem.item.text
                                "
                                color="primary"
                              >
                                mdi-check
                              </v-icon>
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column share-select-item"
                            v-if="selectSlotItem.item.text === 'Restricted'"
                          >
                            <NewFeatureBadge
                              :module-name="'restricted'"
                              :feature-id="'restricted_id'"
                              :offsetX="'140'"
                              :offsetY="'15'"
                              :size="'medium'"
                            >
                              <div>
                                {{ selectSlotItem.item.text }}
                              </div>
                            </NewFeatureBadge>
                            <div
                              class="text-caption share-select-item__description"
                              v-if="selectSlotItem.item.description"
                            >
                              {{ selectSlotItem.item.description }}
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column share-select-item"
                            v-else
                          >
                            <div>
                              {{ selectSlotItem.item.text }}
                            </div>
                            <div
                              class="text-caption share-select-item__description"
                              v-if="selectSlotItem.item.description"
                            >
                              {{ selectSlotItem.item.description }}
                            </div>
                          </div>
                        </template>
                      </v-select>
                    </v-list-item-action>
                  </template>
                </UserSelector>
              </div>
            </div>
          </div>
          <div class="actions" v-if="type === 'modal'">
            <v-btn
              class="primary-button"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              @click="updateTemplateShareInfo"
              :loading="updating"
              :disabled="disableUpdateBtn"
            >
              {{ $t('common.update') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import UserSelector from '../../common/UserSelector/UserSelector.vue';
import {
  getSharePermissionsForSlide,
  updateSharePermissionsForSlide,
} from '../../../utils/api-helper';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents';
import {
  NA,
  TD_ISFAVORITED,
  TD_ISVIEWER,
  TD_ISEDITOR,
  TD_PLACEMENT,
  TD_THUMBNAIL_VIEW,
  TD_ISRESTRICTED,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';

export default {
  name: 'ShareSlidePermissions',
  props: {
    slideOpened: {
      type: Object,
      default: () => ({}),
    },
    template: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'modal',
    },
    getDataForSharePermissionsUpdate: {
      type: Function,
      default: () => {},
    },
    mainSlideHeight: {
      type: Number,
      default: 0,
    },
    getAcceptButtonState: {
      type: Function,
      default: () => {},
    },
    updateSlide: {
      type: Function,
      default: () => {},
    },
  },
  components: { UserSelector, NewFeatureBadge },
  data() {
    return {
      isLoading: true,
      companyPermission: 'Viewer',
      companyPermissionOnLoad: 'Viewer',
      teamPermission: 'Viewer',
      teamPermissionOnLoad: 'Viewer',
      shareOptionType: '',
      shareOptionTypeOnLoad: '',
      companyCheckPermission: null,
      companyCheckPermissionOnLoad: null,
      teamCheckPermission: null,
      teamCheckPermissionOnLoad: null,
      userCheckPermission: null,
      userCheckPermissionOnLoad: null,
      shareOptions: [
        {
          text: 'Restricted',
          description:
            'Your colleagues can view and download a pdf copy of the slide',
        },
        {
          text: 'Viewer',
          description: 'Your colleagues can view and download the slide.',
        },
        {
          text: 'Editor',
          description:
            'Your colleagues can view the slide, edit slide details and download the slide.',
        },
      ],
      userShareOptions: [
        {
          text: 'Restricted',
          description:
            'Your colleagues can view and download a pdf copy of the slide',
        },
        {
          text: 'Viewer',
          description: 'Your colleagues can view and download the slide.',
        },
        {
          text: 'Editor',
          description:
            'Your colleagues can view the slide, edit slide details and download the slide.',
        },
      ],
      usersWithPermissions: [],
      usersWithPermissionsOnLoad: [],
      privatePermission: [],
      privatePermissionOnLoad: [],
      isDataChanged: false,
      updating: false,
    };
  },
  async beforeMount() {
    this.isLoading = true;
    await getSharePermissionsForSlide(this.slideOpened.unique_id)
      .then((resp) => {
        const sharedEntities = resp?.data?.slideACL;
        if (sharedEntities.length <= 0) {
          this.privatePermission.push('Private');
          this.privatePermissionOnLoad.push('Private');
          this.shareOptionType = 'me';
          this.shareOptionTypeOnLoad = 'me';
          this.userCheckPermission = null;
          this.userCheckPermissionOnLoad = null;
          this.teamCheckPermission = null;
          this.teamCheckPermissionOnLoad = null;
          this.companyCheckPermission = null;
          this.companyCheckPermissionOnLoad = null;
        } else {
          this.shareOptionType = 'share';
          this.shareOptionTypeOnLoad = 'share';

          const teamEntities = sharedEntities.filter(
            (ents) =>
              ents.sharedEntityType === 'team' &&
              ents.sharedEntityID === this.currentUser.team.id,
          );
          const companyEntity = sharedEntities.filter(
            (ents) =>
              ents.sharedEntityType === 'company' &&
              ents.sharedEntityID === this.currentUser.company.id,
          );
          const usersEntities = sharedEntities.filter(
            (ents) => ents.sharedEntityType === 'user',
          );

          if (teamEntities.length > 0) {
            this.teamCheckPermission = 'team';
            this.teamCheckPermissionOnLoad = 'team';
            this.teamPermission = teamEntities[0].permissionLevel;
            this.teamPermissionOnLoad = teamEntities[0].permissionLevel;
          } else {
            this.teamCheckPermission = null;
            this.teamCheckPermissionOnLoad = null;
            this.teamPermission = 'Viewer';
            this.teamPermissionOnLoad = 'Viewer';
          }

          if (companyEntity.length > 0) {
            this.companyCheckPermission = 'company';
            this.companyCheckPermissionOnLoad = 'company';
            this.companyPermission = companyEntity[0].permissionLevel;
            this.companyPermissionOnLoad = companyEntity[0].permissionLevel;
          } else {
            this.companyCheckPermission = null;
            this.companyCheckPermissionOnLoad = null;
            this.companyPermission = 'Viewer';
            this.companyPermissionOnLoad = 'Viewer';
          }

          if (usersEntities.length > 0) {
            this.userCheckPermission = 'user';
            this.userCheckPermissionOnLoad = 'user';
            usersEntities.forEach((user) => {
              this.usersWithPermissions.push({
                ...user,
                isActive: true, // making users enabled by default
                id: user.sharedEntityID,
              });
              this.usersWithPermissionsOnLoad.push({
                ...user,
                id: user.sharedEntityID,
              });
            });
          }
        }
      })
      .catch((err) => console.log(err));
    this.getAcceptButtonState(true);
    this.isLoading = false;
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('slidesStore', ['getFavoriteSlides']),

    disableUpdateBtn() {
      if (
        !this.isDataChanged ||
        (this.shareOptionType === 'share' &&
          !this.companyCheckPermission &&
          !this.teamCheckPermission &&
          !this.userCheckPermission) ||
        (this.shareOptionType === 'share' &&
          !this.companyCheckPermission &&
          !this.teamCheckPermission &&
          this.userCheckPermission === 'user' &&
          this.usersWithPermissions.length <= 0)
      ) {
        return true;
      }
      return false;
    },

    disableAccept() {
      if (
        !this.isDataChanged ||
        (this.shareOptionType === 'share' &&
          !this.companyCheckPermission &&
          !this.teamCheckPermission &&
          !this.userCheckPermission) ||
        (this.shareOptionType === 'share' &&
          !this.companyCheckPermission &&
          !this.teamCheckPermission &&
          this.userCheckPermission === 'user' &&
          this.usersWithPermissions.length <= 0)
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    disableAccept(val) {
      this.getAcceptButtonState(val);
    },

    shareOptionType(val) {
      if (val && val !== '' && val === 'me') {
        this.companyCheckPermission = null;
        this.teamCheckPermission = null;
        this.userCheckPermission = null;
        this.usersWithPermissions = [];
      }
    },

    $data: {
      handler() {
        const values = {
          shareOptionType: this.shareOptionType,
          teamCheckPermission: this.teamCheckPermission,
          teamPermission: this.teamPermission,
          companyCheckPermission: this.companyCheckPermission,
          companyPermission: this.companyPermission,
          userCheckPermission: this.userCheckPermission,
          usersWithPermissions: this.usersWithPermissions,
          privatePermission: this.privatePermission,
        };
        const loadedValues = {
          shareOptionType: this.shareOptionTypeOnLoad,
          teamCheckPermission: this.teamCheckPermissionOnLoad,
          teamPermission: this.teamPermissionOnLoad,
          companyCheckPermission: this.companyCheckPermissionOnLoad,
          companyPermission: this.companyPermissionOnLoad,
          userCheckPermission: this.userCheckPermissionOnLoad,
          usersWithPermissions: this.usersWithPermissionsOnLoad,
          privatePermission: this.privatePermissionOnLoad,
        };
        this.getDataForSharePermissionsUpdate(values);
        if (_.isEqual(values, loadedValues)) {
          this.isDataChanged = false;
          this.setIsSlideACLDirty(false);
        } else {
          this.isDataChanged = true;
          this.setIsSlideACLDirty(true);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('slidesStore', ['setIsSlideACLDirty']),

    onSelectedUsersChange(users) {
      this.usersWithPermissions = users.map((user) => {
        if (!user.permissionLevel) {
          user.permissionLevel = this.userShareOptions[1].text;
        }
        return user;
      });
    },

    async updateTemplateShareInfo() {
      let updatePayload = {};
      const teamEntity = [];
      const companyEntity = [];
      let usersEntities = [];
      if (this.shareOptionType === 'me') {
        updatePayload = {
          sharedEntities: [],
        };
      } else if (this.shareOptionType === 'share') {
        if (this.teamCheckPermission === 'team') {
          teamEntity.push({
            sharedEntityID: this.currentUser.team.id,
            sharedEntityType: 'team',
            permissionLevel: this.teamPermission,
          });
        }
        if (this.companyCheckPermission === 'company') {
          companyEntity.push({
            sharedEntityID: this.currentUser.company.id,
            sharedEntityType: 'company',
            permissionLevel: this.companyPermission,
          });
        }
        if (this.userCheckPermission === 'user') {
          usersEntities = [
            ...this.usersWithPermissions.map((usr) => ({
              sharedEntityID: usr.id,
              permissionLevel: usr.permissionLevel,
              sharedEntityType: 'user',
            })),
          ];
        }
        updatePayload = {
          sharedEntities: [...usersEntities, ...teamEntity, ...companyEntity],
        };
      }
      this.updating = true;
      const sharedEntitiesData = this.getSharedEntitiesData(
        updatePayload?.sharedEntities,
      );
      trackSlideEvents.slidesShareUpdate(this.slideOpened, this.currentUser, {
        [TD_ISFAVORITED]: this.getFavoriteSlides.includes(
          this.result?.unique_id,
        )
          ? '1'
          : NA,
        [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
        ...sharedEntitiesData,
      });
      await updateSharePermissionsForSlide({
        ...updatePayload,
        slideID: this.slideOpened.unique_id,
        type: this.slideOpened.prefs.source,
      })
        .then(() => {
          this.handleClose();
          this.updateSlide(updatePayload.sharedEntities.length > 0);
        })
        .catch((err) => console.log(err));
      this.updating = false;
    },
    getSharedEntitiesData(sharedEntities) {
      const sharedEntitiesTrackingData = {};
      if (!sharedEntities.length) {
        return sharedEntitiesTrackingData;
      }

      const viewers = sharedEntities.filter(
        (sharedEntity) => sharedEntity.permissionLevel === 'Viewer',
      );
      const isViewerList = this.getEntityList(viewers);
      if (isViewerList) {
        sharedEntitiesTrackingData[TD_ISVIEWER] = isViewerList;
      }
      const editors = sharedEntities.filter(
        (sharedEntity) => sharedEntity.permissionLevel === 'Editor',
      );
      const isEditorsList = this.getEntityList(editors);
      if (isEditorsList) {
        sharedEntitiesTrackingData[TD_ISEDITOR] = isEditorsList;
      }
      const restricted = sharedEntities.filter(
        (sharedEntity) => sharedEntity.permissionLevel === 'Restricted',
      );
      const isRestrictedList = this.getEntityList(restricted);
      if (isRestrictedList) {
        sharedEntitiesTrackingData[TD_ISRESTRICTED] = isRestrictedList;
      }
      return sharedEntitiesTrackingData;
    },

    getEntityList(entities) {
      let eList = '';
      entities.forEach((sEntity) => {
        const entityType = sEntity.sharedEntityType;
        console.log('entityType', entityType);
        if (entityType === 'company') {
          eList += 'My Company$';
        }
        if (entityType === 'team') {
          eList += 'My Team$';
        }
        if (entityType === 'user') {
          eList += `${sEntity.sharedEntityID}$`;
        }
      });
      return eList;
    },
    handleClose() {
      this.$modal.hide('ShareSlidePermissions');
    },
  },
  destroyed() {
    this.setIsSlideACLDirty(false);
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.main-wrapper,
.main-wrapper-modal {
  height: 100%;
  overflow: auto;
}
.main-wrapper {
  padding: 8px !important;
}
.main-wrapper-modal {
  max-height: 85vh;
  min-height: 230px;
  overflow: auto;
}
.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
.radio-button_item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
}

.share-option-select_dropdown {
  max-width: 120px;
  width: 120px;
}
.sharing-modal-main-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  height: 100%;
  justify-content: space-between;
  padding: 2rem;
  width: 100%;
  .generalTemplateShare {
    width: 100%;
    .step1 {
      width: 100%;
      .sharing-modal-container {
        width: 100%;
        ::v-deep .v-input {
          padding: 0 !important;
          .v-input__control {
            .v-messages {
              display: none !important;
            }
            .v-input__slot {
              margin: 0 !important;
              .v-input--radio-group__input {
                .v-radio {
                  margin: 0 !important;
                  padding: 10px 0px 8px 10px;
                  .v-input--selection-controls__input {
                    margin: 0px 6px 0px 0px;
                  }
                  .v-label {
                    color: black !important;
                  }
                }
              }
            }
          }
        }
        .share-container {
          margin: 16px 4px 0px 25px;

          .radio-button_item {
            ::v-deep .v-input {
              margin: 0px 0px 8px 0px !important;
              margin-bottom: 8px;
              margin-top: 0;
              width: max-content;
              .v-input__control {
                .v-input__slot {
                  .v-label {
                    color: black !important;
                  }
                }
              }
            }
          }
          .v-input {
            margin: 0px 0px 16px 0px !important;
            ::v-deep .v-input__control {
              .v-input__slot {
                .v-label {
                  color: black !important;
                }
              }
            }
          }
        }
      }

      .currentUserRemove {
        margin-right: 24px !important;
        height: 20px !important;
      }
      .colleaguesRemove {
        height: 20px !important;
      }

      .sharing-modal__title {
        font-size: 1.5em;
        font-weight: 500;
        // .templateNameInBold {
        //   b {
        //     color: grey;
        //   }
        // }
      }
      .actions {
        width: 21%;
        margin: 0 auto;
      }
    }
  }
}
.spinner-wrapper {
  text-align: center;
  margin: 200px auto 0px auto;
}
.spinner-wrapper-on-modal {
  text-align: center;
  margin: 100px auto 0px auto;
}
.share-select-item {
  padding: 5px;
  max-width: 220px;

  .share-select-item__description {
    color: gray;
    padding-top: 5px;
  }
}
.share-select-item__selected {
  width: 30px;
}
.select-colleagues-autocomplete {
  margin-bottom: 0 !important;
  padding: 0 14px 5px 14px;
  width: 100% !important;
}
</style>
