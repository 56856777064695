<template>
  <div class="group-insights-member-container" :class="{ readOnly }">
    <div class="avatars">
      <template v-if="audience">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <img
                v-if="profileImageUrl"
                class="logo-profile"
                :src="profileImageUrl"
              />
              <span v-else class="logo-initial">
                {{ audienceInitials }}
              </span>
            </span>
          </template>
          <span>{{ audience.fullName }}</span>
        </v-tooltip>
        <img
          :src="fingerprintData.logo"
          alt="Fingerprint-Logo"
          class="logo-fingerprint"
        />
      </template>
      <template v-else>
        <div class="empty-circle">?</div>
        <div class="empty-circle">?</div>
      </template>
    </div>
    <AudienceSelectorTypesense
      class="aud"
      v-if="!readOnly"
      v-model="audience"
      :isTextFieldStyle="true"
      :showSearchIcon="false"
      :placeholder="$t('fingerprint.compareFingAud.searchByNameOrEmail')"
      :maxWidth="300"
      origin="zentrends"
      :extraFields="['personalPreference']"
      @add="handleAddAudience($event)"
      @change="handleAudienceChange($event)"
    />
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <div class="name" v-bind="attrs" v-on="on">
          {{ audience ? audience.fullName : '' }}
        </div>
      </template>
      <span>{{ audience ? audience.fullName : '' }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { fpTypes } from '@/mock/fingerprint';
import AudienceSelectorTypesense from '../../common/AudienceSelectorTypesense.vue';
import { getInitials } from '../../../utils/common';
import { getFileURL } from '@/utils/calyrex';

export default {
  name: 'GroupInsightMember',
  props: {
    value: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: { AudienceSelectorTypesense },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val !== this.audience && !this.readOnly) {
          this.audience = { defaultAudience: `${val.email}-${val.type}` };
        } else {
          this.audience = val;
        }
      },
    },
    audience: {
      immediate: true,
      handler(value) {
        if (value?.profileImage) {
          if (value.profileImage.startsWith('/assets/img/')) {
            this.profileImageUrl = value?.profileImage;
          } else {
            getFileURL(
              null,
              `public/${value.profileImage}`,
              null,
              'storage',
            ).then((url) => {
              this.profileImageUrl = url;
            });
          }
        } else {
          this.profileImageUrl = null;
        }
      },
    },
  },
  data() {
    return {
      audience: null,
      profileImageUrl: null,
    };
  },
  emits: ['addAudience'],
  computed: {
    fingerprintData() {
      const fpData = fpTypes.find(
        (fprint) => fprint.title === this.audience?.fingerPrint?.toLowerCase(),
      );
      if (!fpData) {
        return fpTypes?.[0];
      }
      return fpData;
    },
    isAudienceLoading() {
      return this.audience && this.audience.defaultAudience;
    },
    audienceInitials() {
      if (this.isAudienceLoading) {
        return '';
      }
      const groupName = this.audience.firstName.replace(/\s+/g, ' ').split(' ');
      const isGroup = this.audience?.lastName?.toLowerCase() === 'group';
      if (isGroup && groupName.length === 1) {
        return this.audience.firstName[0].toUpperCase();
      }
      if (isGroup && groupName.length >= 2) {
        return `${groupName[0][0].toUpperCase()}${groupName[1][0].toUpperCase()}`;
      }
      return getInitials(this.audience.firstName, this.audience.lastName);
    },
  },
  methods: {
    handleAudienceChange(val) {
      return this.$emit('input', val);
    },
    handleAddAudience(value) {
      if (value && !value.fingerPrint) {
        return this.$emit('openRemindModal', value);
      }
      return this.$emit('addAudience', value);
    },
  },
};
</script>
<style scoped lang="scss">
.group-insights-member-container {
  &.readOnly {
    gap: 5px;
  }
  display: flex;
  width: 100%;
  gap: 24px;
  .avatars {
    position: relative;
    display: flex;
    /* margin-bottom: 12px; */
    height: 48px;
    width: 88px;

    .empty-circle {
      position: absolute;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 21.3333px;
      line-height: 26px;
    }

    .empty-circle:first-child {
      border: 0.72px dashed #b6b6b6;
      color: #b6b6b6;
    }

    .empty-circle:last-child {
      border: 0.72px dashed #21a7e0;
      color: #21a7e0;
      left: 40%;
      background: white;
    }

    .logo-profile {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      cursor: default;
    }

    .logo-initial {
      align-items: center;
      background: #21a7e0;
      border-radius: 100%;
      color: white;
      display: flex;
      font-size: 21.333px;
      line-height: 26px;
      height: 48px;
      justify-content: center;
      width: 48px;
      padding-right: 10px;
      cursor: default;
    }
    .logo-fingerprint {
      background: white;
      border-radius: 50%;
      height: 48px;
      margin-left: -20px;
    }
  }

  .aud {
    max-width: 300px;
    width: calc(100% - 112px);
    height: 48px;

    ::v-deep .style-text-field .audience-select-dropdown {
      padding-top: 0;
    }
  }

  .name {
    width: calc(100% - 98px);
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto 0;
  }
}
</style>
